import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client';

import {
  QueryValidateMerchantCredentialsArgs,
  ResultPayload,
  QueryTestMerchantProductsArgs,
  TestMerchantProductPayload,
  QueryMerchantExportFeedDownloadUrlArgs,
  MerchantExportFeedDownloadUrlPayload,
} from '@common/types/GraphqlTypes.d';

const VALIDATE_CREDENTIALS = gql`
  query validateMerchantCredentials($id: String!) {
    validateMerchantCredentials(id: $id) {
      result
    }
  }
`;

const TEST_PRODUCTS = gql`
  query testMerchantProducts($id: String!) {
    testMerchantProducts(id: $id) {
      message
      errors
    }
  }
`;

const EXPORT_DOWNLOAD_FEED_URL = gql`
  query merchantExportFeedDownloadUrl($feedName: String!) {
    merchantExportFeedDownloadUrl(feedName: $feedName) {
      url
    }
  }
`;

/**
 * Validates merchant credentials.
 * @param id - Id of merchant.
 * @param options - Lazy query options.
 * @returns - Function to call to validate credentials.
 */
export const useValidateMerchantCredentials = (
  id: string,
  options: LazyQueryHookOptions<
    { validateMerchantCredentials: ResultPayload },
    QueryValidateMerchantCredentialsArgs
  >,
) => {
  const [validateCredentials] = useLazyQuery<
    { validateMerchantCredentials: ResultPayload },
    QueryValidateMerchantCredentialsArgs
  >(VALIDATE_CREDENTIALS, {
    fetchPolicy: 'no-cache',
    ...options,
    variables: {
      id,
    },
  });

  return { getData: validateCredentials };
};

/**
 * Tests merchant products.
 * @param id - Id of merchant.
 * @param options - Lazy query options.
 * @returns - Function to call to test products.
 */
export const useTestMerchantProducts = (
  id: string,
  options: LazyQueryHookOptions<
    { testMerchantProducts: TestMerchantProductPayload },
    QueryTestMerchantProductsArgs
  >,
) => {
  const [validateCredentials] = useLazyQuery<
    { testMerchantProducts: TestMerchantProductPayload },
    QueryTestMerchantProductsArgs
  >(TEST_PRODUCTS, {
    fetchPolicy: 'no-cache',
    ...options,
    variables: {
      id,
    },
  });

  return { getData: validateCredentials };
};

/**
 * Return URL for downloading exported merchant feed.
 * @param feedName - Feed name - used as file.
 * @param options - Lazy query options.
 */
export const useGetExportFeedUrl = (
  feedName: string,
  options: LazyQueryHookOptions<
    { merchantExportFeedDownloadUrl: MerchantExportFeedDownloadUrlPayload },
    QueryMerchantExportFeedDownloadUrlArgs
  >,
) => {
  const [getFeedDownloadUrl] = useLazyQuery<
    { merchantExportFeedDownloadUrl: MerchantExportFeedDownloadUrlPayload },
    QueryMerchantExportFeedDownloadUrlArgs
  >(EXPORT_DOWNLOAD_FEED_URL, {
    fetchPolicy: 'no-cache',
    ...options,
    variables: {
      feedName,
    },
  });

  return { getData: getFeedDownloadUrl };
};
