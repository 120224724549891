import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { MERCHANT_TAX_TYPES, MERCHANT_DEFAULTS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { taxes: inputField } = MERCHANT_FIELDS;

export const TaxesInformation = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Taxes">
    <Stack spacing={2}>
      <ControlledSelect
        id={inputField.type}
        isLoading={isLoading}
        label="Type"
        options={MERCHANT_TAX_TYPES}
        defaultValue={MERCHANT_DEFAULTS.taxType}
      />
      <TextField
        id={inputField.avataxCode}
        isLoading={isLoading}
        label="Avatax code"
        placeholder="Unique Avatax code"
        tooltip="Avatax will only be used when shipping from a US merchant"
        useHook
      />

      <Switch id={inputField.areTaxesIncluded} label="Taxes incl. in price" useHook />
      <Switch id={inputField.hasNexusEnabled} label="Should use Avatax for orders to US" useHook />
    </Stack>
  </Card>
);
