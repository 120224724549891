import { DropDownButton } from '@components/Button/Dropdown';
import { Launch, ArrowDropDown } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import React from 'react';

import { formatUrl } from '@utils/link';

import { MarkVariantUnavailableItem } from './menuItems/HandleProductAvailability';
import { OpenProductInProductUI } from './menuItems/ViewProduct';
import { useStyles } from './styles';

const getExternalUrl = (productURL?: string | null) => {
  if (!productURL) {
    return;
  }

  const url = formatUrl(productURL);

  if (url) {
    return url;
  }
};

const ExternalLink = ({
  url,
  isArchived,
  className,
}: {
  url: string;
  isArchived: boolean;
  className?: string;
}) => (
  <a className={className} href={url} rel="noreferrer" target="_blank">
    <Launch color={isArchived ? 'disabled' : 'inherit'} fontSize="inherit" />
  </a>
);

/**
 * Renders an external link and a menu button.
 * @param props.isArchived - Whether the product is archived.
 * @param props.productURL - The product URL.
 * @param props.productId - The product ID.
 * @param props.showMenu - Whether to show the menu next to the link.
 */
export const LinkMenu = ({
  isArchived,
  productURL,
  productPublicId,
  productVariantId,
  showMenu = true,
}: {
  isArchived: boolean;
  productURL?: string | null;
  productPublicId: string;
  productVariantId: string;
  showMenu?: boolean;
}) => {
  const classes = useStyles();
  const externalUrl = isArchived ? undefined : getExternalUrl(productURL);
  const menuItems = (
    <>
      <MarkVariantUnavailableItem
        productPublicId={productPublicId}
        productVariantId={productVariantId}
      />
      <OpenProductInProductUI productPublicId={productPublicId} />
    </>
  );

  return (
    <Stack direction="row" justifyContent="flex-end">
      <span className={classes.linkOutContainer}>
        {externalUrl && (
          <ExternalLink url={externalUrl} isArchived={isArchived} className={classes.linkOut} />
        )}

        {showMenu && (
          <>
            <Divider orientation="vertical" className={classes.divider} />
            <DropDownButton
              className={classes.dropDownIcon}
              Icon={ArrowDropDown}
              menuItems={menuItems}
            />
          </>
        )}
      </span>
    </Stack>
  );
};
