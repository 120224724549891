import { CardTitle } from '@components/CardTitle';
import { useCustomerOrderHistory } from '@hooks/useCustomerOrderV2';
import Box from '@mui/material/Box';
import { NotFound } from '@pages/Misc';
import React from 'react';

type CustomerOrderHistoryProps = {
  orderId: string;
};

export const CustomerOrderHistory = ({ orderId }: CustomerOrderHistoryProps) => {
  // TODO: Discuss whether components need to have this within their components or parent should pass data along
  const { isLoading, history } = useCustomerOrderHistory(orderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Comments could not be found after API call
  if (!history && !isLoading) {
    // TODO: Render better "Comments could not be loaded. Please try again"
    return <NotFound />;
  }

  return (
    <Box>
      <CardTitle title="History" />
    </Box>
  );
};
