import { Card } from '@components/Card';
import { Divider, Stack } from '@mui/material';
import { Product } from '@pages/Order/components/Product';
import React from 'react';

import { CheckoutVariant, Refund as IRefund } from '@common/types/GraphqlTypes.d';

import { useStyles } from '../styles';
import { RefundDetails } from './refundDetails';

interface IOrderDetailsProps {
  refund: IRefund;
}

export const OrderRefund: React.FC<IOrderDetailsProps> = ({ refund }) => {
  const classes = useStyles();

  const displayRefundProduct = (variant: CheckoutVariant) => (
    <Product checkoutVariant={variant} isArchived={true} key={variant.variant.id} />
  );

  return (
    <Card className={classes.card}>
      <Stack spacing={2} divider={<Divider />}>
        {refund.checkoutVariants.map(displayRefundProduct)}
        <RefundDetails refund={refund} />
      </Stack>
    </Card>
  );
};
