import { useMutation, gql } from '@apollo/client';
import { MenuItem } from '@components/MenuItem';
import useRouter from '@hooks/useRouter';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

import { IUser } from '../../../fields';

type ActionsProps = {
  user: IUser;
};

export const DELETE_USER_MUTATION = gql`
  mutation adminDeleteUser($input: DeleteUserInput!) {
    adminDeleteUser(input: $input) {
      deletedCount
    }
  }
`;

export const DeleteUserMenuItem = ({ user }: ActionsProps) => {
  const { goto } = useRouter();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [removeUser] = useMutation(DELETE_USER_MUTATION, {
    errorPolicy: 'all',
    onCompleted: () => {
      goto.listUsers();
    },
  });

  const handleRemoveUser = () => {
    removeUser({ variables: { input: { id: user._id } } });
  };

  const openConfirmModal = () => {
    // TODO: Avoid modal from immediately being closed due to handleClose from
    setIsConfirmDialogOpen(true);
  };

  const name = `${user.profile?.firstName} ${user.profile?.lastName ?? ''}`;

  return (
    <>
      <MenuItem action={openConfirmModal} icon={DeleteIcon} label="Delete User" />

      <div>
        <Dialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`You are deleting ${name} (${user.email})?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting this user can not be reverted. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={handleRemoveUser}>
              Delete User
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
