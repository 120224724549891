import { useEffect, useState } from 'react';

import { isChromeExtensionInstalled } from '@lib/chromeExtension';

/**
 * Hook checking whether Chrome Aria extension is installed.
 */
export const useChromeExtensionInstalled = () => {
  const [isInstalled, setIsInstalled] = useState(false);

  const checkIfExtensionIsInstalled = async () => {
    try {
      await isChromeExtensionInstalled();

      setIsInstalled(true);
    } catch {
      setIsInstalled(false);
    }
  };

  useEffect(() => {
    checkIfExtensionIsInstalled();
  });

  return { isInstalled };
};
