import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    margin: 'auto',
    paddingBottom: theme.spacing(6),
    width: theme.spacing(32),
  },
  root: {
    alignItems: 'center',
    background: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  setNewPasswordForm: {
    minWidth: '500px',
  },
}));
