import { Card } from '@components/Card';
import { ICurrentUser, useCurrentUser } from '@hooks/useCurrentUser';
import React from 'react';

import { ProfileHeader } from './Header';
import { useStyles } from './styles';
import { UserImage } from './UserImage';
import { UserInfo } from './UserInfo';

type ProfileCardProps = {
  user: ICurrentUser;
};

export const ProfileCard = ({ user }: ProfileCardProps) => {
  const classes = useStyles();

  const name = `${user.profile.firstName} ${user.profile.lastName}`;

  return (
    <Card className={classes.profileCard} title="Profile information">
      <div className={classes.profileInfo}>
        <UserImage name={name} />
        <UserInfo user={user} />
      </div>
    </Card>
  );
};

export const ViewProfile = () => {
  const { currentUser, isLoading, error } = useCurrentUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    throw error;
  }

  if (!currentUser) {
    return <div>Sorry; we could not fetch your profile. Please log out and log in again.</div>;
  }

  return (
    <>
      <ProfileHeader />
      <ProfileCard user={currentUser} />
    </>
  );
};
