import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const DELETE_PRODUCT_COLLECTION_MUTATION = gql`
  mutation deleteProductCollection($id: String!) {
    deleteProductCollection(id: $id)
  }
`;

/**
 * Hook for deleting product collection.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useDeleteProductCollection = (options: MutationHookOptions = {}) => {
  const [deleteProductCollectionMutation, { error: apiError, loading: isLoading }] = useMutation(
    DELETE_PRODUCT_COLLECTION_MUTATION,
    options,
  );

  return { deleteProductCollectionMutation, isLoading, apiError };
};
