import { PrimaryButton } from '@components/Button/Primary';
import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { TextField } from '@components/Input/TextField';
import { Text } from '@components/Text';
import { Color } from '@theme/palette';
import { ShippingCarrier } from '@types';
import React from 'react';

import { Fields } from '../utils/form';

type TrackingInformationProps = {
  carrier: string;
  isLoading?: boolean;
  isDisabled: boolean;
  ctaText: string;
};

const carrierOptions = Object.values(ShippingCarrier).map((value) => ({ value, label: value }));

export const TrackingInformation = ({
  carrier,
  isLoading,
  isDisabled,
  ctaText,
}: TrackingInformationProps) => (
  <Card title="Tracking information">
    <ControlledSelect
      id={Fields.Carrier}
      label="Carrier"
      options={carrierOptions}
      placeholder="Select service"
      isLoading={isLoading}
    />
    {carrier === ShippingCarrier.Other && (
      <TextField label="Name" id={Fields.CarrierCustomName} useHook />
    )}
    <TextField label="Tracking number" id={Fields.TrackingNumber} useHook isLoading={isLoading} />
    {carrier === ShippingCarrier.Other && (
      <TextField label="Tracking URL" id={Fields.TrackingUrl} useHook />
    )}
    <PrimaryButton type="submit" disabled={isDisabled || isLoading}>
      {ctaText}
    </PrimaryButton>
    <Text color={Color.Gray1} fontSize="smaller">
      The merchant order will automatically be fulfilled once all items have submitted shipping
    </Text>
  </Card>
);
