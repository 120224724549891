import { Card } from '@components/Card';
import ErrorBoundary from '@components/ErrorBoundary';
import { Divider, Stack } from '@mui/material';
import { Product } from '@pages/Order/components/Product';
import { Color } from '@theme/palette';
import React from 'react';

import { MerchantOrder, ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { StatusTag } from '../../../../components/StatusTag';
import { QuantityButton } from './QuantityButton';
import { useStyles } from './styles';
import { getCheckoutVariants, getTaxForCheckoutVariant } from './utils';

interface IItemProps {
  getValues: () => ShortcutProduct[];
  setValues: (items: ShortcutProduct[]) => void;
  isLoading: boolean;
  merchantOrder?: Pick<
    MerchantOrder,
    'checkoutVariants' | 'ghostExternalOrders' | 'fulfillmentDetails'
  > & {
    summary?: MerchantOrder['summary'];
  };
  feedOrderId?: string;
  fulfillmentId?: string;
  showProductLink?: boolean;
  showPrices?: boolean;
}

export const Items = ({
  getValues,
  setValues,
  isLoading,
  merchantOrder,
  feedOrderId,
  fulfillmentId,
  showProductLink = true,
  showPrices = false,
}: IItemProps) => {
  const classes = useStyles();

  if (!isLoading && !merchantOrder) {
    return <ErrorBoundary children={'Empty Merchant order error'}></ErrorBoundary>;
  }

  const checkoutVariants = getCheckoutVariants(merchantOrder, feedOrderId, fulfillmentId);

  const title = (
    <StatusTag
      backgroundColor={Color.DarkNight}
      color={Color.White}
      text={checkoutVariants.length.toString()}
    >
      {checkoutVariants.length > 1 ? 'Items' : 'Item'}
    </StatusTag>
  );

  return (
    <Card title={title} className={classes.card} isLoading={isLoading}>
      <Stack spacing={2} divider={<Divider />}>
        {checkoutVariants.map((product, index) => {
          const tax = getTaxForCheckoutVariant(product, merchantOrder);

          return (
            <Stack key={index} direction="row" alignItems="center" justifyContent="space-between">
              <Product
                checkoutVariant={{ ...product, tax }}
                showMenu={false}
                showTax={showPrices}
                showPrice={showPrices}
                priceInUsd={merchantOrder?.summary?.currency === 'USD'}
                showQuantity={false}
                showLink={showProductLink}
                sx={{ flexGrow: 1 }}
              />

              <div className={classes.quantityButton}>
                <QuantityButton
                  getValues={getValues}
                  setValues={setValues}
                  index={index}
                  productId={product.productId}
                  startingQuantity={product.purchaseQuantity}
                  totalQuantity={product.purchaseQuantity}
                  variantId={product.variant.id ?? ''}
                />
              </div>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};
