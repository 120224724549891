import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID } from '@hooks/useMerchantOrders';

import { MERCHANT_ORDER_WITH_FULFILLMENT_QUERY } from './useMerchantOrderWithFulfillment';

const UPDATE_FULFILLMENT_MUTATION = gql`
  mutation updateFulfillmentDetail($input: UpdateFulfillmentDetailInput!) {
    updateFulfillmentDetail(input: $input) {
      result
    }
  }
`;

/**
 * Hook for updating fulfillment.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useUpdateFulfillment = (options: MutationHookOptions = {}) => {
  const [updateFulfillmentMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateFulfillmentDetail: { result: boolean };
  }>(UPDATE_FULFILLMENT_MUTATION, {
    refetchQueries: [MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID, MERCHANT_ORDER_WITH_FULFILLMENT_QUERY],
    ...options,
  });

  return { updateFulfillmentMutation, isLoading, apiError };
};
