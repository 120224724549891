import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CustomerOrder as ICustomerOrder } from '@common/types/GraphqlTypes.d';

export interface IOrderData {
  customerOrder: ICustomerOrder;
}

export interface ICustomerOrderVariables {
  _id: string;
}

export const CUSTOMER_ORDER_QUERY = gql`
  query ($_id: String!) {
    customerOrder(_id: $_id) {
      _id
      account {
        publicId
        name
        orderConfig {
          fulfillment {
            instructions
          }
        }
      }
      assignee {
        name
        userId
      }
      merchantOrderIds
      deliveryAddress {
        addressLineOne
        addressLineTwo
        city
        country
        name
        phoneNumber
        postalCode
        province
      }
      createdAt
      customer {
        name
        id
        email
        firstName
        lastName
      }
      orderNumber
      externalOrderId
      publicId
      fulfillmentStatus
      paymentStatus
      paymentMethod
      comments {
        _id
        comment
        escalation {
          status
          state
          productName
        }
        createdAt
        user {
          name
          userId
        }
      }
      externalPayment {
        currency
        amounts {
          shipping
          taxes
          goods
        }
      }
      summary {
        customerChargeTotal
      }
      breakdown {
        goods {
          subtotal
          subtotalTax
        }
        shipping {
          amount
          tax
        }
        tax {
          total
        }
      }
    }
  }
`;

export const useCustomerOrder = (
  customerOrderId: string,
  options: QueryHookOptions<IOrderData, ICustomerOrderVariables> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<IOrderData, ICustomerOrderVariables>(CUSTOMER_ORDER_QUERY, {
    ...options,
    variables: { _id: customerOrderId },
  });

  const customerOrder = data?.customerOrder;

  return { customerOrder, error, isLoading };
};
