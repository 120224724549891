import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID } from '@hooks/useMerchantOrders';

import { MERCHANT_ORDER_WITH_FULFILLMENT_QUERY } from './useMerchantOrderWithFulfillment';

const CREATE_FULFILLMENT_MUTATION = gql`
  mutation createFulfillmentDetail($input: CreateFulfillmentDetailInput!) {
    createFulfillmentDetail(input: $input) {
      result
    }
  }
`;

/**
 * Hook for creating fulfillment.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateFulfillment = (options: MutationHookOptions = {}) => {
  const [createFulfillmentMutation, { error: apiError, loading: isLoading }] = useMutation<{
    createFulfillmentDetail: { result: boolean };
  }>(CREATE_FULFILLMENT_MUTATION, {
    refetchQueries: [MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID, MERCHANT_ORDER_WITH_FULFILLMENT_QUERY],
    ...options,
  });

  return { createFulfillmentMutation, isLoading, apiError };
};
