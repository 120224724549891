import { gql, useQuery } from '@apollo/client';
import { withParams } from '@components/WithParams';
import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';

import { Items } from './Items';
import { useStyles } from './styles';

const CUSTOMER_ORDER_QUERY = gql`
  query ($_id: String!) {
    customerOrder(_id: $_id) {
      merchantOrderIds
    }
  }
`;

type IArchivedFeedOrdersParams = { _id: string };

const ArchivedFeedOrders = ({ _id }: IArchivedFeedOrdersParams) => {
  const classes = useStyles();

  const { loading: isLoading, data } = useQuery(CUSTOMER_ORDER_QUERY, {
    variables: { _id },
  });

  // TODO: Implement skeleton loader
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Typography variant="h5" className={classes.pageTitle}>
        Archived Feed Orders
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Items merchantOrderIds={data?.customerOrder?.merchantOrderIds} />
        </Grid>
      </Grid>
    </>
  );
};

export const ArchivedFeedOrdersPage = withParams<IArchivedFeedOrdersParams>(
  ['_id'],
  ArchivedFeedOrders,
);
