import { Text } from '@components/Text';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Color } from '@theme/palette';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const fileTypes = {
  csv: {
    'text/csv': ['.csv'],
  },
};

export type FileType = keyof typeof fileTypes;

type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  activeText?: string;
  inactiveText?: string;
  allowedFileTypes?: FileType[];
};

const Container = styled(Box)({
  border: `1px solid ${Color.Gray3}`,
  padding: 10,
  textAlign: 'center',
  cursor: 'pointer',
  flex: 1,
});

const FileText = ({ children }: { children: string }) => (
  <Text gutterBottom={false} fontSize={13}>
    {children}
  </Text>
);

export const Dropzone = ({
  onDrop,
  activeText = 'Drop the file(s) here ...',
  inactiveText = "Drag'n'drop file(s) here, or click to select files",
  allowedFileTypes,
}: DropzoneProps) => {
  const handleDrop = useCallback(onDrop, [onDrop]);
  const accept =
    allowedFileTypes?.reduce((acc, fileType) => ({ ...acc, ...fileTypes[fileType] }), {}) ?? {};
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop: handleDrop,
    accept,
    // TODO: add multiple files support
    multiple: false,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {acceptedFiles.map((file) => (
        <FileText key={file.name}>{file.name}</FileText>
      ))}
      {acceptedFiles.length === 0 && (
        <FileText>{isDragActive ? activeText : inactiveText}</FileText>
      )}
    </Container>
  );
};
