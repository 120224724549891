import { InputLabel, MenuItem, Select as MuiSelect, SelectProps, Skeleton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { RefCallBack } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { useStyles } from './styles';
import { withController } from './withController';

type PartialProps<T> = {
  id?: string;

  // Built-in loading support
  isLoading?: boolean;

  children?: React.ReactNode;

  defaultValue?: T;

  // Automatically render basic menu items with value/label
  options?: {
    value: T;
    label: string;
  }[];

  // Displays helper text when no options selected
  placeholder?: string;

  // Allows to pass ref to the select when used together with react-hook-form
  innerRef?: RefCallBack;
};

const placeholderProps = <T,>(
  placeholder?: string,
  options?: {
    value: T;
    label: string;
  }[],
) =>
  placeholder
    ? {
        displayEmpty: true,
        renderValue: (selected: T) => {
          if (!selected) {
            return <span className="placeholder">{placeholder}</span>;
          }

          return options?.find(({ value }) => value === selected)?.label ?? selected;
        },
      }
    : {};

type UncontrolledSelectProps = PartialProps<string | number> & SelectProps<string | number>;

export const UncontrolledSelect = ({
  id = getUniqueId(),
  label,
  className,
  isLoading = false,
  placeholder,
  children,
  options,
  innerRef,
  ...rest
}: UncontrolledSelectProps) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <FormControl variant="outlined" size="small" className={clsx([classes.root, className])}>
      {label && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      )}

      {isLoading ? (
        <Skeleton className={classes.loader} height="36px" />
      ) : (
        <MuiSelect
          id={id}
          sx={{ fontSize: 'inherit' }}
          fullWidth
          ref={innerRef ?? ref}
          className={classes.selectMenuItem}
          {...placeholderProps(placeholder, options)}
          {...rest}
        >
          {/* Placeholder item */}
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}

          {/* Default label/value items */}
          {options?.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              {label}
            </MenuItem>
          ))}

          {children}
        </MuiSelect>
      )}
    </FormControl>
  );
};

export const ControlledSelect = withController<string | number, UncontrolledSelectProps>(
  UncontrolledSelect,
);
