import { useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import React from 'react';

import BonsaiLogoWhite from '@common/icons/bonsai_logo_white.svg';
import BonsaiLogo from '@common/icons/bonsai_logo.svg';

/**
 * Returns the Bonsai Logo.
 */
export const Logo = ({ height = 45, ...muiProps }: BoxProps) => {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      component="img"
      alt="uvac"
      src={isDarkMode ? BonsaiLogoWhite : BonsaiLogo}
      color="red"
      height={height}
      {...muiProps}
    />
  );
};
