import { Card } from '@components/Card';
import { Autocomplete } from '@components/Input/__deprecated/Autocomplete';
import { ControlledSelect } from '@components/Input/Select';
import { TextField } from '@components/Input/TextField';
import { IClientAutocomplete, useClientAutocomplete } from '@hooks/useClientAutocomplete';
import { Grid, Stack } from '@mui/material';
import React from 'react';

import { AccountStatus } from '@common/types/GraphqlTypes.d';
import { ACCOUNT_CURRENCIES, ACCOUNT_DEFAULTS } from '@constants/account';

import { ACCOUNT_FIELDS, IAccount } from '../fields';
const { basic: inputField } = ACCOUNT_FIELDS;

// TODO: Archiving should be a separate action. However, currently it's merely a status update without any other workflow.
const STATUSES = [
  { value: AccountStatus.Live, label: 'Live' },
  { value: AccountStatus.Archived, label: 'Archived' },
];

export const BasicInformation = ({ isLoading }: { isLoading?: boolean }) => {
  const { query: clientQuery } = useClientAutocomplete();

  return (
    <Card title="Basic Information">
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <TextField
                id={inputField.name}
                isLoading={isLoading}
                label="Name"
                placeholder="Enter the account name"
                options={{
                  required: 'Account name is required',
                }}
                useHook
              />
              <ControlledSelect
                id={inputField.status}
                isLoading={isLoading}
                label="Status"
                options={STATUSES}
                defaultValue={AccountStatus.Live}
              />
            </Stack>
          </Grid>

          <Grid item xs={10}>
            <Autocomplete
              id={inputField.client}
              isLoading={isLoading}
              fetchQuery={clientQuery}
              label="Client"
              placeholder="Search for a client"
              noOptionsText="No clients were found"
              getOptionLabel={(value: IClientAutocomplete) => value?.name}
              isOptionEqualToValue={(option: IClientAutocomplete, value: IAccount['publisher']) =>
                option._id === value._id
              }
              textFieldProps={{
                required: true,
              }}
              autoSelect
            />
          </Grid>

          <Grid item xs={2}>
            <ControlledSelect
              id={inputField.currency}
              isLoading={isLoading}
              label="Currency"
              options={ACCOUNT_CURRENCIES}
              defaultValue={ACCOUNT_DEFAULTS.currency}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id={inputField.supportEmail}
              isLoading={isLoading}
              label="Support email"
              type="email"
              placeholder="Enter a support email"
              options={{
                required: 'Support email is required',
              }}
              useHook
            />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};
