import { StyledFormControlLabel, StyledSwitch } from '@components/DataFilter/Styled';
import { SwitchProps } from '@mui/material/Switch';
import React from 'react';

type FilterSwitchProps = SwitchProps & {
  /**
   * The label to be displayed next to the switch.
   */
  label?: string | JSX.Element;

  /**
   * Function to be called when the switch is toggled.
   * @param boolean - Value of the switch.
   */
  onUpdate?: (value: boolean) => void;
};

export const FilterSwitch = ({ onUpdate, label, ...muiProps }: FilterSwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onUpdate) {
      onUpdate(event.target.checked);
    }
  };

  return (
    <StyledFormControlLabel
      control={
        <StyledSwitch
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          {...muiProps}
        />
      }
      label={label}
    />
  );
};
