import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  detail: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    rowGap: theme.spacing(3),
  },
  dropdown: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.black,
    },
  },
  textField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.black,
    },
  },
}));
