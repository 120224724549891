import { getStateShortFormat, ZIPCODES_BY_STATE } from '@constants/state';

/**
 * Returns state & zipcode based on country.
 * Currently only supports dynamic zip codes per US state. Uses hardcoded values for other countries.
 */
export const getZipCode = (
  country: string,
  state: string = 'unknown',
): { state?: string; zipCode?: string } => {
  const defaultValue = { state: undefined, zipCode: undefined };

  switch (country) {
    case 'US': {
      const shortState = getStateShortFormat(state);

      return shortState && ZIPCODES_BY_STATE.hasOwnProperty(shortState)
        ? {
            state: shortState,
            zipCode: String(ZIPCODES_BY_STATE[shortState].min),
          }
        : defaultValue;
    }
    case 'CA': {
      return { state: 'ON', zipCode: 'K1V 7Y6' };
    }
    case 'GB': {
      return { state: 'London', zipCode: 'W1B 1AD' };
    }
    case 'PL': {
      return { state: 'Lublin', zipCode: '20-960' };
    }
    default: {
      return defaultValue;
    }
  }
};
