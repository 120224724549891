import * as R from 'ramda';

import { IntegrationType, Merchant } from '@common/types/GraphqlTypes.d';
import { getMatchingDotValues } from '@utils/object';

import { IMerchantInput, MERCHANT_FIELDS } from '../fields';
import { IMerchant } from './graphql';

/**
 * Converts fetched merchant into merchant input values for creating & updating.
 * Both operations allow the same for the moment, but might become different in the future.
 * @param {object} merchant - Fetched merchant.
 */
export const toInputValues = ({
  _id,
  isStripeConnected: _isStripeConnected,
  publishedStage: _publishedStage,
  ...merchant
}: IMerchant): Omit<IMerchantInput, 'id'> =>
  R.mergeDeepRight(
    R.dissocPath(['stripeAccount'], R.dissocPath(['config', 'shipping'], merchant)),
    {
      private: {
        credentials: {
          // Casting between graphQL enums & string
          integrationType: merchant.private?.credentials?.integrationType as IntegrationType,
          // Optional nested objects don't play nicely since the flatten function generates the key of the optional object still
          feed: merchant.private?.credentials?.feed?.accessCredentials?.aws?.kind
            ? merchant.private?.credentials?.feed
            : { ...merchant.private?.credentials?.feed, accessCredentials: undefined },
        },
      },

      // For whatever reason we don't send shipping when updating the same way we return it when fetching... kill me now
      shipping: {
        type: merchant.config?.shipping?.type,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        simple: (merchant.config?.shipping?.simple as any) || [],
        free: {
          enabled: merchant.config?.shipping?.free?.enabled,
        },
        bookie: merchant.config?.shipping?.bookie,
      },

      // Useless need for nested object...
      squareImage: {
        url: merchant.squareImage as string,
      },
    },
  );

/**
 * Returns mapped GraphQL values into input fields key/value for autopopulation.
 * Combines object containing auto-mapped GraphQL keys with loaded data (aka merchant info).
 */
export const getInputValues = (merchant: Merchant): IMerchant => ({
  ...getMatchingDotValues(MERCHANT_FIELDS, merchant),
  billing: {
    accountingId: merchant.billing.accountingId,
    commissionType: merchant.billing.commissionType,
  },
});
