import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { ROLE_FIELDS as inputField } from '../fields';

export const BasicInformation = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Basic Information">
    <Stack spacing={2}>
      <TextField
        id={inputField.name}
        isLoading={isLoading}
        label="Name"
        placeholder="Enter the role name"
        options={{
          required: 'Role name is required',
        }}
        useHook
      />

      <TextField
        id={inputField.description}
        isLoading={isLoading}
        label="Description"
        placeholder="Enter a role description"
        options={{
          required: 'Role description is required',
        }}
        useHook
      />
    </Stack>
  </Card>
);
