/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  cancel: {
    color: theme.palette.text.secondary,
    gridArea: 'cancel',
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
  confirmButton: {
    gridArea: 'confirm',
    marginTop: theme.spacing(2),
  },
  confirmNewPasswordInput: {
    gridArea: 'confirmNewPassword',
  },
  header: {
    gridArea: 'header',
  },
  newPasswordInput: {
    gridArea: 'newPassword',
  },
  root: {
    '& .MuiTextField-root': {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    display: 'grid',
    gridTemplateAreas: `
      "header"
      "newPassword"
      "confirmNewPassword"
      "confirm"
      "cancel"
    `,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 0.5fr 0.5fr auto auto',
    padding: theme.spacing(6),
  },
  subtitle: {
    color: 'grey',
    fontSize: 'small',
  },
  title: {
    fontSize: '30px',
    fontWeight: 600,
  },
}));
