import { equals, mergeDeepRight, omit } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from 'react-context-hook';
import { useSearchParams } from 'react-router-dom';

import { getQueryUrl, parseQueryURL } from '@utils/query';

import { IPagination, usePagination } from './usePagination';
import { ISearch } from './useSearch';
import { ISorting } from './useSorting';

export const QUICK_FILTER_KEY = 'quickFilter';

export type IFilters = {
  [key: string]: string | string | string[] | number | any;
};

type IQuickFilter = {
  [QUICK_FILTER_KEY]?: string;
};

// TODO: Better types with support for generics!
export type IQuickFilterOptions = {
  [filterId: string]: IFilters;
};

type IDataFilterOptions<T> = {
  /**
   * Key to store filters in global context.
   * Defaults to `filters`.
   */
  key?: string;

  /**
   * Default values for filters.
   * Required to ensure all filters are controlled from the beginning.
   */
  defaults: T;

  /**
   * Optional list of all available quick filters.
   */
  quickFilterOptions?: IQuickFilterOptions;
};

/**
 * Returns filters from quick filter options.
 * @param filterId - Quick filter ID.
 * @param options - List of available quick filters for current data set.
 */
export const getQuickFilter = (
  filterId?: string,
  options?: IQuickFilterOptions,
): IFilters | null => {
  if (!options || !filterId) {
    return null;
  }

  return options?.[filterId] ?? null;
};

/**
 * Returns default pagination values.
 * Supports quick filter if supplied (currently support for 1).
 * Either from URL query or default values.
 * @todo - Add safeguard to avoid unlimited pageSize!
 * @param {object} defaults - Default values for filters.
 * @param {object} quickFilterOptions - Quick filter options.
 */
export const getDefaultFilters = <T extends IFilters & IQuickFilter>(
  defaults: T,
  quickFilterOptions: IQuickFilterOptions = {},
): T => {
  const queryObject =
    parseQueryURL<IFilters & IPagination & ISorting & ISearch & IQuickFilter>() ?? {};

  // Optional quick filter - overwrites all other defaults & query values.
  const quickFilter = getQuickFilter(queryObject[QUICK_FILTER_KEY], quickFilterOptions);

  const values = quickFilter ?? mergeDeepRight(defaults, queryObject);

  // Exclude pagination, sorting & search from filters to avoid additional data.
  // Exclude quickFilter as it should only be used to fetch the original filter values.
  return omit(['page', 'pageSize', 'sortField', 'sortOrder', 'q', QUICK_FILTER_KEY], values) as T;
};

/**
 * Returns hook for managing global data filters state.
 * @todo - Add safeguard to avoid reserved words (e.g. Pagination, sorting & search)!.
 */
export const useDataFilters = <T extends IFilters & IQuickFilter>({
  key = 'filters',
  defaults,
  quickFilterOptions = {},
}: IDataFilterOptions<T>) => {
  /**
   * Default values for filters (1. Query, 2. Parent component).
   */
  const defaultValues = useMemo(
    () => getDefaultFilters<T>(defaults, quickFilterOptions),
    [defaults, quickFilterOptions],
  );

  const [hasChanged, setHasChanged] = useState(false);
  const [_searchParams, setSearchParams] = useSearchParams();

  const [pendingFilters, setPendingFilters] = useStore<T>(key, defaultValues);
  const { clearPagination } = usePagination();

  /**
   * Filters to be used in data fetching.
   * Avoids re-rendering when filters have not changed.
   */
  const filters = useMemo(() => pendingFilters, [pendingFilters]);

  /**
   * For some reason, when clearing filters,
   * then navigating to another page with quick filters, the pendingFilters do not get updated.
   * This currently ensures it resets the pendingFilters when navigating to another page.
   * @todo - Investigate why this happens & remove this.
   */
  useEffect(() => {
    setPendingFilters(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  /**
   * Check if filters have changed.
   */
  useEffect(() => {
    setHasChanged(!equals(defaults, defaultValues));
  }, [defaults, defaultValues]);

  /**
   * Updates filters with latest value.
   */
  const updateFilters = (updatedValue: Partial<T>) => {
    // When filters are updated, quick filter should be removed.
    const values = omit(
      [QUICK_FILTER_KEY],
      mergeDeepRight(pendingFilters, updatedValue),
    ) as unknown as T;

    // Update state.
    setPendingFilters(values);

    // Reset pagination to default values to avoid weird behaviour.
    clearPagination();

    // Update URL with new filter values. (appends to URL - not replace)
    // Removes quickFilter to avoid weird behaviour where quick filters overwrite changes.
    setSearchParams(getQueryUrl({ ...values, [QUICK_FILTER_KEY]: undefined }, { replace: false }));
  };

  /**
   * Sets filters to default values.
   */
  const clearFilters = () => {
    updateFilters(defaults);
  };

  return {
    filters,
    updateFilters,
    hasChanged,
    clearFilters,
  };
};
