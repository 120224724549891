import { DropDownButton } from '@components/Button/Dropdown';
import { UserIcon } from '@components/UserIcon';
import { Flag, CheckCircle, MoreHoriz } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Escalation } from '@pages/Order/components/Escalation';
import { getProfileColorForId } from '@theme/palette';
import { EscalationState } from '@types';
import React from 'react';

import { Comment as IComment } from '@common/types/GraphqlTypes.d';
import { toFormattedDate } from '@utils/date';
import { autoHyperlink } from '@utils/link';

import { CloseEscalationMenuItem, ReOpenEscalationMenuItem } from './menuItems';
import { useStyles } from './styles';

interface CommentProps {
  comment: IComment;
  customerOrderId: string;
}

export const Comment = ({
  comment: { _id, escalation, user, comment, createdAt },
  customerOrderId,
}: CommentProps) => {
  const classes = useStyles();

  const escalationComponent = (
    <div className={classes.escalationStatus}>
      {escalation?.state === EscalationState.Open ? (
        <>
          <Flag className={classes.flag} />
          Open
          <DropDownButton
            Icon={MoreHoriz}
            menuItems={
              <CloseEscalationMenuItem commentId={_id} customerOrderId={customerOrderId} />
            }
          />
        </>
      ) : (
        <>
          <CheckCircle className={classes.check} />
          Resolved
          <DropDownButton
            Icon={MoreHoriz}
            menuItems={
              <ReOpenEscalationMenuItem commentId={_id} customerOrderId={customerOrderId} />
            }
          />
        </>
      )}
    </div>
  );

  const getCommentValue = () => ({ __html: autoHyperlink(comment) });

  return (
    <div className={classes.existingComment}>
      <UserIcon
        className={classes.userIcon}
        name={user.name || ''}
        color={getProfileColorForId(user.userId || '1')}
        showName={false}
      />

      <div>
        <div className={classes.commentHeader}>
          <div>
            <Typography className={classes.commentUser}>{user.name || ''}</Typography>
            <div className={classes.date}>{toFormattedDate(createdAt)}</div>
          </div>
          {escalation ? escalationComponent : undefined}
        </div>

        <div>
          {escalation ? (
            <Escalation status={escalation.status} productName={escalation.productName} />
          ) : null}
        </div>

        <Typography className={classes.commentText}>
          <span dangerouslySetInnerHTML={getCommentValue()} />
        </Typography>
      </div>
    </div>
  );
};
