import { DropDownButton } from '@components/Button/Dropdown';
import { Card } from '@components/Card';
import { ITabPanel, Tabs } from '@components/Tabs';
import { Box } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

export enum OrderTabLabels {
  Details = 'Product Details',
  Shipping = 'Shipping Tracking',
  Payment = 'Fulfillment Cost',
  Other = 'Fulfillment Comments',
}

type OrderCardProps = {
  menuItems?: JSX.Element;
  tabPanels: { [key in OrderTabLabels]: ITabPanel };
  header: JSX.Element;
};

/**
 * Renders a card with the details of a processed order.
 * @param props.order - The order to render.
 * @param props.menuItems - The menu items to render in the dropdown menu.
 */
export const OrderCard = ({ menuItems, tabPanels, header }: OrderCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.card} noPadding>
      <div className={classes.cardTitleWrapper}>
        <Box sx={{ width: '100%' }}>{header}</Box>
        {menuItems && <DropDownButton menuItems={menuItems} className={classes.menu} />}
      </div>
      <Tabs
        tabBarClassName={classes.tabBar}
        tabPanelClassName={classes.tabPanel}
        tabPanels={tabPanels}
      />
    </Card>
  );
};
