import { gql, useMutation } from '@apollo/client';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import { Unpublished as UnpublishIcon, RocketLaunch as PublishIcon } from '@mui/icons-material';
import React from 'react';

import { ClientStatus, Merchant } from '@common/types/GraphqlTypes.d';

import { MERCHANT_QUERY } from '../../graphql';

type ActionsProps = {
  merchant: Merchant;
};

export const PUBLISH_MERCHANT = gql`
  mutation publishMerchant($input: PublishMerchantInput!) {
    publishMerchant(input: $input) {
      result
    }
  }
`;

export const UNPUBLISH_MERCHANT = gql`
  mutation unpublishMerchant($input: PublishMerchantInput!) {
    unpublishMerchant(input: $input) {
      result
    }
  }
`;

export const PublishMerchantInfoMenuItem = ({ merchant }: ActionsProps) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [publishMerchant] = useMutation(PUBLISH_MERCHANT, {
    onCompleted: () => {
      enqueueSnackbar(
        `The merchant ${merchant.name} has been set to live. Make sure to check enabled webhooks if this is a Shopify merchant.`,
      );
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleClose();
    },
    refetchQueries: [MERCHANT_QUERY],
  });

  const [unpublishMerchant] = useMutation(UNPUBLISH_MERCHANT, {
    errorPolicy: 'all',
    onCompleted: () => {
      enqueueSnackbar(`The merchant ${merchant.name} has been set to draft.`);

      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleClose();
    },
    refetchQueries: [MERCHANT_QUERY],
  });

  // TODO: Either define globally used PublishableStatus or create MerchantStatus!
  const handlePublishAction = (updateStatus: ClientStatus) => {
    const input = {
      variables: {
        input: {
          id: merchant._id,
        },
      },
    };

    return updateStatus === ClientStatus.Live ? publishMerchant(input) : unpublishMerchant(input);
  };

  return (
    <>
      {merchant.publishedStage === ClientStatus.Live ? (
        <MenuItem
          action={() => handlePublishAction(ClientStatus.Draft)}
          icon={UnpublishIcon}
          label="Set merchant to draft"
        />
      ) : (
        <MenuItem
          action={() => handlePublishAction(ClientStatus.Live)}
          icon={PublishIcon}
          label="Set merchant live"
        />
      )}
    </>
  );
};
