import { gql, MutationHookOptions, useMutation } from '@apollo/client';
const UPDATE_MERCHANTORDERS_BY_CSV_MUTATION = gql`
  mutation updateByCsv($input: CsvUpdateInput!) {
    updateByCsv(input: $input) {
      fileName
      encodedCsv
      result
    }
  }
`;

/**
 * Hook for updating merchant orders.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useUpdateMerchantOrdersByCsv = (options: MutationHookOptions = {}) => {
  const [updateMerchantOrders, { data: exportData, error: apiError }] = useMutation<{
    updateByCsv: { fileName: string; encodedCsv: string };
  }>(UPDATE_MERCHANTORDERS_BY_CSV_MUTATION, options);

  return { updateMerchantOrders, apiError, exportData };
};
