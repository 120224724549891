import { DropDownButton } from '@components/Button/Dropdown';
import { InfoOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton, Stack, Typography, Tooltip, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './styles';

type HeaderProps = {
  title?: React.ReactElement | string;
  buttons?: React.ReactElement | string;
  actions?: React.ReactElement;
  isLoading?: boolean;
  tooltip?: string;
  children?: React.ReactNode;
};

// TODO: This is not excellent logic. If we add proper breadcrumbs again, we should remove this entirely!
const isOverviewPage = (): boolean => window.location.pathname.split('/').pop() === 'list';

export const Header = ({
  title,
  buttons: Buttons,
  actions: Actions,
  isLoading = false,
  tooltip,
  children,
}: HeaderProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const shouldShowBackButton = !isOverviewPage();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Box className={classes.title}>
        {shouldShowBackButton && (
          <ArrowBackIcon onClick={() => navigate(-1)} className={classes.goBack} />
        )}

        {title && (
          <Typography variant="h5" className={classes.titleText}>
            {isLoading ? <Skeleton width={350} height={40} /> : title}
          </Typography>
        )}

        {tooltip && (
          <Tooltip title={tooltip} className={classes.tooltip}>
            <InfoOutlined />
          </Tooltip>
        )}
      </Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        {Buttons && <div className={classes.buttons}>{Buttons}</div>}

        {Actions && <DropDownButton menuItems={Actions} />}

        {children}
      </Stack>
    </Stack>
  );
};
