import ErrorBoundary from '@components/ErrorBoundary';
import { useMerchantOrders } from '@hooks/useMerchantOrders';
import { CircularProgress } from '@mui/material';
import { OrderItems } from '@pages/Order/components/OrderItems';
import { OtherDetails } from '@pages/Order/components/OtherDetails';
import { PaymentDetails } from '@pages/Order/components/PaymentDetails';
import { OrderCard, OrderTabLabels } from '@pages/Order/View/OrderCard';
import { ProcessedOrderHeader } from '@pages/Order/View/OrderedItems/Header';
import { IProcessedOrder } from '@pages/Order/View/OrderedItems/interfaces';
import React from 'react';

import { Loading } from '../../components/Loading';
import { getArchivedProcessedOrders } from './utils';

interface IItems {
  merchantOrderIds: string[];
}

export const Items: React.FC<IItems> = ({ merchantOrderIds }) => {
  const { isLoading, merchantOrders } = useMerchantOrders(merchantOrderIds);

  // TODO: Implement skeleton loader
  if (isLoading || !merchantOrders) {
    return <CircularProgress />;
  }

  // Each merchant order can have multiple archived orders hence flat
  const archivedProcessedOrders = merchantOrders.flatMap(getArchivedProcessedOrders);

  if (archivedProcessedOrders?.length === 0) {
    return <ErrorBoundary children={'No Archived Feed Orders'} />;
  }

  const tabPanels = (order: IProcessedOrder) => ({
    [OrderTabLabels.Details]: {
      component: (
        <OrderItems
          checkoutVariants={order.checkoutVariants}
          integrationType={order.integrationType}
          merchantName={order.merchantName}
        />
      ),
    },
    [OrderTabLabels.Shipping]: {
      disabled: true,
      component: <></>,
    },
    [OrderTabLabels.Payment]: {
      component: <PaymentDetails order={order} />,
    },
    [OrderTabLabels.Other]: {
      component: <OtherDetails order={order} />,
    },
  });

  return (
    <>
      {isLoading ? <Loading /> : null}
      {archivedProcessedOrders.map((order, index) => (
        <OrderCard
          key={`${order.merchantOrderId}-${index}`}
          tabPanels={tabPanels(order)}
          header={<ProcessedOrderHeader order={order} />}
        />
      ))}
    </>
  );
};
