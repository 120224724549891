import { Card } from '@components/Card';
import { AsynchronousAutocomplete } from '@components/Input/__deprecated/AsyncAutocomplete';
import { Switch } from '@components/Input/Switch';
import useMerchantAutocomplete from '@hooks/useMerchantAutocomplete';
import { Grid, Stack } from '@mui/material';
import { routes } from '@routes/private';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { USER_FIELDS as inputField } from '../../fields';

export const UserMerchant = ({ isLoading }: { isLoading?: boolean }) => {
  const { watch, setValue } = useFormContext();

  const merchant = watch(inputField.merchant);
  const [isMerchant, setIsMerchant] = React.useState<boolean>(false);

  // GraphQL hook
  const { getData: getMerchantAutocomplete } = useMerchantAutocomplete();

  useEffect(() => {
    setIsMerchant(Boolean(merchant?._id));
  }, [merchant]);

  const handleToggle = ({
    target: { checked: isChecked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setIsMerchant(isChecked);

    // Reset merchant form value to null if toggle gets disabled
    if (!isChecked) {
      setValue(inputField.merchant, null);
    }
  };

  const label = `Toggle on if you like to associate this user with a specific merchant.
    Make sure to provide the correct Merchant role to avoid any unwanted side-effects.`;

  return (
    <Card title="Merchant" isLoading={isLoading}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={3}>
            <Switch
              label={label}
              checked={isMerchant}
              onChange={handleToggle}
              useHook={false} // No need to have this value stored in our form as it's purely for visual purposes
            />

            {/* Optimize component below! Shouldn't be needed to update an entire merchant object (ID-only keys would be great while showing name of merchant) */}
            {isMerchant && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs>
                  <AsynchronousAutocomplete
                    label={'Merchant'}
                    value={merchant}
                    setValue={(value) => {
                      setValue(inputField.merchant, value);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    getAutocompleteData={getMerchantAutocomplete}
                  />
                </Grid>

                {merchant?._id && (
                  <Grid item xs>
                    <Link to={routes.editMerchant.getPath(merchant._id)} target="_blank">
                      View merchant
                    </Link>
                  </Grid>
                )}
              </Grid>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
