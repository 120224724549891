export type IStripeAPIProps = {
  stripeState: string;
  apiKey: string;
};

/**
 * Returns Stripe API connection url based on state & provided API key.
 * Uses stripeState as a check against CSRF.
 * @param {object} props - Stripe API properties.
 */
export const shapeStripeConnectURL = ({ stripeState, apiKey }: IStripeAPIProps) =>
  `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${encodeURIComponent(
    apiKey ?? '',
  )}&state=${encodeURIComponent(stripeState)}&scope=read_write&redirect_uri=${encodeURIComponent(
    window.location.origin + '/profile',
  )}`;
