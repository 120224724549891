import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { Account, QueryAccountByPublicIdArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within ACCOUNT_QUERY.
export type IAccount = Pick<
  Account,
  | 'name'
  | 'source'
  | 'apiKey'
  | 'publicId'
  | 'currency'
  | 'config'
  | 'billing'
  | 'orderConfig'
  | 'emailProvider'
  | 'publisher'
>;

const ACCOUNT_QUERY = gql`
  query accountByPublicId($publicId: String!) {
    accountByPublicId(publicId: $publicId) {
      name
      publicId
      status
      apiKey
      privateApiKey
      testPublishableApiKey
      testSecretApiKey
      currency
      source
      config {
        supportEmail
        logo
        footer
        favicon
        cartTitleImage
      }
      billing {
        email
        fee
        descriptor
        accountingId
      }
      orderConfig {
        shipping
        slackNotificationChannel
        fulfillment {
          instructions
        }
      }
      emailProvider {
        type
      }
      publisher {
        _id
        name
      }
    }
  }
`;

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount($id: String!, $input: AccountInputUpdate!) {
    updateAccount(id: $id, input: $input) {
      _id
    }
  }
`;

/**
 * Hook for fetching account.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Do not use IMerchantData interface - define custom one!
 */
export const useAccount = (
  options: QueryHookOptions<{ accountByPublicId: IAccount }, QueryAccountByPublicIdArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ accountByPublicId: IAccount }, QueryAccountByPublicIdArgs>(ACCOUNT_QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const { accountByPublicId } = data || {};

  return { data: accountByPublicId, isLoading, apiError };
};

/**
 * Hook for editing account.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditAccount = (options: MutationHookOptions = {}) => {
  const [updateAccountMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateRole: { id: string };
  }>(UPDATE_ACCOUNT_MUTATION, options);

  return { updateAccountMutation, isLoading, apiError };
};
