/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles((theme) => ({
  assigneeHeader: {
    fontWeight: 600,
  },
  card: {
    padding: '24px 20px',
  },
  cardTitleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardWrapper: {
    columnGap: '20px',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    margin: 0,
    rowGap: '20px',
  },
  col1Card: {
    gridColumn: 1,
  },
  col2Card: {
    gridColumn: 2,
  },
  group: {
    padding: theme.spacing(1),
  },
  link: {
    color: theme.palette.text.secondary,
    margin: '0 !important',
  },
  merchantOrderNumber: {
    fontSize: 15,
    fontWeight: 600,
  },
  orderInformation: {
    height: 'fit-content',
  },
  status: {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 8px',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: 16,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 600,
  },
  detailsButton: {
    backgroundColor: 'white',
    borderStyle: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paymentDetails: {
    fontSize: 15,
    fontWeight: 600,
    marginRight: 8,
    display: 'flex',
  },
  tabBar: {
    marginTop: '10px',
  },
  divider: {
    marginTop: '3.45px',
    marginBottom: '8px',
    color: Color.Gray3,
  },
}));
