import { PrimaryEvent, SecondaryEvent } from '@components/HistoryTimeline';

import { OrderHistoryType, OrderHistoryStatus } from '@common/types/GraphqlTypes.d';

enum NonHistoryEventType {
  PaymentEvent = 'PaymentEvent',
  OrderReceivedEvent = 'OrderReceivedEvent',
  OrderFulfilledEvent = 'OrderFulfilledEvent',
}

export const eventType = {
  ...OrderHistoryType,
  ...NonHistoryEventType,
};

export type TEventType = OrderHistoryType | NonHistoryEventType;

export type SecondaryEventType = OrderHistoryType.EmailEvent | OrderHistoryType.ShippingEvent;

export type PrimaryEventType = Exclude<TEventType, SecondaryEventType>;

type OrderHistoryEvent<T, TEventTypes> = Omit<T, 'icon'> & {
  type: TEventTypes;
  status: OrderHistoryStatus;
};
export type OrderPrimaryEvent = OrderHistoryEvent<PrimaryEvent, PrimaryEventType>;
export type OrderSecondaryEvent = OrderHistoryEvent<SecondaryEvent, SecondaryEventType>;
