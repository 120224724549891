import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';

import { IUser } from '../graphql';
import { StyledLink } from '@components/DataGrid/Styled';
import { toFormattedDate } from '@utils/date';
import { routes } from '@routes/private';

export const columns: GridColDef<IUser>[] = [
  {
    field: 'profile.firstName',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    valueGetter: ({ row: { profile } }) => `${profile?.firstName} ${profile?.lastName ?? ''}`,
    renderCell: ({ value, row }) => (
      <StyledLink to={routes.editUser.getPath(row._id)}>{value}</StyledLink>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    sortable: true,
    valueGetter: ({ row }) => toFormattedDate(Number(row?.createdAt)),
    width: 300,
  },
];
