import '@elastic/react-search-ui-views/lib/styles/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  ErrorBoundary,
  SearchProvider,
  Results,
  PagingInfo,
  Paging,
  WithSearch,
} from '@elastic/react-search-ui';
import { Layout } from '@elastic/react-search-ui-views';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import MUISwitch from '@mui/material/Switch';
import { ProductModal, SelectedProduct } from '@pages/Product/Overview/components/ProductModal';
import React, { createContext, useState } from 'react';

import { SearchProductResult } from './components/ProductResult';
import { SearchProductsSearchBox } from './components/Search';
import { SearchProductsSidebar } from './components/Sidebar';
import { appSearchConfig } from './config';
import { ProductOverviewHeader } from './Header';
import { useStyles } from './styles';

type WithSearchProps = {
  wasSearched: boolean;
  isLoading: boolean;
};

/**
 * Context mainly for managing product details modal.
 */
export const ResultContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedProduct: (_product: SelectedProduct) => {},
});

/**
 * List of skeleton loaders matching product list view.
 */
const showLoading = () => (
  <>
    <Skeleton width="100%" height={30} style={{ marginBottom: '8px' }} />
    <Grid container spacing={2}>
      {Array.from({ length: 10 }).map((_v, index) => (
        <Grid item xs={6} key={`load_product_${index}`}>
          <Skeleton variant="rectangular" width="100%" height={165} />
        </Grid>
      ))}
    </Grid>
  </>
);

export const ListProducts = () => {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct | null>(null);

  // Enabled by default - hides products
  const [shouldHideDeletedProducts, setshouldHideDeletedProducts] = React.useState(true);

  const toggleDeletedProducts = () => {
    setshouldHideDeletedProducts(!shouldHideDeletedProducts);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ProductOverviewHeader />

        <FormGroup>
          <FormControlLabel
            control={
              <MUISwitch
                size="small"
                checked={shouldHideDeletedProducts}
                onChange={toggleDeletedProducts}
              />
            }
            label="Hide deleted products"
          />
        </FormGroup>
      </Stack>
      <SearchProvider config={appSearchConfig(shouldHideDeletedProducts)}>
        <WithSearch
          mapContextToProps={({ wasSearched, isLoading }: WithSearchProps) => ({
            wasSearched,
            isLoading,
          })}
        >
          {({ wasSearched, isLoading }: WithSearchProps) => (
            <div className={classes.root}>
              <ResultContext.Provider value={{ setSelectedProduct }}>
                <ErrorBoundary>
                  <Layout
                    header={<SearchProductsSearchBox />}
                    sideContent={<SearchProductsSidebar />}
                    bodyContent={
                      isLoading ? (
                        showLoading()
                      ) : (
                        <Results
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          resultView={SearchProductResult as any} // TODO: Fix this any and make types match!
                          shouldTrackClickThrough={false}
                        />
                      )
                    }
                    bodyHeader={<React.Fragment>{wasSearched && <PagingInfo />}</React.Fragment>}
                    bodyFooter={<Paging />}
                  />
                </ErrorBoundary>
              </ResultContext.Provider>
            </div>
          )}
        </WithSearch>
      </SearchProvider>

      {/* Product details modal */}
      <ProductModal product={selectedProduct} closeModal={() => setSelectedProduct(null)} />
    </>
  );
};
