import { Copy } from '@components/Copy';
import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  unavailable: {
    textDecoration: 'line-through',
  },
  productId: {
    fontSize: '0.8rem',
    color: Color.DarkNight,
  },
}));

export type ProductNameProps = {
  publicId: string;
  name: string;
  validationFlags?: string[]; // TODO: Use shared enum preferably from GraphQL!
};

// TODO: Use shared enum!
const UNAVAILABLE_FLAGS = new Set(['isExcluded', 'isUnavailable', 'isDeleted']);

/**
 * Displays product name.
 * Incorperates styling based on validation flags.
 */
export const ProductName = ({ name, publicId, validationFlags = [] }: ProductNameProps) => {
  const classes = useStyles();

  // Any major issue causing the product to be unavailable
  const isUnavailable = validationFlags.some((flag) => UNAVAILABLE_FLAGS.has(flag));

  return (
    <Stack direction="column">
      <h3 className={clsx([classes.root, isUnavailable ? classes.unavailable : ''])}>
        <span dangerouslySetInnerHTML={{ __html: name }}></span>
      </h3>
      <Stack className={classes.productId} direction="row" gap={0.5}>
        <span>
          <b>ID</b>:
        </span>
        {<Copy value={publicId} />}
      </Stack>
    </Stack>
  );
};
