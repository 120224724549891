import { ICurrentUser } from '@hooks/useCurrentUser';

/**
 * Returns the current jwt payload.
 * @param {string} token - Jwt token.
 */
export const parseJwt = <T>(token: string): T | undefined => {
  // eslint-disable-next-line prefer-destructuring
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      // eslint-disable-next-line unicorn/prefer-spread
      .split('')
      .map(function (c) {
        // eslint-disable-next-line unicorn/prefer-code-point
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * Verifies whether JWT token contains all required properties.
 */
export const jwtHasPayload = (user: ICurrentUser, fields: (keyof ICurrentUser)[]): boolean =>
  fields.every((key) => user.hasOwnProperty(key));
