import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { UserPermission } from '@types';

import { QueryRoleArgs } from '@common/types/GraphqlTypes.d';

export type IRole = {
  _id: string;
  name: string;
  permissions: UserPermission[];
  isProtected: boolean;
};

const ROLE_QUERY = gql`
  query role($_id: String!) {
    role(_id: $_id) {
      _id
      name
      description
      isProtected
      permissions
    }
  }
`;

const UPDATE_ROLE_QUERY = gql`
  mutation updateRole($id: String!, $input: RoleInput!) {
    updateRole(id: $id, input: $input) {
      _id
      name
      description
      isProtected
      permissions
    }
  }
`;

/**
 * Hook for fetching role information.
 * @param {object} [options] - Query hook options for GraphQL.
 * @todo - Do not use IMerchantData interface - define custom one!
 */
export const useRole = (options: QueryHookOptions<{ role: IRole }, QueryRoleArgs>) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ role: IRole }, QueryRoleArgs>(ROLE_QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const { role } = data || {};

  return { data: role, isLoading, apiError };
};

/**
 * Hook for editing role.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditRole = (options: MutationHookOptions = {}) => {
  const [updateRoleMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateRole: { id: string };
  }>(UPDATE_ROLE_QUERY, options);

  return { updateRoleMutation, isLoading, apiError };
};
