import { useCurrentUser } from '@hooks/useCurrentUser';
import { QUICK_FILTER_KEY, useDataFilters } from '@hooks/useDataStore/useFilters';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Escalation as EscalationMode } from '@types';

import { FulfillmentStatus } from '@utils/types';
import { PaymentStatus } from '@types';

/**
 * Filter options for orders.
 */

export type IOrderFilters = {
  fulfillmentStatuses?: FulfillmentStatus[];
  accountIds?: string[];
  assigneeIds?: string[];
  date?: DateRange<number> | number[];
  escalationStatuses?: EscalationMode[];
  merchantIds?: string[];
  paymentStatuses?: PaymentStatus[];
  isTest?: string;

  // Support for quick filter - overwrites all other values!
  [QUICK_FILTER_KEY]?: string;
};

const DEFAULT_ORDER_FILTERS: Omit<IOrderFilters, typeof QUICK_FILTER_KEY> = {
  accountIds: [],
  assigneeIds: [],
  date: [],
  escalationStatuses: [],
  fulfillmentStatuses: [],
  merchantIds: [],
  paymentStatuses: [],
  isTest: 'false',
};

/**
 * Quick filter options for orders.
 * Elaborate on the quick filters here given orders use these frequently.
 */

const SUCCESS_FULFILLMENT_STATUSES = new Set([
  FulfillmentStatus.FULFILLED,
  FulfillmentStatus.PARTIALLY_FULFILLED,
  FulfillmentStatus.CANCELLED,
  FulfillmentStatus.PAYMENT_CANCELLATION,
  FulfillmentStatus.SENT_TO_MERCHANT,
  FulfillmentStatus.PAYMENT_SUCCESSFUL,
]);

/**
 * All available order quick filters.
 */
export enum OrderQuickFilter {
  AssignedToMe = '84756489473',
  All = '9873464537384',
  Delayed = '84367834678324687',
  Escalated = '84747475',
  Paid = '45875478635865',
  Tests = '34732674324',
}

/**
 * Returns all available quick filters for orders.
 */
const getOrderQuickFilters = (): { [key in OrderQuickFilter]: IOrderFilters } => {
  // Needed for assigned to me orders
  const { currentUser } = useCurrentUser();

  // Needed for delayed orders
  const delayedDate = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000); // 3 days ago.
  delayedDate.setMinutes(0, 0, 0); // Ignore minutes, seconds, ms so that we can check if the user is on this page.
  const delayedTimestamp = delayedDate.getTime();

  // Needed for escalated orders
  const escalations = Object.values(EscalationMode).filter(
    (status) => status !== EscalationMode.Delayed,
  );

  return {
    /**
     * Assigned to me orders.
     */
    [OrderQuickFilter.AssignedToMe]: currentUser
      ? {
          ...DEFAULT_ORDER_FILTERS,
          fulfillmentStatuses: [FulfillmentStatus.PAYMENT_SUCCESSFUL],
          assigneeIds: [currentUser.id],
        }
      : DEFAULT_ORDER_FILTERS,

    /**
     * All available orders.
     */
    [OrderQuickFilter.All]: {
      ...DEFAULT_ORDER_FILTERS,
      fulfillmentStatuses: [...SUCCESS_FULFILLMENT_STATUSES],
    },

    /**
     * All paid orders.
     */
    [OrderQuickFilter.Paid]: {
      ...DEFAULT_ORDER_FILTERS,
      fulfillmentStatuses: [FulfillmentStatus.PAYMENT_SUCCESSFUL],
    },

    /**
     * All orders with any sort of escalation.
     */
    [OrderQuickFilter.Escalated]: {
      ...DEFAULT_ORDER_FILTERS,
      escalationStatuses: escalations,
    },

    /**
     * All orders at least 3 days old already paid but not fulfilled.
     */
    [OrderQuickFilter.Delayed]: {
      ...DEFAULT_ORDER_FILTERS,
      date: [1646110800000, delayedTimestamp],
      fulfillmentStatuses: [
        FulfillmentStatus.SENT_TO_MERCHANT,
        FulfillmentStatus.PAYMENT_SUCCESSFUL,
      ],
      paymentStatuses: [PaymentStatus.Paid],
    },

    /**
     * Only test orders.
     */
    [OrderQuickFilter.Tests]: {
      ...DEFAULT_ORDER_FILTERS,
      fulfillmentStatuses: [...SUCCESS_FULFILLMENT_STATUSES],
      isTest: 'true',
    },
  };
};

/**
 * Hook for managing global order filters state.
 */
export const useOrderFilters = () =>
  useDataFilters<IOrderFilters>({
    key: 'orderFilters',
    defaults: DEFAULT_ORDER_FILTERS,
    quickFilterOptions: getOrderQuickFilters(),
  });
