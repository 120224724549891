import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ProductCollectionAccount } from '@common/types/GraphqlTypes.d';
import { newlineToArray } from '@utils/string';

import { BasicInformation } from '../components/Basic';
import { Products } from '../components/Products';
import { IProductCollectionInput } from '../fields';
import { useCreateProductCollection } from './graphql';
import { CreateProductCollectionHeader } from './Header';

export const CreateProductCollection: React.FC = () => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<
    IProductCollectionInput & { productIds: string; account: ProductCollectionAccount }
  >({
    mode: 'onSubmit',
  });

  const { createProductCollectionMutation, isLoading, apiError } = useCreateProductCollection();

  const onSubmit = (
    values: IProductCollectionInput & { productIds: string; account: ProductCollectionAccount },
  ) => {
    // Convert textarea with newlines to array of IDs/URLs
    const productIds = newlineToArray(values.productIds || '');

    // Map account object to accountId & append product IDs
    const input = {
      ...R.omit(['account'], values),
      accountId: values.account?.publicId,
      productIds,
    };

    // Manual check as Autocomplete component does not support react-hook-form validation yet
    if (!input.accountId) {
      return enqueueSnackbar('A collection need to be assigned to an account', {
        variant: 'error',
      });
    }

    createProductCollectionMutation({
      variables: { input },
      onCompleted: ({ createProductCollection }) => {
        goto.viewProductCollection(createProductCollection._id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error creating product collection:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listProductCollections();
  };

  return (
    <Stack gap={3}>
      <CreateProductCollectionHeader />

      <FormProvider {...form}>
        <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
          <>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Stack gap={3}>
                  <BasicInformation />
                  <Products />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  <PrimaryButton type="submit" disabled={isLoading}>
                    Create product collection
                  </PrimaryButton>
                </Stack>
              </Grid>
            </Grid>
          </>
        </Form>
      </FormProvider>
    </Stack>
  );
};
