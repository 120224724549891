import { QUICK_FILTER_KEY, useDataFilters } from '@hooks/useDataStore/useFilters';
import { AccountStatus } from '@common/types/GraphqlTypes.d';

/**
 * Filter options for accounts.
 */

export type IAccountFilters = {
  status?: AccountStatus;

  // Support for quick filter - overwrites all other values!
  [QUICK_FILTER_KEY]?: string;
};

const DEFAULT_ACCOUNT_FILTERS: IAccountFilters = {
  status: AccountStatus.Live,
};

/**
 * All available account quick filters.
 */
export enum AccountQuickFilter {
  NonArchived = '74647467346dfhfg',
}

const getAccountQuickFilters = (): { [key in AccountQuickFilter]: IAccountFilters } => ({
  [AccountQuickFilter.NonArchived]: {
    ...DEFAULT_ACCOUNT_FILTERS,
    status: AccountStatus.Live,
  },
});

/**
 * Hook for managing global account filters state.
 */
export const useAccountFilters = () =>
  useDataFilters<IAccountFilters>({
    key: 'accountFilters',
    defaults: DEFAULT_ACCOUNT_FILTERS,
    quickFilterOptions: getAccountQuickFilters(),
  });
