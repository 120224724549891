import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { QueryUserArgs } from '@common/types/GraphqlTypes.d';

import { IUser } from '../fields';

const USER_QUERY = gql`
  query user($_id: String!) {
    user(_id: $_id) {
      _id
      profile {
        firstName
        lastName
      }
      merchant {
        _id
        name
      }
      userRoles
      email
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: String!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      _id
    }
  }
`;

/**
 * Hook for fetching user.
 * @param {object} [options] - Query hook options for GraphQL.
 */
export const useUser = (options: QueryHookOptions<{ user: IUser }, QueryUserArgs>) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ user: IUser }, QueryUserArgs>(USER_QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const { user } = data || {};

  return { data: user, isLoading, apiError };
};

/**
 * Hook for editing user.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditUser = (options: MutationHookOptions = {}) => {
  const [updateUserMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateUserMutation: { id: string };
  }>(UPDATE_USER_MUTATION, options);

  return { updateUserMutation, isLoading, apiError };
};
