/* eslint-disable @typescript-eslint/no-explicit-any */

import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { useUploadImage } from '@hooks/useImageUpload';
import useRouter from '@hooks/useRouter';
import { Grid, Stack } from '@mui/material';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MERCHANT_DEFAULTS } from '@constants/merchant';

import { BasicInformation } from '../components/Basic/Basic';
import { FinancialInformation } from '../components/Basic/Financial';
import { IntegrationInformation } from '../components/Basic/Integration';
import { OfficeInformation } from '../components/Basic/Office';
import { TaxesInformation } from '../components/Basic/Taxes';
import { Fulfillment } from '../components/Fulfillment';
import { Inventory } from '../components/Inventory';
import { ShippingConfiguration } from '../components/Shipping/Configuration';
import { ShippingOptions } from '../components/Shipping/Options';
import { IMerchant } from '../fields';
import { useCreateMerchant } from './graphql';
import { CreateMerchantHeader } from './Header';
import { toInputValues } from './utils';

export const CreateMerchant = () => {
  const { goto } = useRouter();

  // Set up React form hook
  const form = useForm<IMerchant>({
    mode: 'onSubmit',
    defaultValues: {
      // Causes at least 1 shipping option to already show (better UX)
      config: {
        canUseFlexiblePayment: true,
        shipping: {
          simple: [
            {
              description: '',
              shippingRatePerCountry: [{ countryCode: MERCHANT_DEFAULTS.country }],
            },
          ],
        },
      },
      enabledWebhooks: [],
    },
  });

  // GraphQL hooks
  const { uploadImage } = useUploadImage();
  const { createMerchantMutation, isLoading, apiError } = useCreateMerchant();

  // Handle create form being submitted
  const onSubmit = async (values: IMerchant) => {
    // 1. Upload logo
    const logo = await uploadImage('logo', values.squareImage?.url);

    // 2. Create merchant
    createMerchantMutation({
      variables: {
        input: toInputValues(R.mergeDeepRight(values, { squareImage: logo }) as IMerchant),
      },
      onCompleted: ({ insertMerchant }) => {
        goto.editMerchant(insertMerchant._id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error creating merchant:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listMerchants();
  };

  return (
    <>
      <CreateMerchantHeader />

      <FormProvider {...form}>
        <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
          <>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={8}>
                <Stack gap={3}>
                  <BasicInformation canEditSlug={true} />
                  <OfficeInformation />
                  <Fulfillment />
                  <ShippingOptions />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack gap={3}>
                  <FinancialInformation />
                  <TaxesInformation />
                  <IntegrationInformation />
                  <ShippingConfiguration />
                  <Inventory />
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" loading={isLoading}>
                  Create merchant
                </PrimaryButton>
              </Stack>
            </Grid>
          </>
        </Form>
      </FormProvider>
    </>
  );
};
