import { IShortcutProducts } from '@pages/Order/components/ItemsSelect/types';
import Joi from 'joi';

import { ShortcutProduct } from '@common/types/GraphqlTypes.d';

export enum FEED_ORDER_FIELD {
  MERCHANT_ORDER_NUMBER = 'merchantOrderNumber',
  PAYMENT_METHOD = 'paymentMethod',
  SUBTOTAL = 'subtotal',
  SHIPPING = 'shipping',
  TAXES = 'taxes',
  DISCOUNT = 'discount',
  CURRENCY = 'currency',
  PRODUCTS = 'products',
  ORDER_CREATION_DATE = 'orderCreationDate',
  COMMENTS = 'comments',
}

export interface IFeedOrderFormData extends IShortcutProducts {
  [FEED_ORDER_FIELD.CURRENCY]: string;
  [FEED_ORDER_FIELD.DISCOUNT]: number;
  [FEED_ORDER_FIELD.MERCHANT_ORDER_NUMBER]: string;
  [FEED_ORDER_FIELD.PAYMENT_METHOD]: string;
  [FEED_ORDER_FIELD.PRODUCTS]: ShortcutProduct[];
  [FEED_ORDER_FIELD.SHIPPING]: number;
  [FEED_ORDER_FIELD.SUBTOTAL]: number;
  [FEED_ORDER_FIELD.TAXES]: number;
  [FEED_ORDER_FIELD.ORDER_CREATION_DATE]: Date;
  [FEED_ORDER_FIELD.COMMENTS]: string;
}

export const FeedOrderFormSchema = Joi.object({
  [FEED_ORDER_FIELD.CURRENCY]: Joi.string().required(),
  [FEED_ORDER_FIELD.DISCOUNT]: Joi.number(),
  [FEED_ORDER_FIELD.MERCHANT_ORDER_NUMBER]: Joi.string().required(),
  [FEED_ORDER_FIELD.PAYMENT_METHOD]: Joi.string().required(),
  [FEED_ORDER_FIELD.PRODUCTS]: Joi.array()
    .items(
      Joi.object({
        productId: Joi.string(),
        quantity: Joi.number(),
        variantId: Joi.string(),
      }),
    )
    .required(),
  [FEED_ORDER_FIELD.SHIPPING]: Joi.number().required(),
  [FEED_ORDER_FIELD.SUBTOTAL]: Joi.number().required(),
  [FEED_ORDER_FIELD.TAXES]: Joi.number().required(),
  [FEED_ORDER_FIELD.ORDER_CREATION_DATE]: Joi.date().optional(),
  [FEED_ORDER_FIELD.COMMENTS]: Joi.string().optional().allow(''),
});

export enum FEED_ORDER_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export const FEED_ORDER_MODE_DETAILS = {
  [FEED_ORDER_MODE.CREATE]: {
    buttonText: 'Submit feed order',
  },
  [FEED_ORDER_MODE.EDIT]: {
    buttonText: 'Update feed order',
  },
};
