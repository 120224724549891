import { Card } from '@components/Card';
import { Copy } from '@components/Copy';
import { DataFieldProps } from '@components/DataField';
import { DataStack } from '@components/DataStack';
import { useCustomerOrderCustomer } from '@hooks/useCustomerOrderV2';
import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiError } from '@pages/Misc/ApiError';
import React from 'react';

type CustomerOrderCustomerProps = {
  orderId: string;
};

const useStyles = makeStyles({
  shippingAddress: {
    marginTop: '20px !important', // TODO: Figure out why this needs !important
  },
});

const title = 'Customer information';

// eslint-disable-next-line complexity
export const CustomerOrderCustomer = ({ orderId }: CustomerOrderCustomerProps) => {
  const classes = useStyles();
  const { isLoading, customer, deliveryAddress } = useCustomerOrderCustomer(orderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Customer information could not be found after API call
  if (!customer && !deliveryAddress && !isLoading) {
    return <ApiError cardTitle={title} message="Customer information could not be loaded." />;
  }

  // Contact information
  const {
    name: contactName,
    email,
    phoneNumber,
  } = {
    name: customer?.name ?? '',
    email: customer?.email ?? '',
    phoneNumber: deliveryAddress?.phoneNumber ?? '',
  };

  // Shipping information
  const { name, addressLineOne, addressLineTwo, city, province, postalCode, country } = {
    name: deliveryAddress?.name ?? '',
    addressLineOne: deliveryAddress?.addressLineOne ?? '',
    addressLineTwo: deliveryAddress?.addressLineTwo ?? '',
    city: deliveryAddress?.city ?? '',
    province: deliveryAddress?.province ?? '',
    postalCode: deliveryAddress?.postalCode ?? '',
    country: deliveryAddress?.country ?? '',
  };

  // Display the following information
  const items: DataFieldProps[] = [
    {
      label: 'Contact information',
      direction: 'column' as const,
      value: (
        // Individual copy buttons while keeping them as a single block.
        <Stack direction="column" gap={0.5}>
          <Copy value={contactName} />
          <Copy value={email} />
          <Copy value={phoneNumber} />
        </Stack>
      ),
    },
    {
      label: 'Shipping address',
      direction: 'column' as const,
      className: classes.shippingAddress,
      value: (
        <Stack>
          <span>{name}</span>
          <span>
            {addressLineOne} {addressLineTwo}
          </span>
          <span>
            {city}, {province}, {postalCode}, {country}
          </span>
        </Stack>
      ),
      copy: `${name} ${addressLineOne} ${addressLineTwo} ${city} ${province} ${postalCode}`,
    },
  ];

  return (
    <Card title={title}>
      <DataStack stackId="customerOrderCustomer" items={items} isLoading={isLoading} />
    </Card>
  );
};
