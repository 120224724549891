import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Dialog, DialogProps } from '@components/Dialog';
import { Dropzone, FileType } from '@components/Dropzone';
import { Text } from '@components/Text';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';

interface IUploadFileDialogProps extends DialogProps {
  handleSubmit?: (file: File) => Promise<void>;
  allowedFileTypes: FileType[];
  uploadFileLabel: string;
  callToActionText?: string;
  startComponent?: React.ReactNode;
  endComponent?: React.ReactNode;
  isSubmitDisabled?: boolean;
}

export const UploadFileDialog = ({
  allowedFileTypes,
  handleSubmit,
  callToActionText = 'Upload file',
  uploadFileLabel,
  startComponent,
  endComponent,
  isSubmitDisabled,
  ...dialogProps
}: IUploadFileDialogProps) => {
  const [selectedFile, setSelectedFile] = useState<undefined | File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileSelect = (files: File[]) => {
    setSelectedFile(files[0]);
  };

  const handleFileUpload = async () => {
    if (!handleSubmit) {
      return;
    }

    setIsLoading(true);

    try {
      await handleSubmit(selectedFile as File);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      actions={
        <>
          <SecondaryButton
            onClick={() => {
              dialogProps.onClose();
              // eslint-disable-next-line unicorn/no-useless-undefined
              setSelectedFile(undefined);
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            onClick={handleFileUpload}
            disabled={selectedFile == null || isSubmitDisabled}
            loading={isLoading}
          >
            {callToActionText}
          </PrimaryButton>
        </>
      }
      {...dialogProps}
    >
      <Stack gap={2}>
        {startComponent}
        <Box>
          <Text fontSize="12px" marginBottom="10px" fontWeight={500}>
            {uploadFileLabel}
          </Text>
          <Dropzone
            onDrop={handleFileSelect}
            inactiveText="Select file (CSV only)"
            allowedFileTypes={allowedFileTypes}
          />
        </Box>
        {endComponent}
      </Stack>
    </Dialog>
  );
};
