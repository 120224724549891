import { ClearFilters } from '@components/DataFilter/Clear';
import { DataGridV2 } from '@components/DataGrid';
import ErrorBoundary from '@components/ErrorBoundary';
import { usePagination } from '@hooks/useDataStore/usePagination';
import { useSearch } from '@hooks/useDataStore/useSearch';
import { ISorting, SortOrder, useSorting } from '@hooks/useDataStore/useSorting';
import Stack from '@mui/material/Stack';
import React from 'react';

import { columns } from './config/columns';
import { useMerchantFilters } from './config/filters';
import { SearchFilter } from './Filters/Search';
import { StatusFilter } from './Filters/Status';
import { IMerchant, useMerchants } from './graphql';
import { MerchantOverviewHeader } from './Header';

const DEFAULT_SORTING: ISorting = {
  sortField: 'name',
  sortOrder: SortOrder.Asc,
};

/**
 * As per usual, merchants API endpoints are different than all others...
 */
const getSortBy = (field: string, value: number) => `${value === -1 ? '-' : ''}${field}`;

export const ListMerchants = () => {
  // Fetch global filters, pagination & sorting
  const { pagination, updatePagination, getGraphQLPagination } = usePagination();
  const { getDataGridSort, updateDataGridSort, getGraphQLSort } = useSorting(DEFAULT_SORTING);
  const { filters, hasChanged, clearFilters } = useMerchantFilters();
  const { search } = useSearch();

  // Ugh... merchants API endpoints are different than all others...
  const sortValue = getSortBy('name', getGraphQLSort().sortBy['name']);
  const {
    data = [],
    total: totalRowCount,
    isLoading,
  } = useMerchants({
    variables: {
      input: {
        ...getGraphQLPagination(),
        query: {
          ...filters,
          sortBy: sortValue,
          nameSearch: search,
        },
      },
    },
  });

  return (
    <ErrorBoundary>
      <MerchantOverviewHeader />

      <Stack gap={2}>
        {/** All available filters. */}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
            <SearchFilter />
            <StatusFilter />

            <ClearFilters visible={hasChanged} clear={clearFilters} />
          </Stack>
        </Stack>

        <DataGridV2
          rows={data}
          columns={columns}
          loading={isLoading}
          rowCount={totalRowCount}
          getRowId={(merchant: IMerchant) => merchant._id}
          paginationModel={pagination}
          onPaginationModelChange={updatePagination}
          onSortChange={updateDataGridSort}
          initialState={{
            sorting: getDataGridSort(),
          }}
        />
      </Stack>
    </ErrorBoundary>
  );
};
