import * as R from 'ramda';

import { IntegrationType } from '@common/types/GraphqlTypes.d';

import { IMerchant, IMerchantInput } from '../fields';

/**
 * Converts fetched merchant into merchant input values for creating & updating.
 * Both operations allow the same for the moment, but might become different in the future.
 * @param {object} merchant - Fetched merchant.
 */
export const toInputValues = ({
  _id,
  isStripeConnected: _isStripeConnected,
  publishedStage: _publishedStage,
  ...merchant
}: IMerchant): IMerchantInput =>
  R.mergeDeepRight(R.dissocPath(['config', 'shipping'], merchant), {
    private: {
      credentials: {
        // Casting between graphQL enums & string
        integrationType: merchant.private?.credentials?.integrationType as IntegrationType,
      },
    },

    // For whatever reason we don't send shipping when updating the same way we return it when fetching... kill me now
    shipping: {
      bookie: 'self',
      type: merchant.config?.shipping?.type,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      simple: (merchant.config?.shipping?.simple as any) || [],
      free: {
        enabled: merchant.config?.shipping?.free?.enabled,
      },
    },

    // Useless need for nested object...
    squareImage: {
      url: merchant.squareImage as string,
    },

    // Default values (should be set on API side but not yet)
    config: {
      review: {
        auto: true,
      },
    },
    backSplash: {
      hex: '000000',
    },
  });
