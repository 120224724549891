import { withParams } from '@components/WithParams';
import { useCustomerOrderId } from '@hooks/useCustomerOrderV2';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc';
import React from 'react';

import { CustomerOrderComments } from './components/Comments';
import { CustomerOrderCustomer } from './components/Customer';
import { CustomerOrderHistory } from './components/History';
import { CustomerOrderMetadata } from './components/Metadata';
import { CustomerOrderBasicInformation } from './components/OrderInformation';
import { CustomerOrderPayment } from './components/Payment';
import { ViewCustomerOrderHeader } from './Header';

type ViewCustomerOrderProps = {
  id: string;
};

const ViewCustomerOrder = ({ id: customerOrderId }: ViewCustomerOrderProps) => {
  const { isLoading, customerOrder } = useCustomerOrderId(customerOrderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Order could not be found after API call completed loading
  if (!customerOrder && !isLoading) {
    return <NotFound />;
  }

  return (
    <>
      <ViewCustomerOrderHeader orderNumber={customerOrder?.orderNumber} isLoading={isLoading} />

      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <Stack direction="column" spacing={4}>
            <CustomerOrderBasicInformation orderId={customerOrderId} />
            <CustomerOrderComments orderId={customerOrderId} />
            <CustomerOrderHistory orderId={customerOrderId} />
          </Stack>
        </Grid>

        <Grid item sm={12} md={4}>
          <Stack direction="column" spacing={4}>
            <CustomerOrderCustomer orderId={customerOrderId} />
            <CustomerOrderPayment orderId={customerOrderId} />
            <CustomerOrderMetadata
              customerOrder={{
                _id: customerOrderId,
                publicId: customerOrder?.publicId || 'loading',
              }}
              isLoading={isLoading}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export const ViewOrderPage = withParams<Pick<ViewCustomerOrderProps, 'id'>>(
  ['id'],
  ViewCustomerOrder,
);
