import { Header } from '@components/Header';
import useRouter from '@hooks/useRouter';
import { Button } from '@mui/material';
import React from 'react';

import { ProfileActions } from './Actions';

export const ProfileHeader = () => {
  const { goto } = useRouter();

  return (
    <Header
      title="Your profile"
      actions={<ProfileActions />}
      buttons={
        <Button variant="contained" color="primary" onClick={() => goto.editProfile()}>
          Update profile
        </Button>
      }
    />
  );
};
