import { Card } from '@components/Card';
import { DataGridV2 } from '@components/DataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';

import { Product } from '@common/types/GraphqlTypes.d';

import { IProduct } from '../graphql';

type BasicInformationProps = {
  products?: IProduct[];
  isLoading?: boolean;
};

const columns: GridColDef<Product>[] = [
  {
    field: 'publicId',
    headerName: 'Public ID',
    width: 300,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
  },
  {
    field: 'ProductURL',
    headerName: 'Product URL',
    valueGetter: ({ row }) => row?.productURL ?? 'Unknown URL',
    sortable: false,
    flex: 1,
  },
];

export const ViewProducts = ({ isLoading = true, products }: BasicInformationProps) => (
  <Card title="Products" isLoading={isLoading} noPadding>
    {!isLoading && products && (
      <DataGridV2
        rows={products}
        columns={columns}
        loading={isLoading}
        rowCount={products.length}
        getRowId={(product: IProduct) => product.publicId}
        pagination={false}
      />
    )}
  </Card>
);
