import { CopyToAriaChromeExtension } from '@components/ChromeExtension';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CustomerOrderFulfillmentStatus } from '@pages/CustomerOrderV2/View/components/OrderInformation/FulfillmentStatus';
import { GoToPageItem } from '@pages/Order/components/GoToPageMenuItem';
import { OrderItems } from '@pages/Order/components/OrderItems';
import { PrivateRoutes } from '@routes/private';
import { FulfillmentStatus } from '@types';
import React from 'react';

import { CustomerOrder } from '@common/types/GraphqlTypes.d';

import { OrderCard, OrderTabLabels } from '../../OrderCard';
import { IUnprocessedOrderItems } from '../interfaces';
import { UpdateFulfillmentStatusItem } from '../menuItems';
import { SendExternalOrderItem, SendMerchantTransferOrderItem } from './menuItems';

interface IUnprocessedItemsProps {
  /**
   * Current customer order.
   */
  customerOrder: CustomerOrder;

  /**
   * List of unprocessed items within specific merchant order.
   */
  items: IUnprocessedOrderItems;
}

export const UnprocessedItem = ({ items, customerOrder }: IUnprocessedItemsProps) => {
  const { merchantOrderId, integrationType, checkoutVariants, merchantName, fulfillmentStatus } =
    items;

  const menuItems = (
    <>
      <GoToPageItem
        label="Submit Feed Order"
        path={PrivateRoutes.createFeedOrder}
        pathParams={[merchantOrderId]}
      />
      <GoToPageItem
        label="Refund Order"
        path={PrivateRoutes.refundOrder}
        pathParams={[merchantOrderId]}
      />

      <SendExternalOrderItem integrationType={integrationType} merchantOrderId={merchantOrderId} />
      <SendMerchantTransferOrderItem
        integrationType={integrationType}
        merchantOrderId={merchantOrderId}
      />
      <UpdateFulfillmentStatusItem
        fulfillmentStatus={FulfillmentStatus.CANCELLED}
        merchantOrderId={merchantOrderId}
      />
    </>
  );

  const tabPanels = {
    [OrderTabLabels.Details]: {
      component: (
        <OrderItems
          checkoutVariants={checkoutVariants}
          integrationType={integrationType}
          merchantName={merchantName}
        />
      ),
    },
    [OrderTabLabels.Shipping]: {
      disabled: true,
      component: <></>,
    },
    [OrderTabLabels.Payment]: {
      disabled: true,
      component: <></>,
    },
    [OrderTabLabels.Other]: {
      disabled: true,
      component: <></>,
    },
  };

  return (
    <OrderCard
      menuItems={menuItems}
      tabPanels={tabPanels}
      header={
        fulfillmentStatus === FulfillmentStatus.CANCELLED ? (
          <CustomerOrderFulfillmentStatus status={FulfillmentStatus.CANCELLED} />
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontSize="15px" fontWeight={600}>
              Products need to be processed
            </Typography>
            <CopyToAriaChromeExtension customerOrder={customerOrder} items={items} />
          </Stack>
        )
      }
    />
  );
};
