import { Card } from '@components/Card';
import { DataStack } from '@components/DataStack';
import React from 'react';

import { IProductCollection } from '../graphql';

type MetadataProps = {
  collection?: IProductCollection;
  isLoading?: boolean;
};

export const rows = ({ _id, publicId }: IProductCollection) => [
  {
    label: 'Internal ID',
    value: _id,
  },
  {
    label: 'Public ID',
    value: publicId,
  },
];

export const ViewMetaData = ({ isLoading, collection }: MetadataProps) => (
  <Card title="Metadata" isLoading={isLoading}>
    <DataStack
      stackId="metadata"
      isLoading={isLoading}
      items={collection ? rows(collection) : []}
    />
  </Card>
);
