import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { MERCHANT_FIELDS } from '../../fields';
const { metadata: inputField } = MERCHANT_FIELDS;

export const Metadata = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Metadata">
    <Stack spacing={2}>
      <TextField
        id={inputField.id}
        isLoading={isLoading}
        label="Internal ID"
        allowCopy
        disabled
        useHook
      />
    </Stack>
  </Card>
);
