import { MoreVert, SvgIconComponent } from '@mui/icons-material';
import { Box, Menu, MenuProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactElement, createContext, useContext } from 'react';

import { Color } from '../../theme/palette';

export interface IMoreVertButtonProps {
  menuItems: ReactElement;
  className?: string;
  Icon?: SvgIconComponent;
}

type MenuContextValues = { handleClose: () => void };

// eslint-disable-next-line @typescript-eslint/no-empty-function
const MenuContext = createContext<MenuContextValues>({ handleClose: () => {} });

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiMenuItem-root': {
    // Allows to display white space in the menu item.
    whiteSpace: 'pre-wrap',
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      marginRight: theme.spacing(1.5),
    },
  },
}));

export const useDropDownMenu = () => useContext(MenuContext);

/**
 * Button that expands a dropdown menu.
 * Defaults to a vertical 3 dot (MoreVert) icon.
 * Https://mui.com/material-ui/material-icons/?query=dots&selected=MoreVert.
 * @param input - More vertical button input.
 * @param input.moreOptions - Dropdown components to display when toggled.
 * @param input.className - Optional class name for the button.
 * @param input.Icon - Option icon for the button.
 * @returns {React.FC} - Vertical 3 dot button that expands to show more options when clicked.
 */
export const DropDownButton: React.FC<IMoreVertButtonProps> = ({
  menuItems,
  className,
  Icon = MoreVert,
}): JSX.Element => {
  const [anchorElement, setAnchorElement] = React.useState<null | SVGSVGElement>(null);

  const setAnchor = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Box
        className={className}
        sx={{
          alignItems: 'center',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          fontSize: 24,
          marginLeft: '0',
          padding: '8px',
          transition: '0.250s',
          '&:hover': {
            backgroundColor: Color.Gray2,
          },
        }}
      >
        <Icon sx={{ cursor: 'pointer' }} onClick={setAnchor} fontSize="inherit" />
      </Box>

      <StyledMenu anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleClose}>
        <MenuContext.Provider value={{ handleClose }}>{menuItems}</MenuContext.Provider>
      </StyledMenu>
    </>
  );
};
