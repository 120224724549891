import axios, { HttpStatusCode } from 'axios';

import { ENV } from '@constants/environment';

import { getZipCode } from './zipcode';

import { ICheckoutInput } from '..';

// Should be shared from a package - currently not available
export type ICheckoutCreate = {
  products: {
    public_id: string;
    variant_id: string;
    quantity: number;
  }[];
  shipping_address: {
    name: string;
    first_name: string;
    last_name: string;
    address1: string;
    address2: string | null;
    phone: string;
    city: string;
    zip: string;
    province: string;
    country: string;
  };
  target_currency: string;
};

export type ICheckoutStatus = 'success' | 'error' | 'warning';

/**
 * Returns mapped values needed to perform checkout API call.
 * @throws {Error} If no state or zip code found for the request.
 */
export const getCheckoutValues = (
  productId: string,
  variantId: string,
  input: ICheckoutInput,
): ICheckoutCreate => {
  const { state, zipCode } = getZipCode(input.country, input.state);

  if (!state || !zipCode) {
    throw new Error(
      `No state or zip code found for the request with country ${input.country} and state ${input.state}`,
    );
  }

  return {
    products: [
      {
        public_id: productId,
        variant_id: variantId,
        quantity: 1,
      },
    ],
    shipping_address: {
      name: 'Beam Checkout',
      first_name: 'Beam',
      last_name: 'Checkout',
      address1: '711 - York Street',
      address2: null,
      phone: '555-625-1199',
      city: 'Toronto', // Should not matter for tax calculation
      zip: zipCode,
      province: state,
      country: input.country,
    },
    target_currency: input.currency,
  };
};

/**
 * Handles API error.
 * @param {Error} error - Any potential API error.
 * @throws {Error} If error is not an Axios error.
 */
const handleApiError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response) {
      return {
        data: error.response.data,
        httpStatus: error.response.status,
      };
    }

    return {
      data: error.request || { message: error.message },
      httpStatus: HttpStatusCode.InternalServerError,
    };
  }

  // Shit hit the fan, do not handle this
  throw error;
};

/**
 * Performs checkout API call.
 * @throws {Error} If API call fails.
 */
export const performApiCheckout = async (
  productPublicId: string,
  variantId: string,
  values: ICheckoutInput,
): Promise<{
  input: { headers: Record<string, unknown>; body: ICheckoutCreate };
  data: Record<string, unknown>;
  status: ICheckoutStatus;
  httpStatus: number;
}> => {
  const input = getCheckoutValues(productPublicId, variantId, values);

  const headers = { 'Api-Key': values.account?.apiKey };

  try {
    const { data, status: httpStatus } = await axios.post(`${ENV.ordersApiUrl}/checkouts`, input, {
      headers,
    });

    // For checkout we return HTTP 200 but have errors in the body
    const hasErrors = (data?.errors || []).length > 0;

    return {
      data,
      input: { headers, body: input }, // Mainly for visual transparency
      httpStatus,
      status: hasErrors ? 'warning' : 'success',
    };
  } catch (error: unknown) {
    const { httpStatus, data } = handleApiError(error);

    return {
      httpStatus,
      data,
      status: 'error',
      input: { headers, body: input }, // Mainly for visual transparency
    };
  }
};
