import { Card } from '@components/Card';
import { DataStack } from '@components/DataStack';
import { useCustomerOrderPayment } from '@hooks/useCustomerOrderV2';
import React from 'react';

type CustomerOrderPaymentProps = { orderId: string };

export const CustomerOrderPayment = ({ orderId }: CustomerOrderPaymentProps) => {
  const { isLoading, payment } = useCustomerOrderPayment(orderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Display the following information
  const items = [
    { label: 'Type', value: payment.paymentMethod },
    { label: 'Status', value: payment.paymentStatus },
  ];

  return (
    <Card title="Payment information">
      <DataStack stackId="customerOrderPayment" items={items} isLoading={isLoading} />
    </Card>
  );
};
