import { Header } from '@components/Header';
import React from 'react';

import { RoleActions } from './Actions';
import { IRole } from './graphql';

type ActionsProps = {
  role?: IRole;
  isLoading: boolean;
};

export const EditRoleHeader = ({ role, isLoading }: ActionsProps) => (
  <Header
    title={`Edit role - ${role?.name}`}
    isLoading={isLoading}
    actions={role && <RoleActions role={role} />}
  />
);
