import { Card } from '@components/Card';
import { DataField } from '@components/DataField';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { TextField } from '@components/Input/TextField';
import { StatusTag } from '@components/StatusTag';
import PercentIcon from '@mui/icons-material/Percent';
import { Stack } from '@mui/material';
import { Color } from '@theme/palette';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MERCHANT_CURRENCIES, MERCHANT_DEFAULTS, MERCHANT_COMMISSIONS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { financial: inputField } = MERCHANT_FIELDS;

type FinancialInformationProps = {
  /**
   * Indicates whether the form data is still loading.
   * Only used during edit-mode.
   */
  isLoading?: boolean;

  /**
   * Indiciates whether the stripe connection should be shown.
   * Impossible to show when creating a merchant.
   */
  showStripe?: boolean;
};

export const FinancialInformation = ({
  isLoading,
  showStripe = false,
}: FinancialInformationProps) => {
  const { getValues } = useFormContext();

  const isStripeConnected = getValues(inputField.isStripeConnected);
  const stripeAccount = getValues(inputField.stripeAccount);

  return (
    <Card title="Financial Information">
      <Stack spacing={2}>
        {showStripe && (
          <DataField
            label="Stripe status"
            value={
              <StatusTag
                text={isStripeConnected ? `Connected (${stripeAccount})` : 'Not Connected'}
                color={Color.Blue}
              />
            }
          />
        )}
        <ControlledSelect
          id={inputField.currency}
          isLoading={isLoading}
          label="Currency"
          options={MERCHANT_CURRENCIES}
          defaultValue={MERCHANT_DEFAULTS.currency}
        />
        <TextField
          id={inputField.fee}
          isLoading={isLoading}
          type="number"
          label="Fee"
          placeholder="Merchant fee"
          icon={{ position: 'end', value: PercentIcon }}
          options={{
            required: 'Fee is required',
          }}
          useHook
        />
        <ControlledSelect
          id={inputField.commissionType}
          isLoading={isLoading}
          label="Commission type"
          options={MERCHANT_COMMISSIONS}
          defaultValue={MERCHANT_DEFAULTS.comissionType}
        />
        <Switch
          id={inputField.canUseFlexiblePayment}
          label="Should use flexible payments"
          useHook
        />
      </Stack>
    </Card>
  );
};
