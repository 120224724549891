/* eslint-disable @typescript-eslint/no-explicit-any */

import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import { useUploadImage } from '@hooks/useImageUpload';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc/NotFound';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { ClientStatus } from '@common/types/GraphqlTypes.d';

import { BasicInformation } from '../components/Basic/Basic';
import { FinancialInformation } from '../components/Basic/Financial';
import { IntegrationInformation } from '../components/Basic/Integration';
import { OfficeInformation } from '../components/Basic/Office';
import { TaxesInformation } from '../components/Basic/Taxes';
import { Webhooks } from '../components/Basic/Webhooks';
import { Fulfillment } from '../components/Fulfillment';
import { Inventory } from '../components/Inventory';
import { Metadata } from '../components/Metadata';
import { ShippingConfiguration } from '../components/Shipping/Configuration';
import { ShippingOptions } from '../components/Shipping/Options';
import { IMerchant } from '../fields';
import { useEditMerchant, useMerchant } from './graphql';
import { EditMerchantHeader } from './Header';
import { getInputValues, toInputValues } from './utils';

type IEditMerchantParams = { id: string };

const EditMerchant = ({ id }: IEditMerchantParams) => {
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IMerchant>({
    mode: 'onSubmit',
  });

  // Track when fields got updated
  const { isDirty: hasFormChanged } = useFormState({
    control: form.control,
  });

  // GraphQL hooks
  const { isLoading, data } = useMerchant(id, {
    onCompleted: ({ merchant }) => {
      form.reset(getInputValues(merchant)); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching merchant:', error);
    },
  });

  const { uploadImage } = useUploadImage();
  const {
    editMerchantMutation,
    apiError: updateApiError,
    isLoading: isSubmitting,
  } = useEditMerchant();

  // Handle create form being submitted
  const onSubmit = async (values: IMerchant) => {
    // 1. Updates logo if changed
    const logo = await uploadImage('logo', values.squareImage?.url);

    const updateValues = toInputValues(
      R.mergeDeepRight(values, { squareImage: logo }) as IMerchant,
    );

    // 2. Update merchant
    editMerchantMutation({
      variables: { input: { id, ...updateValues } },
      onCompleted: () => {
        enqueueSnackbar('Successfully updated merchant');

        // Set values to submitted values (resets dirty state)
        form.reset(values);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating merchant:', error);
      },
    });
  };

  const onCancel = () => {
    form.reset();
  };

  // Merchant could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <>
      <EditMerchantHeader merchant={data} isLoading={isLoading} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={8}>
                <Stack gap={4}>
                  <BasicInformation isLoading={isLoading} canEditSlug={false} />
                  <OfficeInformation isLoading={isLoading} />
                  <Fulfillment isLoading={isLoading} />
                  <ShippingOptions isLoading={isLoading} />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack gap={4}>
                  <IntegrationInformation isLoading={isLoading} />
                  <FinancialInformation isLoading={isLoading} showStripe />
                  <TaxesInformation isLoading={isLoading} />
                  <ShippingConfiguration isLoading={isLoading} />
                  <Inventory isLoading={isLoading} />
                  <Webhooks isLoading={isLoading} />
                  <Metadata isLoading={isLoading} />
                </Stack>
              </Grid>
            </Grid>

            {data?.publishedStage !== ClientStatus.Archived && (
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  {hasFormChanged && <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>}
                  <PrimaryButton
                    type="submit"
                    disabled={isLoading || !hasFormChanged}
                    loading={isSubmitting}
                  >
                    Save changes
                  </PrimaryButton>
                </Stack>
              </Grid>
            )}
          </Stack>
        </Form>
      </FormProvider>
    </>
  );
};

export const EditMerchantPage = withParams<IEditMerchantParams>(['id'], EditMerchant);
