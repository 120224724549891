import { CardTitle } from '@components/CardTitle';
import React from 'react';

import { CustomerOrder, MerchantOrder as IMerchantOrder } from '@common/types/GraphqlTypes.d';

import { FulfilledOrders } from './fulfilledOrders';
import { ProcessedOrders } from './processedOrders';
import { UnprocessedItems } from './UnprocessedItems';
import { getTotalItems, getProcessedOrdersAndUnprocessedItems } from './utils';

interface IOrderDetailsProps {
  /**
   * Current customer order.
   */
  customerOrder: CustomerOrder;

  /**
   * List of merchant orders within customer order.
   */
  merchantOrders: IMerchantOrder[];
}

export const OrderedItems: React.FC<IOrderDetailsProps> = ({ merchantOrders, customerOrder }) => {
  const totalItems = getTotalItems(merchantOrders);

  const { unprocessedItems, processedOrders, fulfilledOrders } =
    getProcessedOrdersAndUnprocessedItems(merchantOrders);

  return (
    <div>
      <CardTitle title={`Order details - ${totalItems} item(s)`} divider={true} />

      <UnprocessedItems items={unprocessedItems} customerOrder={customerOrder} />
      <ProcessedOrders orders={processedOrders} />
      <FulfilledOrders orders={fulfilledOrders} />
    </div>
  );
};
