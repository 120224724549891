import { HistoryTimeline } from '@components/HistoryTimeline';
import React from 'react';

import { EventIcon } from './EventIcon';
import { OrderPrimaryEvent, OrderSecondaryEvent } from './interfaces';

export type OrderHistoryTimelineProps = {
  items: (OrderPrimaryEvent | OrderSecondaryEvent)[];
};

export const OrderHistoryTimeline = ({ items }: OrderHistoryTimelineProps) => (
  <HistoryTimeline
    items={items.map(({ type, status, ...rest }) => ({
      ...rest,
      icon: <EventIcon type={type} status={status} />,
    }))}
  />
);
