import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    transform: 'inherit', // Needed to counteract fanciness on MUI labels
    position: 'inherit', // Needed to counteract fanciness on MUI labels
    fontSize: '0.8rem',
    marginBottom: '5px',
  },
  icon: {
    fontSize: '0.95rem',
  },
  copyIcon: {
    cursor: 'pointer',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginTop: '5px',
  },
  hidden: {
    display: 'none',
  },
  loader: {
    transform: 'none',
  },
  input: {
    /*     paddingTop: '2px',
    paddingBottom: '2px', */
  },
  tooltip: {
    fontSize: '1rem',
    cursor: 'pointer',
    marginLeft: 3,
    marginTop: -3,
  },
}));
