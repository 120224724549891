import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { MERCHANT_COUNTRIES, MERCHANT_DEFAULTS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { office: inputField } = MERCHANT_FIELDS;

export const OfficeInformation = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Office">
    <Stack spacing={2}>
      <TextField
        id={inputField.name}
        isLoading={isLoading}
        label="Name"
        placeholder="Provide name"
        options={{
          required: 'Office name is required',
        }}
        useHook
      />
      <TextField
        id={inputField.addressLine1}
        isLoading={isLoading}
        label="Address line 1"
        placeholder="Provide address line 1"
        options={{
          required: 'Address line 1 is required',
        }}
        useHook
      />
      <TextField
        id={inputField.addressLine2}
        isLoading={isLoading}
        label="Address line 2"
        useHook
      />

      <Stack direction="row" spacing={2}>
        <TextField
          id={inputField.postalCode}
          isLoading={isLoading}
          label="Postal code"
          placeholder="Provide valid postal code"
          options={{
            required: 'Postal code is required',
          }}
          useHook
        />
        <TextField
          id={inputField.city}
          isLoading={isLoading}
          label="City"
          placeholder="Provide valid city"
          options={{
            required: 'City is required',
          }}
          useHook
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          id={inputField.region}
          isLoading={isLoading}
          label="2 Character region code"
          placeholder="Provide valid 2 Character region code"
          options={{
            required: '2 Character region code is required',
          }}
          useHook
        />
        <ControlledSelect
          id={inputField.country}
          isLoading={isLoading}
          label="Country"
          options={MERCHANT_COUNTRIES}
          defaultValue={MERCHANT_DEFAULTS.country}
        />
      </Stack>

      <TextField
        id={inputField.email}
        isLoading={isLoading}
        label="Contact email"
        placeholder="Provide contact email"
        options={{
          required: 'Contact email is required',
        }}
        useHook
      />
      <TextField
        id={inputField.phoneNumber}
        isLoading={isLoading}
        label="Phone number"
        placeholder="Provide phone number"
        options={{
          required: 'Phone number is required',
        }}
        useHook
      />
    </Stack>
  </Card>
);
