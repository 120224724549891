/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/naming-convention */

import { useMutation } from '@apollo/client';
import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { Header } from '@components/Header';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCurrentUser } from '@hooks/useCurrentUser';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import Stack from '@mui/material/Stack';
import Joi from 'joi';
import React from 'react';
import { useForm } from 'react-hook-form';

import { UpdateProfileDetails } from '@common/types/GraphqlTypes.d';

import { EditProfileBody } from './body';
import { UPDATE_MY_PROFILE_DETAILS_MUTATION } from './graphql';

export interface IFormData {
  email: string;
  firstName: string;
  lastName: string;
}

const formSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
});

export const EditProfile = () => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const { currentUser, isLoading: currentUserDataLoading } = useCurrentUser();

  const {
    formState: { errors, isDirty: hasFormChanged },
    register,
    handleSubmit,
  } = useForm<IFormData>({
    mode: 'onChange',
    resolver: joiResolver(formSchema),
  });

  const [updateProfile, { error: updateApiError, loading: isLoading }] = useMutation<
    {
      updateMyProfileDetails: boolean;
    },
    { input: UpdateProfileDetails }
  >(UPDATE_MY_PROFILE_DETAILS_MUTATION, {
    errorPolicy: 'all',
    onCompleted: () => {
      enqueueSnackbar('Successfully updated profile');
    },
  });

  if (!currentUser || currentUserDataLoading) {
    return <div>Loading...</div>;
  }

  const { profile, email } = currentUser;

  const onSubmit = ({ firstName, lastName }: IFormData) => {
    updateProfile({
      variables: {
        input: {
          firstName,
          lastName,
        },
      },
    });
  };

  return (
    <>
      <Header title="Update profile" />

      <Form error={updateApiError} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Card title="Profile information">
            <EditProfileBody
              email={email}
              errors={errors}
              register={register}
              firstName={profile.firstName}
              lastName={profile.lastName}
            />
          </Card>

          <Stack direction="row" spacing={1} justifyContent="end">
            {hasFormChanged && (
              <SecondaryButton onClick={() => goto.viewProfile()}>Cancel</SecondaryButton>
            )}
            <PrimaryButton type="submit" disabled={isLoading || !hasFormChanged}>
              Update Profile
            </PrimaryButton>
          </Stack>
        </Stack>
      </Form>
    </>
  );
};
