import { StatusTag, StatusTagType } from '@components/StatusTag';
import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';

import { ClientStatus } from '@common/types/GraphqlTypes.d';

import { IBrand } from '../graphql';

const statusTagConditions = (publishedStage: ClientStatus) => [
  { type: StatusTagType.Success, match: () => publishedStage === ClientStatus.Live },
  { type: StatusTagType.Warn, match: () => publishedStage === ClientStatus.Draft },
  { type: StatusTagType.Error, match: () => publishedStage === ClientStatus.Archived },
];

export const columns: GridColDef<IBrand>[] = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'publishedStage',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row: { publishedStage } = {} }) => (
      <StatusTag
        text={publishedStage as ClientStatus}
        conditions={statusTagConditions(publishedStage as ClientStatus)}
      />
    ),
    sortable: false,
  },
];
