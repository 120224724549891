/* eslint-disable max-lines-per-function */

import { gql, useMutation } from '@apollo/client';
import { Form } from '@components/Form';
import { EmailInput } from '@components/Input/__deprecated/Email';
import { Alert } from '@mui/lab';
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import publicIp from 'public-ip';
import React from 'react';
import { browserName } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useStyles } from './styles';

interface IFormData {
  email: string;
}

interface ILoginFormProps {
  className: string;
}

const RESET_PASSWORD_REQUEST_MUTATION = gql`
  mutation resetPasswordRequestV2($input: ResetPasswordRequestInputV2!) {
    resetPasswordRequestV2(input: $input) {
      result
    }
  }
`;

export const ResetForm = ({ className }: ILoginFormProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { formState, register, handleSubmit } = useForm<IFormData>({
    mode: 'onChange',
  });

  const [resetPasswordRequest, { error, loading: isLoading, data }] = useMutation(
    RESET_PASSWORD_REQUEST_MUTATION,
    {
      errorPolicy: 'all',
    },
  );

  const onSubmit = async ({ email }: IFormData) => {
    const ipAddress = await publicIp.v4();

    resetPasswordRequest({
      variables: { input: { browserName, email, ipAddress } },
    });
  };

  const { isValid } = formState;

  return (
    <Form className={clsx([classes.root, className])} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.header}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          Reset Password
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
          To reset your password, enter your email below and submit. An email will be sent to you
          with instructions about how to complete the processs.
        </Typography>
      </div>
      <EmailInput
        className={classes.emailInput}
        hasError={Boolean(error)}
        name="email"
        placeholder={'Email'}
        register={register}
      />
      {isLoading ? <CircularProgress /> : null}
      <Button
        className={classes.loginButton}
        color="primary"
        disabled={!isValid || isLoading}
        onClick={handleSubmit(onSubmit)}
        type="submit"
        variant="contained"
      >
        Reset Password
      </Button>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      {data?.resetPasswordRequestV2?.result ? (
        <Alert severity="success">
          Email Sent! Please check your email to reset your password.
        </Alert>
      ) : null}
      <Button onClick={() => navigate(-1)} className={classes.forgotPassword}>
        Go Back
      </Button>
    </Form>
  );
};
