import { DropDownButton } from '@components/Button/Dropdown';
import { SecondaryButton } from '@components/Button/Secondary';
import { Image } from '@components/Image';
import { useSnackbar } from '@hooks/useSnackbar';
import { ArrowDropDown } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { PrivateRoutes } from '@routes/private';
import { Color } from '@theme/palette';
import React from 'react';

import { CheckoutVariant, ShippingCarrier } from '@common/types/GraphqlTypes.d';

import { DataField } from '../../../../components/DataField';
import { StatusTag } from '../../../../components/StatusTag';
import { GoToPageItem } from '../GoToPageMenuItem';
import { useStyles } from './styles';
import { VariantOptions } from './variantOptions';

interface ProductShipmentDetails {
  _id: string;
  trackingNumber: string;
  shippingCarrier: string;
  shippingCarrierCustomName?: string | null;
  trackingUrl: string;
  cancelled: boolean;
  quantity: number;
}

interface IShippedProduct {
  checkoutVariant: CheckoutVariant;
  shipmentDetails: ProductShipmentDetails;
  merchantOrderId: string;
}

const StyledDataField = styled(DataField)(({ theme }) => ({
  justifyContent: 'flex-start',
  '& .label': {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.grey[500], // replace with actual color value
  },
  '& .value': {
    fontSize: '13px',
  },
}));

export const ShippedProduct = ({
  checkoutVariant,
  shipmentDetails,
  merchantOrderId,
}: IShippedProduct) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { variant, name } = checkoutVariant;
  const {
    quantity,
    trackingNumber,
    trackingUrl,
    cancelled: isCancelled,
    _id: shipmentId,
    shippingCarrier,
    shippingCarrierCustomName,
  } = shipmentDetails;

  const shippingCarrierText =
    shippingCarrier === ShippingCarrier.Other
      ? `${ShippingCarrier.Other} - ${shippingCarrierCustomName}`
      : shippingCarrier;

  const [firstImage] = variant.images || [];

  const handleClick = async () => {
    await navigator.clipboard.writeText(trackingUrl);
    enqueueSnackbar('Copied to clipboard');
  };

  const tagProps = {
    text: isCancelled ? 'Cancelled' : 'Shipped',
    color: isCancelled ? Color.Red : Color.Green,
  };

  return (
    <Stack direction="row" gap={2}>
      <Image image={firstImage} name={name} />
      <Grid container>
        <Grid container className={classes.gridRow} spacing={1}>
          <Grid item>
            <Typography className={classes.productName}>{name}</Typography>
          </Grid>
          <Grid item className={classes.firstColumn}>
            <Stack direction="row" gap={0.5} justifyContent="flex-end">
              <StatusTag {...tagProps} />
              <DropDownButton
                Icon={ArrowDropDown}
                menuItems={
                  <GoToPageItem
                    label="Edit shipping tracking"
                    path={PrivateRoutes.editShippingTracking}
                    pathParams={[merchantOrderId, shipmentId]}
                  />
                }
                className={classes.dropDownIcon}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container className={classes.gridRow} spacing={1} columns={16}>
          <Grid item xs={5}>
            <VariantOptions
              optionTitles={variant.optionTitles}
              optionValues={variant.optionValues}
            />
          </Grid>
          <Grid item xs>
            <StyledDataField label="Carrier" value={shippingCarrierText} />
          </Grid>
        </Grid>
        <Grid container className={classes.gridRow} spacing={1} columns={16}>
          <Grid item xs={5}>
            <StyledDataField label="Quantity" value={quantity} />
          </Grid>
          <Grid item xs={7}>
            <StyledDataField label="Tracking Number" value={trackingNumber} />
          </Grid>
          <Grid item xs textAlign="right">
            <SecondaryButton
              className={classes.copyButton}
              onClick={handleClick}
              variant="outlined"
            >
              Copy tracking link
            </SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
