import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { AuthProvider } from '@providers/AuthProvider';
import { SnackBarProvider } from '@providers/SnackBarProvider';
import { ThemeProvider } from '@theme/index';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { withStore } from 'react-context-hook';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { client } from '@lib/apollo/client';

import { ENV } from './constants/environment';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';

// Bonsai secrets cannot handle the = sign, so we need to decode it
LicenseInfo.setLicenseKey(decodeURIComponent(ENV.materialUiProLicense));

const rootElement = document.querySelector('#root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <BrowserRouter basename="/">
        <ThemeProvider>
          <SnackBarProvider>
            <StyledEngineProvider injectFirst>
              <Routes />
            </StyledEngineProvider>
          </SnackBarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  </ApolloProvider>
);

// See https://github.com/Spyna/react-context-hook/blob/master/DOCS.md#examples-7 for more info
const storeConfig = {
  listener: () => {},
  logging: process.env.NODE_ENV !== 'production',
};

const WrappedApp = withStore(App, {}, storeConfig);

// TODO: Would be nice to actually use context & users for Beam given limited users. Would allow A/B rollout of features.
const LDUser = {
  kind: 'user',
  key: 'user-anynomous',
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ENV.launchDarklyClientId,
    user: LDUser,
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <WrappedApp />
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
