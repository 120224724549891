interface IDownloadFileParams {
  fileName: string;
  encodedFile: string;
  type: string;
}

/**
 * Decodeds a file and then downloads it.
 * @param input.fileName - The name of encodedFile.
 * @param input.encodedFile - An encoded file to decode before downloading.
 * @param input.type - The file type of encodedFile.
 */
export const downloadFile = ({ fileName, encodedFile, type }: IDownloadFileParams): void => {
  const decodedCsv = decodeURIComponent(encodedFile);
  const file = new Blob([decodedCsv], { type });
  const download = document.createElement('a');

  download.setAttribute('href', window.URL.createObjectURL(file));
  download.setAttribute('download', fileName);
  download.click();
};

/**
 * Simple programmatic file download.
 * @param uri - URI to download.
 * @param name - Filename to store locally.
 */
export const downloadFileFromURI = (uri: string, name: string) => {
  const link = document.createElement('a');

  link.download = name;
  link.href = uri;
  document.body.append(link);
  link.click();
  link.remove();
};
