import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import VerifiedIcon from '@mui/icons-material/Verified';
import React from 'react';

import { useValidateMerchantCredentials } from '../graphql';

type ActionsProps = {
  id: string;
};

export const ValidateCredentialsMenuItem = ({ id }: ActionsProps) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const { getData } = useValidateMerchantCredentials(id, {
    onCompleted: ({ validateMerchantCredentials: { result } }) => {
      const credentialsResultMessage = result ? 'valid' : 'invalid';

      enqueueSnackbar(`Merchant credentials are ${credentialsResultMessage}`, {
        variant: result ? 'success' : 'error',
      });
      handleClose();
    },
  });

  return <MenuItem action={getData} icon={VerifiedIcon} label="Validate Credentials" />;
};
