/* eslint-disable jsx-a11y/click-events-have-key-events */

import { RemoveCircle, AddCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { remove } from 'ramda';
import React, { useState, useEffect } from 'react';

import { ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { useStyles } from './styles';

interface IQuantityButtonProps {
  getValues: () => ShortcutProduct[];
  setValues: (items: ShortcutProduct[]) => void;
  productId: string;
  variantId: string;
  startingQuantity: number;
  totalQuantity: number;
  index: number;
}

/**
 * This adds two buttons that is attached to a specific product from a feed order and allows the user to specify how many items they want.
 * @param totalQuantity - Total stock for the product this button is attached to.
 * @returns None.
 */
export const QuantityButton: React.FC<IQuantityButtonProps> = ({
  getValues,
  setValues,
  productId,
  startingQuantity,
  totalQuantity,
  variantId,
  index,
}) => {
  const classes = useStyles();

  const [quantity, setQuantity] = useState(startingQuantity);

  useEffect(() => {
    const products = getValues();
    const newValues = [...remove(index, 1, products), { productId, quantity, variantId }];

    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleIncrement = () => {
    const updatedQuantity = quantity + 1;

    if (quantity < totalQuantity) {
      setQuantity(updatedQuantity);
    }
  };

  const handleDecrement = () => {
    const updatedQuantity = quantity - 1;

    if (quantity > 0) {
      setQuantity(updatedQuantity);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer} onClick={handleDecrement} role="button" tabIndex={0}>
        <RemoveCircle color={quantity === 0 ? 'disabled' : 'inherit'} />
      </div>
      <Typography className={classes.buttonText}>
        {quantity} <span>&nbsp;</span>
        <span className={classes.endText}> of {totalQuantity}</span>
      </Typography>
      <div className={classes.iconContainer} onClick={handleIncrement} role="button" tabIndex={0}>
        <AddCircle color={quantity === totalQuantity ? 'disabled' : 'inherit'} />
      </div>
    </div>
  );
};
