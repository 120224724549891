import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { getProfileColorForId } from '@theme/palette';

import { useTypedQuery } from '@utils/graphql';

import { User as IUser } from '../../common/types/GraphqlTypes.d';
import { CONTRACTORS_QUERY } from './graphql';

export type ICxContractor = {
  userId: string;
  name: string;
  color: string;
};

export type AssigneeAutocomplete = {
  _id: string;
  profile: {
    name: string;
  };
  email: string;
};

const mapUsersToContractors = (users: IUser[]): ICxContractor[] =>
  users.map(({ _id, profile, email }) => ({
    color: getProfileColorForId(_id),
    name: profile?.name ?? email,
    userId: _id,
  }));

/**
 * Returns all CX contractor user accounts.
 */
export const useCxContractersV2 = (
  options: QueryHookOptions<{
    allCxContractorUsers: { users: IUser[] };
  }> = {},
) => {
  const { data, loading: isLoading, error } = useTypedQuery(CONTRACTORS_QUERY, {}, options);

  const { users = [] } = data?.allCxContractorUsers || {};

  const contractors = mapUsersToContractors(users);

  return { contractors, error, isLoading };
};

/**
 * Returns hook for lazy-fetching assignees (aka users).
 */
export const useAssigneeAutocomplete = (): {
  query: (value: string) => Promise<AssigneeAutocomplete[]>;
  isLoading: boolean;
} => {
  const [operation, { loading: isLoading }] = useLazyQuery<{
    allCxContractorUsers: { users: AssigneeAutocomplete[] };
  }>(CONTRACTORS_QUERY);

  const query = async (value: string): Promise<AssigneeAutocomplete[]> => {
    const input = {
      search: value,
      page: 1,
      pageSize: 20, // Show 20 results max.
    };

    const { data } = await operation({
      variables: {
        input,
      },
    });

    const { users = [] } = data?.allCxContractorUsers ?? {};

    return users;
  };

  return { query, isLoading };
};
