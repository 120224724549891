/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  avatar: {
    fontSize: '0.9rem',
    color: Color.DarkNight,
  },
  name: {
    paddingLeft: '15px',
    fontSize: '14px',
    textOverflow: 'ellipsis',
  },
  children: {
    paddingLeft: '15px',
  },
}));
