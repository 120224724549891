import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    paddingBottom: theme.spacing(6),
    width: theme.spacing(32),
  },
  title: {
    fontSize: '30px',
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 'small',
  },
  form: {
    marginTop: theme.spacing(2),
  },
  loginBtn: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  forgotPasswordBtn: {
    color: theme.palette.text.secondary,
    gridArea: 'forgotPassword',
    marginTop: theme.spacing(2),
    textTransform: 'none',
    width: '100%',
  },
}));
