import { useSnackbar } from '@hooks/useSnackbar';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { useSendOrderConfirmationEmail } from './graphql';

export const SendOrderConfirmationEmailButton = ({
  customerOrderId,
}: {
  customerOrderId: string;
}) => {
  const { sendEmail } = useSendOrderConfirmationEmail();
  const { enqueueSnackbar } = useSnackbar();

  const sendEmailAction = () => {
    sendEmail({
      variables: { customerOrderId },
      onCompleted: () => {
        enqueueSnackbar('Email has been succesfully resent');
      },
      onError(error: Error) {
        enqueueSnackbar(`Error sending email - ${error?.message}`, { variant: 'error' });
      },
    });
  };

  return <SendEmailButton sendEmailMutation={sendEmailAction} />;
};

const SendEmailButton = ({ sendEmailMutation }: { sendEmailMutation: () => void }) => {
  const handleFulfillmentStatusChange = () => {
    sendEmailMutation();
  };

  return (
    <Button onClick={handleFulfillmentStatusChange} variant="outlined">
      <Typography fontSize={10}>Resend Email</Typography>
    </Button>
  );
};
