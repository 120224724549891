import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc/NotFound';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { getMatchingDotValues } from '@utils/object';

import { BasicInformation } from '../components/Basic/Basic';
import { UserMerchant } from '../components/Basic/Merchant';
import { UserRoles } from '../components/Roles';
import { IUser, IUserInput, USER_FIELDS } from '../fields';
import { useEditUser, useUser } from './graphql';
import { EditUserHeader } from './Header';

const getValues = (user: IUser) => getMatchingDotValues<IUserInput>(USER_FIELDS, user);

type IEditUserParams = { id: string };

const EditUser = ({ id }: IEditUserParams) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IUserInput>({
    mode: 'onSubmit',
  });

  // Track when fields got updated
  const { isDirty: hasFormChanged } = useFormState({
    control: form.control,
  });

  // GraphQL hooks
  const { isLoading, data } = useUser({
    variables: { _id: id },
    onCompleted: ({ user }) => {
      form.reset(getValues(user)); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching user:', error);
    },
  });

  const { updateUserMutation, apiError: updateApiError } = useEditUser();

  const onSubmit = (values: IUserInput) => {
    // Map merchant object to merchantId
    const updateValues = {
      ...R.omit(['merchant'], values),
      merchantId: values.merchant?._id ?? undefined,
    };

    // Manual check as table & checkboxes are not hooked decently into react-hook-form
    if (!updateValues.userRoles || updateValues.userRoles.length === 0) {
      return enqueueSnackbar('A user needs to have at least 1 role assigned', { variant: 'error' });
    }

    updateUserMutation({
      variables: { id, input: updateValues },
      onCompleted: () => {
        enqueueSnackbar('Succesfully updated user');

        // Set values to submitted values (resets dirty state)
        form.reset(values);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating role:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listUsers();
  };

  // User could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <Stack gap={3}>
      <EditUserHeader isLoading={isLoading} user={data} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <BasicInformation isLoading={isLoading} isEmailDisabled={true} />
              </Grid>

              <Grid item xs={6}>
                <UserMerchant isLoading={isLoading} />
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <UserRoles isLoading={isLoading} />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  <PrimaryButton type="submit" disabled={isLoading || !hasFormChanged}>
                    Save changes
                  </PrimaryButton>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormProvider>
    </Stack>
  );
};

export const EditUserPage = withParams<IEditUserParams>(['id'], EditUser);
