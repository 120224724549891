/* eslint-disable max-lines-per-function */
import { Theme } from '@mui/material/styles/createTheme';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>(() => ({
  profileCard: {
    display: 'flex',
    padding: '20px',
  },
  profileInfo: {
    display: 'flex',
  },
}));
