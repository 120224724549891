import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Color } from '@theme/palette';
import React from 'react';

const StyledButton = styled(Button)(() => ({
  background: 'none',
  border: `1px solid ${Color.DarkNight}`,
  color: Color.DarkNight,
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: Color.Gray3,
  },
}));

export const SecondaryButton = ({ children, className, ...muiProps }: ButtonProps) => (
  <StyledButton color="secondary" variant="contained" className={className} {...muiProps}>
    {children}
  </StyledButton>
);
