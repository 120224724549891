/* eslint-disable @typescript-eslint/no-explicit-any */
import dot from 'dot-object';
import { mergeAll, chain, map, toPairs, fromPairs, type, values, pipe, path } from 'ramda';

/**
 * Flattens a nested object into a single object with every key as dot notation.
 * @todo - Look into a cleaner implementation that also supports types!
 */
export const flattenObject = <T>(object: T) => {
  const go = (object_: any): any =>
    chain(([k, v]) => {
      if (type(v) === 'Object' || type(v) === 'Array') {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      }

      return [[k, v]];
    }, toPairs(object_));

  return fromPairs(go(object));
};

/**
 * Flattens values of an object into a single object.
 * @todo - Look into a cleaner implementation that also supports types!
 */
export const flattenValues: (object: any) => any = pipe(flattenObject, values);

/**
 * Returns new object where matching keys are found from an object of values.
 * @todo - Look into a cleaner implementation that also supports types!
 * @todo - Look at how react-hook-form handles types (it uses Path)!
 */
export const getMatchingDotValues = <T = any>(fields: any, data: any): T => {
  const fieldNames = flattenValues(fields);

  // Return single object where keys are dot nested strings e.g. { 'office.name': 'Bonsai HQ' }
  const flattened = mergeAll(
    fieldNames.map((fieldName: string) => ({
      [fieldName]: path(fieldName.split('.'), data),
    })),
  );

  // Convert all dot keys back into nested object e.g. { office: { name: 'Bonsai HQ' } }
  return dot.object(flattened) as unknown as T;
};
