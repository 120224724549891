const BONSAI_CHROME_EXTENSION_ID = 'fmcgnjbpndbnhbnhkjalianainggnadg';

export interface IExtensionOrderInformation {
  orderId: string;
  orderNumber: number;
  orderDashboardUrl: string;
  assignee: {
    name?: string;
  };
  account: {
    name: string;
    fulfillmentInstructions?: string | null;
  };
  shipping: {
    firstName?: string | null;
    lastName?: string | null;
    addressOne?: string | null;
    addressTwo?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country?: string | null;
    province?: string | null;
    phone?: string | null;
  };
  merchantOrder: {
    merchantOrderId: string;
    merchantName: string;
    fulfillmentInstructions?: string | null;
  };
  variants: {
    name: string;
    productId: string;
    productURL: string;
    productPublicId: string;
    imageLink: string;
    publicId: string;
    brand: string;
    purchaseQuantity: number;
    options: {
      key: string;
      value: string;
    }[];
  }[];
}

/**
 * Sends any message to Bonsai Chrome Aria extension.
 * @param data - Key-value object to send to the extension.
 */
const sendMessageToChromeExtension = <T>(data: T) => {
  // Chrome will only be available in Chromium browsers.
  if (window.chrome?.runtime) {
    chrome.runtime.sendMessage(BONSAI_CHROME_EXTENSION_ID, data, (response) => {
      if (!response.success) {
        console.log('error sending message to Chrome Extension', response);

        return response;
      }
      console.log('message sent to Chrome Extension', response);
    });
  }
};

/**
 * Returns a promise that resolves if Bonsai Chrome Aria extension is installed.
 */
export const isChromeExtensionInstalled = (): Promise<{ version: string }> =>
  new Promise((resolve, reject) => {
    if (!window.chrome?.runtime) {
      return reject();
    }

    chrome.runtime.sendMessage(BONSAI_CHROME_EXTENSION_ID, { version: 'check' }, (response) => {
      if (!response) {
        return reject();
      }

      resolve({ version: response.version });
    });
  });

// Connect to Bonsai Chrome Aria extension

/**
 * Sends JWT to Bonsai Chrome Aria extension.
 * @param jwt - JWT token.
 */
export const sendTokenToChromeExtension = (jwt: string) => sendMessageToChromeExtension({ jwt });

/**
 * Sends active order information to Bonsai Chrome Aria extension.
 * @param order - Order information to send to Chrome Extension.
 */
export const sendActiveOrderToChromeExtension = (order: IExtensionOrderInformation) => {
  sendMessageToChromeExtension({ activeOrder: order });
};
