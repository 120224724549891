import { ApolloClient } from '@apollo/client';

import { ENV } from '@constants/environment';

import cache from './cache';
import { apolloLink } from './link';

const { apolloClientName: name } = ENV;

export const client = new ApolloClient({
  cache,
  link: apolloLink,
  name,
  version: '0.0.1',
});
