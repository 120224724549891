import { parse, stringify } from 'query-string';
import { mergeDeepRight } from 'ramda';

const DEFAULT_QUERY_CONFIGURATION = {
  skipEmptyString: true, // Skip empty strings - Set in-code as defaults

  // TODO: Use actual booleans instead of "true"/"false" strings
  // parseBooleans: true,
};

/**
 * Returns parsed query params from the URL.
 */
export const parseQueryURL = <T extends object>(): T => {
  const {
    location: { search },
  } = window;

  return parse(search, { ...DEFAULT_QUERY_CONFIGURATION, arrayFormat: 'bracket' }) as T;
};

/**
 * Returns stringified query params using existing query string.
 * Uses mergeDeepRight to merge the existing query params with the new ones.
 * @param {object} values - Object with values to stringify.
 * @param {boolean} options.replace - Indicates whether to clear the query params and replace entirely. Defaults to `false`.
 */
export const getQueryUrl = <T extends object>(
  values: T,
  { replace = false }: { replace?: boolean } = {},
): string => {
  const queryObject = replace ? values : mergeDeepRight(parseQueryURL<T>(), values);

  return stringify(queryObject, {
    ...DEFAULT_QUERY_CONFIGURATION,
    arrayFormat: 'bracket',
  });
};
