import { ICurrentUser } from '@hooks/useCurrentUser';
import { createContext } from 'react';

export interface IAuthContext {
  currentUser?: ICurrentUser;
  isAuthenticated: boolean;
  login: (jwt: string) => void;
  logout: () => void;
}

/**
 * Authentication context.
 */
export const AuthContext = createContext<IAuthContext>({
  currentUser: undefined,
  isAuthenticated: false,
  login: () => {
    // do nothing
  },
  logout: () => {
    // do nothing
  },
});
