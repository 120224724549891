import { ResultEntry, SearchResult } from '@elastic/search-ui';
import { ProductField } from '@pages/Product/Overview/config';

import { Currency } from '@common/types/GraphqlTypes.d';

import { IProduct } from './interfaces';

// Elastic App Search result fields
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IProductResult = SearchResult & Record<ProductField, ResultEntry | any>;

/**
 * Returns parsed value from product result.
 */
const getValue =
  (result: IProductResult) =>
  <T>(field: ProductField, type: 'raw' | 'snippet', defaultValue: T): T => {
    // Return raw value
    if (type === 'raw') {
      return result?.[field]?.raw ?? defaultValue;
    }

    // Return value including <em></em> to highlight matching terms
    if (type === 'snippet') {
      return result?.[field]?.snippet ?? defaultValue;
    }

    return defaultValue;
  };

/**
 * Returns product display values based on Elastic product.
 * @todo - This could be simplified & made more robust using `io-ts` & defined codec.
 */
export const getProductValues = (result: IProductResult): IProduct => {
  const values = getValue(result);

  // Fetch all information & parse accordingly
  const publicId = values<string>(ProductField.publicId, 'raw', 'n/a');

  const name = values<string>(ProductField.name, 'snippet', 'n/a');
  const inventory = values<string>(ProductField.inventory, 'raw', 'No inventory');
  const url = values<string>(ProductField.url, 'raw', '');
  const currency = values<Currency>(ProductField.currency, 'raw', Currency.Usd);
  const gender = values<string>(ProductField.gender, 'raw', 'no gender');
  const category = values<string>(ProductField.category, 'raw', 'no Google category');

  // Merchant
  const merchantId = values<string>(ProductField.merchantId, 'raw', '');
  const merchantName = values<string>(ProductField.merchantName, 'raw', 'No merchant name');

  // brand
  const brandId = values<string>(ProductField.brandId, 'raw', '');
  const brandName = values<string>(ProductField.brandName, 'raw', 'No brand name');

  // Variant prices
  // Can be a single price for all variants, or different ones per variant
  const minPrice = values<number | null>(ProductField.minPrice, 'raw', null);
  const maxPrice = values<number | null>(ProductField.maxPrice, 'raw', null);

  // Issues
  const isUnavailable = values<boolean>(ProductField.isUnavailable, 'raw', false);
  const hasUnavailableVariant = values<boolean>(ProductField.hasUnavailableVariants, 'raw', false);
  const validationFlags = values<string[]>(ProductField.validationFlags, 'raw', []);

  const thumbnail = values<string>(ProductField.primaryImage, 'raw', '');

  // Metadata
  const updatedAt = values<Date | null>(ProductField.updatedAt, 'raw', null);

  return {
    publicId,
    name,
    inventory,
    url,
    minPrice,
    maxPrice,
    currency,
    gender,
    category,
    thumbnail,
    isUnavailable,
    hasUnavailableVariant,
    validationFlags,
    updatedAt,
    merchant: {
      id: merchantId,
      name: merchantName,
    },
    brand: {
      id: brandId,
      name: brandName,
    },
  };
};
