import { Text } from '@components/Text';
import { TimelineDot } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Color } from '@theme/palette';
import React, { cloneElement } from 'react';

import { getTime } from '@utils/date';

import { DateComponent } from './DateComponent';
import { withLayout } from './withLayout';

export type SecondaryItemProps = {
  title: string;
  createdAt: string;
  createdBy?: string;
  additionalComponent?: JSX.Element;
  icon?: JSX.Element;
  first?: boolean;
  last?: boolean;
};

/**
 * A custom dot component for the secondary timeline. It adds a vertical line to the top and bottom
 * of the dot to make it look like a continuation of the line without gaps.
 * @param props.children - The content of the component.
 * @param props.last - Whether this is the last item in the timeline.
 */
const SecondaryDot = ({
  children,
  first,
  last,
}: {
  first?: boolean;
  last?: boolean;
  children: React.ReactNode;
}) => {
  const extraLine = {
    content: "''",
    width: '2px',
    height: '100%',
    background: Color.Gray2,
    position: 'absolute',
  };

  const startLine = first
    ? undefined
    : {
        '&:before': {
          ...extraLine,
          left: 'calc(50% - 1px)',
          top: '-15px',
        },
      };

  const endLine = last
    ? undefined
    : {
        '&:after': {
          ...extraLine,
          right: 'calc(50% - 1px)',
          bottom: '-15px',
        },
      };

  return (
    <TimelineDot
      sx={{
        p: 0,
        fontSize: 'small',
        backgroundColor: Color.Gray2,
        color: Color.Gray1,
        position: 'relative',
        ...startLine,
        ...endLine,
      }}
    >
      {children}
    </TimelineDot>
  );
};

/**
 * Renders a secondary item in the timeline.
 * @param props.title - The title of the item.
 * @param props.createdAt - The date of the item.
 * @param props.createdBy - The user who created the item.
 * @param props.additionalComponent - An additional component to render below the title.
 * @param props.type - The type of the item.
 * @param props.last - Whether this is the last item in the timeline.
 */
// eslint-disable-next-line complexity
const SecondaryItemComponent = ({
  additionalComponent,
  createdAt,
  createdBy,
  title,
  icon,
  first,
  last,
}: SecondaryItemProps) => {
  const time = getTime(new Date(createdAt));

  const hasAdditionalComponent = additionalComponent != null;
  const hasCreatedBy = createdBy != null;

  return (
    <>
      <TimelineSeparator sx={{ pl: '2.5px' }}>
        <SecondaryDot first={first} last={last}>
          {icon && cloneElement(icon, { fontSize: 'inherit', color: 'inherit' })}
        </SecondaryDot>

        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          alignItems: 'flex-start',
          pt: hasCreatedBy ? '3px' : '11px',
          justifyContent: 'center',
        }}
      >
        <Stack flexGrow={1}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Text fontSize="small" gutterBottom={!hasCreatedBy && hasAdditionalComponent}>
              {title}
            </Text>

            <Text gutterBottom={false} fontSize="x-small">
              {time}
            </Text>
          </Stack>
          {hasCreatedBy && (
            <Text
              fontSize="x-small"
              gutterBottom={hasAdditionalComponent}
              sx={{ marginBottom: hasAdditionalComponent ? '10px' : undefined }}
            >
              By {createdBy}
            </Text>
          )}
          <Box sx={{ marginBottom: hasAdditionalComponent ? '10px' : undefined }}>
            {additionalComponent}
          </Box>
        </Stack>
      </TimelineContent>
    </>
  );
};

export const SecondaryItem = withLayout(SecondaryItemComponent, DateComponent, 'secondary');
