import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { Logo } from '../Logo';

export interface IErrorPageProps {
  isHeightRelative?: boolean;
}

export const ErrorPage: React.FC<IErrorPageProps> = () => (
  <Box
    sx={(theme) => ({
      alignItems: 'center',
      background: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      fontStyle: `${theme.typography.fontFamily} !important`,
      height: '100vh',
      justifyContent: 'center',
      width: '100%',
    })}
  >
    <Logo sx={{ width: '20%' }} />
    <Typography variant={'h5'} sx={(theme) => ({ margin: theme.spacing(5) })}>
      Something ain't right here...
    </Typography>
  </Box>
);
