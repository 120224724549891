import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export interface IItem {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface IDropdownProps {
  className?: string;
  defaultValue?: string;
  items: IItem[];
  onChange?: (event: SelectChangeEvent<string>) => void;
  placeholder?: string;
  registrationProps?: UseFormRegisterReturn;
}

/**
 * @deprecated - Use Select from inputs instead.
 */
export const Dropdown: React.FC<IDropdownProps> = ({
  className,
  defaultValue,
  items,
  onChange,
  placeholder,
  registrationProps,
}) => (
  <Select
    className={className}
    defaultValue={defaultValue}
    displayEmpty
    fullWidth
    IconComponent={KeyboardArrowDownIcon}
    onChange={onChange}
    renderValue={(selected: string) =>
      items.find(({ value }) => value === selected)?.label || (
        <Box component="span" sx={{ opacity: '50%' }}>
          {placeholder}
        </Box>
      )
    }
    {...registrationProps}
    size="small"
  >
    {items.map(({ label, value, disabled = false }, index) => (
      <MenuItem key={`${label}-${index}`} value={value} disabled={disabled}>
        {label}
      </MenuItem>
    ))}
  </Select>
);
