import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Color } from '@theme/palette';
import React from 'react';

export type CardTitleProps = Omit<TypographyProps, 'title'> & {
  divider?: boolean;

  /**
   * Renders optional title above the card.
   */
  title?: string | JSX.Element;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: Color.DarkNight,
  fontWeight: 600,
  lineHeight: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

export const CardTitle = ({ title, divider = false, ...muiProps }: CardTitleProps) => {
  if (!title) {
    return null;
  }

  return (
    <>
      <StyledTypography variant="h3" fontSize="large" gutterBottom {...muiProps}>
        {title}
      </StyledTypography>
      {divider && <Divider style={{ marginBottom: 24 }} />}
    </>
  );
};
