import { Card, CardProps } from '@components/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';

type DropdownCellProps = CardProps & {
  /**
   * Name to display.
   */
  name: string;

  /**
   * Children to render when dropdown is open.
   */
  children: React.ReactNode;
};

/**
 * Returns data grid cell with dropdown support.
 * Currently uses `onClick` to toggle dropdown.
 */
export const DropdownCell = ({ name, children, ...muiCardProps }: DropdownCellProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box sx={{ position: 'relative' }}>
        <Button onClick={() => setIsOpen(!isOpen)} sx={{ fontWeight: 400, textAlign: 'left' }}>
          <Stack direction="row" alignItems="center" gap={0.5} justifyContent="space-between">
            {name}
            <ExpandMoreIcon
              sx={{ fontSize: '1.3em', transform: `rotate(${isOpen ? 180 : 0}deg)` }}
            />
          </Stack>
        </Button>

        {isOpen && (
          <Card
            sx={{
              zIndex: '1000',
              position: 'absolute',
              whiteSpace: 'nowrap',
              fontSize: '0.9em',
            }}
            {...muiCardProps}
          >
            {children}
          </Card>
        )}
      </Box>
    </ClickAwayListener>
  );
};
