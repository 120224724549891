/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  menuItemText: {
    cursor: 'pointer',
    fontWeight: 600,
    marginRight: 20,
  },
}));
