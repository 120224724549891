import { ControlPoint } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import React, { MouseEventHandler } from 'react';

type CreateButtonProps = ButtonProps & {
  onClick?: <T>(event: MouseEventHandler<T> | undefined) => void;
  children?: React.ReactNode;
};

export const CreateButton = ({ onClick, children, ...muiProps }: CreateButtonProps) => (
  <Button
    color={'primary'}
    onClick={onClick}
    startIcon={<ControlPoint />}
    type="button"
    variant="contained"
    {...muiProps}
  >
    {children}
  </Button>
);
