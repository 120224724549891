import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { PRODUCT_COLLECTION_FIELDS as inputField } from '../fields';

const placeholder = `Add one or more products by public ID or URL separated by a new line. Make sure to add all products as these will be overwritten entirely!

For example:
https://merchant-url.org/black-pants-124324
393475947fdj74945
`;

export const Products = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Products" noPadding>
    <Stack spacing={2}>
      <TextField
        id={inputField.productIds}
        isLoading={isLoading}
        placeholder={placeholder}
        multiline={true}
        minRows={7}
        useHook
      />
    </Stack>
  </Card>
);
