import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  QueryProductsByPublicIdsArgs,
  QueryProductCollectionArgs,
} from '@common/types/GraphqlTypes.d';

export type IProductCollection = {
  _id: string;
  name: string;
  handle: string;
  publicId: string;
  productIds: string[];
  account: {
    publicId: string;
    name: string;
  };
};

export type IProduct = {
  _id: string;
  name: string;
  publicId: string;
  productURL: string;
};

const PRODUCT_COLLECTION_QUERY = gql`
  query productCollection($_id: String!) {
    productCollection(_id: $_id) {
      _id
      name
      handle
      publicId
      productIds
      account {
        publicId
        name
      }
    }
  }
`;

const PRODUCTS_QUERY = gql`
  query productsByPublicIds($input: ProductsByIdInput!) {
    productsByPublicIds(input: $input) {
      _id
      name
      publicId
      productURL
    }
  }
`;

/**
 * Hook for fetching product collection.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useProductCollection = (
  options: QueryHookOptions<{ productCollection: IProductCollection }, QueryProductCollectionArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productCollection: IProductCollection }, QueryProductCollectionArgs>(
    PRODUCT_COLLECTION_QUERY,
    {
      fetchPolicy: 'no-cache',
      ...options,
    },
  );

  const { productCollection } = data || {};

  return { data: productCollection, isLoading, apiError };
};

/**
 * Hook for fetching products.
 * @param {boolean} productCollectionLoaded - Checks whether product collection data is loaded.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useProducts = (
  productCollectionLoaded: boolean,
  options: QueryHookOptions<{ productsByPublicIds: IProduct[] }, QueryProductsByPublicIdsArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productsByPublicIds: IProduct[] }, QueryProductsByPublicIdsArgs>(PRODUCTS_QUERY, {
    skip: !productCollectionLoaded,
    ...options,
  });

  const { productsByPublicIds } = data || {};

  return { data: productsByPublicIds, isLoading, apiError };
};
