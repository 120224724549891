/* eslint-disable max-lines-per-function */

import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import { CUSTOMER_ORDER_QUERY } from '@hooks/useCustomerOrder';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Typography, Grid } from '@mui/material';
import { Items } from '@pages/Order/components/ItemsSelect';
import { checkoutVariantToShortCutProduct } from '@pages/Order/components/ItemsSelect/utils';
import currencyJs from 'currency.js';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { filterZeroQuantityProducts } from '../utils/products';
import { RefundFields } from './fields';
import { useMerchantOrder, useSubmitRefund } from './graphql';
import { IRefundFormData, RefundFormFields } from './types';

type IRefundMerchantOrderParams = { merchantOrderId: string };

/**
 * If the transfer to a merchant should be reverse based on comission type.
 * @param commissionType - Merchant commission Type.
 * @returns {boolean} - Whether the transfer should be reversed.
 */
const shouldReverseMerchantTransfer = (_commissionType?: string): boolean => true;

const RefundMerchantOrder = ({ merchantOrderId }: IRefundMerchantOrderParams) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, merchantOrder } = useMerchantOrder(merchantOrderId);
  const { refundMerchantOrderMutation } = useSubmitRefund();

  // Default to undefined so the value rerenders if it becomes available.
  // Defaulting to 0 will cause the value to not rerender when it changes
  const taxes = merchantOrder
    ? currencyJs(merchantOrder.summary.customerChargeTotal)
        .subtract(merchantOrder.summary.subTotal)
        .subtract(merchantOrder.summary.duties)
        .subtract(merchantOrder.summary.shippingWithTax).value
    : undefined;

  const form = useForm<IRefundFormData>({
    mode: 'onSubmit',
    defaultValues: {
      merchantOrderId,
      amount: merchantOrder?.summary.subTotal,
      taxes,
      shipping: 0,
      duties: 0,
      products: merchantOrder?.checkoutVariants.map(checkoutVariantToShortCutProduct) || [],
      isBonsaiOnlyRefund: !shouldReverseMerchantTransfer(merchantOrder?.merchant?.commissionType),
    },
  });

  if (!merchantOrder) {
    return <div>Order does not exist</div>;
  }

  const getProductValues = () => form.getValues(RefundFormFields.Products);
  const setProductValues = (items: ShortcutProduct[]) =>
    form.setValue(RefundFormFields.Products, items);

  const goToOrderView = () => {
    goto.viewOrder(merchantOrder.customerOrderId);
  };

  const submitRefund = (values: IRefundFormData) => {
    refundMerchantOrderMutation({
      variables: { input: { ...values, products: filterZeroQuantityProducts(values.products) } },
      onCompleted: ({ submitMerchantOrderRefund: { error } }) => {
        //TODO change this and the API once old beam is no longer used.
        //throw the error instead of returning it like this
        const snackbarVariant = error ? 'error' : 'default';

        enqueueSnackbar(error ?? 'Refund submitted', { variant: snackbarVariant });

        if (!error) {
          goToOrderView();
        }
      },
      onError: (error) => {
        enqueueSnackbar(`Error submitting refund: ${error.message}`, { variant: 'error' });
      },
      refetchQueries: [CUSTOMER_ORDER_QUERY],
    });
  };

  return (
    <>
      <Typography variant="h5">Refund Order Form</Typography>

      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(submitRefund)}>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <Items
                getValues={getProductValues}
                setValues={setProductValues}
                showPrices={true}
                isLoading={isLoading}
                merchantOrder={merchantOrder}
              />
            </Grid>
            <Grid item xs={6}>
              <RefundFields
                onCancel={goToOrderView}
                isLoading={isLoading}
                orderSummary={merchantOrder?.summary}
              />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </>
  );
};

export const RefundMerchantOrderPage = withParams<IRefundMerchantOrderParams>(
  ['merchantOrderId'],
  RefundMerchantOrder,
);
