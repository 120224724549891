/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  profileCard: {
    display: 'grid',
    gridTemplateAreas: `
      "imageContainer userInfo"
    `,
    gridTemplateColumns: 'auto 3fr',
    gridTemplateRows: '1fr',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
  },
}));
