/* eslint-disable max-lines-per-function */
import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import useRouter from '@hooks/useRouter';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BasicInformation } from '../components/Basic';
import { IClientInputCreate } from '../fields';
import { useCreateClient } from './graphql';
import { CreateClientHeader } from './Header';

export const CreateClient: React.FC = () => {
  const { goto } = useRouter();

  // Set up React form hook
  const form = useForm<IClientInputCreate>({
    mode: 'onSubmit',
  });

  const { createClientMutation, isLoading, apiError } = useCreateClient();

  const onSubmit = (values: IClientInputCreate) => {
    createClientMutation({
      variables: { input: values },
      onCompleted: ({ createClient }) => {
        goto.editClient(createClient._id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error creating client:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listClients();
  };

  return (
    <Stack gap={3}>
      <CreateClientHeader />

      <FormProvider {...form}>
        <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
          <>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <BasicInformation />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  <PrimaryButton type="submit" disabled={isLoading}>
                    Create client
                  </PrimaryButton>
                </Stack>
              </Grid>
            </Grid>
          </>
        </Form>
      </FormProvider>
    </Stack>
  );
};
