import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_MERCHANT_MUTATION = gql`
  mutation insertMerchant($input: MerchantInput!) {
    insertMerchant(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for creating merchants.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateMerchant = (options: MutationHookOptions = {}) => {
  const [createMerchantMutation, { error: apiError, loading: isLoading }] = useMutation<{
    insertMerchant: { _id: string };
  }>(CREATE_MERCHANT_MUTATION, options);

  return { createMerchantMutation, isLoading, apiError };
};
