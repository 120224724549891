import { gql } from '@apollo/client';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AnyObject: any;
  Date: any;
  DateRangeFilter: any;
  OptionsFilter: any;
  SearchFilter: any;
  Sort: any;
  Upload: any;
};

export type AccessCredentials = {
  __typename?: 'AccessCredentials';
  aws: AwsCredentials;
};

export type AccessCredentialsInput = {
  aws?: InputMaybe<AwsCredentialsInput>;
};

export type Account = {
  __typename?: 'Account';
  _id: Scalars['String'];
  analytics?: Maybe<Array<Maybe<AccountAnalytics>>>;
  apiKey: Scalars['String'];
  billing: AccountBilling;
  config: AccountConfig;
  currency: Currency;
  emailProvider: AccountEmailConfig;
  name: Scalars['String'];
  orderConfig: AccountOrderConfig;
  preferences?: Maybe<AccountPreferences>;
  privateApiKey: Scalars['String'];
  publicId: Scalars['String'];
  publisher: AccountPublisher;
  source: AccountSource;
  status: AccountStatus;
  testPublishableApiKey: Scalars['String'];
  testSecretApiKey: Scalars['String'];
};

export type AccountAnalytics = {
  __typename?: 'AccountAnalytics';
  apiKey: Scalars['String'];
  provider: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type AccountAnalyticsInput = {
  apiKey: Scalars['String'];
  provider: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type AccountBilling = {
  __typename?: 'AccountBilling';
  accountingId?: Maybe<Scalars['String']>;
  descriptor: Scalars['String'];
  email: Scalars['String'];
  fee: Scalars['Int'];
};

export type AccountBillingInput = {
  accountingId?: InputMaybe<Scalars['String']>;
  descriptor: Scalars['String'];
  email: Scalars['String'];
  fee: Scalars['Int'];
};

export type AccountConfig = {
  __typename?: 'AccountConfig';
  cartTitleImage?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  links: Array<Maybe<AccountConfigLink>>;
  linksTitle?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  webhooks?: Maybe<AccountConfigWebhooks>;
};

export type AccountConfigInput = {
  cartTitleImage?: InputMaybe<Scalars['String']>;
  favicon?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Array<InputMaybe<AccountConfigLinkInput>>>;
  linksTitle?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  webhooks?: InputMaybe<AccountWebhooksConfigInput>;
};

export type AccountConfigLink = {
  __typename?: 'AccountConfigLink';
  href: Scalars['String'];
  name: Scalars['String'];
};

export type AccountConfigLinkInput = {
  href: Scalars['String'];
  name: Scalars['String'];
};

export type AccountConfigWebhooks = {
  __typename?: 'AccountConfigWebhooks';
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type AccountEmailConfig = {
  __typename?: 'AccountEmailConfig';
  config?: Maybe<AccountEmailProviderConfig>;
  type: Scalars['String'];
};

export type AccountEmailProviderConfig = {
  __typename?: 'AccountEmailProviderConfig';
  apiKey: Scalars['String'];
};

export type AccountEmailProviderInput = {
  type: Scalars['String'];
};

export type AccountFulfillment = {
  __typename?: 'AccountFulfillment';
  instructions?: Maybe<Scalars['String']>;
};

export type AccountFulfillmentInput = {
  instructions?: InputMaybe<Scalars['String']>;
};

export type AccountInputCreate = {
  analytics?: InputMaybe<Array<InputMaybe<AccountAnalyticsInput>>>;
  billing: AccountBillingInput;
  config: AccountConfigInput;
  currency: Currency;
  emailProvider: AccountEmailProviderInput;
  name: Scalars['String'];
  orderConfig: AccountOrderConfigInput;
  preferences?: InputMaybe<AccountPreferencesInput>;
  publisher: Scalars['String'];
  source: AccountSource;
};

export type AccountInputUpdate = {
  billing?: InputMaybe<AccountBillingInput>;
  config?: InputMaybe<AccountConfigInput>;
  currency?: InputMaybe<Currency>;
  emailProvider?: InputMaybe<AccountEmailProviderInput>;
  name?: InputMaybe<Scalars['String']>;
  orderConfig?: InputMaybe<AccountOrderConfigInput>;
  preferences?: InputMaybe<AccountPreferencesInput>;
  publisher?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AccountStatus>;
};

export type AccountList = {
  __typename?: 'AccountList';
  data: Array<Maybe<Account>>;
  totalCount: Scalars['Int'];
};

export type AccountOrderConfig = {
  __typename?: 'AccountOrderConfig';
  fulfillment?: Maybe<AccountFulfillment>;
  shipping: Scalars['String'];
  slackNotificationChannel?: Maybe<Scalars['String']>;
};

export type AccountOrderConfigInput = {
  fulfillment?: InputMaybe<AccountFulfillmentInput>;
  shipping: Scalars['String'];
  slackNotificationChannel?: InputMaybe<Scalars['String']>;
};

export type AccountPreferences = {
  __typename?: 'AccountPreferences';
  buttonColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  fontName?: Maybe<Scalars['String']>;
};

export type AccountPreferencesInput = {
  buttonColor?: InputMaybe<Scalars['String']>;
  fontColor?: InputMaybe<Scalars['String']>;
  fontName?: InputMaybe<Scalars['String']>;
};

export type AccountPublisher = {
  __typename?: 'AccountPublisher';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export enum AccountSource {
  Platform = 'platform',
  Widget = 'widget'
}

export enum AccountStatus {
  Archived = 'archived',
  Live = 'live'
}

export type AccountWebhooksConfigInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type AccountsCountInput = {
  filters?: InputMaybe<AccountsFilters>;
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type AccountsFilters = {
  createdAt?: InputMaybe<Scalars['DateRangeFilter']>;
  hasEnabledCsvOrders?: InputMaybe<Scalars['Boolean']>;
  publisher?: InputMaybe<Scalars['SearchFilter']>;
  status?: InputMaybe<AccountStatus>;
};

export type AccountsInput = {
  filters?: InputMaybe<AccountsFilters>;
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<AccountsSort>;
};

export type AccountsSort = {
  _id?: InputMaybe<Scalars['Sort']>;
  name?: InputMaybe<Scalars['Sort']>;
};

export type AddCommentInput = {
  comment: Scalars['String'];
  escalationStatus?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<CommentProductInput>;
  user: UserRefInput;
};

export type Address = {
  __typename?: 'Address';
  _id?: Maybe<Scalars['String']>;
  addressLineOne?: Maybe<Scalars['String']>;
  addressLineTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type ApiCredentials = {
  __typename?: 'ApiCredentials';
  accessToken?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type ApiCredentialsInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  apiKey?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type ApplicationFeeBreakdown = {
  __typename?: 'ApplicationFeeBreakdown';
  amount: Scalars['Int'];
  amountWithTax: Scalars['Int'];
  rate: Scalars['Int'];
  tax: Scalars['Int'];
  taxRate: Scalars['Int'];
};

export type Auto = {
  __typename?: 'Auto';
  auto?: Maybe<Scalars['Boolean']>;
};

export type AutoInput = {
  auto?: InputMaybe<Scalars['Boolean']>;
};

export type AutocompleteInput = {
  isLiveOnly?: InputMaybe<Scalars['Boolean']>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type AwsCredentials = {
  __typename?: 'AwsCredentials';
  accessKeyId: Scalars['String'];
  kind: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  secretAccessKey: Scalars['String'];
};

export type AwsCredentialsInput = {
  accessKeyId: Scalars['String'];
  kind: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  secretAccessKey: Scalars['String'];
};

export type BillingInput = {
  accountingId?: InputMaybe<Scalars['String']>;
  commissionType?: InputMaybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedStage?: Maybe<Scalars['String']>;
};

export type BrandListInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type BrandListPayload = {
  __typename?: 'BrandListPayload';
  data?: Maybe<Array<Maybe<Brand>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Breakdown = {
  __typename?: 'Breakdown';
  applicationFee: ApplicationFeeBreakdown;
  currency: CurrencyBreakdown;
  customer: CustomerBreakdown;
  duties: DutiesBreakdown;
  goods: GoodsBreakdown;
  merchant: MerchantBreakdown;
  platform: PlatformBreakdown;
  shipping: ShippingBreakdown;
  store: StoreBreakdown;
  tax: TaxBreakdown;
};

export type CancelFulfillmentDetailInput = {
  fulfillmentDetailId: Scalars['String'];
  merchantOrderId: Scalars['String'];
};

export type CancelFulfillmentDetailResult = {
  __typename?: 'CancelFulfillmentDetailResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type Cart = {
  __typename?: 'Cart';
  merchantId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
  purchaseQuantity: Scalars['Int'];
  shippingCode: Scalars['String'];
  variant: Variant;
};

export type CategoryMetadata = {
  __typename?: 'CategoryMetadata';
  bonsai?: Maybe<Array<Maybe<Scalars['String']>>>;
  google?: Maybe<Scalars['String']>;
  googleCategoryNeedsReview?: Maybe<Scalars['Boolean']>;
  original?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  utility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CheckoutVariant = {
  __typename?: 'CheckoutVariant';
  _id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productId: Scalars['String'];
  productPublicId: Scalars['String'];
  productURL?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  purchaseQuantity: Scalars['Int'];
  variant: Variant;
};

export type Client = {
  __typename?: 'Client';
  _id: Scalars['String'];
  accounts?: Maybe<Array<Maybe<Account>>>;
  name: Scalars['String'];
  status: ClientStatus;
};

export type ClientInputCreate = {
  name: Scalars['String'];
  status: ClientStatus;
};

export type ClientInputUpdate = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ClientStatus>;
};

export enum ClientStatus {
  Archived = 'archived',
  Draft = 'draft',
  Live = 'live'
}

export type ClientsCountInput = {
  filters?: InputMaybe<ClientsFilters>;
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type ClientsFilters = {
  status?: InputMaybe<Scalars['OptionsFilter']>;
};

export type ClientsInput = {
  filters?: InputMaybe<ClientsFilters>;
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ClientsSort>;
};

export type ClientsSort = {
  name?: InputMaybe<Scalars['Sort']>;
};

export type Colour = {
  __typename?: 'Colour';
  hex?: Maybe<Scalars['String']>;
  hsl?: Maybe<Hsl>;
  hsv?: Maybe<Hsv>;
  oldHue?: Maybe<Scalars['Float']>;
  rgb?: Maybe<Rgb>;
  source?: Maybe<Scalars['String']>;
};

export type ColourInput = {
  hex?: InputMaybe<Scalars['String']>;
  hsl?: InputMaybe<HslInput>;
  hsv?: InputMaybe<HsvInput>;
  oldHue?: InputMaybe<Scalars['Float']>;
  rgb?: InputMaybe<RgbInput>;
  source?: InputMaybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Date'];
  escalation?: Maybe<Escalation>;
  user: UserRef;
};

export type CommentProductInput = {
  name: Scalars['String'];
  productId: Scalars['String'];
  variantId: Scalars['String'];
};

export type Config = {
  __typename?: 'Config';
  canUseFlexiblePayment?: Maybe<Scalars['Boolean']>;
  hasNexusEnabled: Scalars['Boolean'];
  isTestMerchant?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Options>;
  restockingFee?: Maybe<Scalars['Int']>;
  review?: Maybe<Auto>;
  shipping?: Maybe<ShippingType>;
  taxes?: Maybe<TaxEnum>;
};

export type ConfigInput = {
  canUseFlexiblePayment?: InputMaybe<Scalars['Boolean']>;
  hasNexusEnabled?: InputMaybe<Scalars['Boolean']>;
  isTestMerchant?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<OptionsInput>;
  restockingFee?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<AutoInput>;
  taxes?: InputMaybe<TaxEnum>;
};

export type CreateExternalOrder = {
  __typename?: 'CreateExternalOrder';
  result?: Maybe<Scalars['String']>;
};

export type CreateFulfillmentDetailInput = {
  fulfillmentDetails: FulfillmentDetailInput;
  merchantOrderId: Scalars['String'];
};

export type CreateFulfillmentDetailResult = {
  __typename?: 'CreateFulfillmentDetailResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type Credentials = {
  __typename?: 'Credentials';
  feed?: Maybe<FeedCredentials>;
  integrationType?: Maybe<Scalars['String']>;
  shopify?: Maybe<ShopifyCredentials>;
};

export type CredentialsInput = {
  feed?: InputMaybe<FeedCredentialsInput>;
  integrationType?: InputMaybe<IntegrationType>;
  shopify?: InputMaybe<ShopifyCredentialsInput>;
};

export type CsvRow = {
  BonsaiOrderNumber?: InputMaybe<Scalars['String']>;
  FulfillmentStatus?: InputMaybe<Scalars['String']>;
  TrackingNum?: InputMaybe<Scalars['String']>;
  TrackingURL?: InputMaybe<Scalars['String']>;
};

export type CsvUpdateInput = {
  csvRowArray?: InputMaybe<Array<InputMaybe<CsvRow>>>;
};

export type CsvUpdatePayload = {
  __typename?: 'CsvUpdatePayload';
  encodedCsv?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CurrencyBreakdown = {
  __typename?: 'CurrencyBreakdown';
  conversionRate: Scalars['Int'];
  source: Currency;
  target: Currency;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  email: Scalars['String'];
  id: Scalars['String'];
  merchant?: Maybe<CurrentUserMerchant>;
  permissions: Array<Scalars['String']>;
  profile: CurrentUserProfile;
};

export type CurrentUserMerchant = {
  __typename?: 'CurrentUserMerchant';
  id: Scalars['String'];
  isStripeConnected: Scalars['Boolean'];
  name: Scalars['String'];
  offices: MerchantAddress;
  stripeState?: Maybe<Scalars['String']>;
};

export type CurrentUserProfile = {
  __typename?: 'CurrentUserProfile';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CustomerBreakdown = {
  __typename?: 'CustomerBreakdown';
  charge: Scalars['Int'];
};

export type CustomerChargeTotalInput = {
  highPrice?: InputMaybe<Scalars['Float']>;
  lowPrice?: InputMaybe<Scalars['Float']>;
};

export type CustomerDateInput = {
  max?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['String']>;
};

export type CustomerDetailsInput = {
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
};

export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  _id: Scalars['String'];
  account: Account;
  assignee?: Maybe<UserRef>;
  breakdown: Breakdown;
  cart: Array<Array<Cart>>;
  comments: Array<Comment>;
  createdAt: Scalars['Date'];
  customer: Customer;
  customerId?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Address>;
  escalations?: Maybe<Array<Maybe<Escalation>>>;
  externalOrderId?: Maybe<Scalars['String']>;
  externalPayment?: Maybe<ExternalPayment>;
  fulfillmentStatus: Scalars['String'];
  history: Array<OrderHistory>;
  isTest?: Maybe<Scalars['Boolean']>;
  merchantNames: Array<Scalars['String']>;
  merchantOrderIds: Array<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Int'];
  partnerId: Scalars['String'];
  payment?: Maybe<Scalars['String']>;
  paymentInfo: OrderPayment;
  paymentMethod: Scalars['String'];
  paymentStatus?: Maybe<PaymentStatus>;
  publicId: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  summary: PaymentSummary;
  updatedAt: Scalars['Date'];
};

export type CustomerOrderCustomer = {
  __typename?: 'CustomerOrderCustomer';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerOrderDeliveryAddress = {
  __typename?: 'CustomerOrderDeliveryAddress';
  addressLineOne?: Maybe<Scalars['String']>;
  addressLineTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type CustomerOrderIdInput = {
  customerOrderId: Scalars['String'];
};

export type CustomerOrdersInput = {
  filters?: InputMaybe<CustomerOrdersQueryInput>;
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  query?: InputMaybe<CustomerOrdersQueryInput>;
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type CustomerOrdersPayload = {
  __typename?: 'CustomerOrdersPayload';
  data: Array<Maybe<CustomerOrder>>;
  total?: Maybe<Scalars['Int']>;
};

export type CustomerOrdersQueryInput = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assigneeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  date?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  escalationStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fulfillmentStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isTest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  merchantIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partnerId?: InputMaybe<Scalars['String']>;
  paymentStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DateRangeInput = {
  dateRangeEnd?: InputMaybe<Scalars['Date']>;
  dateRangeStart?: InputMaybe<Scalars['Date']>;
};

export type DeleteGhostOrderInput = {
  feedOrderId: Scalars['String'];
  merchantOrderId: Scalars['String'];
};

export type DeleteMerchantPayload = {
  __typename?: 'DeleteMerchantPayload';
  message?: Maybe<Scalars['String']>;
  nextStep?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type DeleteUserInput = {
  id: Scalars['String'];
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeletedCount = {
  __typename?: 'DeletedCount';
  deletedCount: Scalars['Int'];
};

export type DeletedGhostOrderCount = {
  __typename?: 'DeletedGhostOrderCount';
  result?: Maybe<Scalars['Int']>;
};

export type DeliveryAddressInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
};

export type DutiesBreakdown = {
  __typename?: 'DutiesBreakdown';
  amount: Scalars['Int'];
};

export type EditProductUrlInput = {
  merchantOrderId: Scalars['String'];
  newProductUrl: Scalars['String'];
  variantId: Scalars['String'];
};

export type EditProductUrlPayload = {
  __typename?: 'EditProductUrlPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type EnableWebhooksInput = {
  id: Scalars['String'];
  webhooks: Array<Scalars['String']>;
};

export type EnableWebhooksPayload = {
  __typename?: 'EnableWebhooksPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type ErrorOrderInfo = {
  __typename?: 'ErrorOrderInfo';
  number: Scalars['String'];
  rows: Array<Scalars['Int']>;
};

export type Escalation = {
  __typename?: 'Escalation';
  productId: Scalars['String'];
  productName: Scalars['String'];
  state: Scalars['String'];
  status: Scalars['String'];
  variantId: Scalars['String'];
};

export type ExportCsvPayload = {
  __typename?: 'ExportCsvPayload';
  encodedCsv?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ExportMerchantOrdersInput = {
  searchTerms?: InputMaybe<MerchantOrderSearchTerms>;
};

export type FeedCredentials = {
  __typename?: 'FeedCredentials';
  accessCredentials?: Maybe<AccessCredentials>;
  feedName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FeedCredentialsInput = {
  accessCredentials?: InputMaybe<AccessCredentialsInput>;
  feedName?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  /** @deprecated No longer supported */
  contentfulId?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type FileInput = {
  contentfulId?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type FreeShippingInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type FreeShippingType = {
  __typename?: 'FreeShippingType';
  enabled?: Maybe<Scalars['Boolean']>;
};

export type FulfillmentDetail = {
  __typename?: 'FulfillmentDetail';
  _id: Scalars['String'];
  cancelled: Scalars['Boolean'];
  products: Array<ShortcutProduct>;
  shippingCarrier: ShippingCarrier;
  shippingCarrierCustomName?: Maybe<Scalars['String']>;
  trackingNumber: Scalars['String'];
  trackingUrl: Scalars['String'];
};

export type FulfillmentDetailInput = {
  cancelled: Scalars['Boolean'];
  products: Array<ShortcutProductInput>;
  shippingCarrier: ShippingCarrier;
  shippingCarrierCustomName?: InputMaybe<Scalars['String']>;
  trackingNumber: Scalars['String'];
  trackingUrl?: InputMaybe<Scalars['String']>;
};

export type GhostExternalOrder = {
  __typename?: 'GhostExternalOrder';
  _id: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  currency?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  fulfillment: Scalars['String'];
  merchantOrderNumber: Scalars['String'];
  paymentMethod: Scalars['String'];
  products: Array<ShortcutProduct>;
  shipping: Scalars['Float'];
  subtotal: Scalars['Float'];
  taxes: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

export type GhostOrder = {
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  currency?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  fulfillment?: InputMaybe<Scalars['String']>;
  merchantOrderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<ShortcutProductInput>>>;
  shipping?: InputMaybe<Scalars['Float']>;
  subtotal?: InputMaybe<Scalars['Float']>;
  taxes?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type GhostOrderInput = {
  merchantOrderId: Scalars['String'];
  order: GhostOrder;
};

export type GoodsBreakdown = {
  __typename?: 'GoodsBreakdown';
  compareAtSubtotal: Scalars['Int'];
  subtotal: Scalars['Int'];
  subtotalTax: Scalars['Int'];
  subtotalWithTax: Scalars['Int'];
};

export type Hsl = {
  __typename?: 'Hsl';
  a?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  l?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
};

export type HslInput = {
  a?: InputMaybe<Scalars['Float']>;
  h?: InputMaybe<Scalars['Float']>;
  l?: InputMaybe<Scalars['Float']>;
  s?: InputMaybe<Scalars['Float']>;
};

export type Hsv = {
  __typename?: 'Hsv';
  a?: Maybe<Scalars['Float']>;
  h?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Float']>;
  v?: Maybe<Scalars['Float']>;
};

export type HsvInput = {
  a?: InputMaybe<Scalars['Float']>;
  h?: InputMaybe<Scalars['Float']>;
  s?: InputMaybe<Scalars['Float']>;
  v?: InputMaybe<Scalars['Float']>;
};

export type IdAndUser = {
  __typename?: 'IdAndUser';
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  /** @deprecated No longer supported */
  contentfulId?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type ImageInput = {
  contentfulId?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type ImportProductsInput = {
  id: Scalars['String'];
};

export type ImportProductsPayload = {
  __typename?: 'ImportProductsPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export enum IntegrationType {
  Feed = 'feed',
  Manual = 'manual',
  Shopify = 'shopify'
}

export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  result?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  token: Scalars['String'];
};

export type Merchant = {
  __typename?: 'Merchant';
  _id: Scalars['String'];
  areProductsPendingOnboarding?: Maybe<Scalars['Boolean']>;
  areTaxesIncluded?: Maybe<Scalars['Boolean']>;
  backSplash?: Maybe<Colour>;
  billing: MerchantBilling;
  blacklistedBrandIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  config?: Maybe<Config>;
  country?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Image>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabledWebhooks?: Maybe<Array<Maybe<Scalars['String']>>>;
  fulfillment?: Maybe<MerchantFulfillment>;
  headerImage?: Maybe<Image>;
  isStripeConnected?: Maybe<Scalars['Boolean']>;
  liveProductCount?: Maybe<Scalars['Int']>;
  merchantBrands?: Maybe<Array<Maybe<Brand>>>;
  name: Scalars['String'];
  offices?: Maybe<MerchantAddress>;
  phone?: Maybe<Scalars['String']>;
  private?: Maybe<MerchantPrivate>;
  productCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  productCount?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  publishedStage?: Maybe<Scalars['String']>;
  returnPolicy?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  squareImage?: Maybe<Image>;
  stripe?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<Scalars['String']>;
  stripeState?: Maybe<Scalars['String']>;
  variantCount?: Maybe<Scalars['Int']>;
  whitelistedBrands?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MerchantAddress = {
  __typename?: 'MerchantAddress';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type MerchantAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type MerchantBilling = {
  __typename?: 'MerchantBilling';
  accountingId?: Maybe<Scalars['String']>;
  commissionType: Scalars['String'];
  fee?: Maybe<Scalars['Int']>;
};

export type MerchantBreakdown = {
  __typename?: 'MerchantBreakdown';
  revenue: Scalars['Int'];
};

export type MerchantExportFeedDownloadUrlPayload = {
  __typename?: 'MerchantExportFeedDownloadUrlPayload';
  url: Scalars['String'];
};

export type MerchantFulfillment = {
  __typename?: 'MerchantFulfillment';
  instructions?: Maybe<Scalars['String']>;
};

export type MerchantFulfillmentInput = {
  instructions?: InputMaybe<Scalars['String']>;
};

export type MerchantInput = {
  areTaxesIncluded?: InputMaybe<Scalars['Boolean']>;
  backSplash?: InputMaybe<ColourInput>;
  billing?: InputMaybe<BillingInput>;
  blacklistedBrandIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  companyCode?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<ConfigInput>;
  coverImage?: InputMaybe<ImageInput>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  enabledWebhooks: Array<Scalars['String']>;
  fulfillment?: InputMaybe<MerchantFulfillmentInput>;
  headerImage?: InputMaybe<ImageInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  offices?: InputMaybe<MerchantAddressInput>;
  private?: InputMaybe<MerchantPrivateInput>;
  returnPolicy?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<ShippingInput>;
  slug?: InputMaybe<Scalars['String']>;
  squareImage?: InputMaybe<ImageInput>;
};

export type MerchantListInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  query?: InputMaybe<MerchantListQueryInput>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
};

export type MerchantListPayload = {
  __typename?: 'MerchantListPayload';
  items?: Maybe<Array<Maybe<Merchant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MerchantListQueryInput = {
  hasInternationalShipping?: InputMaybe<Scalars['Boolean']>;
  nameSearch?: InputMaybe<Scalars['String']>;
  publishFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type MerchantOrder = {
  __typename?: 'MerchantOrder';
  _id: Scalars['String'];
  checkoutVariants: Array<CheckoutVariant>;
  conversionRate?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  customerDetails?: Maybe<MerchantOrderCustomerDetails>;
  customerOrderId: Scalars['String'];
  displayStatus?: Maybe<Scalars['String']>;
  externalOrder: MerchantOrderExternalOrder;
  fulfillmentDetails: Array<FulfillmentDetail>;
  fulfillmentInstructions?: Maybe<Scalars['String']>;
  fulfillmentStatus: Scalars['String'];
  ghostExternalOrders: Array<GhostExternalOrder>;
  id: Scalars['String'];
  incrementalId: Scalars['Float'];
  isComplete?: Maybe<Scalars['Boolean']>;
  merchant: MerchantOrderMerchant;
  merchantPayment: Scalars['String'];
  partnerDescriptor: Scalars['String'];
  partnerId: Scalars['String'];
  payment: MerchantOrderPayment;
  paymentStatus: Scalars['String'];
  refundStatus?: Maybe<Scalars['String']>;
  refunds: Array<Refund>;
  shipping: MerchantOrderShipping;
  sourceType: Scalars['String'];
  summary: MerchantOrderSummary;
  totalSpend?: Maybe<TotalSpend>;
  updatedAt: Scalars['Date'];
};

export type MerchantOrderAddress = {
  __typename?: 'MerchantOrderAddress';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type MerchantOrderCustomerDetails = {
  __typename?: 'MerchantOrderCustomerDetails';
  addresses?: Maybe<Array<Maybe<Address>>>;
  createdAt?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerOrderCustomer>;
  customerId?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<CustomerOrderDeliveryAddress>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderNumber?: Maybe<Scalars['Int']>;
};

export type MerchantOrderExternalOrder = {
  __typename?: 'MerchantOrderExternalOrder';
  _id: Scalars['String'];
  cancelReason?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['String']>;
  createExternalOrder?: Maybe<CreateExternalOrder>;
  createdAt?: Maybe<Scalars['Date']>;
  externalCustomerId?: Maybe<Scalars['String']>;
  externalOrderId?: Maybe<Scalars['String']>;
  fulfillmentStatus?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderStatusUrl?: Maybe<Scalars['String']>;
  shippingLabelUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MerchantOrderIdsInput = {
  merchantOrderIds: Array<Scalars['String']>;
};

export type MerchantOrderInput = {
  customerOrderId?: InputMaybe<Scalars['String']>;
  fulfillmentStatus?: InputMaybe<Scalars['String']>;
  merchantOrderId: Scalars['String'];
};

export type MerchantOrderListPayload = {
  __typename?: 'MerchantOrderListPayload';
  items: Array<Maybe<MerchantOrder>>;
};

export type MerchantOrderMerchant = {
  __typename?: 'MerchantOrderMerchant';
  commissionType: Scalars['String'];
  companyCode?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email: Scalars['String'];
  feePercentage: Scalars['Float'];
  id: Scalars['String'];
  importService?: Maybe<Scalars['String']>;
  integrationType: Scalars['String'];
  migrated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offices: MerchantOrderAddress;
  shipping?: Maybe<ShippingType>;
  stripe?: Maybe<Scalars['String']>;
  stripeConnected?: Maybe<Scalars['Boolean']>;
  stripeState?: Maybe<Scalars['String']>;
  warehouses?: Maybe<MerchantOrderAddress>;
};

export type MerchantOrderPayment = {
  __typename?: 'MerchantOrderPayment';
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  refundStatus?: Maybe<Scalars['String']>;
  stripeMessage?: Maybe<Scalars['String']>;
  stripeZip?: Maybe<Scalars['String']>;
};

export type MerchantOrderSearchTerms = {
  customerChargeTotal?: InputMaybe<CustomerChargeTotalInput>;
  customerDetails?: InputMaybe<CustomerDetailsInput>;
  customerOrderNumber?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<DateRangeInput>;
  email?: InputMaybe<Scalars['String']>;
  fulfillmentStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  incrementalId?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  partnerDescriptor?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
};

export type MerchantOrderShipping = {
  __typename?: 'MerchantOrderShipping';
  selectedShippingOption?: Maybe<SelectedShippingOption>;
  ups?: Maybe<Ups>;
};

export type MerchantOrderSummary = {
  __typename?: 'MerchantOrderSummary';
  applicationFee: Scalars['Float'];
  applicationFeeWithTax: Scalars['Float'];
  commissionFee: Scalars['Float'];
  commissionFeeWithTax: Scalars['Float'];
  currency: Scalars['String'];
  customerChargeTotal: Scalars['Float'];
  duties: Scalars['Float'];
  merchantRevenue: Scalars['Float'];
  shipping: Scalars['Float'];
  shippingWithTax: Scalars['Float'];
  subTotal: Scalars['Float'];
  subTotalWithTax: Scalars['Float'];
};

export type MerchantOrderTotalPayload = {
  __typename?: 'MerchantOrderTotalPayload';
  total: Scalars['Int'];
};

export type MerchantOrdersInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  searchTerms?: InputMaybe<MerchantOrderSearchTerms>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type MerchantOrdersTotalInput = {
  searchTerms?: InputMaybe<MerchantOrderSearchTerms>;
};

export type MerchantPayoutCsvRow = {
  merchantPayment: Scalars['String'];
  publicId: Scalars['String'];
};

export type MerchantPreviewProfile = {
  __typename?: 'MerchantPreviewProfile';
  coverPicture?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Image>;
};

export type MerchantPrivate = {
  __typename?: 'MerchantPrivate';
  credentials?: Maybe<Credentials>;
  fee?: Maybe<Scalars['Float']>;
};

export type MerchantPrivateInput = {
  credentials?: InputMaybe<CredentialsInput>;
  fee?: InputMaybe<Scalars['Float']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  rawCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCommentToCustomerOrder?: Maybe<ResultPayload>;
  addGhostExternalOrder?: Maybe<AddGhostExternalOrderPayload>;
  adminDeleteUser?: Maybe<DeleteUserPayload>;
  assignOldestOrdersToUser?: Maybe<ResultPayload>;
  cancelFulfillmentDetail?: Maybe<CancelFulfillmentDetailResult>;
  closeEscalation?: Maybe<ResultPayload>;
  createAccount: Account;
  createClient: Client;
  createFulfillmentDetail?: Maybe<CreateFulfillmentDetailResult>;
  createProductCollection: ProductCollection;
  createRole: Role;
  createUser: User;
  deleteGhostOrder?: Maybe<DeletedGhostOrderCount>;
  deleteMerchant?: Maybe<DeleteMerchantPayload>;
  deleteProductCollection: Scalars['Boolean'];
  deleteRole: DeletedCount;
  editMerchant?: Maybe<Merchant>;
  editProductUrl?: Maybe<EditProductUrlPayload>;
  enableWebhooks?: Maybe<EnableWebhooksPayload>;
  importProducts?: Maybe<ImportProductsPayload>;
  insertMerchant?: Maybe<Merchant>;
  login?: Maybe<LoginPayload>;
  markProductAvailable?: Maybe<Scalars['Boolean']>;
  markProductUnavailable?: Maybe<Scalars['Boolean']>;
  markVariantUnavailable?: Maybe<Scalars['Boolean']>;
  processCsvOrdersFile: Array<UploadCsvOrdersOutput>;
  publishMerchant?: Maybe<UpdateMerchantPayload>;
  reinviteUser?: Maybe<InviteUserPayload>;
  reopenEscalation?: Maybe<ResultPayload>;
  resendOrderConfirmationEmail?: Maybe<ResultPayload>;
  resendRefundEmail?: Maybe<ResendRefundEmailPayload>;
  resetPasswordRequestV2?: Maybe<ResetPasswordRequestPayloadV2>;
  sendFulfillmentDetailEmail?: Maybe<SendFulfillmentDetailEmailResult>;
  sendMerchantOrderToExternalService?: Maybe<SendMerchantOrderToShopifyPayload>;
  setExternalFulfillmentStatus?: Maybe<SetExternalFulfillmentStatusPayload>;
  setFulfillmentStatus?: Maybe<SetFulfillmentStatusPayload>;
  setNewPassword?: Maybe<SetNewPasswordPayload>;
  submitMerchantOrderRefund?: Maybe<RefundMerchantOrderPayload>;
  unpublishMerchant?: Maybe<UpdateMerchantPayload>;
  updateAccount: Account;
  updateByCsv?: Maybe<CsvUpdatePayload>;
  updateClient: Client;
  updateCustomerOrder?: Maybe<UpdateCustomerOrderPayload>;
  updateFulfillmentDetail?: Maybe<CreateFulfillmentDetailResult>;
  updateGhostExternalOrder?: Maybe<UpdateGhostExternalOrderPayload>;
  updateMerchantStripe?: Maybe<Merchant>;
  updateMyProfileDetails?: Maybe<Scalars['Boolean']>;
  updateProductCollection: ProductCollection;
  updateRole: Role;
  updateUser: User;
  uploadImages: Array<File>;
  uploadMerchantPayouts?: Maybe<UploadMerchantPayoutsPayload>;
};


export type MutationAddCommentToCustomerOrderArgs = {
  _id: Scalars['String'];
  input: AddCommentInput;
};


export type MutationAddGhostExternalOrderArgs = {
  input: GhostOrderInput;
};


export type MutationAdminDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationAssignOldestOrdersToUserArgs = {
  input: UserRefInput;
};


export type MutationCancelFulfillmentDetailArgs = {
  input: CancelFulfillmentDetailInput;
};


export type MutationCloseEscalationArgs = {
  commentId: Scalars['String'];
  customerOrderId: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  input: AccountInputCreate;
};


export type MutationCreateClientArgs = {
  input: ClientInputCreate;
};


export type MutationCreateFulfillmentDetailArgs = {
  input: CreateFulfillmentDetailInput;
};


export type MutationCreateProductCollectionArgs = {
  input: ProductCollectionInput;
};


export type MutationCreateRoleArgs = {
  input: RoleInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteGhostOrderArgs = {
  input: DeleteGhostOrderInput;
};


export type MutationDeleteMerchantArgs = {
  input: PublishMerchantInput;
};


export type MutationDeleteProductCollectionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['String'];
};


export type MutationEditMerchantArgs = {
  input: MerchantInput;
};


export type MutationEditProductUrlArgs = {
  input: EditProductUrlInput;
};


export type MutationEnableWebhooksArgs = {
  input: EnableWebhooksInput;
};


export type MutationImportProductsArgs = {
  input: ImportProductsInput;
};


export type MutationInsertMerchantArgs = {
  input: MerchantInput;
};


export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationMarkProductAvailableArgs = {
  input: ProductIdInput;
};


export type MutationMarkProductUnavailableArgs = {
  input: ProductIdInput;
};


export type MutationMarkVariantUnavailableArgs = {
  input: VariantUnavailableInput;
};


export type MutationProcessCsvOrdersFileArgs = {
  input: Array<UploadCsvOrdersInput>;
};


export type MutationPublishMerchantArgs = {
  input: PublishMerchantInput;
};


export type MutationReinviteUserArgs = {
  input: ReinviteUserInput;
};


export type MutationReopenEscalationArgs = {
  commentId: Scalars['String'];
  customerOrderId: Scalars['String'];
};


export type MutationResendOrderConfirmationEmailArgs = {
  customerOrderId: Scalars['String'];
};


export type MutationResendRefundEmailArgs = {
  input: ResendRefundEmailInput;
};


export type MutationResetPasswordRequestV2Args = {
  input: ResetPasswordRequestInputV2;
};


export type MutationSendFulfillmentDetailEmailArgs = {
  input: SendFulfillmentDetailEmailInput;
};


export type MutationSendMerchantOrderToExternalServiceArgs = {
  input: MerchantOrderInput;
};


export type MutationSetExternalFulfillmentStatusArgs = {
  input: SetExternalFulfillmentStatusInput;
};


export type MutationSetFulfillmentStatusArgs = {
  input: MerchantOrderInput;
};


export type MutationSetNewPasswordArgs = {
  input: SetNewPasswordInput;
};


export type MutationSubmitMerchantOrderRefundArgs = {
  input: RefundInput;
};


export type MutationUnpublishMerchantArgs = {
  input: PublishMerchantInput;
};


export type MutationUpdateAccountArgs = {
  id: Scalars['String'];
  input: AccountInputUpdate;
};


export type MutationUpdateByCsvArgs = {
  input: CsvUpdateInput;
};


export type MutationUpdateClientArgs = {
  id: Scalars['String'];
  input: ClientInputUpdate;
};


export type MutationUpdateCustomerOrderArgs = {
  _id: Scalars['String'];
  input: UpdateCustomerOrderInput;
};


export type MutationUpdateFulfillmentDetailArgs = {
  input: UpdateFulfillmentDetailInput;
};


export type MutationUpdateGhostExternalOrderArgs = {
  input: UpdateGhostOrderInput;
};


export type MutationUpdateMerchantStripeArgs = {
  input: UpdateMerchantStripeInput;
};


export type MutationUpdateMyProfileDetailsArgs = {
  input: UpdateProfileDetails;
};


export type MutationUpdateProductCollectionArgs = {
  _id: Scalars['String'];
  input: ProductCollectionInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['String'];
  input: RoleInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: UserInput;
};


export type MutationUploadImagesArgs = {
  files: Array<UploadImagesFilesInput>;
};


export type MutationUploadMerchantPayoutsArgs = {
  input: UploadMerchantPayoutsInput;
};

export type Options = {
  __typename?: 'Options';
  defaultInventoryAmount?: Maybe<Scalars['Int']>;
  imageHeaders?: Maybe<Scalars['AnyObject']>;
  importLimit?: Maybe<Scalars['Int']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  selfHosted?: Maybe<Scalars['Boolean']>;
  shouldForceImport?: Maybe<Scalars['Boolean']>;
  shouldLimitImport?: Maybe<Scalars['Boolean']>;
  shouldRemoveQueryParamsFromImgUrls?: Maybe<Scalars['Boolean']>;
  shouldUseImageProxy?: Maybe<Scalars['Boolean']>;
  targetCurrency?: Maybe<Scalars['String']>;
};

export type OptionsInput = {
  defaultInventoryAmount?: InputMaybe<Scalars['Int']>;
  imageHeaders?: InputMaybe<Scalars['AnyObject']>;
  importLimit?: InputMaybe<Scalars['Int']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selfHosted?: InputMaybe<Scalars['Boolean']>;
  shouldForceImport?: InputMaybe<Scalars['Boolean']>;
  shouldLimitImport?: InputMaybe<Scalars['Boolean']>;
  shouldRemoveQueryParamsFromImgUrls?: InputMaybe<Scalars['Boolean']>;
  shouldUseImageProxy?: InputMaybe<Scalars['Boolean']>;
};

export type OrderBillingAddress = {
  __typename?: 'OrderBillingAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  createdAt: Scalars['Date'];
  customerOrderId: Scalars['String'];
  event: OrderHistoryEvent;
  payload: OrderHistoryPayload;
  status: OrderHistoryStatus;
};

export type OrderHistoryEvent = {
  __typename?: 'OrderHistoryEvent';
  action: OrderHistoryEventType;
  type: OrderHistoryType;
};

export enum OrderHistoryEventType {
  CustomerOrderEmailSent = 'CustomerOrderEmailSent',
  CustomerOrderProcessedOrderEmailSent = 'CustomerOrderProcessedOrderEmailSent',
  CustomerOrderRefundEmailSent = 'CustomerOrderRefundEmailSent',
  CustomerOrderShippingEmailSent = 'CustomerOrderShippingEmailSent',
  MerchantOrderShippingRecorded = 'MerchantOrderShippingRecorded',
  MerchantOrderShippingUpdated = 'MerchantOrderShippingUpdated'
}

export type OrderHistoryPayload = {
  __typename?: 'OrderHistoryPayload';
  email?: Maybe<Scalars['String']>;
  merchantOrderId?: Maybe<Scalars['String']>;
  merchantOrderNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum OrderHistoryStatus {
  Error = 'error',
  Success = 'success'
}

export enum OrderHistoryType {
  EmailEvent = 'EmailEvent',
  ShippingEvent = 'ShippingEvent'
}

export type OrderLineItemProperties = {
  __typename?: 'OrderLineItemProperties';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type OrderLineItems = {
  __typename?: 'OrderLineItems';
  name: Scalars['String'];
  price: Scalars['Float'];
  product_id: Scalars['String'];
  properties?: Maybe<OrderLineItemProperties>;
  public_id: Scalars['String'];
  quantity: Scalars['Int'];
  requires_shipping: Scalars['Boolean'];
  variant_id: Scalars['String'];
  variant_sku?: Maybe<Scalars['String']>;
};

export type OrderMarketing = {
  __typename?: 'OrderMarketing';
  buyer_accepts_marketing?: Maybe<Scalars['Boolean']>;
};

export type OrderMetaFields = {
  __typename?: 'OrderMetaFields';
  key?: Maybe<Scalars['String']>;
  namespace?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_type?: Maybe<Scalars['String']>;
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  stripeMessage?: Maybe<Scalars['String']>;
};

export type OrderShippingAddress = {
  __typename?: 'OrderShippingAddress';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type OrderShippingLines = {
  __typename?: 'OrderShippingLines';
  code: Scalars['String'];
  price: Scalars['Float'];
  source: Scalars['String'];
  tax?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PaginationTotalCount = {
  __typename?: 'PaginationTotalCount';
  total?: Maybe<Scalars['Int']>;
};

export enum PaymentStatus {
  Paid = 'Paid',
  PartiallyRefunded = 'PartiallyRefunded',
  Refunded = 'Refunded',
  Unpaid = 'Unpaid'
}

export type PaymentSummary = {
  __typename?: 'PaymentSummary';
  currency: Scalars['String'];
  customerChargeTotal: Scalars['Float'];
  shippingWithTax: Scalars['Float'];
  subTotal: Scalars['Float'];
  subTotalWithTax: Scalars['Float'];
};

export type PickupTimeDetails = {
  earliestTimeReady?: InputMaybe<Scalars['String']>;
  latestTimeReady?: InputMaybe<Scalars['String']>;
  pickupDate?: InputMaybe<Scalars['String']>;
};

export type PlatformBreakdown = {
  __typename?: 'PlatformBreakdown';
  revenue: Scalars['Int'];
  revenueTax: Scalars['Int'];
  revenueTaxRate: Scalars['Int'];
  revenueWithTax: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  _id?: Maybe<Scalars['String']>;
  beamImages?: Maybe<Array<Maybe<Image>>>;
  brand?: Maybe<Brand>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<CategoryMetadata>;
  compareAtPrice?: Maybe<Scalars['Float']>;
  compareAtPrices?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  inventory?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
  metadata?: Maybe<MetaData>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCAD?: Maybe<Scalars['Float']>;
  productURL?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  shippedFrom?: Maybe<Scalars['String']>;
  sizing?: Maybe<Scalars['String']>;
  truthyValidationFlags: Array<Maybe<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['String']>;
  validationFlags?: Maybe<ValidationFlags>;
  variants?: Maybe<Array<Maybe<Variant>>>;
};


export type ProductDescriptionArgs = {
  isHTML?: InputMaybe<Scalars['Boolean']>;
};


export type ProductVariantsArgs = {
  allVariants?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCollection = {
  __typename?: 'ProductCollection';
  _id?: Maybe<Scalars['String']>;
  account: ProductCollectionAccount;
  createdAt?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  name: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
  publicId: Scalars['String'];
};

export type ProductCollectionAccount = {
  __typename?: 'ProductCollectionAccount';
  name: Scalars['String'];
  publicId: Scalars['String'];
};

export type ProductCollectionInput = {
  accountId: Scalars['String'];
  handle: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

export type ProductCollectionsCountInput = {
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type ProductCollectionsInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type ProductCountInput = {
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type ProductIdInput = {
  productId: Scalars['String'];
};

export type ProductTotalCount = {
  __typename?: 'ProductTotalCount';
  total: Scalars['Int'];
};

export type ProductsByIdInput = {
  productIds: Array<Scalars['String']>;
};

export type ProductsInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type Profile = {
  __typename?: 'Profile';
  coverPicture?: Maybe<Image>;
  description?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Image>;
};

export type PublishMerchantInput = {
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  accountByPublicId: Account;
  accounts: AccountList;
  allCxContractorUsers?: Maybe<UserQueryPayload>;
  brands?: Maybe<BrandListPayload>;
  client: Client;
  clients: Array<Client>;
  clientsCount: PaginationTotalCount;
  currentUser: CurrentUser;
  customerOrder?: Maybe<CustomerOrder>;
  customerOrders: CustomerOrdersPayload;
  customerOrdersCount: PaginationTotalCount;
  exportMerchantOrders?: Maybe<ExportCsvPayload>;
  getMerchantOrdersByCustomerOrderId: Array<Maybe<MerchantOrder>>;
  getMultipleMerchantOrders: Array<Maybe<MerchantOrder>>;
  getOneMerchantOrder?: Maybe<MerchantOrder>;
  merchant?: Maybe<Merchant>;
  merchantAutocomplete?: Maybe<Array<Maybe<Merchant>>>;
  merchantExportFeedDownloadUrl?: Maybe<MerchantExportFeedDownloadUrlPayload>;
  merchantOrders?: Maybe<MerchantOrderListPayload>;
  merchantOrdersTotal?: Maybe<MerchantOrderTotalPayload>;
  merchants?: Maybe<MerchantListPayload>;
  productByPublicId: Product;
  productCollection?: Maybe<ProductCollection>;
  productCollections: Array<ProductCollection>;
  productCollectionsCount: PaginationTotalCount;
  products: Array<Maybe<Product>>;
  productsByPublicIds: Array<Maybe<Product>>;
  productsCount: ProductTotalCount;
  role?: Maybe<Role>;
  roles: Array<Maybe<Role>>;
  rolesCount: PaginationTotalCount;
  test?: Maybe<Test>;
  testMerchantProducts?: Maybe<TestMerchantProductPayload>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  usersCount?: Maybe<PaginationTotalCount>;
  validateMerchantCredentials?: Maybe<ResultPayload>;
};


export type QueryAccountArgs = {
  _id?: InputMaybe<Scalars['String']>;
};


export type QueryAccountByPublicIdArgs = {
  publicId: Scalars['String'];
};


export type QueryAccountsArgs = {
  input: AccountsInput;
};


export type QueryBrandsArgs = {
  input: BrandListInput;
};


export type QueryClientArgs = {
  id: Scalars['String'];
};


export type QueryClientsArgs = {
  input: ClientsInput;
};


export type QueryClientsCountArgs = {
  input: ClientsCountInput;
};


export type QueryCustomerOrderArgs = {
  _id: Scalars['String'];
};


export type QueryCustomerOrdersArgs = {
  input?: InputMaybe<CustomerOrdersInput>;
};


export type QueryExportMerchantOrdersArgs = {
  input: ExportMerchantOrdersInput;
};


export type QueryGetMerchantOrdersByCustomerOrderIdArgs = {
  input: CustomerOrderIdInput;
};


export type QueryGetMultipleMerchantOrdersArgs = {
  input: MerchantOrderIdsInput;
};


export type QueryGetOneMerchantOrderArgs = {
  input: MerchantOrderInput;
};


export type QueryMerchantArgs = {
  id: Scalars['String'];
};


export type QueryMerchantAutocompleteArgs = {
  input: AutocompleteInput;
};


export type QueryMerchantExportFeedDownloadUrlArgs = {
  feedName: Scalars['String'];
};


export type QueryMerchantOrdersArgs = {
  input: MerchantOrdersInput;
};


export type QueryMerchantOrdersTotalArgs = {
  input: MerchantOrdersTotalInput;
};


export type QueryMerchantsArgs = {
  input: MerchantListInput;
};


export type QueryProductByPublicIdArgs = {
  input: ProductIdInput;
};


export type QueryProductCollectionArgs = {
  _id: Scalars['String'];
};


export type QueryProductCollectionsArgs = {
  input: ProductCollectionsInput;
};


export type QueryProductCollectionsCountArgs = {
  input: ProductCollectionsCountInput;
};


export type QueryProductsArgs = {
  input: ProductsInput;
};


export type QueryProductsByPublicIdsArgs = {
  input: ProductsByIdInput;
};


export type QueryProductsCountArgs = {
  input: ProductCountInput;
};


export type QueryRoleArgs = {
  _id: Scalars['String'];
};


export type QueryRolesArgs = {
  input: RolesInput;
};


export type QueryRolesCountArgs = {
  input: RolesCountInput;
};


export type QueryTestMerchantProductsArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  _id: Scalars['String'];
};


export type QueryUsersArgs = {
  input: UsersInput;
};


export type QueryUsersCountArgs = {
  input: UsersCountInput;
};


export type QueryValidateMerchantCredentialsArgs = {
  id: Scalars['String'];
};

export type Refund = {
  __typename?: 'Refund';
  _id: Scalars['String'];
  amount: Scalars['Float'];
  checkoutVariants: Array<CheckoutVariant>;
  createdAt: Scalars['Date'];
  createdBy: UserRef;
  options: RefundOptions;
  refundReason: Scalars['String'];
  refundedAmount: Scalars['Float'];
  refundedDuties: Scalars['Float'];
  refundedShipping: Scalars['Float'];
  refundedSubtotal: Scalars['Float'];
  refundedTaxes: Scalars['Float'];
};

export type RefundInput = {
  amount: Scalars['Float'];
  duties: Scalars['Float'];
  isBonsaiOnlyRefund: Scalars['Boolean'];
  merchantOrderId: Scalars['String'];
  products: Array<InputMaybe<ShortcutProductInput>>;
  reason: Scalars['String'];
  shipping: Scalars['Float'];
  taxes: Scalars['Float'];
};

export type ReinviteUserInput = {
  email: Scalars['String'];
};

export type ResendRefundEmailInput = {
  merchantOrderId: Scalars['String'];
  refundId: Scalars['String'];
};

export type ResetPasswordRequestInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordRequestInputV2 = {
  browserName: Scalars['String'];
  email: Scalars['String'];
  ipAddress: Scalars['String'];
};

export type ResetPasswordRequestPayload = {
  __typename?: 'ResetPasswordRequestPayload';
  result?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type ResetPasswordRequestPayloadV2 = {
  __typename?: 'ResetPasswordRequestPayloadV2';
  result?: Maybe<Scalars['Boolean']>;
};

export type Rgb = {
  __typename?: 'Rgb';
  a?: Maybe<Scalars['Float']>;
  b?: Maybe<Scalars['Float']>;
  g?: Maybe<Scalars['Float']>;
  r?: Maybe<Scalars['Float']>;
};

export type RgbInput = {
  a?: InputMaybe<Scalars['Float']>;
  b?: InputMaybe<Scalars['Float']>;
  g?: InputMaybe<Scalars['Float']>;
  r?: InputMaybe<Scalars['Float']>;
};

export type Role = {
  __typename?: 'Role';
  _id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isProtected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: InputMaybe<Array<Scalars['String']>>;
};

export type RolesCountInput = {
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type RolesInput = {
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type SchedulePickupInput = {
  customerOrderId?: InputMaybe<Scalars['String']>;
  merchantOrderId?: InputMaybe<Scalars['String']>;
  pickupTime?: InputMaybe<PickupTimeDetails>;
};

export type SchedulePickupPayload = {
  __typename?: 'SchedulePickupPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SelectedShippingOption = {
  __typename?: 'SelectedShippingOption';
  code: Scalars['Int'];
  description: Scalars['String'];
  shippingRate: Scalars['Float'];
  shippingRateCurrency: Currency;
  shippingTax: Scalars['Float'];
  shippingTaxCurrency: Currency;
};

export type SendFulfillmentDetailEmailInput = {
  fulfillmentDetailId: Scalars['String'];
  merchantOrderId: Scalars['String'];
};

export type SendFulfillmentDetailEmailResult = {
  __typename?: 'SendFulfillmentDetailEmailResult';
  result?: Maybe<Scalars['Boolean']>;
};

export type SendMerchantOrderToShopifyPayload = {
  __typename?: 'SendMerchantOrderToShopifyPayload';
  result?: Maybe<Scalars['String']>;
};

export type SetExternalFulfillmentStatusInput = {
  feedOrderId: Scalars['String'];
  fulfillmentStatus: Scalars['String'];
  merchantOrderId: Scalars['String'];
};

export type SetExternalFulfillmentStatusPayload = {
  __typename?: 'SetExternalFulfillmentStatusPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SetFulfillmentStatusPayload = {
  __typename?: 'SetFulfillmentStatusPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SetNewPasswordInput = {
  browserName: Scalars['String'];
  confirmNewPassword: Scalars['String'];
  ipAddress: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type SetNewPasswordPayload = {
  __typename?: 'SetNewPasswordPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SetOrderToCancelledPayload = {
  __typename?: 'SetOrderToCancelledPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type SetOrderToReadyForFulfillmentPayload = {
  __typename?: 'SetOrderToReadyForFulfillmentPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type ShippingBreakdown = {
  __typename?: 'ShippingBreakdown';
  amount: Scalars['Int'];
  amountWithTax: Scalars['Int'];
  tax: Scalars['Int'];
};

export enum ShippingCarrier {
  Dhl = 'DHL',
  FedEx = 'FedEx',
  Other = 'Other',
  Ups = 'UPS',
  Usps = 'USPS'
}

export type ShippingInput = {
  bookie?: InputMaybe<Scalars['String']>;
  free?: InputMaybe<FreeShippingInput>;
  simple?: InputMaybe<Array<InputMaybe<SimpleShippingInput>>>;
  type?: InputMaybe<Scalars['String']>;
};

export type ShippingRatePerCountryInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  freeIfPassedSubtotal?: InputMaybe<Scalars['String']>;
  shippingRate?: InputMaybe<Scalars['String']>;
};

export type ShippingRatePerCountryType = {
  __typename?: 'ShippingRatePerCountryType';
  countryCode?: Maybe<Scalars['String']>;
  freeIfPassedSubtotal?: Maybe<Scalars['String']>;
  shippingRate?: Maybe<Scalars['String']>;
};

export type ShippingType = {
  __typename?: 'ShippingType';
  bookie?: Maybe<Scalars['String']>;
  free?: Maybe<FreeShippingType>;
  simple?: Maybe<Array<Maybe<SimpleShippingType>>>;
  type?: Maybe<Scalars['String']>;
};

export type ShopifyCredentials = {
  __typename?: 'ShopifyCredentials';
  keys?: Maybe<ShopifyKeys>;
  shopName?: Maybe<Scalars['String']>;
};

export type ShopifyCredentialsInput = {
  keys?: InputMaybe<ShopifyKeysInput>;
  shopName?: InputMaybe<Scalars['String']>;
};

export type ShopifyKeys = {
  __typename?: 'ShopifyKeys';
  order?: Maybe<ApiCredentials>;
  product?: Maybe<ApiCredentials>;
};

export type ShopifyKeysInput = {
  order?: InputMaybe<ApiCredentialsInput>;
  product?: InputMaybe<ApiCredentialsInput>;
};

export type ShortcutProduct = {
  __typename?: 'ShortcutProduct';
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  variantId: Scalars['String'];
};

export type ShortcutProductInput = {
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  variantId: Scalars['String'];
};

export type SimpleShippingInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  shippingRateCurrency?: InputMaybe<Scalars['String']>;
  shippingRatePerCountry?: InputMaybe<Array<InputMaybe<ShippingRatePerCountryInput>>>;
};

export type SimpleShippingType = {
  __typename?: 'SimpleShippingType';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shippingRateCurrency?: Maybe<Scalars['String']>;
  shippingRatePerCountry?: Maybe<Array<Maybe<ShippingRatePerCountryType>>>;
};

export type SizeVariant = {
  __typename?: 'SizeVariant';
  id?: Maybe<Scalars['String']>;
  inventory?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type StoreBreakdown = {
  __typename?: 'StoreBreakdown';
  revenue: Scalars['Int'];
  revenueRate: Scalars['Int'];
  revenueTax: Scalars['Int'];
  revenueWithTax: Scalars['Int'];
};

export type TaxBreakdown = {
  __typename?: 'TaxBreakdown';
  total: Scalars['Int'];
};

export enum TaxEnum {
  Default = 'default',
  Eci = 'eci'
}

export type Test = {
  __typename?: 'Test';
  text?: Maybe<Scalars['Date']>;
};

export type TestMerchantProductPayload = {
  __typename?: 'TestMerchantProductPayload';
  errors: Array<Scalars['String']>;
  message: Scalars['String'];
};

export type TotalSpend = {
  __typename?: 'TotalSpend';
  amount?: Maybe<Scalars['Float']>;
};

export type UniversalOrder = {
  __typename?: 'UniversalOrder';
  billing_address?: Maybe<OrderBillingAddress>;
  currency?: Maybe<Scalars['String']>;
  customer: OrderCustomer;
  email?: Maybe<Scalars['String']>;
  financial_status?: Maybe<Scalars['String']>;
  fulfillment_status?: Maybe<Scalars['String']>;
  incrementalId?: Maybe<Scalars['Int']>;
  line_items?: Maybe<Array<Maybe<OrderLineItems>>>;
  marketing?: Maybe<OrderMarketing>;
  merchant_country?: Maybe<Scalars['String']>;
  merchant_revenue?: Maybe<Scalars['Float']>;
  metafields?: Maybe<Array<Maybe<OrderMetaFields>>>;
  partner_id?: Maybe<Scalars['String']>;
  public_id?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<OrderShippingAddress>;
  shipping_lines?: Maybe<OrderShippingLines>;
  token?: Maybe<Scalars['String']>;
  total_tax?: Maybe<Scalars['Float']>;
};

export type UpdateBlacklistInput = {
  brandId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBlacklistWhitelistPayload = {
  __typename?: 'UpdateBlacklistWhitelistPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateCustomerOrderInput = {
  assignee?: InputMaybe<UserRefInput>;
};

export type UpdateCustomerOrderPayload = {
  __typename?: 'UpdateCustomerOrderPayload';
  customerOrder?: Maybe<CustomerOrder>;
};

export type UpdateFulfillmentDetailInput = {
  fulfillmentDetailId: Scalars['String'];
  fulfillmentDetails: FulfillmentDetailInput;
  merchantOrderId: Scalars['String'];
};

export type UpdateGhostOrderInput = {
  feedOrderId: Scalars['String'];
  merchantOrderId: Scalars['String'];
  order: GhostOrder;
};

export type UpdateLocationsInput = {
  id?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateLocationsPayload = {
  __typename?: 'UpdateLocationsPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateMerchantInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<Scalars['String']>;
  srcType?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantPayload = {
  __typename?: 'UpdateMerchantPayload';
  merchant?: Maybe<Merchant>;
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateMerchantStripeInput = {
  stripeAccount?: InputMaybe<Scalars['String']>;
  stripeCode?: InputMaybe<Scalars['String']>;
  stripeState?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileDetails = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateWhitelistInput = {
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isWhitelisted?: InputMaybe<Scalars['Boolean']>;
};

export type UploadCsvOrdersError = {
  __typename?: 'UploadCsvOrdersError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  order: ErrorOrderInfo;
};

export type UploadCsvOrdersInput = {
  accountId: Scalars['String'];
  file: Scalars['Upload'];
};

export type UploadCsvOrdersOutput = {
  __typename?: 'UploadCsvOrdersOutput';
  accountId: Scalars['String'];
  errors: Array<UploadCsvOrdersError>;
  orders: Array<UniversalOrder>;
  url: Scalars['String'];
};

export type UploadImagesFilesInput = {
  file: Scalars['Upload'];
  name?: InputMaybe<Scalars['String']>;
};

export type UploadMerchantPayoutsInput = {
  rows: Array<MerchantPayoutCsvRow>;
};

export type UploadedAsset = {
  __typename?: 'UploadedAsset';
  createdAt?: Maybe<Scalars['Date']>;
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Ups = {
  __typename?: 'Ups';
  labelHtmlString?: Maybe<Scalars['String']>;
  pickupReferenceNumber?: Maybe<Scalars['Int']>;
  shipmentIdentificationNumber?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type UpsInvoiceTotalType = {
  __typename?: 'UpsInvoiceTotalType';
  CurrencyCode?: Maybe<Scalars['String']>;
  MonetaryValue?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  email: Scalars['String'];
  jwt?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  merchantId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  roles: Array<Scalars['String']>;
  userRoles?: Maybe<Array<Scalars['String']>>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  profile: UserProfile;
  userRoles: Array<Scalars['String']>;
};

export type UserProfile = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserQueryPayload = {
  __typename?: 'UserQueryPayload';
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UserRef = {
  __typename?: 'UserRef';
  name: Scalars['String'];
  userId: Scalars['String'];
};

export type UserRefInput = {
  name: Scalars['String'];
  userId: Scalars['String'];
};

export type UsersCountInput = {
  filters?: InputMaybe<UsersFilters>;
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
};

export type UsersFilters = {
  roles?: InputMaybe<Scalars['OptionsFilter']>;
};

export type UsersInput = {
  filters?: InputMaybe<UsersFilters>;
  /** Maximum amount of items for pagination. Added by PaginatedDirective */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page of pagination. Added by PaginatedDirective */
  page?: Scalars['Int'];
  /** Current page size of pagination. Added by PaginatedDirective */
  pageSize?: Scalars['Int'];
  /** search options. Added by SearchableDirective */
  search?: InputMaybe<Scalars['String']>;
  /** Amount of items to skip for pagination. Added by PaginatedDirective */
  skip?: InputMaybe<Scalars['Int']>;
  /** sort options. Added by sortableDirective */
  sortBy?: InputMaybe<Scalars['AnyObject']>;
};

export type UsersResult = {
  __typename?: 'UsersResult';
  users?: Maybe<Array<Maybe<User>>>;
};

export type ValidationFlags = {
  __typename?: 'ValidationFlags';
  brandIsBlacklistedInMerchant?: Maybe<Scalars['Boolean']>;
  brandIsNotLive?: Maybe<Scalars['Boolean']>;
  hasDuplicateVariants?: Maybe<Scalars['Boolean']>;
  hasNoImages?: Maybe<Scalars['Boolean']>;
  hasNoShippingOptions?: Maybe<Scalars['Boolean']>;
  hasNoVariants?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  isMissingOptions?: Maybe<Scalars['Boolean']>;
  isNotPublished?: Maybe<Scalars['Boolean']>;
  isOutOfStock?: Maybe<Scalars['Boolean']>;
  isUnavailable?: Maybe<Scalars['Boolean']>;
  merchantIsNotLive?: Maybe<Scalars['Boolean']>;
  needsFurtherRefinement?: Maybe<Scalars['Boolean']>;
  variantHasInvalidCompareAtPrice?: Maybe<Scalars['Boolean']>;
  variantHasInvalidPrice?: Maybe<Scalars['Boolean']>;
};

export type Variant = {
  __typename?: 'Variant';
  beamImages?: Maybe<Array<Maybe<Image>>>;
  brand?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  id: Scalars['String'];
  images: Array<Scalars['String']>;
  importVariantId: Scalars['String'];
  inventory: Scalars['Float'];
  isDeleted: Scalars['Boolean'];
  isUnavailable: Scalars['Boolean'];
  option: VariantOptionType;
  optionTitles: Array<Scalars['String']>;
  optionValues: Array<Scalars['String']>;
  price: Scalars['Float'];
  priceUSD?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  sizeVariants?: Maybe<Array<Maybe<SizeVariant>>>;
  sku?: Maybe<Scalars['String']>;
};

export type VariantOptionType = {
  __typename?: 'VariantOptionType';
  color?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type VariantUnavailableInput = {
  productId: Scalars['String'];
  variantId: Scalars['String'];
};

export type AddCommentPayload = {
  __typename?: 'addCommentPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type AddGhostExternalOrderPayload = {
  __typename?: 'addGhostExternalOrderPayload';
  status?: Maybe<Scalars['Int']>;
  statusText?: Maybe<Scalars['String']>;
};

export type ExternalPayment = {
  __typename?: 'externalPayment';
  amounts: ExternalPaymentAmounts;
  currency?: Maybe<Scalars['String']>;
};

export type ExternalPaymentAmounts = {
  __typename?: 'externalPaymentAmounts';
  goods: Scalars['Float'];
  shipping: Scalars['Float'];
  taxes: Scalars['Float'];
};

export type RefundMerchantOrderPayload = {
  __typename?: 'refundMerchantOrderPayload';
  action?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  duties?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  isBonsaiOnlyRefund?: Maybe<Scalars['Boolean']>;
  merchantOrderId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ShortcutProduct>>>;
  reason?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type RefundOptions = {
  __typename?: 'refundOptions';
  isBonsaiOnlyRefund?: Maybe<Scalars['Boolean']>;
};

export type ResendRefundEmailPayload = {
  __typename?: 'resendRefundEmailPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type ResultPayload = {
  __typename?: 'resultPayload';
  error?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateGhostExternalOrderPayload = {
  __typename?: 'updateGhostExternalOrderPayload';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdatedGhostOrderCount = {
  __typename?: 'updatedGhostOrderCount';
  result?: Maybe<Scalars['Int']>;
};

export type UploadImagesResponse = {
  __typename?: 'uploadImagesResponse';
  url: Scalars['String'];
};

export type UploadMerchantPayoutsPayload = {
  __typename?: 'uploadMerchantPayoutsPayload';
  result?: Maybe<Scalars['Boolean']>;
};
