import { StatusTag } from '@components/StatusTag';
import { Divider, Stack, Typography } from '@mui/material';
import { Color } from '@theme/palette';
import { EciIntegrationType } from '@types';
import React from 'react';

import { CheckoutVariant } from '@common/types/GraphqlTypes.d';

import { Product } from '../Product';

interface IOrderItemsProps {
  checkoutVariants: CheckoutVariant[];
  merchantName: string;
  integrationType: EciIntegrationType;
  isArchived?: boolean;
}

export const OrderItems = ({
  isArchived = false,
  checkoutVariants,
  merchantName,
  integrationType,
}: IOrderItemsProps) => {
  if (checkoutVariants.length === 0) {
    return null;
  }

  return (
    <div>
      <Stack direction="row" alignItems="center" gap={2} sx={{ marginBottom: '10px' }}>
        <Typography sx={{ fontSize: 15, fontWeight: 600 }}>{merchantName}</Typography>
        <StatusTag
          text={integrationType}
          color={Color.Blue}
          sx={{
            fontSize: '12px !important',
            fontWeight: '500 !important',
          }}
        />
      </Stack>
      <Stack spacing={2} divider={<Divider />}>
        {checkoutVariants.map((variant, index) => (
          <Product key={index} checkoutVariant={variant} isArchived={isArchived} />
        ))}
      </Stack>
    </div>
  );
};
