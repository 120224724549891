import { Card } from '@components/Card';
import { DataFieldProps } from '@components/DataField';
import { DataStack } from '@components/DataStack';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PriceRow } from '@pages/Order/components/PaymentDetails';
import currencyJs from 'currency.js';
import React, { useState } from 'react';

import { CustomerOrder, MerchantOrder, ExternalPayment } from '@common/types/GraphqlTypes.d';

import { useStyles } from '../styles';

interface IPaymentDetailsProps {
  order: CustomerOrder;
  isLoading: boolean;
  merchantOrders: MerchantOrder[];
}

const getPaymentDetailRows = (customerOrder: CustomerOrder): DataFieldProps[] => [
  { label: 'Type', value: customerOrder.paymentMethod },
  {
    label: 'Status',
    value: (
      <Typography variant="body2">
        {customerOrder.paymentStatus ?? 'Status not available.'}
      </Typography>
    ),
  },
  {
    label: 'Total amount',
    value: <Typography variant="body2">${customerOrder.summary.customerChargeTotal}</Typography>,
  },
];

const CustomerPaymentSummary: React.FC<{
  customerOrder: CustomerOrder;
  shouldShowCustomerPaymentSummary: boolean;
}> = ({ customerOrder, shouldShowCustomerPaymentSummary }) => {
  if (!shouldShowCustomerPaymentSummary) {
    return null;
  }

  return (
    <div style={{ marginLeft: '8px' }}>
      <PriceRow
        title="Goods base"
        price={currencyJs(customerOrder.breakdown.goods.subtotal).divide(100).value}
      />
      <PriceRow
        title="Goods tax"
        price={currencyJs(customerOrder.breakdown.goods.subtotalTax).divide(100).value}
      />
      <PriceRow
        title="Shipping base"
        price={currencyJs(customerOrder.breakdown.shipping.amount).divide(100).value}
      />
      <PriceRow
        title="Shipping tax"
        price={currencyJs(customerOrder.breakdown.shipping.tax).divide(100).value}
      />
      <PriceRow
        title="Total taxes"
        price={currencyJs(customerOrder.breakdown.tax.total).divide(100).value}
      />
    </div>
  );
};

const CustomerPaymentDetails: React.FC<{
  customerOrder: CustomerOrder;
  merchantOrders: MerchantOrder[];
}> = ({ customerOrder, merchantOrders }) => {
  const classes = useStyles();
  const [shouldShowCustomerPaymentSummary, setShowCustomerPaymentSummary] = useState(false);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <div className={classes.paymentDetails}>Customer</div>
        <button
          className={classes.detailsButton}
          onClick={() => setShowCustomerPaymentSummary(!shouldShowCustomerPaymentSummary)}
        >
          <div style={{ textDecoration: 'underline' }}>
            {shouldShowCustomerPaymentSummary ? 'hide details' : 'view more'}
          </div>
        </button>
      </Stack>
      <Divider className={classes.divider} />
      <DataStack stackId="paymentDetails" items={getPaymentDetailRows(customerOrder)} />

      <CustomerPaymentSummary
        shouldShowCustomerPaymentSummary={shouldShowCustomerPaymentSummary}
        customerOrder={customerOrder}
      />
      <DataStack
        stackId="merchantOrders"
        items={merchantOrders.map(({ merchant, merchantPayment }) => ({
          label: `Payment to ${merchant.name}`,
          value: merchantPayment,
        }))}
      />
    </Stack>
  );
};

const getExternalPaymentRows = ({
  amounts: { shipping, taxes, goods },
}: ExternalPayment): DataFieldProps[] => [
  { label: 'Shipping', value: `$ ${shipping}` },
  { label: 'Taxes', value: `$ ${taxes}` },
  { label: 'Subtotal', value: `$ ${goods}` },
];

const ExternalPaymentDetails: React.FC<{ externalPayment: ExternalPayment }> = ({
  externalPayment,
}) => {
  const classes = useStyles();
  const [shouldShowExternalPayment, setShowExternalPayment] = useState(false);

  return (
    <>
      <button
        className={classes.detailsButton}
        onClick={() => setShowExternalPayment(!shouldShowExternalPayment)}
      >
        {shouldShowExternalPayment ? <ExpandMore /> : <ChevronRight />}
        <Typography className={classes.paymentDetails}>
          {shouldShowExternalPayment
            ? 'Hide external payment details'
            : 'Show external payment details'}
        </Typography>
      </button>
      {shouldShowExternalPayment ? (
        <DataStack stackId="externalPayment" items={getExternalPaymentRows(externalPayment)} />
      ) : (
        <></>
      )}
    </>
  );
};

export const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  isLoading,
  order,
  merchantOrders,
}) => (
  <Card title="Payment Details" isLoading={isLoading}>
    <CustomerPaymentDetails customerOrder={order} merchantOrders={merchantOrders} />
    {order.externalPayment && <ExternalPaymentDetails externalPayment={order.externalPayment} />}
  </Card>
);
