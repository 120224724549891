import { StyledTextField } from '@components/DataFilter/Styled';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';
import { Color } from '@theme/palette';
import React, { useEffect } from 'react';

type SearchProps = Omit<TextFieldProps, 'value'> & {
  /**
   * Indicates whether to call `onUpdate` only when enter is pressed.
   */
  updateOnEnter?: boolean;

  /**
   * Function to call when the input value changes.
   * Automatically extracts event.target.value.
   */
  onUpdate?: (value: string) => void;

  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: string;
};

/**
 * Returns Search textfield component.
 * @todo - Add debounce support!
 */
export const FilterSearch = ({ onUpdate, updateOnEnter, value = '', ...muiProps }: SearchProps) => {
  const [pendingValue, setPendingValue] = React.useState<string>(value);

  /**
   * Allows to call parent only when enter is pressed.
   */
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (key === 'Enter' && updateOnEnter && onUpdate && pendingValue) {
      onUpdate(pendingValue as string);
    }
  };

  /**
   * Clears the value of the input field by informing parent.
   */
  const clearValue = () => {
    setPendingValue('');
  };

  /**
   * Informs parent about the value change.
   */
  useEffect(() => {
    // Update on every keystroke (depends on `updateOnEnter` prop)
    if (onUpdate && !updateOnEnter) {
      onUpdate(pendingValue);
    }

    // Update parent whenever value gets cleared
    if (onUpdate && pendingValue === '') {
      onUpdate('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingValue]);

  /**
   * Show clear button if value is not empty.
   */
  const endAdornment =
    pendingValue === '' ? null : (
      <Box component="span" onClick={clearValue}>
        <ClearIcon
          sx={{ fontSize: '1.1em', color: Color.Gray1, cursor: 'pointer', verticalAlign: 'middle' }}
        />
      </Box>
    );

  return (
    <StyledTextField
      size="small"
      value={pendingValue}
      onKeyDown={onKeyDown}
      onChange={({ target: { value: updatedValue } }) => {
        setPendingValue(updatedValue);
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...muiProps.InputProps,
        endAdornment,
        startAdornment: (
          <SearchIcon sx={{ fontSize: '1.1em', color: Color.Gray1, marginRight: '5px' }} />
        ),
      }}
      {...muiProps}
    />
  );
};
