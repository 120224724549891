/**
 * Takes a 'fullName' and splits it into a 'firstName' and 'lastName'.
 */
export const splitFullName = (fullName: string) => {
  // in the case of no last name
  if (!/\s/.test(fullName)) {
    return {
      firstName: fullName,
      lastname: '',
    };
  }

  return {
    firstName: fullName.split(' ').slice(0, -1).join(' '),
    lastName: fullName.split(' ').slice(-1).join(' '),
  };
};
