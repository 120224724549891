import { useStore } from 'react-context-hook';
import { useSearchParams } from 'react-router-dom';

import { getQueryUrl, parseQueryURL } from '@utils/query';

const DEFAULT_PAGINATION = {
  page: 0,
  pageSize: 10,
};

export type IPagination = {
  page: number;
  pageSize: number;
};

/**
 * Returns default pagination values.
 * Either from URL query or default values.
 * @todo - Add safeguard to avoid unlimited pageSize!
 */
export const getDefaultPagination = () => {
  const { page = DEFAULT_PAGINATION.page, pageSize = DEFAULT_PAGINATION.pageSize } =
    parseQueryURL<IPagination>() ?? {};

  // Convert to number is turned off as IDs can be strings
  return {
    page: Number(page),
    pageSize: Number(pageSize),
  };
};

/**
 * Returns hook for managing global pagination state.
 * Automatically extracts pagination from URL query.
 * @param {string} key - Key to identify the context store (defaults to pagination). Useful if you want to use multiple pagination hooks.
 */
export const usePagination = (key: string = 'pagination') => {
  const defaultValues = getDefaultPagination();
  const [_searchParams, setSearchParams] = useSearchParams();

  const [pagination, setPagination] = useStore<IPagination>(key, defaultValues);

  const updatePagination = (updatedValue: Partial<IPagination>) => {
    const values = { ...pagination, ...updatedValue };

    // Update state.
    setPagination(values);

    // Update URL with new pagination values. (appends to query - not replace)
    setSearchParams(getQueryUrl(values, { replace: false }));
  };

  // Used for GraphQL queries - maps pagination from MUI table to graphQL.
  const getGraphQLPagination = () => ({
    page: pagination.page + 1,
    pageSize: pagination.pageSize,
  });

  // Sets pagination to default values.
  const clearPagination = () => {
    updatePagination(DEFAULT_PAGINATION);
  };

  return {
    pagination,
    getGraphQLPagination,
    updatePagination,
    clearPagination,
  };
};
