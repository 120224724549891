import { SvgIconComponent } from '@mui/icons-material';
import { Grid, MenuItem as MuiMenuItem } from '@mui/material';
import React from 'react';

type MenuItemProps = {
  action: () => void;
  handleClose?: () => void;
  icon: SvgIconComponent;
  label: string;
};

// TODO: Make the menu item close without having the dialog close! This is due to the fact that both Dialog & MenuItem are within Menu
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const MenuItem = ({ action, icon: Icon, label, handleClose = () => {} }: MenuItemProps) => (
  <MuiMenuItem
    onClick={() => {
      action();
      handleClose();
    }}
  >
    <Grid container columnGap={2}>
      <Icon />
      {label}
    </Grid>
  </MuiMenuItem>
);
