import { StyledButton } from '@components/DataFilter/Styled';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForkRef } from '@mui/material/utils';
import { SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro';
import { Color } from '@theme/palette';
import React, { forwardRef } from 'react';

type DateRangeButtonFieldProps = SingleInputDateRangeFieldProps<Date> & {
  /**
   * Indicates whether the date range picker is open.
   */
  isOpen?: boolean;

  /**
   * Function allowing to set the date range picker open state.
   */
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Placeholder to display.
   */
  placeholder?: string;
};

/**
 * Returns a button looking like a Select dropdown button.
 */
export const DateButton = forwardRef(
  (props: DateRangeButtonFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      setOpen,
      isOpen,
      id,
      disabled: isDisabled,
      placeholder,
      InputProps: { ref: containerRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <StyledButton
        id={id}
        disabled={isDisabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => setOpen?.((previous) => !previous)}
        endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={() => ({
          borderColor: isOpen ? Color.Gray2 : Color.Gray3,
        })}
      >
        {placeholder}
      </StyledButton>
    );
  },
) as ((
  props: DateRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { fieldType?: string };

DateButton.fieldType = 'single-input';
