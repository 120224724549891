import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import PercentIcon from '@mui/icons-material/Percent';
import { Grid, Stack } from '@mui/material';
import React from 'react';

import { ACCOUNT_FIELDS } from '../fields';
const { billing: inputField } = ACCOUNT_FIELDS;

export const BillingConfig = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Billing configuration">
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id={inputField.email}
            isLoading={isLoading}
            label="Email"
            type="email"
            placeholder="Enter a billing email address"
            options={{
              required: 'Billing email is required',
            }}
            useHook
          />
        </Grid>

        <Grid item xs={10}>
          <TextField
            id={inputField.descriptor}
            isLoading={isLoading}
            label="Payment descriptor"
            placeholder="Enter a payment descriptor"
            options={{
              required: 'Payment descriptor is required',
            }}
            useHook
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id={inputField.fee}
            isLoading={isLoading}
            label="Fee"
            placeholder="Fee"
            type="number"
            icon={{
              position: 'end',
              value: PercentIcon,
            }}
            options={{
              required: 'Billing fee is required',
              min: {
                value: 1,
                message: 'Fee must be higher than 0',
              },
              max: {
                value: 100,
                message: 'Fee can not be higher than 100',
              },
            }}
            useHook
          />
        </Grid>
      </Grid>
    </Stack>
  </Card>
);
