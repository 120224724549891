import { SvgElement } from '@components/SvgElement';
import { TextField as MuiTextField, InputAdornment } from '@mui/material';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';

// TODO: Replace with mui icon
import LockIcon from '@common/icons/lock.svg';

type PasswordInputProps = {
  className: string;
  hasError: boolean;
  name: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  validate?: (password: string) => boolean;
  value?: string;
};

/**
 * @deprecated - Use TextField instead with built-in support for icons & validation.
 */
export const PasswordInput = ({
  className,
  hasError,
  name,
  placeholder,
  register,
  validate,
  value,
}: PasswordInputProps) => {
  const { ref, ...rest } = register(name, {
    minLength: { value: 8, message: 'Must be at least 8 characters' },
    required: 'Password required',
    validate,
  });

  return (
    <MuiTextField
      className={className}
      error={hasError}
      inputRef={ref}
      placeholder={placeholder}
      type="password"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgElement dataurl={LockIcon} />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
