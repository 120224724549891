import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { Stack } from '@mui/material';
import React from 'react';

import { MERCHANT_DEFAULTS, MERCHANT_SHIPPING_OPTIONS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';

const { shipping: inputField } = MERCHANT_FIELDS;

export const ShippingConfiguration = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Shipping Configuration">
    <Stack spacing={2}>
      <ControlledSelect
        id={inputField.type}
        options={MERCHANT_SHIPPING_OPTIONS}
        defaultValue={MERCHANT_DEFAULTS.shipping}
        isLoading={isLoading}
        label="Type"
      />
      <Switch id={inputField.freeShipping} label="Free shipping" useHook />
    </Stack>
  </Card>
);
