import { FilterAutocomplete } from '@components/DataFilter/Autocomplete';
import { AccountAutocomplete, useAccountAutocomplete } from '@hooks/useAccountAutocomplete';
import { Truthy } from 'lodash';
import React from 'react';

import { useOrderFilters } from '../config/filters';

export const AccountFilter = () => {
  const { query: accountQuery, isLoading } = useAccountAutocomplete();
  const { filters, updateFilters } = useOrderFilters();

  // All available options for autocomplete.
  const [options, setOptions] = React.useState<AccountAutocomplete[]>([]);

  /**
   * When search field value changes (during typing).
   * Updates available options for rendering in autocomplete.
   */
  const handleInputChange = async (inputValue: string) => {
    const accounts = await accountQuery(inputValue);

    // Set matching results as options within autocomplete.
    setOptions(accounts);

    // Needs to be returned for preselecting options after reload.
    return accounts;
  };

  /**
   * When value changes (after selecting an option).
   * Updates filters with new value(s).
   */
  const handleValueChange = (values: AccountAutocomplete[]) => {
    updateFilters({ accountIds: values.map((account) => account.publicId) });
  };

  /**
   * Returns initial preselected values for autocomplete when loading.
   * Currently fetches all accounts. Should be replaced by "fetch by IDs" query.
   * Needed to preselect options after reload which is a pain in the *ss.
   */
  const onInitialRender = async () => {
    const accounts = await handleInputChange('');

    return (filters.accountIds ?? [])
      .map((publicId) => accounts.find((account) => account.publicId === publicId))
      .filter(Boolean) as Truthy<AccountAutocomplete>[];
  };

  return (
    <FilterAutocomplete
      options={options}
      value={filters.accountIds}
      onInputUpdate={handleInputChange}
      onValueUpdate={handleValueChange}
      onInitialRender={onInitialRender}
      placeholder="Account"
      noOptionsText="No accounts found"
      getOptionLabel={(option) => (option as AccountAutocomplete).name}
      isLoading={isLoading}
      multiple
    />
  );
};
