/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { AuthorizedRoute } from '../layouts/AuthorizedRoute';
import { Layout } from '../layouts/Layout';
import { NotFound } from '../pages/Misc';
import { routes as privateRoutes } from './private';
import { routes as publicRoutes } from './public';

export const Routes = () => (
  <ReactRoutes>
    <Route path="/" element={<Layout />}>
      {/* Non-authorized routes */}
      {Object.values(publicRoutes).map(({ path, component: Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}

      {/* Authorized routes */}
      {Object.values(privateRoutes).map(({ path, permission, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <AuthorizedRoute permission={permission}>
              <Component />
            </AuthorizedRoute>
          }
        />
      ))}

      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Route>
  </ReactRoutes>
);
