import { Card } from '@components/Card';
import { FileField } from '@components/Input/File';
import { Grid, Stack } from '@mui/material';
import React from 'react';

import { ACCOUNT_FIELDS } from '../fields';
const { preferences: inputField } = ACCOUNT_FIELDS;

export const Preferences = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Preferences">
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FileField
            id={inputField.logo}
            label="Logo"
            accept="image/*"
            isLoading={isLoading}
            showPreview
          />
        </Grid>

        <Grid item xs={6}>
          <FileField
            id={inputField.footer}
            label="Footer"
            accept="image/*"
            isLoading={isLoading}
            showPreview
          />
        </Grid>

        <Grid item xs={6}>
          <FileField
            id={inputField.favicon}
            label="Favicon (optional)"
            accept="image/*"
            isLoading={isLoading}
            showPreview
          />
        </Grid>

        <Grid item xs={6}>
          <FileField
            id={inputField.cartTitleImage}
            label="Cart title (optional)"
            accept="image/*"
            isLoading={isLoading}
            showPreview
          />
        </Grid>
      </Grid>
    </Stack>
  </Card>
);
