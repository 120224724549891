import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

import { getBonsaiUiProductUrl } from '@lib/bonsaiUI';

interface IOpenProductInBonsaiUIItemProps {
  productPublicId: string;
}

export const OpenProductInBonsaiUI: React.FC<IOpenProductInBonsaiUIItemProps> = ({
  productPublicId,
}) => {
  const { handleClose } = useDropDownMenu();

  const handleOpenProductInBonsaiUI = () => {
    window.open(getBonsaiUiProductUrl(productPublicId), '_blank');
    handleClose();
  };

  return (
    <MenuItem onClick={handleOpenProductInBonsaiUI}>
      <Typography variant="subtitle2">View in Bonsai UI</Typography>
    </MenuItem>
  );
};
