import React from 'react';

import { ClientStatus, Merchant } from '@common/types/GraphqlTypes.d';

import { ArchiveMerchantMenuItem } from './ArchiveMerchant';
import { DownloadFeedMenuItem } from './DownloadFeed';
import { ImportProductsMenuItem } from './ImportProducts';
import { PublishMerchantInfoMenuItem } from './PublishMerchant';
import { TestProductsMenuItem } from './TestProducts';
import { ValidateCredentialsMenuItem } from './ValidateCredentials';

type ActionsProps = {
  merchant?: Merchant;
};

export const MerchantsActions = ({ merchant }: ActionsProps) => {
  const isArchived = merchant?.publishedStage === ClientStatus.Archived;

  return merchant ? (
    <>
      <PublishMerchantInfoMenuItem merchant={merchant} />

      {/** Do not allow the following actions when the merchant has been archived. */}
      {!isArchived && (
        <>
          <ImportProductsMenuItem id={merchant._id} />
          <ArchiveMerchantMenuItem merchant={merchant} />
          <TestProductsMenuItem id={merchant._id} />
          <ValidateCredentialsMenuItem id={merchant._id} />
          <DownloadFeedMenuItem feedName={merchant.slug} />
        </>
      )}
    </>
  ) : null;
};
