/* eslint-disable complexity */
import { Form } from '@components/Form';
import { useSnackbar } from '@hooks/useSnackbar';
import { Stack } from '@mui/material';
import { NotFound } from '@pages/Misc';
import { filterZeroQuantityProducts } from '@pages/Order/utils/products';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CheckoutVariant, ShortcutProduct, ShippingCarrier } from '@common/types/GraphqlTypes.d';
import { isValidTrackingNumber } from '@utils/tracking';

import { Items } from '../../components/ItemsSelect';
import { TrackingInformation } from '../components/TrackingInformation';
import { OrderData } from '../graphql/useMerchantOrderWithFulfillment';
import { Fields, FieldValues } from '../utils/form';

type ShippingTrackingFormData = {
  shippingCarrier: ShippingCarrier | null;
  shippingCarrierCustomName?: string | null;
  products: ShortcutProduct[];
  trackingNumber: string | null;
  trackingUrl: string | null;
};

type ShippingTrackingOnSubmitData = ShippingTrackingFormData & {
  cancelled: boolean;
};

type ShippingTrackingFormProps = {
  onSubmit: (data: ShippingTrackingOnSubmitData) => void;
  merchantOrder?: OrderData;
  getDefaultValues: (order?: OrderData) => {
    form: FieldValues;
    checkoutVariants: CheckoutVariant[];
  };
  isSubmitting: boolean;
  isLoading: boolean;
  ctaText: string;
  error?: Error;
  fulfillmentId?: string;
};

export const ShippingTrackingForm = ({
  onSubmit,
  merchantOrder,
  getDefaultValues,
  isSubmitting,
  ctaText,
  error,
  isLoading,
  fulfillmentId,
}: ShippingTrackingFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<ShippingTrackingFormData>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues().form,
  });

  const watchCarrier = form.watch(Fields.Carrier) ?? '';
  const products: ShortcutProduct[] = form.watch(Fields.Products) ?? [];

  useEffect(() => {
    if (!merchantOrder) {
      return;
    }
    const defaultValues = getDefaultValues(merchantOrder);

    form.reset(defaultValues.form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOrder]);

  if (!merchantOrder && !isLoading) {
    return <NotFound />;
  }

  const getProductValues = () => form.getValues('products');
  const setProductValues = (items: ShortcutProduct[]) => form.setValue('products', items);
  const handleSubmit = (data: ShippingTrackingFormData) => {
    const values = {
      trackingNumber: data.trackingNumber ? data.trackingNumber.trim() : null,
      shippingCarrier: data.shippingCarrier,
      trackingUrl: data.shippingCarrier === ShippingCarrier.Other ? data.trackingUrl : null,
      shippingCarrierCustomName:
        data.shippingCarrier === ShippingCarrier.Other ? data.shippingCarrierCustomName : null,
      products: filterZeroQuantityProducts(data.products),
      cancelled: false,
    };

    // TODO: Should happen within form validation preferably
    if (!values.shippingCarrier) {
      return enqueueSnackbar('Please select a shipping carrier', { variant: 'error' });
    }

    // Validate whether tracking number is valid!
    if (values.trackingNumber && values.shippingCarrier) {
      const isValidNumber = isValidTrackingNumber(values.trackingNumber, values.shippingCarrier);

      if (!isValidNumber) {
        return enqueueSnackbar(
          `An invalid tracking number for ${values.shippingCarrier} was provided`,
          { variant: 'error' },
        );
      }
    }

    onSubmit(values);
  };

  const totalQuantity = products.reduce((acc, { quantity }) => acc + quantity, 0);

  return (
    <FormProvider {...form}>
      <Form error={error} onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack direction="row" justifyContent="space-between" gap={4}>
          <Stack flex={1.25}>
            <Items
              merchantOrder={merchantOrder}
              getValues={getProductValues}
              setValues={setProductValues}
              isLoading={isLoading}
              fulfillmentId={fulfillmentId}
              showProductLink={false}
            />
          </Stack>
          <Stack flex={1}>
            <TrackingInformation
              carrier={watchCarrier}
              isLoading={isLoading}
              ctaText={ctaText}
              isDisabled={totalQuantity === 0 || isSubmitting}
            />
          </Stack>
        </Stack>
      </Form>
    </FormProvider>
  );
};
