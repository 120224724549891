import { MenuItem } from '@components/MenuItem';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

import { IProductCollection } from '../../graphql';
import { useDeleteProductCollection } from './graphql';

type ActionsProps = {
  collection: IProductCollection;
};

export const DeleteProductCollectionMenuItem = ({ collection }: ActionsProps) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { deleteProductCollectionMutation } = useDeleteProductCollection();

  const onSubmit = () => {
    deleteProductCollectionMutation({
      variables: { id: collection._id },
      onCompleted: () => {
        goto.listProductCollections();
      },
      onError: (error) => {
        console.error('API error deleting product collection:', error);
        enqueueSnackbar(`Error deleting product collection: ${error.message}`, {
          variant: 'error',
        });
      },
    });
  };

  const openConfirmModal = () => {
    setIsConfirmDialogOpen(true);
  };

  return (
    <>
      <MenuItem action={openConfirmModal} icon={DeleteIcon} label="Delete collection" />

      <div>
        <Dialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`You are deleting ${collection.name} (${collection.handle})`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting this collection might break clients using these collections. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={onSubmit}>
              Delete collection
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
