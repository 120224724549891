import { useDropDownMenu } from '@components/Button/Dropdown';
import { useSnackbar } from '@hooks/useSnackbar';
import { FileDownload } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { FulfillmentStatus } from '@types';
import React from 'react';

import { downloadFile } from '@utils/file';

import { useExportMerchantOrders } from './graphql';

export const ExportMerchantOrdersMenuItem: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose: handleMenuClose } = useDropDownMenu();

  const { exportMerchantOrdersQuery, exportData, apiError } = useExportMerchantOrders({
    onCompleted: () => {
      if (apiError) {
        enqueueSnackbar(`Failed to export Merchant Orders with error ${apiError}`, {
          variant: 'error',
        });
        handleMenuClose();
      }

      if (exportData) {
        const {
          exportMerchantOrders: { encodedCsv, fileName },
        } = exportData;

        downloadFile({ fileName, encodedFile: encodedCsv, type: 'text/csv' });
        enqueueSnackbar('Merchant orders exported successfully!', { variant: 'success' });
        handleMenuClose();
      }
    },
  });

  const handleExport = () => {
    exportMerchantOrdersQuery({
      variables: {
        input: {
          searchTerms: {
            fulfillmentStatus: [FulfillmentStatus.SENT_TO_MERCHANT],
          },
        },
      },
    });
  };

  return (
    <MenuItem onClick={handleExport}>
      <FileDownload />
      Download sent merchant orders
    </MenuItem>
  );
};
