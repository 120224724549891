import { FilterSelect } from '@components/DataFilter/Select';
import { Escalation } from '@types';
import React from 'react';

import { useOrderFilters } from '../config/filters';

const options = Object.values(Escalation).map((status) => ({
  label: status,
  value: status,
}));

export const EscalationFilter = () => {
  const { filters, updateFilters } = useOrderFilters();

  return (
    <FilterSelect
      placeholder="Escalation(s)"
      options={options}
      value={filters.escalationStatuses}
      onUpdate={(escalationStatuses: Escalation[]) => updateFilters({ escalationStatuses })}
      multiple
    />
  );
};
