import { SelectProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type WithControllerProps = {
  id: string;
  defaultValue?: number | string;
};

export const withController = <TValue, TProps extends SelectProps<TValue>>(
  WrappedComponent: React.ComponentType<TProps>,
) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithController = ({
    id,
    defaultValue,
    ...otherProps
  }: TProps & WithControllerProps) => (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={useFormContext().control}
      render={({ field: { ref, ...otherFieldProps } }) => (
        <WrappedComponent
          labelId={id}
          innerRef={ref}
          {...otherFieldProps}
          {...(otherProps as TProps)}
        />
      )}
    />
  );

  ComponentWithController.displayName = `withController(${displayName})`;

  return ComponentWithController;
};
