import { MenuItem } from '@components/MenuItem';
import { useAuth } from '@hooks/useAuthentication';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';

export const LogoutMenuItem = () => {
  const { logout } = useAuth();

  return (
    <>
      <MenuItem action={logout} label="Logout" icon={LogoutIcon} />
    </>
  );
};
