import * as R from 'ramda';

import { getMatchingDotValues } from '@utils/object';

import { ACCOUNT_FIELDS, IAccountInputUpdate } from '../fields';
import { IAccount } from './graphql';

/**
 * Converts fetched account into account input values for creating & updating.
 * Both operations allow the same for the moment, but might become different in the future.
 * @param {object} account - Fetched account.
 */
export const toInputValues = (account: IAccount): IAccountInputUpdate => ({
  ...R.omit(
    [
      'publicId',
      'clientId',
      'apiKey',
      'privateApiKey',
      'testPublishableApiKey',
      'testSecretApiKey',
      'publisher',
      'source',
      'orderConfig.slackNotificationChannel',
    ],
    account,
  ),
  publisher: account.publisher._id,
});

/**
 * Returns mapped GraphQL values into input fields key/value for autopopulation.
 */
export const getInputValues = (account: IAccount) =>
  getMatchingDotValues<IAccount>(ACCOUNT_FIELDS, account);
