import {
  AccountInputUpdate as IAccountInputUpdate,
  AccountInputCreate as IAccountInputCreate,
  Account as IAccount,
} from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
// eslint-disable-next-line unicorn/prefer-export-from
export type { IAccountInputUpdate, IAccountInputCreate, IAccount };

export type IAccountCategory =
  | 'basic'
  | 'preferences'
  | 'billing'
  | 'order'
  | 'externalPayment'
  | 'metadata'
  | 'emailProvider';

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for keys.
 */
export type IAccountField = Join<PathsToStringProps<IAccount & { publisher: string }>, '.'>;

/**
 * Returns all available account fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const ACCOUNT_FIELDS: Record<IAccountCategory, Record<string, IAccountField>> = {
  // Basic information
  basic: {
    name: 'name',
    status: 'status',
    currency: 'currency',
    client: 'publisher',
    supportEmail: 'config.supportEmail',
  },

  // Account preferences
  preferences: {
    logo: 'config.logo',
    footer: 'config.footer',
    favicon: 'config.favicon',
    cartTitleImage: 'config.cartTitleImage',
  },

  // Billing configuration
  billing: {
    email: 'billing.email',
    descriptor: 'billing.descriptor',
    fee: 'billing.fee',
    accountingId: 'billing.accountingId',
  },

  // Order configuration
  order: {
    shipping: 'orderConfig.shipping',
    slackNotificationChannel: 'orderConfig.slackNotificationChannel',
    fulfillmentInstructions: 'orderConfig.fulfillment.instructions',
  },

  //Email config
  emailProvider: { type: 'emailProvider.type' },

  // External payment (optional)
  externalPayment: {},

  // Metadata (read-only)
  metadata: {
    publicId: 'publicId',
    apiKey: 'apiKey',
    privateApiKey: 'privateApiKey',
    testPublishableApiKey: 'testPublishableApiKey',
    testSecretApiKey: 'testSecretApiKey',
  },
};
