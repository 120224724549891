import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CustomerOrder, QueryCustomerOrdersArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within CUSTOMER_ORDERS_QUERY.
export type ICustomerOrder = Pick<
  CustomerOrder,
  | '_id'
  | 'merchantOrderIds'
  | 'account'
  | 'assignee'
  | 'customer'
  | 'createdAt'
  | 'deliveryAddress'
  | 'summary'
  | 'orderNumber'
  | 'fulfillmentStatus'
  | 'paymentStatus'
  | 'escalations'
  | 'merchantNames'
>;

const CUSTOMER_ORDERS_QUERY = gql`
  query customerOrders($input: CustomerOrdersInput!) {
    customerOrders(input: $input) {
      data {
        _id
        merchantOrderIds
        account {
          publicId
          name
        }
        assignee {
          name
          userId
        }
        customer {
          name
          email
        }
        createdAt
        deliveryAddress {
          name
          addressLineOne
          addressLineTwo
          province
          country
          postalCode
          city
        }
        summary {
          subTotal
          currency
        }
        orderNumber
        fulfillmentStatus
        paymentStatus
        escalations {
          state
          status
          productName
        }
        merchantNames
      }
      total
    }
  }
`;

/**
 * Hook for fetching customer orders.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCustomerOrders = (
  options: QueryHookOptions<
    { customerOrders: { data: ICustomerOrder[]; total: number } },
    QueryCustomerOrdersArgs
  >,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
    fetchMore,
  } = useQuery<
    { customerOrders: { data: ICustomerOrder[]; total: number } },
    QueryCustomerOrdersArgs
  >(CUSTOMER_ORDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { data, total } = apiResult?.customerOrders ?? {};

  return { data, fetchMore, total, isLoading, apiError };
};
