import { FilterSelect } from '@components/DataFilter/Select';
import React from 'react';

import { ClientStatus } from '@common/types/GraphqlTypes.d';

import { useMerchantFilters } from '../config/filters';

const options = [
  {
    label: 'Live',
    value: ClientStatus.Live,
  },
  {
    label: 'Draft',
    value: ClientStatus.Draft,
  },
  {
    label: 'Archived',
    value: ClientStatus.Archived,
  },
];

export const StatusFilter = () => {
  const { filters, updateFilters } = useMerchantFilters();

  return (
    <FilterSelect
      placeholder="Status"
      options={options}
      value={filters.publishFilters}
      onUpdate={(publishFilters: ClientStatus[]) => updateFilters({ publishFilters })}
      multiple
    />
  );
};
