import { FilterSearch } from '@components/DataFilter/Search';
import { useSearch } from '@hooks/useDataStore/useSearch';
import React from 'react';

export const SearchFilter = () => {
  const { search, updateSearch } = useSearch();

  return (
    <FilterSearch placeholder="Search users" value={search} onUpdate={updateSearch} updateOnEnter />
  );
};
