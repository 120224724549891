import { Card } from '@components/Card';
import { Box, Divider, Skeleton, Stack } from '@mui/material';
import React from 'react';

import { CustomerOrder, MerchantOrder } from '@common/types/GraphqlTypes.d';

type OrderFulfillmentInstructionsProps = {
  /**
   * Current customer order.
   */
  customerOrder?: CustomerOrder;

  /**
   * List of merchant orders.
   */
  merchantOrders?: MerchantOrder[];

  /**
   * Indicates whether data is loading.
   */
  isLoading?: boolean;
};

export const OrderFulfillmentInstructions = ({
  customerOrder,
  merchantOrders = [],
  isLoading = false,
}: OrderFulfillmentInstructionsProps) => {
  if (!isLoading && merchantOrders.length === 0) {
    return null;
  }

  // Extract account information
  const accountName = customerOrder?.account?.name;
  const accountInstructions = customerOrder?.account?.orderConfig?.fulfillment?.instructions;

  return (
    <Card title="Fulfillment Tips">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Stack spacing={2}>
          {/** Merchant instructions. */}
          {merchantOrders.map((merchantOrder, index) => (
            <Box key={`${merchantOrder.id}-${index}`}>
              <span>
                <b>{merchantOrder.merchant.name}</b> (<i>Merchant</i>)
              </span>

              {merchantOrder.fulfillmentInstructions ? (
                <Box
                  dangerouslySetInnerHTML={{ __html: merchantOrder.fulfillmentInstructions }}
                  sx={{
                    'p:first-of-type': {
                      marginTop: '4px',
                      marginBottom: 0,
                    },
                    'p:last-of-type': {
                      marginBottom: 0,
                    },
                  }}
                ></Box>
              ) : (
                <p>No fulfillment instructions found.</p>
              )}
            </Box>
          ))}

          {/** Account instructions. */}
          {accountInstructions && (
            <div>
              <Divider />
              <Box sx={{ marginTop: 2 }}>
                <b>{accountName}</b> (<i>Account</i>)
              </Box>

              <Stack direction="column" gap={1.5}>
                <Box
                  dangerouslySetInnerHTML={{ __html: accountInstructions }}
                  sx={{
                    'p:first-of-type': {
                      marginTop: '4px',
                      marginBottom: 0,
                    },
                  }}
                ></Box>
              </Stack>
            </div>
          )}
        </Stack>
      )}
    </Card>
  );
};
