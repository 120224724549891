import React from 'react';

import { IUser } from '../../fields';
import { DeleteUserMenuItem } from './DeleteUser';
import { ReinviteUserMenuItem } from './ReinviteUser';

type ActionsProps = {
  user?: IUser;
};

export const UserActions = ({ user }: ActionsProps) => (
  <>
    {user && (
      <>
        <ReinviteUserMenuItem user={user} />
        <DeleteUserMenuItem user={user} />
      </>
    )}
  </>
);
