import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc/NotFound';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ProductCollectionAccount } from '@common/types/GraphqlTypes.d';
import { getMatchingDotValues } from '@utils/object';

import { arrayToNewline, newlineToArray } from '../../../utils/string';
import { BasicInformation } from '../components/Basic';
import { Products } from '../components/Products';
import { IProductCollectionInput, PRODUCT_COLLECTION_FIELDS } from '../fields';
import { IProductCollection, useEditProductCollection, useProductCollection } from './graphql';
import { EditProductCollectionHeader } from './Header';

/**
 * Returns mapped GraphQL values into input fields key/value for autopopulation.
 */
const getValues = (collection: IProductCollection) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMatchingDotValues<IProductCollectionInput>(PRODUCT_COLLECTION_FIELDS, collection) as any;

type IEditProductCollectionParams = { id: string };

const EditProductCollection = ({ id }: IEditProductCollectionParams) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<
    IProductCollectionInput & { productIds: string; account: ProductCollectionAccount }
  >({
    mode: 'onSubmit',
  });

  // GraphQL hooks
  const { isLoading, data } = useProductCollection({
    variables: { _id: id },
    onCompleted: ({ productCollection }) => {
      const values = getValues(productCollection);

      // Convert array product IDs to be on a separate newline
      const productIds = arrayToNewline(values.productIds);

      form.reset({ ...values, productIds }); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching product collection:', error);
    },
  });

  const { updateProductCollectionMutation, apiError: updateApiError } = useEditProductCollection();

  const onSubmit = (
    values: IProductCollectionInput & { productIds: string; account: ProductCollectionAccount },
  ) => {
    // Convert textarea with newlines to array of IDs/URLs
    const productIds = newlineToArray(values.productIds || '');

    // Map account object to accountId & append product IDs
    const updateValues = {
      ...R.omit(['account'], values),
      accountId: values.account?.publicId,
      productIds,
    };

    // Manual check as Autocomplete component does not support react-hook-form validation yet
    if (!updateValues.accountId) {
      return enqueueSnackbar('A collection need to be assigned to an account', {
        variant: 'error',
      });
    }

    updateProductCollectionMutation({
      variables: { _id: id, input: updateValues },
      onCompleted: () => {
        goto.viewProductCollection(id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating product collection:', error);
      },
    });
  };

  const onCancel = () => {
    goto.viewProductCollection(id);
  };

  // Client could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <Stack gap={3}>
      <EditProductCollectionHeader collection={data} isLoading={isLoading} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Stack gap={3}>
                <BasicInformation isLoading={isLoading} hasAccount />
                <Products isLoading={isLoading} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={isLoading}>
                  Save changes
                </PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};

export const EditProductCollectionPage = withParams<IEditProductCollectionParams>(
  ['id'],
  EditProductCollection,
);
