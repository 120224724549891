import { PrivateRoutes, routes as privateRoutes } from '@routes/private';
import { PublicRoutes, routes as publicRoutes } from '@routes/public';
import { useNavigate } from 'react-router-dom';

// TODO: Fix _id is not supposed to be optional; types start to break if it's not
type VoidFunction = () => void;
type VoidFunctionWithId = (_id?: string) => void;
type VoidFunctionWithMultipleParams = (...args: string[]) => void;

type TGoTo =
  | Record<PrivateRoutes | PublicRoutes, VoidFunction>
  | Record<PrivateRoutes | PublicRoutes, VoidFunctionWithId>
  | Record<PrivateRoutes | PublicRoutes, VoidFunctionWithMultipleParams>;

interface IUseRouter {
  goto: TGoTo;
  goToCustom: (path: string) => void;
}

const useRouter = (): IUseRouter => {
  const navigate = useNavigate();

  //TODO improve typecasting
  const createGoToFunctions = () => {
    const routes = { ...privateRoutes, ...publicRoutes };

    return Object.keys(routes).reduce<TGoTo>((goTo, name) => {
      const { [name as PrivateRoutes | PublicRoutes]: config } = routes;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      goTo[name as PrivateRoutes | PublicRoutes] = (...args: string[]) =>
        navigate(config.getPath(...args));

      return goTo;
    }, {} as TGoTo);
  };

  return {
    goto: createGoToFunctions(),
    goToCustom: (path: string) => navigate(path),
  };
};

export default useRouter;
