/* eslint-disable complexity */
import { Copy } from '@components/Copy';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  loader: {
    width: '100%',
  },
  label: {
    fontWeight: 700,
    paddingRight: 10,
  },
  value: {
    fontWeight: 400,
    wordBreak: 'break-word',
    '&:first-letter': {
      textTransform: (props: { shouldCapitalize?: boolean }) =>
        props.shouldCapitalize ? 'uppercase' : 'none',
    },
  },
});

export type DataFieldProps = Omit<StackProps, 'text'> & {
  direction?: 'row' | 'column';

  /**
   * Label to provide context regarding the field being rendered.
   */
  label?: string | React.ReactElement;

  /**
   * The value to render. Can be undefined or empty.
   */
  value?: string | number | React.ReactElement | null;

  /**
   * Render a default value if no loading and no data is provided.
   */
  defaultValue?: string | number | React.ReactElement;

  /**
   * Control loading state automatically rendering skeleton loader.
   */
  isLoading?: boolean;

  /**
   * Allows copy of a specific value with click of a button.
   */
  copy?: string | boolean;

  /**
   * Capitalize the first letter of the value.
   */
  shouldCapitalize?: boolean;

  /**
   * Optional classname to allow custom styling or logic.
   */
  className?: string;
};

/**
 * Returns whether provided value is a boolean.
 */
export const isBoolean = (value: boolean | unknown): boolean => typeof value == 'boolean';

/**
 * Renders a single field for displaying information.
 * This component will be used in most view pages with key/value pairs of information.
 */
export const DataField = ({
  label,
  value,
  defaultValue,
  copy,
  className,
  direction = 'row', // Default is key/value in row
  isLoading = false,
  shouldCapitalize = true,
  ...muiProps
}: DataFieldProps) => {
  const classes = useStyles({ shouldCapitalize });

  // Do not render anything if there's no loading state and no value
  if (!isLoading && !value && !defaultValue) {
    return null;
  }

  // Value being displayed (can be string, number or React component)
  const displayText = value ?? defaultValue;

  // Either uses default value, or custom value provided
  const copyText = isBoolean(copy) ? value : copy;

  return (
    <Stack
      className={clsx([classes.root, className])}
      direction={direction}
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      flexWrap="wrap"
      justifyContent="space-between"
      gap={0.5}
      {...muiProps}
    >
      {/* Data still loading */}
      {isLoading && <Skeleton className={classes.loader} />}

      {/* Data has finished loading */}
      {!isLoading && (
        <>
          {label && (
            <Box component="span" className={clsx(['label', classes.label])}>
              {label}
            </Box>
          )}

          <Box component="span" className={clsx(['value', classes.value])}>
            {copy ? <Copy value={String(copyText)}>{displayText}</Copy> : displayText}
          </Box>
        </>
      )}
    </Stack>
  );
};
