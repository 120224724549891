import { CreateButton } from '@components/Button/Create';
import { Header } from '@components/Header';
import useRouter from '@hooks/useRouter';
import React from 'react';

const Buttons: React.FC = () => {
  const { goto } = useRouter();

  return (
    <CreateButton
      onClick={() => {
        goto.createRole();
      }}
    >
      Create User Role
    </CreateButton>
  );
};

export const RoleOverviewHeader = () => <Header title={'Roles'} buttons={<Buttons />} />;
