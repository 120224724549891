import { CardTitle } from '@components/CardTitle';
import currencyJs from 'currency.js';
import React, { Fragment } from 'react';

import { MerchantOrder as IMerchantOrder } from '@common/types/GraphqlTypes.d';

import { OrderRefund } from './orderRefund';

interface IOrderRefundProps {
  merchantOrders: IMerchantOrder[];
}

export const OrderRefunds: React.FC<IOrderRefundProps> = ({ merchantOrders }) => {
  const refunds = merchantOrders.flatMap(({ refunds }) => refunds);

  if (refunds.length === 0) {
    return null;
  }

  const ordersTotal = merchantOrders.reduce(
    (total, { summary: { customerChargeTotal } }) =>
      currencyJs(total).add(customerChargeTotal).value,
    0,
  );

  const refundsTotal = refunds.reduce(
    (refundtotal, { refundedAmount }) => currencyJs(refundtotal).add(refundedAmount).value,
    0,
  );

  const { value: netTotal } = currencyJs(ordersTotal).subtract(refundsTotal);

  const sectionTitle = (
    <>
      <div>{'Order Refunds'}</div>
      <div>{`Total Amount Refunded: $${refundsTotal}`}</div>
      <div>{`Remaining Customer Charge: $${netTotal}`}</div>
    </>
  );

  return (
    <>
      <CardTitle title={sectionTitle} divider={true} />
      {refunds.map((refund) => (
        <Fragment key={refund._id}>
          <OrderRefund refund={refund} />
        </Fragment>
      ))}
    </>
  );
};
