import { useDropDownMenu } from '@components/Button/Dropdown';
import { ICurrentUser, useCurrentUser } from '@hooks/useCurrentUser';
import { useSnackbar } from '@hooks/useSnackbar';
import { AddTask } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loading } from '@pages/Order/components/Loading';
import { routes } from '@routes/private';
import React from 'react';
import { Link } from 'react-router-dom';

import { OrderQuickFilter } from '../../config/filters';
import { useAssignOldestOrders } from './graphql';

const StyledLink = styled(Link)`
  color: #ffffff;
`;

export const AssignOrdersMenuItem: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose: handleMenuClose } = useDropDownMenu();
  const { currentUser } = useCurrentUser();

  const showError = (error: string) => {
    enqueueSnackbar(`Failed to assign orders with error ${error}`, {
      variant: 'error',
    });
  };

  const { assignOldestOrdersQuery, assignOrdersData, apiError, isLoading } = useAssignOldestOrders({
    onCompleted: () => {
      if (apiError) {
        enqueueSnackbar(`Failed to export Merchant Orders with error ${apiError}`, {
          variant: 'error',
        });
        handleMenuClose();
      }

      if (assignOrdersData) {
        const {
          assignOldestOrdersToUser: { result: isValid, error },
        } = assignOrdersData;

        if (isValid && currentUser !== undefined) {
          enqueueSnackbar('Successfully assigned orders!', {
            variant: 'success',
            action: (
              <StyledLink
                to={`${routes.listOrdersV2.path}?quickFilter=${OrderQuickFilter.AssignedToMe}`}
              >
                See assigned orders
              </StyledLink>
            ),
          });
        } else if (error !== undefined) {
          showError(error);
        }

        handleMenuClose();
      }
    },
    onError: (error) => {
      showError(error.message);
    },
  });

  const handleAssign = (user?: ICurrentUser) => {
    if (!user) {
      return;
    }

    assignOldestOrdersQuery({
      variables: {
        input: {
          userId: user.id,
          name: user.profile.name,
        },
      },
    });
  };

  return (
    <MenuItem onClick={() => handleAssign(currentUser)}>
      {isLoading ? <Loading size={20} style={{ marginRight: 12 }} /> : <AddTask />}
      Assign orders to me
    </MenuItem>
  );
};
