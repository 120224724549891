import { Typography } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

interface IEscalationProps {
  status: string;
  productName: string;
}

export const Escalation: React.FC<IEscalationProps> = ({ status, productName }) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.status}>
        <Typography className={classes.statusText}>{status}</Typography>
      </span>
      <span className={classes.productName}>{productName}</span>
    </div>
  );
};
