import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IProcessedOrder } from '@pages/Order/View/OrderedItems/interfaces';
import React from 'react';

type OrderCardProps = {
  order: IProcessedOrder;
};

const StyledText = styled(Typography)(() => ({
  gutterBottom: true,
  fontSize: 'small',
}));

export const OtherDetails = ({ order }: OrderCardProps) => (
  <Stack>
    <StyledText sx={{ fontWeight: 600 }}>Comments</StyledText>
    <StyledText>{order.comments ?? ''}</StyledText>
  </Stack>
);
