import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for creating user.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateUser = (options: MutationHookOptions = {}) => {
  const [createUserMutation, { error: apiError, loading: isLoading }] = useMutation(
    CREATE_USER_MUTATION,
    options,
  );

  return { createUserMutation, isLoading, apiError };
};
