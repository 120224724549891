import { gql, MutationHookOptions, useMutation } from '@apollo/client';
export const ASSIGN_OLDEST_ORDERS_QUERY = gql`
  mutation assignOldestOrdersToUser($input: UserRefInput!) {
    assignOldestOrdersToUser(input: $input) {
      result
      error
    }
  }
`;

/**
 * Hook for assigning oldest orders to a user.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useAssignOldestOrders = (options: MutationHookOptions = {}) => {
  const [assignOldestOrdersQuery, { error: apiError, loading: isLoading, data: assignOrdersData }] =
    useMutation<{
      assignOldestOrdersToUser: { result: boolean; error?: string };
    }>(ASSIGN_OLDEST_ORDERS_QUERY, options);

  return { assignOldestOrdersQuery, isLoading, apiError, assignOrdersData };
};
