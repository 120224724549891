import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const DELETE_USER_ROLE_MUTATION = gql`
  mutation deleteRole($id: String!) {
    deleteRole(id: $id) {
      deletedCount
    }
  }
`;

/**
 * Hook for deleting role.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useDeleteRole = (options: MutationHookOptions = {}) => {
  const [deleteRoleMutation, { error: apiError, loading: isLoading }] = useMutation<{
    deleteRole: { id: string };
  }>(DELETE_USER_ROLE_MUTATION, options);

  return { deleteRoleMutation, isLoading, apiError };
};
