import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { QueryRolesArgs, QueryRolesCountArgs, Role } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within USER_ROLES_QUERY.
export type IUserRole = Pick<Role, '_id' | 'name' | 'description'>;

// Not reusing the existing query from roles as we don't require all information
export const USER_ROLES_QUERY = gql`
  query roles($input: RolesInput!) {
    roles(input: $input) {
      _id
      name
      description
    }
  }
`;

export const USER_ROLES_COUNT_QUERY = gql`
  query rolesCount($input: RolesCountInput!) {
    rolesCount(input: $input) {
      total
    }
  }
`;

/**
 * Hook for fetching user roles.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useUserRoles = (options: QueryHookOptions<{ roles: IUserRole[] }, QueryRolesArgs>) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ roles: IUserRole[] }, QueryRolesArgs>(USER_ROLES_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { roles: data = [] } = apiResult ?? {};

  return { data, isLoading, apiError };
};

/**
 * Hook for fetching user roles total count.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useUserRolesCount = (
  options: QueryHookOptions<{ rolesCount: { total: number } }, QueryRolesCountArgs>,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ rolesCount: { total: number } }, QueryRolesCountArgs>(USER_ROLES_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { total: data = 0 } = apiResult?.rolesCount ?? {};

  return { data, isLoading, apiError };
};
