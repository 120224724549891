import { StatusTag, StatusTagType } from '@components/StatusTag';
import { FulfillmentStatus } from '@types';
import React from 'react';

type FulfilmentStatusProps = {
  status: string | undefined;
  className?: string;
};

export const CustomerOrderFulfillmentStatus = ({ status, className }: FulfilmentStatusProps) => {
  const conditions = [
    {
      match: () => status === FulfillmentStatus.FULFILLED,
      type: StatusTagType.Success,
    },
  ];

  return (
    <StatusTag
      text={status ?? 'No fulfilment status found'}
      conditions={conditions}
      type={StatusTagType.Error}
      className={className}
    />
  );
};
