import { Text } from '@components/Text';
import { TimelineDot } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Stack } from '@mui/material';
import React, { cloneElement } from 'react';

import { getTime } from '@utils/date';

import { DateComponent } from './DateComponent';
import { withLayout } from './withLayout';

export type PrimaryItemProps = {
  last?: boolean;
  title: string;
  createdAt: string;
  icon?: JSX.Element;
};

/**
 * Renders a primary event in the timeline.
 * @param props.last - Whether this is the last item in the timeline.
 * @param props.title - The title of the event.
 * @param props.createdAt - The date and time of the event.
 * @param props.type - The type of the event.
 */
export const PrimaryItemComponent = ({ createdAt, title, last, icon }: PrimaryItemProps) => {
  const time = getTime(new Date(createdAt));

  return (
    <>
      <TimelineSeparator>
        <TimelineDot sx={{ p: 0, fontSize: '1.13rem' }} color="primary">
          {icon && cloneElement(icon, { fontSize: 'inherit' })}
        </TimelineDot>

        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ alignItems: 'flex-start', pt: '13px' }}>
        <Stack flexGrow={1}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Text fontWeight={700} fontSize="small" gutterBottom={false}>
              {title}
            </Text>
            <Text gutterBottom={false} fontSize="x-small">
              {time}
            </Text>
          </Stack>
        </Stack>
      </TimelineContent>
    </>
  );
};

export const PrimaryItem = withLayout(PrimaryItemComponent, DateComponent);
