import {
  ClientInputUpdate as IClientInputUpdate,
  ClientInputCreate as IClientInputCreate,
  Client as IClient,
} from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
// eslint-disable-next-line unicorn/prefer-export-from
export type { IClientInputUpdate, IClientInputCreate, IClient };

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for keys.
 */
export type IClientField = Join<
  PathsToStringProps<
    IClient & {
      accounts: string;
    }
  >,
  '.'
>;

/**
 * Returns all available merchant fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const CLIENT_FIELDS: Record<string, IClientField> = {
  id: '_id',
  name: 'name',
  accounts: 'accounts',
  status: 'status',
};
