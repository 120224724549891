import { useLazyQuery } from '@apollo/client';

import { GET_MERCHANTS_AUTOCOMPLETE } from './graphql';

export type MerchantForAutocomplete = {
  _id: string;
  name: string;
};

type MerchantAutocompleteInput = {
  searchText: string;
  isLiveOnly: boolean;
};

const useMerchantAutocomplete = () => {
  const [operation] = useLazyQuery<{
    merchantAutocomplete: MerchantForAutocomplete[];
  }>(GET_MERCHANTS_AUTOCOMPLETE);

  const getData = async (value: string): Promise<MerchantForAutocomplete[]> => {
    const input: MerchantAutocompleteInput = {
      searchText: value,
      isLiveOnly: false,
    };

    const { data } = await operation({
      variables: {
        input,
      },
    });

    return data?.merchantAutocomplete ?? [];
  };

  return { getData };
};

export default useMerchantAutocomplete;
