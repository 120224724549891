import { EciIntegrationType, FulfillmentStatus, PaymentStatus } from '@types';
import { partition } from 'ramda';

import {
  CheckoutVariant,
  GhostExternalOrder,
  MerchantOrder,
  ShortcutProduct,
} from '@common/types/GraphqlTypes.d';

import { IProcessedOrder, IUnprocessedOrderItems } from './interfaces';

/**
 * Checks if an order is a feed order.
 * @param order - A processed order.
 * @returns {boolean} - If the order is feed or not.
 */
export const isOrderFeed = (order: IProcessedOrder): boolean =>
  order.integrationType === EciIntegrationType.FEED;

/**
 * Checks if an order is archived.
 * @param order - A feed order.
 * @returns {boolean} - If the order is archived or not.
 */
export const isArchived = (order: GhostExternalOrder): boolean =>
  order?.fulfillment === FulfillmentStatus.CANCELLED;

/**
 * Gets the total number of items purchased in a list of checkout varaints.
 * Eg. If the customer purchased 3 of 1 item and 2 of another item this returns 5.
 * @param merchantOrder - A merchant order.
 * @returns {number} - Number of items purchased in the merchant order.
 */
export const getTotalItemPurchaseQuantity = (checkoutVariants: CheckoutVariant[]) =>
  checkoutVariants.reduce(
    (total: number, { purchaseQuantity }: CheckoutVariant) => total + (purchaseQuantity ?? 0),
    0,
  );

/**
 * Gets the total number of items purchased across an array of merchant orders.
 * Eg.
 * Order 1 has 2 of item x 3 of item y
 * Order 2 has 1 of item z and 2 of item y
 * This returns 8.
 * @param merchantOrders - A list of merchant orders.
 * @returns {number} - The total number of items purchase across all the merchantOrders.
 */
export const getTotalItems = (merchantOrders: MerchantOrder[]) =>
  merchantOrders.reduce(
    (total: number, { checkoutVariants }: MerchantOrder) =>
      total + getTotalItemPurchaseQuantity(checkoutVariants),
    0,
  );

/**
 * Constructs a processed order from a ghost order and a merchant order.
 * @param merchantOrder - A merchant order.
 * @param ghostOrder - A feed/ghost order for the given merchant order.
 * @returns {IProcessedOrder} - A processed order to display in beamx.
 */
export const makeMerchantOrderAndGhostOrderToProcessedOrder =
  (merchantOrder: MerchantOrder) =>
  (ghostOrder: GhostExternalOrder): IProcessedOrder => {
    const checkoutVariants = getProcessedProducts(
      merchantOrder.checkoutVariants,
      ghostOrder.products,
    );

    return {
      merchantName: merchantOrder.merchant.name,
      integrationType: EciIntegrationType.FEED,
      merchantOrderId: merchantOrder._id,
      externalOrderNumber: ghostOrder.merchantOrderNumber,
      processedOrderId: ghostOrder._id,
      subtotal: ghostOrder.subtotal,
      shipping: ghostOrder.shipping,
      taxes: ghostOrder.taxes,
      duties: 0,
      discount: ghostOrder.discount || 0,
      createdAt: ghostOrder.createdAt,
      updatedAt: ghostOrder.updatedAt,
      payment: ghostOrder.paymentMethod,
      paymentStatus: merchantOrder.paymentStatus as PaymentStatus,
      shipmentDetails: merchantOrder.fulfillmentDetails,
      checkoutVariants,
      comments: ghostOrder.comments as string, //TODO fix type on beam api
      createdBy: ghostOrder.email as string, //TODO fix type on beam api
      //We don't update indivdual ghost orders when updating fulfillment status so we need to use the merchant order status here
      fulfillmentStatus: merchantOrder.fulfillmentStatus as FulfillmentStatus,
    };
  };

/**
 * Gets a unique key for a variant.
 * @param productId - Product id.
 * @param variantId - Variant id.
 * @returns {string} - Unqiue key for a variant.
 */
const getUniqueVariantKey = (productId: string, variantId: string): string =>
  `${productId}-${variantId}`;

/**
 * Gets a map containing the total number of items purchased for each product.
 * Key is the product Id and variant Id.
 * @param products - Products to create a map for.
 * @returns {Record<string, number>} - Map with quantity for each product.
 */
const getFulfilledProductsQuantityMap = (products: ShortcutProduct[]): Record<string, number> =>
  products.reduce((acc: Record<string, number>, { productId, variantId, quantity }) => {
    const key = getUniqueVariantKey(productId, variantId);

    return {
      ...acc,
      [key]: (acc[key] ?? 0) + quantity,
    };
  }, {});

/**
 * Gets processed products for a ghost order.
 * @param checkoutVariants - Checkout variants for a merchant order.
 * @param products - Products for a ghost order.
 * @returns {CheckoutVariant[]} - Checkout variants with the purchase quantity of the ghost order.
 */
const getProcessedProducts = (
  checkoutVariants: CheckoutVariant[],
  products: ShortcutProduct[],
): CheckoutVariant[] => {
  const fulfilledProductQuantities = getFulfilledProductsQuantityMap(products);

  return checkoutVariants.flatMap((product) => {
    const {
      productId,
      variant: { id: variantId },
    } = product;
    const key = getUniqueVariantKey(productId, variantId);

    if (fulfilledProductQuantities[key] > 0) {
      return [
        {
          ...product,
          purchaseQuantity: fulfilledProductQuantities[key],
        },
      ];
    }

    return [];
  });
};

/**
 * Constructs a processed order for an external order of a merchant order.
 * @param merchantorder - A merchant order.
 * @returns {IProcessedOrder} - A processed order to display in beamx.
 */
const getProcessedExternalOrder = (merchantorder: MerchantOrder): IProcessedOrder => {
  const shippingTax = merchantorder.summary.shippingWithTax - merchantorder.summary.shipping;
  const itemTaxes = merchantorder.summary.subTotalWithTax - merchantorder.summary.subTotal;

  return {
    checkoutVariants: merchantorder.checkoutVariants,
    merchantName: merchantorder.merchant.name,
    integrationType: merchantorder.merchant?.integrationType as EciIntegrationType,
    externalOrderNumber: merchantorder.externalOrder?.orderNumber as string,
    processedOrderId: merchantorder.externalOrder._id,
    merchantOrderId: merchantorder._id,
    subtotal: merchantorder.summary.subTotal,
    duties: merchantorder.summary.duties,
    discount: 0,
    taxes: itemTaxes + shippingTax,
    shipping: merchantorder.summary.shipping,
    createdAt: merchantorder.externalOrder.createdAt,
    updatedAt: merchantorder.externalOrder.updatedAt,
    payment: 'Stripe connect',
    paymentStatus: merchantorder.paymentStatus as PaymentStatus,
    shipmentDetails: merchantorder.fulfillmentDetails,
    createdBy: 'Shopify API',
    fulfillmentStatus: merchantorder.fulfillmentStatus as FulfillmentStatus,
  };
};

/**
 * Adds the processed order for an external order to a list of procesed orders.
 * Only adds a new order if it exists.
 * @param processedGhostOrders - A list of Processed orders.
 * @param order - The merchant order.
 * @returns {IProcessedOrder[]} - A list of Processed orders.
 */
const addExternalOrderToOrders = (
  processedOrders: IProcessedOrder[],
  order: MerchantOrder,
): IProcessedOrder[] =>
  order.externalOrder?.externalOrderId
    ? [...processedOrders, getProcessedExternalOrder(order)]
    : processedOrders;

/**
 * Filters out products that have already been processed.
 * @param allCheckoutVariants - All checkout variants for a customer order.
 * @param processedCheckoutVariants - Checkout variants that have already been processed.
 * @returns {CheckoutVariant[]} - Checkout variants that have not been processed.
 */
const filterNotFulfilledProducts = (
  allCheckoutVariants: CheckoutVariant[],
  processedCheckoutVariants: CheckoutVariant[],
): CheckoutVariant[] =>
  allCheckoutVariants.flatMap((checkoutVariant) => {
    const { purchaseQuantity, variant } = checkoutVariant;

    const match = processedCheckoutVariants.find(
      ({ variant: processedVariant }) => variant === processedVariant,
    );

    if (match) {
      return purchaseQuantity - match.purchaseQuantity > 0
        ? [
            {
              ...checkoutVariant,
              purchaseQuantity: purchaseQuantity - match.purchaseQuantity,
            },
          ]
        : [];
    }

    return [checkoutVariant];
  });

/**
 * Determines which items on a merchant order have not been processed yet.
 * @param orders - A list of processed orders.
 * @param merchantOrder - A merchant order.
 * @returns {IUnprocessedOrderItems} - Unprocessed items.
 */
const getUnprocessedItemsBasedOnOrders = (
  orders: IProcessedOrder[],
  merchantOrder: MerchantOrder,
): IUnprocessedOrderItems => {
  const processedCheckoutVariants = orders?.flatMap(({ checkoutVariants }) => checkoutVariants);

  return {
    checkoutVariants: filterNotFulfilledProducts(
      merchantOrder.checkoutVariants,
      processedCheckoutVariants,
    ),
    merchantName: merchantOrder.merchant.name,
    integrationType: merchantOrder.merchant?.integrationType as EciIntegrationType,
    merchantOrderId: merchantOrder._id,
    fulfillmentInstructions: merchantOrder.fulfillmentInstructions,
    fulfillmentStatus: merchantOrder.fulfillmentStatus as FulfillmentStatus,
  };
};

/**
 * Gets a list of processed orders for a merchant order. Then based on that list also
 * returns unprocessed items for that order.
 * @param order - A merchant order.
 * @returns - The processed orders and unprocessed items for the merchant order.
 */
const getOrdersAndItems = (
  order: MerchantOrder,
): {
  processedOrders: IProcessedOrder[];
  unprocessedItems: IUnprocessedOrderItems;
  fulfilledOrders: IProcessedOrder[];
} => {
  const getProcessedOrder = makeMerchantOrderAndGhostOrderToProcessedOrder(order);

  const processedGhostOrders =
    (order.ghostExternalOrders as GhostExternalOrder[])
      .filter((order) => !isArchived(order))
      .map(getProcessedOrder) ?? [];

  const allProcessedOrders = addExternalOrderToOrders(processedGhostOrders, order);

  const unprocessedItems = getUnprocessedItemsBasedOnOrders(allProcessedOrders, order);

  const [fulfilledOrders, processedOrders] = partition(
    (order) => order.fulfillmentStatus === FulfillmentStatus.FULFILLED,
    allProcessedOrders,
  );

  return { processedOrders, unprocessedItems, fulfilledOrders };
};

/**
 * Gets a list of processed orders for a list of merchant orders. Then based on that list also
 * returns unprocessed items for those orders.
 * @param merchantOrders - A list of merchant orders.
 * @returns Processed orders and unprocessed items for those orders.
 */
export const getProcessedOrdersAndUnprocessedItems = (
  merchantOrders: MerchantOrder[],
): {
  processedOrders: IProcessedOrder[];
  unprocessedItems: IUnprocessedOrderItems[];
  fulfilledOrders: IProcessedOrder[];
} => {
  const ordersAndItemsByMerchantOrders = merchantOrders.map(getOrdersAndItems);

  //Each merchant order can have an array of processed orders and items. Flatten the array here.
  return ordersAndItemsByMerchantOrders.reduce<{
    fulfilledOrders: IProcessedOrder[];
    processedOrders: IProcessedOrder[];
    unprocessedItems: IUnprocessedOrderItems[];
  }>(
    (accumulator, merchantOrder) => ({
      processedOrders: [...accumulator.processedOrders, ...merchantOrder.processedOrders],
      fulfilledOrders: [...accumulator.fulfilledOrders, ...merchantOrder.fulfilledOrders],
      unprocessedItems: [
        ...accumulator.unprocessedItems,
        // It will always have name and integration type so check the items.
        ...(merchantOrder.unprocessedItems.checkoutVariants.length > 0
          ? [merchantOrder.unprocessedItems]
          : []),
      ],
    }),
    { processedOrders: [], unprocessedItems: [], fulfilledOrders: [] },
  );
};
