import { PaletteColor, PaletteMode, SimplePaletteColorOptions } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

/**
 * Custom palette for the app.
 */
declare module '@mui/material/styles' {
  interface Palette {
    navbar: PaletteColor;
    table: PaletteColor;
  }

  interface PaletteOptions {
    navbar: SimplePaletteColorOptions;
    table: SimplePaletteColorOptions;
  }
}

/**
 * All available colors for the app.
 */
export enum Color {
  LightGreen = '#C7E18F',

  // V2 Design
  White = '#FFFFFF',
  BackgroundGrey = '#F4F6F8',
  BackgroundBlue = '#E8EEFC',
  BackgroundGreen = '#F1F9F1',
  BackgroundRed = '#FEF0EF',
  BackgroundYellow = '#FFF7EB',
  BackgroundMapleRed = '#FF503336',
  Black = '#171c1f',
  Blue = '#4364CD',
  DarkNight = '#263238',
  ErrorRed = '#C62B26',
  Gray1 = '#727272',
  Gray2 = '#BFBFBF',
  Gray3 = '#E0E0E0',
  Gray4 = '#F7F7F7',
  Green = '#4CAF50',
  MapleRed = '#FF5033',
  Red = '#F44336',
  SteelBlue = '#546E7A',
  Yellow = '#FF9800',
}

/**
 * Dark mode palette.
 * See https://www.halo-lab.com/blog/dark-ui-design-11-tips-for-dark-mode-design when implementing dark mode fully.
 */
const darkModePalette: PaletteOptions = {
  background: {
    // default: '#1D2125',
    default: Color.BackgroundGrey,
    paper: Color.White, // Controls a lot! (e.g. table background)
  },
  navbar: {
    light: alpha('#606162', 0.6),
    main: '#161A1D',
    contrastText: alpha(Color.White, 0.87),
  },

  /**
   * TODO: Revisit below!
   */
  table: {
    main: Color.White,
  },
  primary: {
    light: '#ff735b',
    main: Color.DarkNight,
    dark: Color.Black,
    contrastText: Color.White,
  },
  secondary: {
    light: Color.White,
    main: Color.White,
    dark: Color.White,
  },
  text: {
    primary: Color.DarkNight,
    secondary: Color.SteelBlue,
  },
  common: {
    white: Color.White,
  },
  grey: {
    100: '#B2B2B2',
    200: '#727272',
    300: '#263238',
    50: '#E0E0E0',
  },
  success: {
    main: '#4CAf50',
  },
  warning: {
    main: '#FF9800',
  },
  error: {
    main: Color.ErrorRed,
  },
};

/**
 * Light mode palette.
 */
const lightModePalette: PaletteOptions = {
  background: {
    default: Color.BackgroundGrey,
    paper: Color.White,
  },
  navbar: {
    light: Color.Gray4,
    main: Color.White,
    contrastText: Color.Black,
  },
  table: {
    main: Color.White,
  },
  primary: {
    light: '#ff735b',
    main: Color.DarkNight,
    dark: Color.Black,
    contrastText: Color.White,
  },
  secondary: {
    light: Color.White,
    main: Color.White,
    dark: Color.White,
  },
  text: {
    primary: Color.DarkNight,
    secondary: Color.SteelBlue,
  },
  common: {
    white: Color.White,
  },
  grey: {
    100: '#B2B2B2',
    200: '#727272',
    300: '#263238',
    50: '#E0E0E0',
  },
  success: {
    main: '#4CAf50',
  },
  warning: {
    main: '#FF9800',
  },
  error: {
    main: Color.ErrorRed,
  },
};

/**
 * Returns palette based on mode.
 * @param mode - Dark or light mode.
 */
export const getPalette = (mode: PaletteMode): PaletteOptions =>
  mode === 'dark' ? darkModePalette : lightModePalette;

export const profileBackgroundColors = [
  '#FFB69F',
  '#EB9EC3',
  '#CDC0FF',
  '#B4C6FF',
  '#C7E18F',
  '#FFE091',
];

const getColorBasedForId = (colorList: string[]) => (id: string) => {
  const numbersInId = id.replace(/\D/g, '');
  const index = Number(numbersInId) % colorList.length;

  return colorList[index || 0];
};

export const getProfileColorForId = getColorBasedForId(profileBackgroundColors);
