import { FilterDate } from '@components/DataFilter/Date';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { endOfMonth, endOfWeek, startOfWeek, startOfMonth, subDays } from 'date-fns';
import React from 'react';

import { useOrderFilters } from '../config/filters';

const shortcutsItems = (): PickersShortcutsItem<DateRange<Date>>[] => {
  const today = new Date();

  return [
    {
      label: 'This Week',
      getValue: () => [startOfWeek(today), endOfWeek(today)],
    },
    {
      label: 'Last Week',
      getValue: () => {
        const previousWeek = subDays(today, 7);

        return [startOfWeek(previousWeek), endOfWeek(previousWeek)];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => [subDays(today, 7), today],
    },
    {
      label: 'Current Month',
      getValue: () => [startOfMonth(today), endOfMonth(today)],
    },
    {
      label: 'Reset',
      getValue: () => [null, null],
    },
  ];
};

export const DateFilter = () => {
  const { filters, updateFilters } = useOrderFilters();

  return (
    <FilterDate
      placeholder="Date"
      value={filters.date}
      onUpdate={(date) => updateFilters({ date })}
      shortcutsItems={shortcutsItems()}
      dateFormat="dd/MM/yyyy"
      calendars={2}
    />
  );
};
