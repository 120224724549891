import { DataField } from '@components/DataField';
import { Image } from '@components/Image';
import Stack from '@mui/material/Stack';
import { styled, SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

import { CheckoutVariant } from '@common/types/GraphqlTypes.d';
import { formatCurrency } from '@utils/currency';

import { LinkMenu } from './linkMenu';
import { useStyles } from './styles';
import { VariantOptions } from './variantOptions';

interface IProduct {
  checkoutVariant: CheckoutVariant & { tax?: number };
  isArchived?: boolean;
  showMenu?: boolean;
  showPrice?: boolean;
  showQuantity?: boolean;
  priceInUsd?: boolean;
  showTax?: boolean;
  showLink?: boolean;
  sx?: SxProps<Theme>;
}

const getVariantOptionsMargin = (showPrice: boolean, showQuantity: boolean) => ({
  marginBottom: showPrice || showQuantity ? '8px' : undefined,
  marginTop: '16px',
});

const StyledDataField = styled(DataField)(({ theme }) => ({
  justifyContent: 'flex-start',
  '& .label': {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.grey[500], // replace with actual color value
  },
  '& .value': {
    fontSize: '13px',
  },
}));

// eslint-disable-next-line complexity
export const Product = ({
  checkoutVariant,
  isArchived = false,
  showPrice = true,
  showMenu = true,
  showQuantity = true,
  priceInUsd = false,
  showTax = false,
  showLink = true,
  sx,
}: IProduct) => {
  const classes = useStyles();
  const { publicId, purchaseQuantity, productURL, variant, name, tax } = checkoutVariant;
  const { images = [], brand, optionTitles, optionValues, price, currency, priceUSD, id } = variant;

  const displayPrice = priceInUsd ? priceUSD ?? price : price;
  const displayCurrency = priceInUsd && priceUSD ? 'USD' : currency;

  const [firstImage] = images;

  return (
    <Stack direction="row" spacing="16px" sx={sx}>
      <Image image={firstImage} name={name} />
      <Stack sx={{ flexGrow: 1 }}>
        <Typography className={classes.brandName}>{brand}</Typography>
        <Typography className={classes.productName}>{name}</Typography>
        <VariantOptions
          sx={getVariantOptionsMargin(showPrice, showQuantity)}
          optionTitles={optionTitles}
          optionValues={optionValues}
        />
        {showPrice && price && (
          <StyledDataField
            label="Unit Price"
            value={formatCurrency({ value: displayPrice, locale: displayCurrency })}
          />
        )}
        {showTax && tax && (
          <StyledDataField label="Unit Tax" value={formatCurrency({ value: tax, locale: 'USD' })} />
        )}
        {showQuantity && <StyledDataField label="Quantity" value={purchaseQuantity} />}

        {showLink && (
          <LinkMenu
            isArchived={isArchived}
            productURL={productURL}
            productPublicId={publicId}
            productVariantId={id}
            showMenu={showMenu}
          />
        )}
      </Stack>
    </Stack>
  );
};
