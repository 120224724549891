import { StyledButton } from '@components/DataFilter/Styled';
import { StatusTag } from '@components/StatusTag';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Color } from '@theme/palette';
import React from 'react';

import { getUniqueId } from '@utils/id';

type DropdownButtonProps = {
  /**
   * Used to identify the element for accessibility.
   * Attaches to aria-describedby.
   */
  id?: string;

  /**
   * Allows passing of custom click handler.
   */
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * Placeholder to display.
   */
  placeholder?: string;

  /**
   * Used to indicate amount of selected options.
   */
  selectedOptions: number;

  /**
   * Indicates whether the attached dropdown is expanded.
   */
  open: boolean;
};

/**
 * Returns a button looking like a Select dropdown button.
 * Allows custom actions on click.
 * Mainly used for autocomplete component replacing the original Select.
 * @todo - Replace endIcon with same as Select material UI one.
 */
export const DropdownButton = ({
  id = getUniqueId(),
  open: isOpen,
  handleClick,
  selectedOptions = 0,
  placeholder = 'Select',
  ...muiProps
}: DropdownButtonProps & ButtonProps) => (
  <StyledButton
    aria-describedby={id}
    onClick={handleClick}
    endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    sx={() => ({
      borderColor: isOpen ? `${Color.Gray1} !important` : Color.Gray3,
    })}
    {...muiProps}
  >
    <Stack direction="row" alignItems="center" gap={2}>
      {placeholder}

      {/* Show how many are selected */}
      {selectedOptions !== 0 && (
        <StatusTag
          text={selectedOptions.toString()}
          color={Color.White}
          backgroundColor={Color.SteelBlue}
          sx={{ padding: '0 8px !important' }}
        />
      )}
    </Stack>
  </StyledButton>
);
