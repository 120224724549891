import { AuthContext } from '@contexts/authContext';
import { ICurrentUser } from '@hooks/useCurrentUser';
import { useLocalStorage } from '@hooks/useLocalStorage';
import React from 'react';

import { LocalStorageKey } from '@constants/localStorage';
import { jwtHasPayload, parseJwt } from '@utils/jwt';

import { client } from '../../lib/apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthProvider = ({ children }: any) => {
  const {
    storedValue: jwt,
    setValue: setJwtToken,
    removeValue: deleteToken,
  } = useLocalStorage<string>({
    key: LocalStorageKey.JWT,
    serializer: { parse: (x) => x, stringify: (x) => x },
  });

  /**
   * Stores JWT token in local storage.
   * @param {string} jwt - JWT token.
   */
  const login = (jwt: string) => {
    setJwtToken(jwt);
  };

  /**
   * Clear the token in localStorage & user data.
   */
  const logout = () => {
    deleteToken();
    client.clearStore();
  };

  const currentUser = jwt ? parseJwt<ICurrentUser>(jwt) : undefined;

  // This needs to be available or app routing will fail
  if (currentUser && !currentUser.permissions) {
    logout();
  }

  // Verify whether JWT contains all required properties (backwards-compatibility)
  if (currentUser) {
    const hasAllProps = jwtHasPayload(currentUser, ['id', 'email', 'permissions', 'profile']);

    if (!hasAllProps) {
      console.log('JWT token found without all required properties. Logging out...', currentUser);
      logout();
    }
  }

  // Add full name to currentUser so it can be used in the UI
  const name = `${currentUser?.profile.firstName} ${currentUser?.profile.lastName}`;

  const value = {
    login,
    logout,
    currentUser: currentUser ? { ...currentUser, name } : undefined,
    isAuthenticated: Boolean(currentUser),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
