import {
  differenceInSeconds,
  format,
  fromUnixTime,
  isBefore,
  isSameDay,
  isSameYear,
  isValid,
  isWithinInterval,
  max,
  startOfYear,
  subDays,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import formatRelative from 'date-fns/formatRelative';
import enCA from 'date-fns/locale/en-CA';

import { RELATIVE_DATES_LOCAL_KEY, TIMEZONE_LOCAL_KEY } from '../constants/timezone';

/**
 * Checks whether unknown function is function.
 * Currently only used for toFormattedDate. Once used elsewhere, abstract into its own file.
 */
export const isFunction = (fn: unknown): fn is Function => fn instanceof Function;

/**
 * Returns preferred timezone from local storage.
 * Defaults to browser timezone.
 * Defaults to UTC if browser timezone is not available.
 */
export const getLocalTimezone = (): string =>
  window.localStorage.getItem(TIMEZONE_LOCAL_KEY) ||
  Intl.DateTimeFormat().resolvedOptions().timeZone ||
  'UTC';

/**
 * Returns whether to show relative dates.
 * Defaults to false.
 */
export const shouldUseRelativeDates = (): boolean => {
  const localValue = window.localStorage.getItem(RELATIVE_DATES_LOCAL_KEY);

  return localValue === 'true' ?? false;
};

/**
 * Stores preferred timezone in local storage.
 * @param timezone - Timezone to store.
 */
export const setLocalStorageTimezone = (timezone: string): void =>
  window.localStorage.setItem(TIMEZONE_LOCAL_KEY, timezone);

/**
 * Stores whether to show relative time in local storage.
 * @param showRelativeDates - Indicates whether to show relative dates.
 */
export const setLocalStorageUseRelative = (showRelativeDates: boolean): void =>
  window.localStorage.setItem(RELATIVE_DATES_LOCAL_KEY, showRelativeDates.toString());

const getFormat = (includeTime: boolean) => (baseFormat: string) => {
  if (includeTime) {
    return `${baseFormat}, H:mm`;
  }

  return baseFormat;
};

/**
 * Checks if the date is from one of the past years.
 * @param dateToCheck - Date to check.
 * @param dateToCompareAgainst - Date to compare against.
 */
const isDateFromPastYears = (
  dateToCheck: Date | number,
  dateToCompareAgainst: Date | number,
): boolean => isBefore(dateToCheck, startOfYear(dateToCompareAgainst));

/**
 * Checks if the date is within the last 7 days.
 * @param dateToCheck - Date to check.
 * @param dateToCompareAgainst - Date to compare against.
 */
const isDateWithinLastSevenDays = (
  dateToCheck: Date | number,
  dateToCompareAgainst: Date | number,
): boolean => {
  const pastDate = subDays(dateToCompareAgainst, 7);

  return isWithinInterval(dateToCheck, { start: pastDate, end: dateToCompareAgainst });
};

/**
 * Formats a date to Beam-X conventions if it doesn't match any of the other cases.
 * @param date - Date to format.
 * @param now - Date to compare against.
 * @param format - Format function.
 */
const sameElse = (
  date: Date | number,
  now: Date | number,
  format: (baseFormat: string) => string,
) => {
  // If it is past a year.
  if (isDateFromPastYears(date, now)) {
    return format('MMM dd yyyy');
  }

  // If it is within the year.
  if (isSameYear(date, now)) {
    return format('MMM dd');
  }

  // If it is within the week.
  if (isDateWithinLastSevenDays(date, now)) {
    return format('eeee');
  }

  // Everything else. (Shouldn't happen)
  return format('MMM dd yyyy');
};

/**
 * Formats a date to Beam-X conventions.
 *
 * If it is today:
 * WEEKDAY, TIME
 * eg: Thursday, 9:30.
 *
 * If it is within the week:
 * WEEKDAY, TIME
 * eg: Thursday, 9:30.
 *
 * If it is within the year:
 * MMM DD, TIME
 * eg: Nov 18, 9:30.
 *
 * If it is past a year:
 * MMM DD, YEAR
 * eg: Nov 18, 2019.
 *
 * @param date - Date to format.
 * @param options - { includeTime?: boolean }. Defaults to true.
 * @returns String.
 */
export const toFormattedDate = (
  utcDate: Date | number,
  options?: { includeTime?: boolean },
): string | null => {
  if (!isValid(utcDate)) {
    return null;
  }

  const currentTimezone = getLocalTimezone();
  const useRelativeDate = shouldUseRelativeDates();

  // Convert UTC date to custom timezone.
  const date = utcToZonedTime(utcDate, currentTimezone);
  const now = utcToZonedTime(new Date(), currentTimezone);

  const formatToUse = getFormat(options?.includeTime ?? true);

  if (useRelativeDate) {
    const formatRelativeLocale = {
      lastWeek: formatToUse('MMM dd'),
      yesterday: formatToUse("'Yesterday'"),
      today: formatToUse("'Today'"),
      tomorrow: formatToUse("'Tomorrow'"),
      nextWeek: formatToUse('eee'),
      other: () => sameElse(date, now, formatToUse),
    };

    const locale = {
      ...enCA,
      formatRelative: (token: keyof typeof formatRelativeLocale) => {
        const { [token]: format } = formatRelativeLocale;

        return isFunction(format) ? format() : format;
      },
    };

    return formatRelative(date, now, { locale });
  }

  // Default regular format;
  return format(date, sameElse(date, now, formatToUse));
};

export const getTime = (date: Date): string => format(date, 'H:mm');

/**
 * Returns the most recent date from a list of dates, that's within the same day as the compare date.
 * @param compareDate - Date to compare to.
 * @param allCreatedAtDates - All dates to compare.
 */
export const getMostRecentDate = (compareDate: Date, allCreatedAtDates: Date[]): Date => {
  const mostRecentTimestamp = max(
    allCreatedAtDates.flatMap((date) => {
      const dateObject = new Date(date);

      const isSameDate = isSameDay(dateObject, compareDate);

      return isSameDate ? [date.getTime()] : [];
    }),
  );

  return new Date(mostRecentTimestamp);
};

/**
 * Returns `true` if both dates are the same.
 * @param date1 - First date to compare.
 * @param date2 - Second date to compare.
 */

/**
 * Formats the date to the format: MMM d, yyyy.
 * @param date - Date to format.
 */
export const formatToMonthDayYear = (date: Date | number): string => format(date, 'MMM d, yyyy');

/**
 * Returns the amount of seconds in between two dates.
 * @param {Date|number} date - First date.
 * @param {Date|number} secondDate - Second date.
 */
export const secondsBetween = (date: Date | number, secondDate: Date | number): number =>
  differenceInSeconds(date, secondDate);

/**
 * Returns unix milliseconds from a date.
 * @param {Date} date - Date object.
 */
export const toUnixMilliseconds = (date: Date): number => date.valueOf();

/**
 * Returns date from unix milliseconds.
 * @param {number} milliseconds - Unix milliseconds.
 */
export const fromUnixMilliseconds = (milliseconds: number): Date | null => {
  try {
    return fromUnixTime(milliseconds / 1000);
  } catch (error) {
    console.error('Error converting unix milliseconds to date', error);

    return null;
  }
};

export { isEqual as areDatesEqual } from 'date-fns';
