import makeStyles from '@mui/styles/makeStyles';

import { Color } from '../../theme/palette';

export const useStyles = makeStyles(() => ({
  tab: {
    color: Color.DarkNight,
    fontWeight: 500,
    fontSize: 'small',
    lineHeight: '1.25rem',
    flex: 1,
    height: '2.3rem',
    minHeight: '2.3rem',
    borderBottom: `1px solid ${Color.Gray3}`,

    '&.Mui-selected': {
      fontWeight: 700,
    },
  },
  tabBar: {
    '& .MuiTabs-indicator': {
      backgroundColor: Color.Gray2,
      height: '1.5px',
    },
    height: '2.3rem',
    minHeight: '2.3rem',
  },
}));
