import { PrimaryButton } from '@components/Button/Primary';
import { useChromeExtensionInstalled } from '@hooks/useChromeExtension';
import { useSnackbar } from '@hooks/useSnackbar';
import React from 'react';

import { CheckoutVariant, CustomerOrder } from '@common/types/GraphqlTypes.d';
import { sendActiveOrderToChromeExtension } from '@lib/chromeExtension';

type CopyToAriaProps = {
  /**
   * Current customer order.
   * Will be replaced by needing only ID once Aria handles fetching data itself.
   */
  customerOrder: CustomerOrder;

  /**
   * List of products needed for fulfillment.
   */
  items: {
    merchantOrderId: string;
    merchantName: string;
    fulfillmentInstructions?: string | null;
    checkoutVariants: CheckoutVariant[];
  };
};

/**
 * Returns component that copies order to Aria Chrome Extension.
 */
export const CopyToAriaChromeExtension = ({ customerOrder, items }: CopyToAriaProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isInstalled: isChromeExtensionInstalled } = useChromeExtensionInstalled();

  // Handles copying order to Aria
  // In the future, we'll most likely only want to copy the order ID, and have the extension load the order via API!
  const handleSetActiveOrder = () => {
    sendActiveOrderToChromeExtension({
      orderId: customerOrder._id,
      orderNumber: customerOrder.orderNumber,
      orderDashboardUrl: `${window.location.protocol}//${window.location.host}/orders/view/${customerOrder._id}`,
      assignee: {
        name: customerOrder.assignee?.name,
      },
      account: {
        name: customerOrder.account.name,
        fulfillmentInstructions: customerOrder.account.orderConfig?.fulfillment?.instructions,
      },
      shipping: {
        firstName: customerOrder.customer.firstName,
        lastName: customerOrder.customer.lastName,
        addressOne: customerOrder.deliveryAddress?.addressLineOne,
        addressTwo: customerOrder.deliveryAddress?.addressLineTwo,
        city: customerOrder.deliveryAddress?.city,
        province: customerOrder.deliveryAddress?.province,
        country: customerOrder.deliveryAddress?.country,
        postalCode: customerOrder.deliveryAddress?.postalCode,
        phone: customerOrder.deliveryAddress?.phoneNumber,
      },
      merchantOrder: {
        merchantOrderId: items.merchantOrderId,
        merchantName: items.merchantName,
        fulfillmentInstructions: items.fulfillmentInstructions,
      },
      variants: items.checkoutVariants.map((checkoutVariant) => ({
        name: checkoutVariant.name,
        productId: checkoutVariant.productId,
        productPublicId: checkoutVariant.productPublicId,
        productURL: checkoutVariant.productURL ?? 'n/a',
        publicId: checkoutVariant.publicId,
        brand: checkoutVariant.variant.brand ?? 'n/a',
        purchaseQuantity: checkoutVariant.purchaseQuantity,
        imageLink: checkoutVariant.variant.images[0],

        // Maps variant options to key-value pairs
        options: checkoutVariant.variant.optionTitles.map((title, index) => ({
          key: title,
          value: checkoutVariant.variant.optionValues[index] ?? 'n/a',
        })),
      })),
    });

    enqueueSnackbar(`Order ${customerOrder.orderNumber} copied to Aria`, { variant: 'success' });
  };

  return isChromeExtensionInstalled ? (
    <PrimaryButton onClick={handleSetActiveOrder}>Copy to Aria</PrimaryButton>
  ) : (
    <PrimaryButton disabled>Aria Extension Missing</PrimaryButton>
  );
};
