import { TabContext, TabPanel } from '@mui/lab';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { useStyles } from './styles';

export interface ITabPanel {
  component: React.ReactNode;
  disabled?: boolean;
}

type Props = {
  tabPanels: { [tabLabel: string]: ITabPanel };
  tabBarClassName?: string;
  tabPanelClassName?: string;
};

export const Tabs = ({ tabPanels, tabBarClassName, tabPanelClassName }: Props) => {
  const [currentTab, setCurrentTab] = useState('0');
  const classes = useStyles();

  return (
    <TabContext value={currentTab}>
      <MuiTabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
        textColor="primary"
        className={clsx([classes.tabBar, tabBarClassName])}
      >
        {Object.keys(tabPanels).map((label, index) => (
          <Tab
            disabled={tabPanels[label].disabled}
            key={label}
            label={label}
            value={String(index)}
            className={classes.tab}
          />
        ))}
      </MuiTabs>

      {Object.entries(tabPanels).map(([label, { component }], index) => (
        <TabPanel key={label} value={String(index)} className={tabPanelClassName}>
          {component}
        </TabPanel>
      ))}
    </TabContext>
  );
};
