import { ShippingCarrier, ShortcutProduct } from '@common/types/GraphqlTypes.d';

export enum Fields {
  Carrier = 'shippingCarrier',
  CarrierCustomName = 'shippingCarrierCustomName',
  TrackingNumber = 'trackingNumber',
  TrackingUrl = 'trackingUrl',
  Products = 'products',
}

export type FieldValues = {
  [Fields.Carrier]: ShippingCarrier | null;
  [Fields.TrackingNumber]: string | null;
  [Fields.TrackingUrl]: string | null;
  [Fields.CarrierCustomName]: string | null;
  [Fields.Products]: ShortcutProduct[];
};

export const EMPTY_VALUES: FieldValues = {
  [Fields.Carrier]: null,
  [Fields.TrackingNumber]: null,
  [Fields.TrackingUrl]: null,
  [Fields.CarrierCustomName]: null,
  [Fields.Products]: [],
};
