import { Header } from '@components/Header';
import React from 'react';

import { AssignOrdersMenuItem } from './Actions/AssignOrders';
import { ExportMerchantOrdersMenuItem } from './Actions/ExportOrders';
import { UpdateMerchantOrdersByCsvMenuItem } from './Actions/UpdateMerchantOrders';
import { UploadNewOrdersCsvMenuItem } from './Actions/UploadCsvOrders';

/**
 * Returns header shown on orders overview page.
 * @todo - Revisit Actions components and see if they can be optimized. Copied from V1.
 */
export const OrderOverviewHeader = () => (
  <Header
    title="Orders"
    actions={
      <>
        <UploadNewOrdersCsvMenuItem />
        <UpdateMerchantOrdersByCsvMenuItem />
        <ExportMerchantOrdersMenuItem />
        <AssignOrdersMenuItem />
      </>
    }
  />
);
