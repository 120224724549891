import { Card } from '@components/Card';
import { CardTitle } from '@components/CardTitle';
import { ControlledSelect } from '@components/Input/Select';
import { TextField } from '@components/Input/TextField';
import { Add as AddIcon, Delete as DeleteIcon, Remove as RemoveIcon } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { MERCHANT_DEFAULTS, MERCHANT_SHIPPING_COUNTRIES } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
import { useStyles } from './styles';

const { financial: financialInputField, shipping: shippingInputField } = MERCHANT_FIELDS;

// Gotta love the shipping API structure <3
const { simple: id } = shippingInputField;
const ratesId = 'shippingRatePerCountry';

const defaultShippingOption = {
  [ratesId]: [{ countryCode: MERCHANT_DEFAULTS.country }],
};

/**
 * Returns shipping rate(s) per shipping option.
 */
export const ShippingRates = ({
  optionIndex,
  currency,
}: {
  optionIndex: number;
  currency: string;
}) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${id}[${optionIndex}].${ratesId}`,
  });

  // TODO: Required validation for shipping rate does not work!!

  return (
    <Stack direction="column" spacing={2}>
      {fields.map((item, index) => (
        <Stack key={item.id} direction="row" alignItems="center" spacing={2}>
          <ControlledSelect
            id={`${id}[${optionIndex}].${ratesId}[${index}].countryCode`}
            options={MERCHANT_SHIPPING_COUNTRIES}
            defaultValue={MERCHANT_DEFAULTS.country}
            label={index === 0 ? 'Country' : undefined}
          />
          <TextField
            id={`${id}[${optionIndex}].${ratesId}[${index}].shippingRate`}
            label={index === 0 ? `Shipping rate (${currency})` : undefined}
            placeholder="Fill out shipping rate"
            options={{
              required: 'A shipping rate is required',
            }}
            useHook
          />
          <TextField
            id={`${id}[${optionIndex}].${ratesId}[${index}].freeIfPassedSubtotal`}
            label={index === 0 ? `Free shipping over (${currency})` : undefined}
            placeholder="Empty if not applicable"
            useHook
          />

          {/* Remove shipping rate - 1 is required */}
          <Button className={index === 0 ? classes.hidden : ''} onClick={() => remove(index)}>
            <RemoveIcon />
          </Button>
        </Stack>
      ))}

      <Button onClick={() => append({})}>
        <AddIcon />
        Add shipping rate
      </Button>
    </Stack>
  );
};

/**
 * Returns shipping option(s).
 */
export const ShippingOptions = ({ isLoading }: { isLoading?: boolean }) => {
  const classes = useStyles();
  const { control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: id, // unique name for your Field Array
  });

  const currency = watch(financialInputField.currency);

  return (
    <div>
      <CardTitle title="Shipping options" />
      {isLoading ? (
        <Skeleton variant="rectangular" height={400} />
      ) : (
        <Stack direction="column" spacing={2}>
          {fields.map((item, index) => (
            <Card key={item.id}>
              <Stack direction="column" gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <h3 className={classes.optionsTitle}>{`Option #${index + 1}`}</h3>

                  {/* Remove shipping options - 1 is required */}
                  {index !== 0 && (
                    <Button onClick={() => remove(index)}>
                      <DeleteIcon />
                    </Button>
                  )}
                </Stack>

                <TextField
                  id={`${id}.${index}.description`}
                  label="Description"
                  placeholder="Standard (3-5 business days)"
                  options={{
                    required: 'A shipping rate is required',
                  }}
                  useHook
                />

                <h4 className={classes.ratesTitle}>Rates</h4>
                {<ShippingRates optionIndex={index} currency={currency || ''} />}
              </Stack>
            </Card>
          ))}

          <Stack>
            <Button onClick={() => append(defaultShippingOption)}>
              <AddIcon />
              Add shipping option
            </Button>
          </Stack>
        </Stack>
      )}
    </div>
  );
};
