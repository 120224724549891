import {
  LocalizationProvider,
  PickersShortcutsItem,
  DateRange,
  DateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { StaticDateRangePickerProps } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { fromUnixMilliseconds, toUnixMilliseconds } from '@utils/date';

import { DateButton } from './DateButton';

const DEFAULT_PLACEHOLDER = 'Select Date';

type FilterDateProps = Omit<StaticDateRangePickerProps<Date>, 'value'> & {
  /**
   * The value of the date range in unix timestamp.
   * Currently only supports unix timestamp!
   */
  value?: DateRange<number> | number[];

  /**
   * Gets called when the value of the date picker changes.
   */
  onUpdate: (value: DateRange<number> | []) => void;

  /**
   * The label to be displayed next to the switch.
   */
  label?: string | JSX.Element;

  /**
   * Placeholder to be displayed when no value is selected.
   */
  placeholder?: string;

  /**
   * Array of shortcuts to be displayed in the picker.
   * E.g. This Week, Last Week, etc.
   */
  shortcutsItems?: PickersShortcutsItem<DateRange<Date>>[];

  /**
   * Date format to be used when displaying the date.
   * Defaults to dd/MM/yyyy.
   * Uses `date-fns` .format.
   */
  dateFormat?: string;
};

/**
 * Formats the placeholder based on the date range selected.
 * @param dates - The date range selected.
 * @param placeholder - The placeholder to be displayed when no value is selected.
 * @param dateFormat - The date format to be used when displaying the date.
 */
const formatPlaceholder = (
  dates: DateRange<Date>,
  placeholder: string,
  dateFormat: string = 'dd/MM/yyyy',
): string => {
  if (!dates[0] && !dates[1]) {
    return placeholder;
  }

  try {
    return dates.map((date) => (date ? format(date, dateFormat) : 'null')).join(' - ');
  } catch {
    return placeholder;
  }
};

/**
 * Returns a date range picker component supporting unix timestamp.
 * Currently only supports unix timestamp using milliseconds, not seconds.
 * Currently forces from & to to be both filled out. No single date selection.
 * Known issue: only supports controlling the value from outside to display placeholder value correctly.
 */
export const FilterDate = ({
  dateFormat,
  onUpdate,
  value,
  shortcutsItems = [],
  placeholder: defaultPlaceholder = DEFAULT_PLACEHOLDER,
  ...muiProps
}: FilterDateProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [pendingValue, setPendingValue] = useState<DateRange<Date>>([null, null]);

  /**
   * Keeps placeholder value up-to-date based on date range selected and placeholder.
   */
  useEffect(() => {
    setPlaceholder(formatPlaceholder(pendingValue, defaultPlaceholder, dateFormat));
  }, [pendingValue, defaultPlaceholder, dateFormat]);

  /**
   * Keeps track of the date range picker value based on unix timestamp value.
   */
  useEffect(() => {
    if (value) {
      const [fromDate, toDate] = value;

      // When external values are cleared, set date values to null.
      if (!fromDate || !toDate) {
        return setPendingValue([null, null]);
      }

      setPendingValue([fromUnixMilliseconds(fromDate), fromUnixMilliseconds(toDate)]);
    }
  }, [value]);

  /**
   * Handles the change of the date range picker.
   * Only informs parent of update if both from & to are selected.
   * Sends unix timestamps to parent.
   */
  const handleOnChange = (values: DateRange<Date>) => {
    const [fromDate, toDate] = values;

    // When datepicker values are cleared, set external unix values to [].
    if (!fromDate || !toDate) {
      return onUpdate([]);
    }

    return onUpdate([toUnixMilliseconds(fromDate), toUnixMilliseconds(toDate)]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        {...muiProps}
        value={pendingValue}
        slotProps={{
          // Passes props to the field component.
          field: { setOpen: setIsOpen, isOpen, placeholder } as any,

          // Optional shortcuts (e.g. This Week, Last Week, etc.)
          shortcuts: { items: shortcutsItems },
        }}
        onChange={handleOnChange}
        slots={{ field: DateButton }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      />
    </LocalizationProvider>
  );
};
