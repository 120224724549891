import { Divider, Stack, Typography } from '@mui/material';
import { IProcessedOrder } from '@pages/Order/View/OrderedItems/interfaces';
import React from 'react';

import { CheckoutVariant, ShortcutProduct, FulfillmentDetail } from '@common/types/GraphqlTypes.d';

import { ShippedProduct } from '../Product/shippingProduct';

interface IOrderItemsProps {
  order: IProcessedOrder;
}

const getMatchingCheckoutVariant =
  (
    checkoutVariants: CheckoutVariant[],
    shipmentDetail: FulfillmentDetail,
    merchantOrderId: string,
  ) =>
  ({ productId, variantId, quantity }: ShortcutProduct): React.ReactNode => {
    const variant = checkoutVariants.find(
      (variant) => variant.productId === productId && variant.variant.id === variantId,
    );

    if (!variant) {
      return <></>;
    }

    return (
      <ShippedProduct
        checkoutVariant={variant}
        shipmentDetails={{ ...shipmentDetail, quantity }}
        merchantOrderId={merchantOrderId}
      />
    );
  };

export const ShippingTracking = ({ order }: IOrderItemsProps) => {
  if (order.shipmentDetails.length === 0) {
    return (
      <Typography>
        No shipping tracking available. Select <b>Submit shipping tracking</b> if you wish to track
        shipping information through our dashboard.
      </Typography>
    );
  }

  const displayProductsForShipment = (shipmentDetail: FulfillmentDetail) =>
    shipmentDetail.products.map(
      getMatchingCheckoutVariant(order.checkoutVariants, shipmentDetail, order.merchantOrderId),
    );

  return (
    <Stack spacing={2} divider={<Divider />}>
      {order.shipmentDetails.map(displayProductsForShipment)}
    </Stack>
  );
};
