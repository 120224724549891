import Person from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { Color } from '@theme/palette';
import clsx from 'clsx';
import React from 'react';

import { getInitials } from '@utils/string';

import { useStyles } from './styles';

export type UserIconProps = {
  color?: string;
  name?: string;
  className?: string;
  isDefault?: boolean;
  showName?: boolean;
  children?: React.ReactNode;
};

export const UserIcon = ({
  color,
  name,
  className = '',
  isDefault = false,
  showName = false,
  children,
}: UserIconProps) => {
  const classes = useStyles();

  // TODO: We could & should support actual profile pictures in the future
  return (
    <Box component="span" className={clsx([classes.root, className])}>
      <Avatar
        className={classes.avatar}
        sx={{
          width: 33,
          height: 33,
          lineHeight: 33,
          background: color ?? Color.Gray4,
        }}
      >
        {isDefault ? <Person /> : getInitials(name)}
      </Avatar>
      {showName && name && <span className={classes.name}>{name}</span>}
      {children && <span className={classes.children}>{children}</span>}
    </Box>
  );
};
