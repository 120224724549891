import { CardTitle } from '@components/CardTitle';
import { Box } from '@mui/material';
import { Loading } from '@pages/Order/components/Loading';
import { OrderHistoryTimeline } from '@pages/Order/components/OrderHistoryTimeline';
import { SendOrderConfirmationEmailButton } from '@pages/Order/components/SendEmailButton';
import React from 'react';

import { OrderHistoryEventType } from '@common/types/GraphqlTypes.d';

import { IHistoryCustomerOrder, useCustomerOrderHistory } from './graphql';
import { createdEvent, orderHistoryToTimelineItem } from './utils';

type OrderHistoryProps = { customerOrderId: string };

export const additionalComponents = {
  [OrderHistoryEventType.CustomerOrderEmailSent]: ({ _id }: IHistoryCustomerOrder) => (
    <SendOrderConfirmationEmailButton customerOrderId={_id} />
  ),
};

export const OrderHistory = ({ customerOrderId }: OrderHistoryProps) => {
  const { isLoading, customerOrder } = useCustomerOrderHistory(customerOrderId);

  if (isLoading) {
    return <Loading />;
  }

  if (!customerOrder) {
    return <div>Order does not exist</div>;
  }

  const { history } = customerOrder;

  // history is read only here.
  const sortedHistory = [...history].sort(({ createdAt: a }, { createdAt: b }) => b - a);

  const items = [
    ...sortedHistory.map(orderHistoryToTimelineItem(customerOrder)),
    createdEvent(customerOrder),
  ];

  return (
    <Box>
      <CardTitle title="History" />

      <OrderHistoryTimeline items={items} />
    </Box>
  );
};
