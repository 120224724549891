/* eslint-disable max-lines-per-function */
import { Dropdown } from '@components/Input/__deprecated/Dropdown';
import { Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { Currency } from '@types';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { capitalizeFirstLetter } from '@utils/string';

import { FEED_ORDER_FIELD, IFeedOrderFormData } from '../../types';
import { useStyles } from './styles';
import { isValidCurrencyInput } from './utils';

interface IPriceBreakdownProps {
  formValidationHook: UseFormReturn<IFeedOrderFormData>;
}

const currencies = [
  { label: Currency.USD, symbol: '$', value: Currency.USD },
  { label: Currency.CAD, symbol: '$', value: Currency.CAD },
  { label: Currency.GBP, symbol: '£', value: Currency.GBP },
  { label: Currency.EUR, symbol: '€', value: Currency.EUR },
];

export const PriceBreakdown: React.FC<IPriceBreakdownProps> = ({ formValidationHook }) => {
  const classes = useStyles();
  const { getValues, register, watch } = formValidationHook;

  const [isShowingDiscountField, setIsShowingDiscountField] = useState<boolean>(
    getValues(FEED_ORDER_FIELD.DISCOUNT) > 0,
  );
  const lineItems = [
    {
      display: true,
      field: FEED_ORDER_FIELD.SUBTOTAL,
    },
    {
      display: true,
      field: FEED_ORDER_FIELD.SHIPPING,
    },
    {
      display: true,
      field: FEED_ORDER_FIELD.TAXES,
    },
    {
      display: isShowingDiscountField,
      field: FEED_ORDER_FIELD.DISCOUNT,
    },
  ];

  watch([...lineItems.map(({ field }) => field), FEED_ORDER_FIELD.CURRENCY]);
  const onAddingDiscountClick = () => setIsShowingDiscountField(true);
  const total = lineItems.reduce(
    (previous, { field }) =>
      previous +
      ((field === FEED_ORDER_FIELD.DISCOUNT
        ? -Number.parseFloat(getValues(field) as unknown as string)
        : Number.parseFloat(getValues(field) as string)) || 0),
    0,
  );

  return (
    <div className={classes.priceBreakdown}>
      {lineItems.map(
        ({ display, field }, index) =>
          display && (
            <div className={classes.lineItem} key={`${field}-${index}`}>
              <Typography variant="subtitle2">{capitalizeFirstLetter(field)}</Typography>
              <TextField
                className={classes.textField}
                error={!isValidCurrencyInput(getValues(field) as string)}
                helperText={
                  isValidCurrencyInput(getValues(field) as string)
                    ? ''
                    : `Invalid ${capitalizeFirstLetter(field)}`
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                placeholder="0.00"
                size="small"
                sx={{ input: { textAlign: 'right' } }}
                {...register(field)}
              />
            </div>
          ),
      )}
      {!isShowingDiscountField && (
        <div className={classes.discount}>
          <Typography className={classes.link} onClick={onAddingDiscountClick} variant="caption">
            + <u>Add Discount</u>
          </Typography>
        </div>
      )}
      <Divider />
      <div className={classes.lineItem}>
        <Typography variant="h6">Total</Typography>
        <div className={classes.lineItem}>
          <Typography variant="h6">
            {currencies.find(({ label }) => label === getValues(FEED_ORDER_FIELD.CURRENCY))?.symbol}
            {total.toFixed(2)}
          </Typography>
          <Dropdown
            className={classes.dropdown}
            defaultValue={getValues(FEED_ORDER_FIELD.CURRENCY)}
            items={currencies}
            registrationProps={register(FEED_ORDER_FIELD.CURRENCY)}
          />
        </div>
      </div>
    </div>
  );
};
