import { GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useStore } from 'react-context-hook';
import { useSearchParams } from 'react-router-dom';

import { getQueryUrl, parseQueryURL } from '@utils/query';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

const DEFAULT_SORTING = {
  sortField: '_id',
  sortOrder: SortOrder.Desc,
};

export type ISorting = {
  sortField: string;
  sortOrder: SortOrder;
};

/**
 * Returns default sorting values.
 * Either from URL query or default values.
 * @todo - Add safeguard to avoid invalid values!
 */
export const getDefaultSorting = (defaults: Partial<ISorting>) => {
  const { sortField, sortOrder } = parseQueryURL<ISorting>() ?? {};

  // 1. Query URL params
  // 2. Defaults provided by parent component
  // 3. Default values in `DEFAULT_SORTING`
  return {
    sortField: sortField ?? defaults.sortField ?? DEFAULT_SORTING.sortField,
    sortOrder: sortOrder ?? defaults.sortOrder ?? DEFAULT_SORTING.sortOrder,
  };
};

/**
 * Returns hook for managing global sorting state.
 * @param {object} defaults - Default values for sorting.
 * @param {string} key - Key to identify the context store (defaults to sorting). Useful if you want to use multiple sorting hooks.
 */
export const useSorting = (defaults: Partial<ISorting> = {}, key: string = 'sorting') => {
  const defaultValues = getDefaultSorting(defaults);
  const [_searchParams, setSearchParams] = useSearchParams();

  const [sorting, setSorting] = useStore<ISorting>(key, defaultValues);

  const updateSorting = (updatedValue: ISorting) => {
    const values = { ...sorting, ...updatedValue };

    // Update state.
    setSorting(values);

    // Update URL with new sorting values. (appends to URL - not replace)
    setSearchParams(getQueryUrl(values, { replace: false }));
  };

  // Used for GraphQL queries.
  const getGraphQLSort = () => ({
    sortBy: {
      [sorting.sortField]: sorting.sortOrder === SortOrder.Desc ? -1 : 1,
    },
  });

  // Used for DataGrid sorting.
  const getDataGridSort = (): GridInitialStatePro['sorting'] => ({
    sortModel: [{ field: sorting.sortField, sort: sorting.sortOrder as GridSortDirection }],
  });

  // Allows DataGrid sorting to be mapped & applied.
  // Currently only supports single sort filter!
  const updateDataGridSort = (sortModel: GridSortModel) => {
    const [updatedSorting] = sortModel;

    if (updatedSorting) {
      updateSorting({
        sortField: updatedSorting.field,
        sortOrder: updatedSorting.sort as SortOrder,
      });
    }
  };

  return {
    sorting,
    getGraphQLSort,
    getDataGridSort,
    updateDataGridSort,
    updateSorting,
  };
};
