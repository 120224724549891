import { useDropDownMenu } from '@components/Button/Dropdown';
import { Text } from '@components/Text';
import { useSnackbar } from '@hooks/useSnackbar';
import { TaskAlt } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { UploadFileDialog } from '@pages/Order/components/FileUploadDialog';
import React, { useState } from 'react';

import { downloadFile } from '@utils/file';

import { useUpdateMerchantOrdersByCsv } from './graphql';
import { csvToOrdersUpdate } from './utils';

export const UpdateMerchantOrdersByCsvMenuItem = () => {
  const { handleClose: handleMenuClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);

  //Dialog box
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);

  const handleFileSubmit = async (file: File) => {
    setIsOpen(false);
    await fileHandler(file);
  };

  const { updateMerchantOrders, apiError, exportData } = useUpdateMerchantOrdersByCsv({
    onCompleted: () => {
      if (apiError) {
        enqueueSnackbar(`File failed to update merchant orders with error ${apiError}`, {
          variant: 'error',
        });
      }

      if (exportData) {
        const {
          updateByCsv: { encodedCsv, fileName },
        } = exportData;

        downloadFile({ fileName, encodedFile: encodedCsv, type: 'text/csv' });
        enqueueSnackbar('Updated merchant orders successfully');
      }

      handleMenuClose();
    },
  });

  const fileHandler = (file: File) =>
    new Promise<void>((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(file);

      reader.addEventListener('load', async () => {
        const file = reader.result as string;
        const csvRowArray = csvToOrdersUpdate(file);

        await updateMerchantOrders({ variables: { input: { csvRowArray } } });
        resolve();
      });

      reader.addEventListener('error', (error) => {
        enqueueSnackbar(`File failed to update merchant orders with error ${apiError}`, {
          variant: 'error',
        });
        reject(error);
      });
    });

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <TaskAlt />
        Update{' '}
        <Text fontWeight={600} gutterBottom={false}>
          existing order(s)
        </Text>
      </MenuItem>
      <UploadFileDialog
        allowedFileTypes={['csv']}
        handleSubmit={handleFileSubmit}
        title="Update existing order(s)"
        onClose={handleClose}
        open={isOpen}
        uploadFileLabel="Merchant order(s)"
      />
    </>
  );
};
