import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const REOPEN_ESCLATION_MUTATION = gql`
  mutation reopenEscalation($customerOrderId: String!, $commentId: String!) {
    reopenEscalation(customerOrderId: $customerOrderId, commentId: $commentId) {
      result
    }
  }
`;

const CLOSE_ESCALATION_MUTATION = gql`
  mutation closeEscalation($customerOrderId: String!, $commentId: String!) {
    closeEscalation(customerOrderId: $customerOrderId, commentId: $commentId) {
      result
    }
  }
`;

/**
 * Hook for closing an escalation.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCloseEscalation = (options: MutationHookOptions = {}) => {
  const [closeEscalationMutation, { error: apiError, loading: isLoading }] = useMutation(
    CLOSE_ESCALATION_MUTATION,
    options,
  );

  return { closeEscalationMutation, isLoading, apiError };
};
/**
 * Hook for reopening an escalation.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useReopenEscalation = (options: MutationHookOptions = {}) => {
  const [reopenEscalationMutation, { error: apiError, loading: isLoading }] = useMutation(
    REOPEN_ESCLATION_MUTATION,
    options,
  );

  return { reopenEscalationMutation, isLoading, apiError };
};
