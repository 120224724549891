import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles(() => ({
  status: {
    background: Color.ErrorRed,
    borderRadius: '4px',
    display: 'inline-block',
    marginTop: '10px',
    padding: '2px 8px',
  },
  statusText: {
    color: Color.White,
    fontSize: '13px',
    fontWeight: '500',
  },
  productName: {
    color: Color.DarkNight,
    fontSize: '13px',
    fontWeight: 600,
    paddingLeft: '8px',
  },
}));
