import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import { Download as ImportProductsIcon } from '@mui/icons-material';
import React from 'react';

import { downloadFileFromURI } from '@utils/file';

import { useGetExportFeedUrl } from '../graphql';

type ActionsProps = {
  feedName?: string | null;
};

export const DownloadFeedMenuItem = ({ feedName }: ActionsProps) => {
  // In case a merchant has not slug - only supports download of CSV exported feeds!
  const url = feedName ? `${feedName}.csv` : '';

  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const { getData: downloadFeed } = useGetExportFeedUrl(url, {
    onCompleted: ({ merchantExportFeedDownloadUrl: { url: presignedDownloadUrl } }) => {
      enqueueSnackbar(`Export feed ${url} will start downloading soon`);

      // Programatically download the file - avoids generating presigned URLs without clicking on menu item
      downloadFileFromURI(presignedDownloadUrl, `export-feed-${url}`);

      // Close modal right after download
      handleClose();
    },
  });

  return <MenuItem action={downloadFeed} icon={ImportProductsIcon} label="Download export feed" />;
};
