import { StyledPopper, StyledTextField } from '@components/DataFilter/Styled';
import SearchIcon from '@mui/icons-material/Search';
import { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import MuiAutocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import { Color } from '@theme/palette';
import React, { useCallback, useEffect, useState } from 'react';

import { getUniqueId } from '@utils/id';

import { DropdownButton } from './DropdownButton';
import { PopperComponent } from './Popper';

export type IAutocompleteProps<T> = Omit<
  Partial<AutocompleteProps<T, true | false, false, false | true>>,
  'options'
> & {
  /**
   * Handles when search input value changes.
   * Needs to be handled by parent component to manage options.
   */
  onInputUpdate: (value: string) => Promise<T[]> | T[];

  /**
   * Triggers when a value is selected.
   */
  onValueUpdate: (value: T[]) => void;

  /**
   * Function called when component gets rendered first
   * Should return preselected values.
   */
  onInitialRender?: () => Promise<T[]> | T[];

  /**
   * Show loading state when fetching data.
   */
  isLoading?: boolean;

  /**
   * Optional label to display above the input field.
   */
  label?: string;

  /**
   * List of options to render.
   */
  options: T[];
};

/**
 * Renders custom input field.
 */
const renderSearchInputField = (params: AutocompleteRenderInputParams, isLoading: boolean) => {
  const endAdornment = (
    <Stack direction="row" gap={0.5}>
      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
      <SearchIcon sx={{ fontSize: '1.2em', color: Color.Gray1 }} />
    </Stack>
  );

  return (
    <StyledTextField
      {...params}
      size="small"
      placeholder="Search"
      InputLabelProps={{ shrink: true }}
      InputProps={{ ...params.InputProps, endAdornment }}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  );
};

/**
 * Returns enhanced MUI autocomplete component.
 * Currently supports multi-select (multiple values) only.
 * @todo - Add label support again!
 */
export const FilterAutocomplete = <T,>({
  id = getUniqueId(),
  isLoading = false,
  placeholder,
  options,
  value,
  onInputUpdate,
  onInitialRender,
  onValueUpdate,
  ...muiProps
}: IAutocompleteProps<T>) => {
  // Avoids re-rendering when value changes causing funky behaviour when value is passed from parent.
  const [pendingValue, setPendingValue] = React.useState<T[]>([]);

  // Tracks anchor element for dropdown as we're using a custom popover.
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorElement);

  /**
   * Only loads once on initial mount.
   * Currently used to preselect values after reload.
   */
  useEffect(() => {
    const setPreselectedValues = async () => {
      if (onInitialRender) {
        const selectedValues = await onInitialRender();

        setPendingValue(selectedValues);
      }
    };

    setPreselectedValues();
  }, []);

  /**
   * Handles when button is clicked to open dropdown.
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  /**
   * When user clicks away from the dropdown.
   */
  const handleClickAway = () => {
    if (anchorElement) {
      anchorElement.focus();
    }
    setAnchorElement(null);
  };

  /**
   * When input field value changes (during typing).
   * Informs parent component of the new search value.
   */
  const handleOnInputChange = useCallback(
    (inputValue: string) => onInputUpdate(inputValue),
    [onInputUpdate],
  );

  /**
   * When value changes (after selecting an option).
   * Informs parent component of the new value(s).
   */
  const handleOnChange = (value: T[]) => {
    setPendingValue(value);
    onValueUpdate(value);
  };

  /**
   * When external value gets emptied, reset values.
   */
  useEffect(() => {
    if (Array.isArray(value) && value.length === 0) {
      setPendingValue([]);
    }
  }, [value]);

  return (
    <Box>
      <DropdownButton
        id={id}
        handleClick={handleClick}
        placeholder={placeholder}
        selectedOptions={Array.isArray(pendingValue) ? pendingValue.length : 0}
        open={isOpen}
      />

      <StyledPopper id={id} open={isOpen} anchorEl={anchorElement} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ padding: '8px 10px' }}>
            <MuiAutocomplete
              {...muiProps}
              value={pendingValue}
              options={options}
              onChange={(_, value) => handleOnChange(value as T[])}
              onInputChange={(_, value) => handleOnInputChange(value)}
              renderInput={(params) => renderSearchInputField(params, isLoading)}
              filterOptions={(x) => x}
              loading={isLoading}
              renderTags={() => null}
              PopperComponent={PopperComponent}
              disableCloseOnSelect
              open
            />
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  );
};
