import { ContentCopy as ContentCopyIcon, Check as CheckIcon } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import React, { useState } from 'react';

export const useStyles = makeStyles({
  copyButton: {
    display: 'inline-block',
    marginLeft: '4px',
  },
  content: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Color.Gray4,
    },
  },
  icon: {
    fontSize: '1rem',
  },
});

type CopyProps = {
  /**
   * Optionally provide a classname to allow custom styling or logic.
   */
  className?: string;

  /**
   * Value to copy.
   * If no children are provided, this will be used as the value to display.
   */
  value: string;

  /**
   * Optionally provide children to display.
   */
  children?: React.ReactNode;
};

/**
 * Custom tooltip to override default styles.
 */
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 10,
    fontSize: '0.9em',
    backgroundColor: '#183643',
    letterSpacing: '0.5px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#183643',
  },
}));

/**
 * Potential copy states.
 * Idle: No copy action has been taken.
 * Active: Copy action has been taken.
 * Copied: Copy action has been taken and value has been copied.
 */
type CopyState = 'idle' | 'active' | 'copied';

/**
 * Returns copy component.
 * Makes child component copyable.
 */
export const Copy = ({ children, className, value }: CopyProps) => {
  const classes = useStyles();
  const [copyState, setCopyState] = useState<CopyState>('idle');

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setCopyState('copied');

    // Make sure to to hide confirmation after 2 seconds.
    setTimeout(() => setCopyState('idle'), 2000);
  };

  const handleClose = () => {
    // When copied - keep tooltip open.
    if (copyState !== 'copied') {
      setCopyState('idle');
    }
  };

  const handleOpen = () => {
    setCopyState('active');
  };

  // Dynamic tooltip title based on copy state.
  const title = (
    <Stack direction="row" gap={1} alignItems="center">
      {copyState === 'copied' ? (
        <>
          <CheckIcon className={classes.icon} />
          Copied to Clipboard
        </>
      ) : (
        <>
          <ContentCopyIcon className={classes.icon} />
          Click to copy
        </>
      )}
    </Stack>
  );

  return (
    <Stack direction="row" alignItems="center" className={className}>
      <CustomTooltip
        placement="top"
        open={copyState === 'active' || copyState === 'copied'}
        onOpen={handleOpen}
        onClose={handleClose}
        onClick={() => copyToClipboard(value)}
        title={title}
        arrow
      >
        {/* If no children are provided - use value to copy as display */}
        <span className={classes.content}> {children || value}</span>
      </CustomTooltip>
    </Stack>
  );
};
