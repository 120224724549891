import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Currency, QueryProductByPublicIdArgs } from '@common/types/GraphqlTypes.d';

export type IProduct = {
  id: string;
  publicId: string;
  productURL: string;
  inventory: number;
  name: string;
  description: string;
  gender: string;
  price: string;
  currency: Currency;
  images: string[];
  type: string; // Google Category
  updatedAt: Date;
  truthyValidationFlags: string[]; // TODO: Should be a shared enum!
  brand: {
    name: string | 'brand_undefined';
  };
  merchant: {
    _id: string;
    name: string;
  };
  variants: {
    importVariantId: string;
    sku?: string;
    currency: string;
    price: number;
    inventory: number;
    option: {
      color?: string;
      size?: string;
    };
    optionTitles: string[];
    optionValues: string[];
    // Availability information
    isDeleted: boolean;
    isUnavailable: boolean;
  }[];
};

const PRODUCT_QUERY = gql`
  query productByPublicId($input: ProductIdInput!) {
    productByPublicId(input: $input) {
      id
      publicId
      productURL
      description
      inventory
      name
      gender
      price
      currency
      images
      type
      updatedAt
      truthyValidationFlags
      brand {
        name
      }
      merchant {
        _id
        name
      }
      variants {
        importVariantId
        sku
        currency
        inventory
        price
        isUnavailable
        isDeleted
        option {
          color
          size
        }
        optionTitles
        optionValues
      }
    }
  }
`;

/**
 * Hook for fetching user.
 * @param {object} [options] - Query hook options for GraphQL.
 */
export const useProduct = (
  options: QueryHookOptions<{ productByPublicId: IProduct }, QueryProductByPublicIdArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productByPublicId: IProduct }, QueryProductByPublicIdArgs>(PRODUCT_QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const { productByPublicId } = data || {};

  return { data: productByPublicId, isLoading, apiError };
};
