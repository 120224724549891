import { QUICK_FILTER_KEY, useDataFilters } from '@hooks/useDataStore/useFilters';
import { ClientStatus } from '@common/types/GraphqlTypes.d';

/**
 * Filter options for merchants.
 */

export type IMerchantFilters = {
  publishFilters?: ClientStatus[];

  // Support for quick filter - overwrites all other values!
  [QUICK_FILTER_KEY]?: string;
};

const DEFAULT_MERCHANT_FILTERS: IMerchantFilters = {
  publishFilters: [],
};

/**
 * All available merchant quick filters.
 */
export enum MerchantQuickFilter {
  NonArchived = '746483246724',
}

const getMerchantQuickFilters = (): { [key in MerchantQuickFilter]: IMerchantFilters } => ({
  [MerchantQuickFilter.NonArchived]: {
    ...DEFAULT_MERCHANT_FILTERS,
    publishFilters: [ClientStatus.Draft, ClientStatus.Live],
  },
});

/**
 * Hook for managing global merchant filters state.
 */
export const useMerchantFilters = () =>
  useDataFilters<IMerchantFilters>({
    key: 'merchantFilters',
    defaults: DEFAULT_MERCHANT_FILTERS,
    quickFilterOptions: getMerchantQuickFilters(),
  });
