import { Stack, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import React from 'react';

import { secondsBetween, toFormattedDate } from '@utils/date';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.8rem',
    marginTop: '',
  },
  syncStatus: {
    width: '0.9rem',
    height: '0.9rem',
    marginRight: 8,
    borderRadius: '50%',
  },

  updatedAt: {
    fontWeight: 400,
  },

  updatedTag: {
    fontWeight: 600,
    paddingRight: 3,
  },
}));

export type ProductSyncStatusProps = {
  updatedAt: number; // unix milliseconds
  externalUpdatedAt?: Date | null; // Elastic
};

/**
 * Returns appropriate color to indicate sync status.
 * @param {number} seconds - Amount of seconds in between dates.
 */
const getSyncStatusColor = (seconds: number): string => {
  // 10 minutes discrepancy
  if (seconds > 600) {
    return Color.MapleRed;
  }

  // 30 seconds discrepancy
  if (seconds > 30) {
    return Color.Yellow;
  }

  return Color.Green;
};

/**
 * Displays updated at sync status.
 */
export const ProductSyncStatus = ({ updatedAt, externalUpdatedAt }: ProductSyncStatusProps) => {
  const classes = useStyles();

  /**
   * Returns a status indicator if external updatedAt is provided.
   * This highlights whether there's a difference between both dates to indicate a lag between syncs.
   */
  const showStatusIndicator = () => {
    if (!externalUpdatedAt) {
      return null;
    }

    const comparableDate = new Date(externalUpdatedAt);
    const seconds = secondsBetween(updatedAt, comparableDate);

    return (
      <Tooltip title={`External sync: ${toFormattedDate(comparableDate)}`}>
        <div
          className={classes.syncStatus}
          style={{ backgroundColor: getSyncStatusColor(seconds) }}
        ></div>
      </Tooltip>
    );
  };

  return (
    <Stack direction="row" justifyContent="end" alignItems="center" className={classes.root}>
      {showStatusIndicator()}
      <span className={classes.updatedTag}>Updated</span>
      <span className={classes.updatedAt}>{toFormattedDate(updatedAt)}</span>
    </Stack>
  );
};
