/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { SecondaryButton } from '@components/Button/Secondary';
import { Close as CloseIcon } from '@mui/icons-material';
import { InputLabel, Skeleton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { getUniqueId } from '@utils/id';
import { getBase64 } from '@utils/image';

import { useStyles } from './styles';

export type ITextFieldProps = {
  showPreview?: boolean;
  options?: RegisterOptions<any, any>;

  // Built-in loading support
  isLoading?: boolean;
};

// TODO: Fix the actual correct input props!!
// TODO: Currently only supports single files!
export const FileField: React.FC<ITextFieldProps & any> = ({
  id = getUniqueId(),
  label,
  className,
  showPreview = false,
  isLoading = false,
  options, // React-hook-form options
  ...otherProps
}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState('');
  const [preview, setPreview] = useState('');
  const isFileSelected = fileName !== '';
  const hasPreview = preview !== '';

  // React-hook-form support (best practices)
  // Even if register is not used it has to be called since it's a React hook. Bunch of {} defaults in case forms aren't used
  const { register, setValue, getValues } = useFormContext<any>() || {};

  // React-hooks form support
  const { ref, onChange, ...restOfRegistrationProps } = register(id, options);

  // Form value
  const formValue = getValues(id);

  // When form field already has a pre-existing value
  useEffect(() => {
    // Existing file URL has been set on field value
    if (typeof formValue === 'string') {
      const filename = formValue.slice(Math.max(0, formValue.lastIndexOf('/') + 1));

      setFileName(filename);

      if (showPreview) {
        setPreview(formValue);
      }
    }
  }, [formValue, showPreview]);

  /**
   * Handles whenever the input field value changes.
   */
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // First & single file
    if (event.target.files?.[0]) {
      const {
        target: { files },
      } = event;

      // 1 file support right now
      const [file] = files;

      // Sets filename used for checking whether file has been selected
      setFileName(file.name);

      // Image preview
      if (showPreview) {
        const base64 = await getBase64(file);

        setPreview(base64);
      }

      // Make sure react-hook-form gets updated value
      onChange(event);
    }
  };

  /**
   * Clears the input value.
   */
  const clearSelectedImage = () => {
    setFileName('');
    setPreview('');
    setValue(id, null);
  };

  // TODO: Add support for reverting to the original passed along value

  /**
   * Returns different label value based on config & current state.
   */
  const labelValue = () => {
    if (isFileSelected) {
      return hasPreview ? '' : fileName;
    }

    return 'Select file';
  };

  return (
    <FormControl variant="outlined" className={clsx([classes.root, className])}>
      {label && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      )}

      {isLoading ? (
        <Skeleton className={classes.loader} height="36px" />
      ) : (
        <div className={classes.input}>
          <SecondaryButton
            className={classes.button}
            style={{
              backgroundImage: hasPreview ? `url(${preview})` : '',
            }}
          >
            <label htmlFor={id}>{labelValue()}</label>

            <input
              id={id}
              ref={ref}
              type="file"
              hidden
              defaultValue={''}
              onChange={handleChange}
              {...restOfRegistrationProps}
              {...otherProps}
            />
          </SecondaryButton>

          {/* File has been selected or pre-filled */}
          {isFileSelected && (
            <span className={classes.reset}>
              <CloseIcon onClick={clearSelectedImage} />
            </span>
          )}
        </div>
      )}
    </FormControl>
  );
};
