import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { PublisherStatus } from '@common/types/graphql';

import { CLIENT_FIELDS as inputField } from '../fields';

const STATUSES = [
  { value: PublisherStatus.DRAFT, label: 'Draft' },
  { value: PublisherStatus.LIVE, label: 'Live' },
];

export const BasicInformation = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Basic Information">
    <Stack spacing={2}>
      <TextField
        id={inputField.name}
        isLoading={isLoading}
        label="Name"
        placeholder="Enter the client name"
        options={{
          required: 'Client name is required',
        }}
        useHook
      />
      <ControlledSelect
        id={inputField.status}
        isLoading={isLoading}
        label="Status"
        options={STATUSES}
        defaultValue={PublisherStatus.DRAFT}
      />
    </Stack>
  </Card>
);
