import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles({
  brandName: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '20px',
  },
  linkOutContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textDecoration: 'none',
    fontSize: 16,
  },
  productName: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
  },
  divider: {
    height: 16,
  },
  dropDownIcon: {
    padding: 0,
    fontSize: 16,
  },
  linkOut: {
    display: 'flex',
  },
  gridRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  firstColumn: {
    flexGrow: 1,
    maxWidth: '25%',
  },
  copyButton: {
    borderColor: Color.Gray3,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontWeight: 400,
    padding: '5px 10px',
  },
});
