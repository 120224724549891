import { Card } from '@components/Card';
import { AsynchronousAutocomplete } from '@components/Input/__deprecated/AsyncAutocomplete';
import { TextField } from '@components/Input/TextField';
import { useAccountAutocomplete } from '@hooks/useAccountAutocomplete';
import { Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { PRODUCT_COLLECTION_FIELDS as inputField } from '../fields';

type IBasicProps = {
  isLoading?: boolean;

  // TODO: Hacky way to avoid AsynchronousAutocomplete from rendering before input field is set
  // TODO: Optimize AsynchronousAutocomplete component to work better with loading & react-hook-form
  // This allows reusability between edit & create screen!
  hasAccount?: boolean;
};

export const BasicInformation = ({ isLoading, hasAccount = false }: IBasicProps) => {
  const { watch, setValue } = useFormContext();
  const account = watch(inputField.account);

  // GraphQL hook
  const { query: getAccountAutocomplete } = useAccountAutocomplete();

  // Either wait for data to be loaded, or show autocomplete right away (create vs. edit)
  const shouldRenderAccountAutocomplete = !hasAccount || (hasAccount && Boolean(account));

  return (
    <Card title="Basic Information">
      <Stack spacing={2}>
        <TextField
          id={inputField.name}
          isLoading={isLoading}
          label="Name"
          placeholder="Enter the collection name"
          options={{
            required: 'Collection name is required',
          }}
          useHook
        />
        <TextField
          id={inputField.handle}
          isLoading={isLoading}
          label="Handle"
          placeholder="Enter a unique handle"
          options={{
            required: 'Handle is required and needs to be unique',
          }}
          useHook
        />

        {shouldRenderAccountAutocomplete && (
          <AsynchronousAutocomplete
            label={'Account'}
            value={account}
            setValue={(value) => {
              // Value is required - should be handled cleaner when component gets optimized
              // TODO: Add support for required values - see TextField!
              if (value) {
                setValue(inputField.account, value);
              }
            }}
            isOptionEqualToValue={(option, value) => option.publicId === value.publicId}
            getOptionLabel={(option) => option.name}
            getAutocompleteData={getAccountAutocomplete}
          />
        )}
      </Stack>
    </Card>
  );
};
