import { GridColDef } from '@mui/x-data-grid-pro';
import { StyledLink } from '@components/DataGrid/Styled';
import { IMerchant } from '../graphql';
import React from 'react';
import { routes } from '@routes/private';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { ClientStatus, IntegrationType } from '@common/types/GraphqlTypes.d';
import { Color } from '../../../../theme/palette';

/**
 * Style conditions for published stage.
 * @todo: Move this into reusable function outside of columns config.
 */
const statusTagConditions = (publishedStage: string = '') => [
  { type: StatusTagType.Success, match: () => publishedStage === ClientStatus.Live },
  { type: StatusTagType.Warn, match: () => publishedStage === ClientStatus.Draft },
  { type: StatusTagType.Error, match: () => publishedStage === ClientStatus.Archived },
];

/**
 * Style conditions for integration tag.
 */
const integrationTagConditions = (integrationType: IntegrationType) => [
  {
    color: Color.White,
    backgroundColor: Color.Green,
    match: () => integrationType === IntegrationType.Shopify,
  },
  {
    color: Color.White,
    backgroundColor: Color.Blue,
    match: () => integrationType === IntegrationType.Feed,
  },
];

export const columns: GridColDef<IMerchant>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: ({ value, row }) => (
      <StyledLink to={routes.editMerchant.getPath(row._id)}>{value}</StyledLink>
    ),
  },
  {
    field: 'integration',
    headerName: 'Integration',
    flex: 1,
    renderCell: ({ row }) => (
      // TODO: Add conditions for rendering the right colour!
      <StatusTag
        text={row?.private?.credentials?.integrationType ?? 'Unknown'}
        conditions={integrationTagConditions(
          row?.private?.credentials?.integrationType as IntegrationType,
        )}
      />
    ),
    sortable: false,
  },
  {
    field: 'productCount',
    headerName: 'Total SKUs',
    width: 150,
    sortable: false,
  },
  {
    field: 'liveProductCount',
    headerName: 'Live SKUs',
    width: 150,
    sortable: false,
  },
  {
    field: 'stage',
    headerName: 'Stage',
    flex: 1,
    renderCell: ({ row: { publishedStage } = {} }) => (
      <StatusTag
        text={publishedStage as string}
        conditions={statusTagConditions(publishedStage as string)}
      />
    ),
    sortable: false,
  },
  {
    field: 'fee',
    headerName: 'Fee',
    width: 100,
    valueGetter: ({ row: { private: privateInfo } = {} }) => `${privateInfo?.fee}%`,
    sortable: false,
  },
];
