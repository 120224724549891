import { UserIcon } from '@components/UserIcon';
import { useAuth } from '@hooks/useAuthentication';
import useRouter from '@hooks/useRouter';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import { Color } from '../theme/palette';

export const UserInfo = () => {
  const { currentUser } = useAuth();
  const { goto } = useRouter();

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  if (!currentUser) {
    return <UserIcon showName={false} isDefault={true} />;
  }

  const { profile } = currentUser;
  const name = `${profile.firstName} ${profile.lastName}`;

  return (
    <Box onClick={() => goto.viewProfile()} sx={{ cursor: 'pointer' }}>
      <UserIcon
        name={name}
        showName={false}
        isDefault={!name}
        color={isDarkMode ? Color.Gray2 : Color.BackgroundMapleRed}
      />
    </Box>
  );
};
