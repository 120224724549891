import React from 'react';

export type ProductImagesProps = {
  images: string[];
};

/**
 * Displays product image(s).
 */
export const ProductImages = ({ images = [] }: ProductImagesProps) => (
  <>
    TODO: Fix back Carousel (images: {images.length})
    {/*    <Carousel>
      {images.map((image, index) => (
        <div key={`${image}-${index}`}>
          <img
            src={image}
            alt={image}
            key={`product-image-${index}`}
            style={{ width: 'auto', maxHeight: '100px' }}
          />
        </div>
      ))}
    </Carousel>*/}
  </>
);
