import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 16,
  },
  modalButton: {
    fontSize: 13,
    height: 44,
    textTransform: 'none',
    width: 105,
  },
  modalCancelButton: {
    '&:active, &:hover': {
      borderColor: theme.palette.common.black,
    },
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  pageTitle: {
    fontWeight: 600,
  },
}));
