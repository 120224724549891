import React from 'react';

import { getInitials } from '@utils/string';

import { useStyles } from './styles';

interface IUserImageProps {
  name: string;
}

export const UserImage = ({ name }: IUserImageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.image}>{getInitials(name)}</div>
    </div>
  );
};
