import { formatCurrency } from '@utils/currency';

/**
 * Returns range of prices or a single price as a string.
 */
export const getPrices = (
  minPrice: number | null,
  maxPrice: number | null,
  currency: string,
): string => {
  if (!minPrice) {
    return 'No price';
  }

  if (minPrice === maxPrice || !maxPrice) {
    return formatCurrency({ value: minPrice, locale: currency, showCurrency: false });
  }

  // Show range if both are available
  return `${formatCurrency({
    value: minPrice,
    locale: currency,
    showCurrency: false,
  })} - ${formatCurrency({ value: maxPrice, locale: currency, showCurrency: false })}`;
};
