import { PrimaryButton } from '@components/Button/Primary';
import { Form } from '@components/Form';
import { TextField } from '@components/Input/TextField';
import { Logo } from '@components/Logo';
import { useAuth } from '@hooks/useAuthentication';
import useRouter from '@hooks/useRouter';
import { Email as EmailIcon, Lock as LockIcon } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import queryString from 'query-string';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { sendTokenToChromeExtension } from '@lib/chromeExtension';

import { ILoginInput } from '../fields';
import { LOGIN_FIELDS as inputField } from '../fields';
import { useLogin } from './graphql';
import { useStyles } from './styles';

export const Login = () => {
  const classes = useStyles();
  const { goto } = useRouter();

  // Set global context after logging in succesfully
  const { login: recordLogin } = useAuth();

  // Set up React form hook
  const form = useForm<ILoginInput>({
    mode: 'onSubmit',
  });

  // Support for password reset redirect
  const { success } = queryString.parse(useLocation()?.search);

  // GraphQL hooks
  const { loginMutation, apiError, isLoading } = useLogin();

  const onSubmit = (values: ILoginInput) => {
    loginMutation({
      variables: { input: values },
      onCompleted: ({ login }) => {
        // Set JWT token on global authentication context (used for future API calls)
        recordLogin(login.token);

        // Authenticate Bonsai Google Chrome Extension
        sendTokenToChromeExtension(login.token);

        // Redirect to home page
        goto.home();
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error logging in:', error);
      },
    });
  };

  return (
    <div className={classes.root}>
      <div>
        <Logo className={classes.logo} />

        {/* Header */}
        <div>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Sign In
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
            If you don't have an account, please contact Bonsai for access.
          </Typography>
        </div>

        {/* Email & password */}
        <div className={classes.form}>
          <FormProvider {...form}>
            <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <TextField
                  id={inputField.email}
                  isLoading={isLoading}
                  type="email"
                  label="Email address"
                  placeholder="Enter your email address"
                  icon={{
                    position: 'start',
                    value: EmailIcon,
                  }}
                  options={{
                    required: 'Email address is required',
                  }}
                  useHook
                />

                <TextField
                  id={inputField.password}
                  isLoading={isLoading}
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  icon={{
                    position: 'start',
                    value: LockIcon,
                  }}
                  options={{
                    required: 'Password is required',
                  }}
                  useHook
                />
                <Stack direction="row" spacing={1} justifyContent="end">
                  <PrimaryButton className={classes.loginBtn} type="submit" disabled={isLoading}>
                    Login
                  </PrimaryButton>
                </Stack>
              </Stack>
            </Form>
          </FormProvider>
        </div>

        {success && <Alert severity="success">Password Set!</Alert>}

        <Button onClick={() => goto.resetPassword()} className={classes.forgotPasswordBtn}>
          Forgot your password?
        </Button>
      </div>
    </div>
  );
};
