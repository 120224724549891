import { QueryHookOptions } from '@apollo/client';

import { CustomerOrder } from '@common/types/GraphqlTypes.d';
import { useTypedQuery } from '@utils/graphql';

import {
  CUSTOMER_ORDER_BASIC_QUERY,
  CUSTOMER_ORDER_COMMENTS_QUERY,
  CUSTOMER_ORDER_CUSTOMER_QUERY,
  CUSTOMER_ORDER_HISTORY_QUERY,
  CUSTOMER_ORDER_ID_QUERY,
  CUSTOMER_ORDER_PAYMENT_QUERY,
} from './graphl';

export type ICustomerOrder = Pick<
  CustomerOrder,
  | '_id'
  | 'account'
  | 'assignee'
  | 'merchantOrderIds'
  | 'createdAt'
  | 'orderNumber'
  | 'externalOrderId'
  | 'publicId'
  | 'note'
  | 'fulfillmentStatus'
  | 'isTest'
>;

export type ICustomerOrderCustomer = Pick<CustomerOrder, '_id' | 'customer' | 'deliveryAddress'>;
export type ICustomerOrderPayment = Pick<
  CustomerOrder,
  '_id' | 'paymentStatus' | 'paymentMethod' | 'externalPayment'
>;
export type ICustomerOrderComments = Pick<CustomerOrder, '_id' | 'comments'>;
export type ICustomerOrderHistory = Pick<CustomerOrder, '_id' | 'history'>;
export type ICustomerOrderMetadata = Pick<CustomerOrder, '_id' | 'publicId'>;

/**
 * Returns basic customer order ID(s).
 * Mainly used to verify whether an order exists and render the appropriate Not Found result.
 */
export const useCustomerOrderId = (
  id: string,
  options: QueryHookOptions<
    { customerOrder: { _id: string; publicId: string; orderNumber: string } },
    { id: string }
  > = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_ID_QUERY, { id }, options);

  const customerOrder = data?.customerOrder;

  return { customerOrder, error, isLoading };
};

/**
 * Returns basic customer order information.
 * @param {string} id - Customer order ID.
 */
export const useCustomerOrder = (
  id: string,
  options: QueryHookOptions<{ customerOrder: ICustomerOrder }, { id: string }> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_BASIC_QUERY, { id }, options);

  const customerOrder = data?.customerOrder;

  return { customerOrder, error, isLoading };
};

/**
 * Returns customer order customer information.
 * @param {string} id - Customer order ID.
 */
export const useCustomerOrderCustomer = (
  id: string,
  options: QueryHookOptions<{ customerOrder: ICustomerOrderCustomer }, { id: string }> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_CUSTOMER_QUERY, { id }, options);

  const { customer, deliveryAddress } = data?.customerOrder || {};

  return { customer, deliveryAddress, error, isLoading };
};

/**
 * Returns customer order payment information.
 * @param {string} id - Customer order ID.
 */
export const useCustomerOrderPayment = (
  id: string,
  options: QueryHookOptions<{ customerOrder: ICustomerOrderPayment }, { id: string }> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_PAYMENT_QUERY, { id }, options);

  const { paymentMethod, paymentStatus, externalPayment } = data?.customerOrder || {};

  return {
    payment: { paymentMethod, paymentStatus, externalPayment },
    error,
    isLoading,
  };
};

/**
 * Returns list of customer order history events.
 * @param {string} id - Customer order ID.
 */
export const useCustomerOrderHistory = (
  id: string,
  options: QueryHookOptions<{ customerOrder: ICustomerOrderHistory }, { id: string }> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_HISTORY_QUERY, { id }, options);

  const { history } = data?.customerOrder || {};

  return { history, error, isLoading };
};

/**
 * Returns list of customer order comments.
 * @param {string} id - Customer order ID.
 */
export const useCustomerOrderComments = (
  id: string,
  options: QueryHookOptions<{ customerOrder: ICustomerOrderComments }, { id: string }> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useTypedQuery(CUSTOMER_ORDER_COMMENTS_QUERY, { id }, options);

  const { comments } = data?.customerOrder || {};

  return { comments, error, isLoading };
};
