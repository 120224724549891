import { FilterSwitch } from '@components/DataFilter/Switch';
import React, { useEffect, useState } from 'react';

import { useOrderFilters } from '../config/filters';

/**
 * Returns a switch component for the isTest filter.
 * @todo - Look at passing & setting boolean values instead of strings.
 */
export const IsTestFilter = () => {
  const { filters, updateFilters } = useOrderFilters();
  const [isChecked, setIsChecked] = useState<boolean>(filters.isTest === 'true');

  useEffect(() => {
    setIsChecked(filters.isTest === 'true');
  }, [filters.isTest]);

  return (
    <FilterSwitch
      label="Test orders"
      checked={isChecked}
      onUpdate={(value) => updateFilters({ isTest: value.toString() })}
    />
  );
};
