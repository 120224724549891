import { SearchDriverOptions } from '@elastic/search-ui';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

import { ENV } from '@constants/environment';

const { elasticAppSearch } = ENV;

const connector = new AppSearchAPIConnector({
  endpointBase: elasticAppSearch.endpoint,
  searchKey: elasticAppSearch.searchKey,
  engineName: elasticAppSearch.productsEngine,
});

// All viewable product fields
export enum ProductField {
  publicId = 'publicId',
  name = 'name',
  currency = 'currency',
  primaryImage = 'primaryImage',
  url = 'productURL',
  gender = 'gender',
  category = 'category',

  // Product availability
  inventory = 'inventory',
  isUnavailable = 'isUnavailable',
  hasUnavailableVariants = 'hasUnavailableVariants',
  validationFlags = 'validationFlags',

  // Merchant
  merchantId = 'merchant.id',
  merchantName = 'merchant.name',

  // Brand
  brandId = 'brand.id',
  brandName = 'brand.name',

  // Variant price information
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',

  // Variants - nested by default
  variantIds = 'variants.id',

  // Metadata
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

/**
 * App Search configuration.
 */

export const appSearchConfig = (hideDeletedProducts: boolean): SearchDriverOptions => ({
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  initialState: {
    resultsPerPage: 15,
  },
  searchQuery: {
    result_fields: {
      [ProductField.publicId]: { raw: {} },

      // Should be highlighted on matching terms
      [ProductField.name]: {
        snippet: {
          fallback: true,
        },
      },

      [ProductField.primaryImage]: { raw: {} },
      [ProductField.gender]: { raw: {} },
      [ProductField.url]: { raw: {} },
      [ProductField.inventory]: { raw: {} },
      [ProductField.category]: { raw: {} },

      // Merchant
      [ProductField.merchantId]: { raw: {} },
      [ProductField.merchantName]: { raw: {} },

      // Brand
      [ProductField.brandName]: { raw: {} },

      // Variant pricing information
      [ProductField.minPrice]: { raw: {} },
      [ProductField.maxPrice]: { raw: {} },
      [ProductField.currency]: { raw: {} },

      // Metadata
      [ProductField.validationFlags]: { raw: {} },
      [ProductField.updatedAt]: { raw: {} },
    },

    // TODO: Setting weights here does not seem to work?! Had to set relevance through Elastic UI as well
    search_fields: {
      [ProductField.name]: {
        weight: 10,
      },
      [ProductField.publicId]: {
        weight: 10,
      },
    },

    // Allow OR instead of AND
    disjunctiveFacets: [ProductField.merchantName, ProductField.brandName, ProductField.gender],

    // Filters
    facets: {
      [ProductField.validationFlags]: { type: 'value', size: 15 },
      [ProductField.merchantName]: { type: 'value', size: 30 },
      [ProductField.brandName]: { type: 'value', size: 30 },
      [ProductField.gender]: { type: 'value', size: 5 },
    },

    // Filter out deleted and excluded products if configured.
    filters: hideDeletedProducts
      ? [
          {
            field: ProductField.validationFlags,
            type: 'none',
            values: ['isDeleted', 'isExcluded'],
          },
        ]
      : [],
  },

  // TODO: Currently disabled as UI needs to be improved (see index.tsx)
  /*   autocompleteQuery: {
    results: {
      resultsPerPage: 5,
      result_fields: {
        name: {
          raw: {},
          snippet: {
            size: 100,
            fallback: true,
          },
        },
      },
    },
  }, */
});

/**
 * Sorting configuration.
 */

// eslint-disable-next-line array-plural/array-plural
export const appSearchSorting = [
  {
    name: 'Relevance',
    value: '',
    direction: '',
  },
];
