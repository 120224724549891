import { checkoutVariantToShortCutProduct } from '@pages/Order/components/ItemsSelect/utils';
import { Currency } from '@types';

import { MerchantOrder } from '@common/types/GraphqlTypes.d';

import { FEED_ORDER_FIELD, IFeedOrderFormData } from './types';

/**
 * Gets feed order form values from existing order or provided default values.
 * @param feedOrder - An existing feed order.
 * @todo - Proper type for feedOrder!
 * @returns {IFeedOrderFormData} - Feed order form values.
 */
// eslint-disable-next-line complexity
export const getFormDefaultValues = (
  merchantOrder: MerchantOrder,
  feedOrder?: any,
): IFeedOrderFormData => ({
  [FEED_ORDER_FIELD.CURRENCY]: feedOrder?.currency ?? Currency.USD,
  [FEED_ORDER_FIELD.PRODUCTS]:
    feedOrder?.products ?? merchantOrder.checkoutVariants.map(checkoutVariantToShortCutProduct),
  [FEED_ORDER_FIELD.MERCHANT_ORDER_NUMBER]: feedOrder?.merchantOrderNumber ?? '',
  [FEED_ORDER_FIELD.PAYMENT_METHOD]: feedOrder?.paymentMethod ?? '',
  [FEED_ORDER_FIELD.SUBTOTAL]: feedOrder?.subtotal ?? merchantOrder.summary.subTotal,
  [FEED_ORDER_FIELD.SHIPPING]: feedOrder?.shipping ?? 0,
  [FEED_ORDER_FIELD.TAXES]: feedOrder?.taxes ?? 0,
  [FEED_ORDER_FIELD.DISCOUNT]: feedOrder?.discount ?? 0,
  [FEED_ORDER_FIELD.ORDER_CREATION_DATE]: feedOrder?.createdAt ?? new Date(),
  [FEED_ORDER_FIELD.COMMENTS]: feedOrder?.comments ?? '',
});
