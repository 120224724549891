/* eslint-disable max-lines-per-function */
import { Dropdown } from '@components/Input/__deprecated/Dropdown';
import { Typography, TextField } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { formatToMonthDayYear as formatDate } from '@utils/date';

import { IFeedOrderFormData, FEED_ORDER_FIELD } from '../../types';
import { useStyles } from './styles';

interface IDetailsProps {
  formValidationHook: UseFormReturn<IFeedOrderFormData>;
  merchantOrderIds: string[];
}

const paymentMethods = [
  { label: 'BREX USD \u002A6206', value: '6206' },
  { label: 'Stylight Mastercard \u002A1870', value: '1870' },
  { label: 'Gift card', value: 'Gift Card' },
];

export const Details: React.FC<IDetailsProps> = ({ formValidationHook }) => {
  const classes = useStyles();

  const { getValues, register } = formValidationHook;

  return (
    <div className={classes.details}>
      <div className={classes.detail}>
        <Typography variant="caption">Order Creation Date</Typography>
        <TextField
          size="small"
          fullWidth
          className={classes.textField}
          value={formatDate(getValues(FEED_ORDER_FIELD.ORDER_CREATION_DATE))}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className={classes.detail}>
        <Typography variant="caption">Merchant Order No.</Typography>
        <TextField
          size="small"
          fullWidth
          className={classes.textField}
          //inputRef={merchantOrderNumberRef}
          {...register(FEED_ORDER_FIELD.MERCHANT_ORDER_NUMBER)}
        />
      </div>
      <div className={classes.detail}>
        <Typography variant="caption">Comments</Typography>
        <TextField
          size="small"
          fullWidth
          className={classes.textField}
          {...register(FEED_ORDER_FIELD.COMMENTS)}
        />
      </div>
      <div className={classes.detail}>
        <Typography variant="caption">Payment Method</Typography>
        <Dropdown
          className={classes.dropdown}
          defaultValue={getValues(FEED_ORDER_FIELD.PAYMENT_METHOD)}
          items={paymentMethods}
          placeholder="Select payment"
          registrationProps={register(FEED_ORDER_FIELD.PAYMENT_METHOD)}
        />
      </div>
    </div>
  );
};
