import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import useRouter from '@hooks/useRouter';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BasicInformation } from '../components/Basic';
import { Permissions } from '../components/Permissions';
import { IRoleInput } from '../fields';
import { useCreateRole } from './graphql';
import { CreateRoleHeader } from './Header';

export const CreateRole = () => {
  const { goto } = useRouter();

  // Set up React form hook
  const form = useForm<IRoleInput>({
    mode: 'onSubmit',
  });

  // GraphQL hooks
  const { createRoleMutation, apiError } = useCreateRole();

  const onSubmit = (values: IRoleInput) => {
    createRoleMutation({
      variables: { input: values },
      onCompleted: ({ createRole }) => {
        goto.editRole(createRole._id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error create role:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listRoles();
  };

  return (
    <Stack gap={3}>
      <CreateRoleHeader />

      <FormProvider {...form}>
        <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Stack gap={3}>
                <BasicInformation />
                <Permissions />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Create role</PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};
