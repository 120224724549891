import { Header } from '@components/Header';
import React from 'react';

import { Merchant } from '@common/types/GraphqlTypes.d';

import { MerchantsActions } from './Actions';

type ActionsProps = {
  merchant?: Merchant;
  isLoading: boolean;
};

export const EditMerchantHeader = ({ merchant, isLoading }: ActionsProps) => (
  <Header
    title={`Merchant - ${merchant?.name}`}
    actions={<MerchantsActions merchant={merchant} />}
    isLoading={isLoading}
  />
);
