import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import { getUniqueId } from '@utils/id';

const DEFAULT_QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
};

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'initial',
    overflowX: 'auto',
    backgroundColor: Color.White,
    marginTop: '0 !important',
    '& .ql-container': {
      minHeight: 80,
      height: 'initial',
      fontSize: '1em',
    },
    '& .ql-toolbar': {
      borderBottom: 'none',
    },
    '& .ql-editor.ql-blank::before': {
      fontStyle: 'normal',
    },
    '& .ql-tooltip': {
      zIndex: 999,
    },
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    fontSize: '1.1rem',
    marginBottom: '5px',
  },
}));

type TextEditorProps = {
  /**
   * The ID of the input field.
   * Mainly useful for react-hook-form.
   */
  id?: string;

  /**
   * Support for React-hook-form.
   */
  useHook?: boolean;

  /**
   * Support for optional react-hook-form options.
   */
  options?: RegisterOptions;

  /**
   * Placeholder text to display when the editor is empty.
   */
  placeholder?: string;

  /**
   * Optional label to display above the editor.
   */
  label?: string;

  /**
   * Optional classname to allow custom styling or logic.
   */
  className?: string;

  /**
   * Indicates if the editor is loading.
   */
  isLoading?: boolean;

  /**
   * Optional Quill modules. No types available.
   * See https://quilljs.com/docs/modules/ for all options.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: { [key: string]: any };
};

/**
 * Returns text editor component.
 */
export const TextEditor = ({
  id = getUniqueId(),
  placeholder,
  className,
  options,
  label,
  isLoading = false,
  useHook = false,
  modules = DEFAULT_QUILL_MODULES,
}: TextEditorProps) => {
  const classes = useStyles();

  // React-hook-form support (best practices)
  // Even if register is not used it has to be called since it's a React hook. Bunch of {} defaults in case forms aren't used
  const { register, setValue, watch } = useFormContext() || {};

  // React-hook-form support (watch value)
  const content = watch(id);

  // Only register once
  useEffect(() => {
    if (useHook) {
      register(id, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueChange = (content: string) => {
    // React-hook-form support (update value)
    if (useHook) {
      setValue(id, content, { shouldDirty: true });
    }
  };

  return isLoading ? (
    <Skeleton height="36px" />
  ) : (
    <>
      <InputLabel shrink htmlFor={id} className={classes.label}>
        {label}
      </InputLabel>
      <ReactQuill
        className={clsx([classes.root, className])}
        placeholder={placeholder}
        modules={modules}
        theme="snow"
        value={content}
        onChange={valueChange}
      />
    </>
  );
};
