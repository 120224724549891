/* eslint-disable complexity */
import { Copy } from '@components/Copy';
import { Dropdown } from '@components/Input/__deprecated/Dropdown';
import { Info } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import clsx from 'clsx';
import { groupBy, prop } from 'ramda';
import React, { useState } from 'react';

import { IProduct } from '../View/graphql';

const useStyles = makeStyles((theme) => ({
  colorButton: {
    padding: '10px 20px',
    border: `1px solid ${theme.palette.primary.main}`,

    '&.active': {
      backgroundColor: Color.Gray3,
    },
  },

  productContainer: {
    marginTop: 4,
  },

  sku: {
    fontSize: '0.8rem',
    color: Color.DarkNight,
  },

  disclaimer: {
    fontSize: '0.8rem',
    color: Color.Gray1,

    '& svg': {
      fontSize: '1.2rem',
      marginRight: 3,
    },
  },
}));

export type ProductVariantProps = {
  variants: IProduct['variants'];
  onVariantChange?: (variantId: string) => void;
};

type VariantInfo = {
  sku: string | null; // Combined color + size variant SKU
  variantId: string; // Combined color + size variant ID
  color: string;
  size: string | null;
  optionTitles: string[];
  optionValues: string[];
  options: Record<string, any>;
  // Availability
  isUnavailable: boolean;
  isDeleted: boolean;
};

const checkColor = (variants: IProduct['variants']) =>
  variants.map(({ option, ...rest }) => ({
    ...rest,
    option: {
      ...option,
      color: option.color || 'o/s',
    },
  }));

/**
 * Indicates through option label whether there's an issue with the label.
 */
const getOptionLabel = (size: string | null, isDeleted: boolean, isUnavailable: boolean) => {
  if (isDeleted) {
    return `${size} - Deleted by merchant`;
  }

  if (isUnavailable) {
    return `${size} - Made unavailable by Bonsai`;
  }

  if (!size) {
    return 'No size';
  }

  return size;
};

const getOptionVariants = (variants: IProduct['variants'], color: string): VariantInfo[] =>
  variants
    .filter(({ option }) => option?.color === color)
    .reduce<VariantInfo[]>(
      (
        acc,
        { importVariantId, option, sku, isDeleted, isUnavailable, optionTitles, optionValues },
      ) => {
        const options = optionTitles.reduce(
          (previous, current, index) =>
            current === 'color' ? previous : { ...previous, [current]: optionValues[index] },
          {},
        );

        return [
          ...acc,
          {
            variantId: importVariantId,
            sku: sku || null,
            color: option?.color || 'o/s',
            size: option?.size || null,
            optionTitles,
            optionValues,
            options,
            isUnavailable,
            isDeleted,
          },
        ];
      },
      [],
    );

/*Filters unique option values */
const getDropdownItems = (availableOptionVariants: VariantInfo[], option: string) => {
  const uniqueOptionValues = new Set();

  return availableOptionVariants
    .map(({ options, variantId, isUnavailable, isDeleted }) => ({
      label: getOptionLabel(options[option], isDeleted, isUnavailable),
      value: variantId,
      disabled: isUnavailable || isDeleted,
    }))
    .filter(({ label }) => {
      if (uniqueOptionValues.has(label)) {
        return false;
      }
      uniqueOptionValues.add(label);

      return true;
    });
};

/**
 * Displays product variants.
 */
export const ProductVariants = ({ variants, onVariantChange }: ProductVariantProps) => {
  const classes = useStyles();
  const [selectedVariant, setSelectedVariant] = useState<VariantInfo>();
  const [availableOptionVariants, setAvailableOptionVariants] = useState<VariantInfo[]>([]);

  variants = checkColor(variants);
  // TODO: Sort sizes & chain map using Ramda for better readability!
  const groupedVariants = groupBy(
    prop('color'),
    variants.map(
      ({ importVariantId, sku, option, isDeleted, isUnavailable, optionTitles, optionValues }) => ({
        variantId: importVariantId,
        sku: sku || null,
        color: option.color || 'o/s', // Optional color (show o/s for UI purposes)
        size: option.size || null, // Optional size
        optionTitles,
        optionValues,
        // Availability
        isUnavailable,
        isDeleted,
      }),
    ),
  );

  // If SKU is the same as variant ID, no need to show both
  const isVariantIdSKU = selectedVariant && selectedVariant.sku === selectedVariant.variantId;

  /**
   * Returns specific variant information (options & IDs) based on color.
   */
  const getAvailableOptionVariants = (color: string): VariantInfo[] =>
    getOptionVariants(variants, color);

  /**
   * Returns specific size value based on color & variant ID.
   */
  const getOptionVariant = (variantId: string, color?: string): VariantInfo | undefined => {
    if (!color) {
      return;
    }

    return getAvailableOptionVariants(color).find((variant) => variant.variantId === variantId);
  };

  /**
   * Handles changing color variant.
   * Takes first variant & ID.
   */
  const handleColorChange = (color: string) => {
    const optionVariants = getAvailableOptionVariants(color);
    const [variant] = optionVariants;

    // No color change
    if (color && color === selectedVariant?.color) {
      return;
    }

    // Set first for better UX purposes
    setSelectedVariant(variant);

    // Set all available options for this color variant
    setAvailableOptionVariants(optionVariants);

    // Trigger callback towards parent in case variant-specific behaviour is required
    if (onVariantChange) {
      onVariantChange(variant.variantId);
    }
  };

  /**
   * Handles option changes.
   * @todo - // TODO Add support to indicate `isUnavailable` or `isDeleted` displayed next to the variant!
   */
  const handleOptionChange = (variantId: string) => {
    const currentVariant = selectedVariant;

    // No option change
    if (variantId && variantId === currentVariant?.variantId) {
      return;
    }

    // Fetch enire variant based on color & ID
    const variant = getOptionVariant(variantId, currentVariant?.color);

    // Can only happen if a variant was found & color selected
    if (variant) {
      setSelectedVariant(variant);

      // Trigger callback towards parent in case variant-specific behaviour is required
      if (onVariantChange) {
        onVariantChange(variant.variantId);
      }
    }
  };

  // On initial load - already set first variant
  if (!selectedVariant && groupedVariants) {
    const [firstColorVariant] = Object.keys(groupedVariants);

    handleColorChange(firstColorVariant);
  }

  const additionalVariantOptions =
    selectedVariant?.optionTitles?.filter((title) => title !== 'color') || [];

  return (
    <Stack direction="column" spacing={1.5}>
      {/* Show all color variants */}
      <div className="product-variant-colors">
        <span>Color</span>

        <Stack direction="row" spacing={1} flexWrap="wrap">
          {Object.keys(groupedVariants).map((colorVariant, index) => (
            <div key={`${colorVariant}-${index}`}>
              <Button
                onClick={() => handleColorChange(colorVariant)}
                className={clsx([
                  classes.colorButton,
                  selectedVariant?.color === colorVariant ? 'active' : '',
                ])}
              >
                {colorVariant}
              </Button>
            </div>
          ))}
        </Stack>
      </div>

      {/* Show all additional options if available - will be `null` if not a single color variant was assigned during color change */}
      {/* TODO: Swap below with a Form select component!! */}

      {selectedVariant &&
        additionalVariantOptions &&
        additionalVariantOptions.map((option) => (
          <div className="product-variant-sizes" key={option}>
            <span>{option.charAt(0).toUpperCase() + option.slice(1)}</span>
            <div className={classes.productContainer}>
              <Dropdown
                items={getDropdownItems(availableOptionVariants, option)}
                defaultValue={selectedVariant.variantId}
                onChange={(event) => handleOptionChange(event.target.value)}
              />
            </div>
          </div>
        ))}

      {/* Indicate current selection */}
      {selectedVariant && (
        <Stack className={classes.sku} direction="row" gap={3}>
          {/* Variant ID */}
          <Stack direction="row" gap={0.5}>
            <span>
              <b>Variant ID{isVariantIdSKU && <> / SKU</>}</b>:
            </span>
            {<Copy value={selectedVariant.variantId} />}
          </Stack>

          {/* Optional SKU (can be null) */}
          {selectedVariant.sku && !isVariantIdSKU && (
            <Stack direction="row" gap={0.5}>
              <span>
                <b>SKU</b>:
              </span>
              {<Copy value={selectedVariant.sku} />}
            </Stack>
          )}
        </Stack>
      )}

      {/* Disclaimer */}
      <Stack direction="row" spacing={1} alignItems="center" className={classes.disclaimer}>
        <Info />
        Selecting a specific variant might reflect potential changes in stock, price, images &
        issues
      </Stack>
    </Stack>
  );
};
