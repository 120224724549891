import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from '@hooks/useSnackbar';

const UPLOAD_IMAGES_MUTATION = gql`
  mutation uploadImages($files: [UploadImagesFilesInput!]!) {
    uploadImages(files: $files) {
      id
      url
      name
      originalName
    }
  }
`;

/**
 * Hook for uploading images.
 * Automatically handles existing image URLs to avoid re-uploading.
 * Returns `null` when no image is selected.
 */
export const useUploadImage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadImagesMutation, { error: apiError, loading: isLoading }] = useMutation<{
    uploadImages: IUploadedImage[];
  }>(UPLOAD_IMAGES_MUTATION);

  const uploadImage = (name: string, value?: File[] | string | null): Promise<string | null> =>
    new Promise((resolve) => {
      // Only occurs for existing images as they are Sharpener URLs
      if (typeof value === 'string') {
        return resolve(value);
      }

      // No file selected (reset)
      if (!value || (value || []).length === 0) {
        return resolve(null);
      }

      uploadImagesMutation({
        variables: {
          files: {
            // eslint-disable-next-line array-plural/array-plural
            file: value[0],
            name,
          },
        },
        onCompleted: ({ uploadImages = [] }) => {
          const [{ url }] = uploadImages;

          resolve(url);
        },
        onError: (error) => {
          enqueueSnackbar(`Error uploading image: ${error.message}`, { variant: 'error' });
        },
      });
    });

  return { uploadImage, isLoading, apiError };
};

/**
 * Interfaces.
 */
export interface IUploadedImage {
  id: string;
  url: string;
  name: string;
  originalName: string;
}
