import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { ServerParseError } from '@apollo/client/link/http';
import { ServerError } from '@apollo/client/link/utils';
import { createUploadLink } from 'apollo-upload-client';

import { ENV } from '@constants/environment';

import { JWT } from './utils';

const { graphQlUrl: uri } = ENV;

export const httpLink = new HttpLink({ uri });
export const uploadLink = createUploadLink({
  uri,
  headers: {
    'x-apollo-operation-name': 'UploadFile',
  },
}) as any;

export const authLink = setContext((_, { headers }) => {
  const jwt = JWT.get();

  return {
    headers: {
      ...headers,
      authorization: jwt ? `Bearer ${jwt}` : '',
    },
  };
});

const logoutLink = onError(({ networkError }: ErrorResponse) => {
  if (networkError && (networkError as ServerError | ServerParseError)?.statusCode === 401) {
    JWT.remove();
  }
});

export const apolloLink = ApolloLink.from([logoutLink, authLink, uploadLink]);
