export const ENV = {
  nodeEnv: process.env.NODE_ENV,
  apolloClientName: process.env.APOLLO_CLIENT_NAME,
  graphQlUrl: process.env.GRAPHQL_URL,
  launchDarklyClientId: process.env.LAUNCHDARKLY_CLIENT_ID || '',
  materialUiProLicense: process.env.MATERIAL_UI_PRO_LICENSE || '',
  stripeClientCA: process.env.STRIPE_CLIENT_ID_CA || '',
  stripeClientUS: process.env.STRIPE_CLIENT_ID_US || '',

  // Orders API
  ordersApiUrl: process.env.ORDERS_API_URL,

  // Bonsai UI Hosted URL
  bonsaiUIHostedUrl: process.env.BONSAI_UI_HOSTED_URL,

  // Elastic App Search
  elasticAppSearch: {
    endpoint: process.env.ELASTIC_APP_SEARCH_ENDPOINT || '',
    searchKey: process.env.ELASTIC_APP_SEARCH_KEY || '',
    productsEngine: process.env.ELASTIC_APP_SEARCH_PRODUCTS_ENGINE_V2 || '',
  },
};
