import { FilterSelect } from '@components/DataFilter/Select';
import React from 'react';

import { AccountStatus, ClientStatus } from '@common/types/GraphqlTypes.d';

import { useAccountFilters } from '../config/filters';

const options = [
  {
    label: 'Live',
    value: ClientStatus.Live,
  },
  {
    label: 'Archived',
    value: ClientStatus.Archived,
  },
];

export const StatusFilter = () => {
  const { filters, updateFilters } = useAccountFilters();

  return (
    <FilterSelect
      placeholder="Status"
      options={options}
      value={filters.status}
      onUpdate={(status: AccountStatus) => updateFilters({ status })}
    />
  );
};
