import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import { useUploadImage } from '@hooks/useImageUpload';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { NotFound } from '../../Misc/NotFound';
import { BasicInformation } from '../components/Basic';
import { BillingConfig } from '../components/BillingConfig';
import { EmailConfig } from '../components/EmailProvider';
import { Metadata } from '../components/Metadata';
import { OrderConfig } from '../components/OrderConfig';
import { Preferences } from '../components/Preferences';
import { IAccount, useAccount, useEditAccount } from './graphql';
import { EditAccountHeader } from './Header';
import { getInputValues, toInputValues } from './utils';

type IEditAccountParams = { id: string };

const EditAccount = ({ id }: IEditAccountParams) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IAccount>({
    mode: 'onSubmit',
  });

  // Track when fields got updated
  const { isDirty: hasFormChanged } = useFormState({
    control: form.control,
  });

  // GraphQL hooks
  const { isLoading, data } = useAccount({
    variables: { publicId: id },
    onCompleted: ({ accountByPublicId }) => {
      const values = getInputValues(accountByPublicId);

      form.reset(values); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching account:', error);
    },
  });

  const { updateAccountMutation, apiError: updateApiError } = useEditAccount();
  const { uploadImage } = useUploadImage();

  /**
   * Submit form values.
   * Includes file uploading.
   * @todo - Fix `asAccount` casting for setting footer & header.
   */
  const onSubmit = async (values: IAccount) => {
    // 1. Upload images
    const logo = await uploadImage('logo', values.config.logo);
    const footer = await uploadImage('footer', values.config.footer);
    const favicon = await uploadImage('favicon', values.config.favicon);
    const cartTitleImage = await uploadImage('cartTitleImage', values.config.cartTitleImage);

    // TODO: Once FileInput has validation, this will not be needed anymore
    if (!logo || !footer) {
      return enqueueSnackbar('Header & footer are required', { variant: 'error' });
    }

    const updateValues = toInputValues(
      R.mergeDeepRight(values, {
        config: { logo, footer, favicon, cartTitleImage },
      }) as IAccount,
    );

    // 2. Update account values
    updateAccountMutation({
      variables: { id, input: updateValues },
      onCompleted: () => {
        enqueueSnackbar('Successfully updated account');

        // Set values to submitted values (resets dirty state)
        form.reset(values);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating account:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listAccounts();
  };

  // Account could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <Stack gap={3}>
      <EditAccountHeader isLoading={isLoading} account={data} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8}>
              <Stack gap={3}>
                <BasicInformation isLoading={isLoading} />
                <BillingConfig isLoading={isLoading} />
                <OrderConfig isLoading={isLoading} />
                <EmailConfig isLoading={isLoading} />
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Stack gap={3}>
                <Preferences isLoading={isLoading} />
                <Metadata isLoading={isLoading} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={isLoading || !hasFormChanged}>
                  Save changes
                </PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};

export const EditAccountPage = withParams<IEditAccountParams>(['id'], EditAccount);
