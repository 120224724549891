import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

/**
 * Hook for logging in a user.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useLogin = (options: MutationHookOptions = {}) => {
  const [loginMutation, { error: apiError, loading: isLoading }] = useMutation<{
    login: { token: string };
  }>(LOGIN_MUTATION, options);

  return { loginMutation, isLoading, apiError };
};
