import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    '&:active, &:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[50],
    },
    backgroundColor: theme.palette.grey[300],
    textTransform: 'none',
    width: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    rowGap: theme.spacing(3),
  },
  divider: {
    margin: '0 -32px',
  },
}));
