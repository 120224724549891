import { Card } from '@components/Card';
import { DataGridV2 } from '@components/DataGrid';
import { StyledLink } from '@components/DataGrid/Styled';
import { ISorting, SortOrder, useSorting } from '@hooks/useDataStore/useSorting';
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { routes } from '@routes/private';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Role } from '@common/types/GraphqlTypes.d';

import { USER_FIELDS as inputField } from '../../fields';
import { IUserRole, useUserRoles, useUserRolesCount } from './graphql';

const columns: GridColDef<Role>[] = [
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) =>
      row._id && <StyledLink to={routes.editRole.getPath(row._id)}>{value}</StyledLink>,
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    valueGetter: ({ row }) => row?.description,
    flex: 1,
  },
];

const DEFAULT_SORTING: ISorting = {
  sortField: 'name',
  sortOrder: SortOrder.Asc,
};

export const UserRoles = ({ isLoading }: { isLoading?: boolean }) => {
  const { setValue, watch } = useFormContext();
  const { getDataGridSort, updateDataGridSort, getGraphQLSort } = useSorting(
    DEFAULT_SORTING,
    'userRoles',
  );

  // Links react-hook-form value to checkboxes
  const selectionModel = watch(inputField.userRoles) || [];

  // Whenever a checkbox gets selected. Returns entire list of grid IDs (role IDs)
  // TODO: Since we require at least 1 role, this is a happy coincidence. However, it does not work without this check during the initial render!
  const onSelectionModelChange = (value: GridRowId[]) => {
    if (value.length > 0) {
      setValue(inputField.userRoles, value, { shouldDirty: true });
    }
  };

  const { data, isLoading: isRolesLoading } = useUserRoles({
    variables: {
      input: {
        ...getGraphQLSort(),
      },
    },
  });

  /**
   * Fetches total row count for pagination separately from the main query.
   * Avoids blocking the main query with a `count` query which is often an expensive operation.
   * Does not get called unless filters change (Ignores pagination & sorting).
   */
  const { data: totalRowCount } = useUserRolesCount({
    variables: {
      input: {},
    },
  });

  return (
    <Card title="Roles" isLoading={isLoading} noPadding>
      <DataGridV2
        checkboxSelection={true}
        rows={data}
        columns={columns}
        loading={isRolesLoading}
        rowCount={totalRowCount}
        getRowId={(role: IUserRole) => role._id as string}
        pagination={false}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionModelChange}
        onSortChange={updateDataGridSort}
        initialState={{
          sorting: getDataGridSort(),
        }}
      />
    </Card>
  );
};
