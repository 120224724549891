import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Merchant, QueryMerchantsArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within BRANDS_QUERY.
export type IMerchant = Pick<
  Merchant,
  '_id' | 'name' | 'publishedStage' | 'liveProductCount' | 'productCount' | 'private'
>;

const MERCHANTS_QUERY = gql`
  query merchants($input: MerchantListInput!) {
    merchants(input: $input) {
      items {
        _id
        name
        publishedStage
        liveProductCount
        productCount
        private {
          fee
          credentials {
            integrationType
          }
        }
      }
      total
    }
  }
`;

/**
 * Hook for fetching merchants.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useMerchants = (
  options: QueryHookOptions<
    { merchants: { items: IMerchant[]; total: number } },
    QueryMerchantsArgs
  >,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ merchants: { items: IMerchant[]; total: number } }, QueryMerchantsArgs>(
    MERCHANTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const { items: data, total } = apiResult?.merchants ?? {};

  return { data, total, isLoading, apiError };
};
