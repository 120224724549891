import { Card } from '@components/Card';
import { DataGridV2 } from '@components/DataGrid';
import { GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { IPermission, userPermissions } from '@types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ROLE_FIELDS as inputField } from '../fields';

const columns: GridColDef<IPermission>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
];

export const Permissions = ({ isLoading }: { isLoading?: boolean }) => {
  const { getValues, setValue } = useFormContext();
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

  // Fetch permissions from form values
  const permissions = getValues(inputField.permissions);

  // All available permissions (shared across FE & API)
  // Map `_id` as every row needs a unique id and DataGrid does some funky stuff with getRowId
  const availablePermissions = Object.values(userPermissions).map((x) => ({ ...x, _id: x.key }));

  // After permissions get loaded, set initial selected checkboxes. (row ID = permission key)
  useEffect(() => {
    setSelectionModel(permissions || []);
  }, [permissions]);

  // Whenever a checkbox gets selected. Returns entire list of grid IDs (permission keys)
  const onSelectionModelChange = (value: GridRowId[]) => {
    // Update UI form checkbox
    setSelectionModel(value);

    // Update react-hook-form value - trigger dirty change (affects save button)
    setValue(inputField.permissions, value, { shouldDirty: true });
  };

  return (
    <Card title="Permissions" isLoading={isLoading} noPadding>
      {!isLoading && (
        <DataGridV2
          checkboxSelection={true}
          rows={availablePermissions}
          columns={columns}
          loading={isLoading}
          rowCount={availablePermissions.length}
          getRowId={(permission: IPermission & { _id: string }) => permission._id} // Id gets added (see availablePermissions)
          pagination={false}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
        />
      )}
    </Card>
  );
};
