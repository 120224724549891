import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

import { getProductUiUrl } from '@utils/productUi';

interface IOpenProductInProductUIItemProps {
  productPublicId: string;
}

export const OpenProductInProductUI: React.FC<IOpenProductInProductUIItemProps> = ({
  productPublicId,
}) => {
  const { handleClose } = useDropDownMenu();

  const handleOpenProductInProductUI = () => {
    window.open(getProductUiUrl(productPublicId), '_blank');
    handleClose();
  };

  return (
    <MenuItem onClick={handleOpenProductInProductUI}>
      <Typography variant="subtitle2">View in Product UI</Typography>
    </MenuItem>
  );
};
