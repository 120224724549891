import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { Client, QueryClientsArgs, QueryClientsCountArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within CLIENTS_QUERY.
export type IClient = Pick<Client, '_id' | 'accounts' | 'name' | 'status'>;

const CLIENTS_QUERY = gql`
  query clients($input: ClientsInput!) {
    clients(input: $input) {
      _id
      accounts {
        _id
        publicId
        name
        source
      }
      name
      status
    }
  }
`;

const CLIENTS_COUNT_QUERY = gql`
  query clientsCount($input: ClientsCountInput!) {
    clientsCount(input: $input) {
      total
    }
  }
`;

/**
 * Hook for fetching clients.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useClients = (options: QueryHookOptions<{ clients: IClient[] }, QueryClientsArgs>) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ clients: IClient[] }, QueryClientsArgs>(CLIENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { clients: data = [] } = apiResult ?? {};

  return { data, isLoading, apiError };
};

/**
 * Hook for fetching clients total count.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useClientsCount = (
  options: QueryHookOptions<{ clientsCount: { total: number } }, QueryClientsCountArgs>,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ clientsCount: { total: number } }, QueryClientsCountArgs>(CLIENTS_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { total: data = 0 } = apiResult?.clientsCount ?? {};

  return { data, isLoading, apiError };
};
