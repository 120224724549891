import { GridColDef } from '@mui/x-data-grid-pro';
import { StyledLink } from '@components/DataGrid/Styled';
import { IClient } from '../graphql';
import { routes } from '@routes/private';
import React from 'react';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { ClientStatus } from '@common/types/GraphqlTypes.d';

/**
 * Style conditions for published stage.
 * @todo: Move this into reusable function outside of columns config.
 */
const statusTagConditions = (publishedStage: string = '') => [
  { type: StatusTagType.Success, match: () => publishedStage === ClientStatus.Live },
  { type: StatusTagType.Warn, match: () => publishedStage === ClientStatus.Draft },
  { type: StatusTagType.Error, match: () => publishedStage === ClientStatus.Archived },
];

export const columns: GridColDef<IClient>[] = [
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) => (
      <StyledLink to={routes.editClient.getPath(row._id)}>{value}</StyledLink>
    ),
    flex: 1,
  },
  {
    field: 'accounts',
    headerName: 'Usage',
    // TODO: Reimpliment new dropdown cell component
    renderCell: ({ value: accounts = [] }) => {
      const { length: accountsCount = 0 } = accounts;

      return <>{accountsCount} account(s)</>;
    },
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => <StatusTag text={value} conditions={statusTagConditions(value)} />,
    sortable: false,
    flex: 1,
  },
];
