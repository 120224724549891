import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  menuItemText: {
    cursor: 'pointer',
    fontWeight: 600,
    marginRight: 20,
  },
  fulfillmentStatus: {
    marginBottom: 10,
  },
}));
