import Stack from '@mui/material/Stack';
import { AccountQuickFilter } from '@pages/Account/Overview/config/filters';
import { OrderQuickFilter } from '@pages/CustomerOrderV2/Overview/config/filters';
import { MerchantQuickFilter } from '@pages/Merchant/Overview/config/filters';
import { PrivateRoutes } from '@routes/private';
import { PublicRoutes } from '@routes/public';
import React from 'react';

import { MenuItemGroup, MenuItemGroupProps } from './MenuItem';

const routes = { ...PrivateRoutes, ...PublicRoutes };

const menuItems: MenuItemGroupProps[] = [
  {
    title: 'Orders',
    items: [
      {
        title: 'My orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.AssignedToMe,
      },
      {
        title: 'All orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.All,
      },
      {
        title: 'Escalated orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.Escalated,
      },
      {
        title: 'Paid orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.Paid,
      },
      {
        title: 'Delayed orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.Delayed,
      },
      {
        title: 'Test orders',
        route: routes.listOrdersV2,
        quickFilter: OrderQuickFilter.Tests,
      },
    ],
  },
  {
    title: 'Merchants',
    route: routes.listMerchants,
    quickFilter: MerchantQuickFilter.NonArchived,
  },
  {
    title: 'Brands',
    route: routes.listBrands,
  },
  {
    title: 'Products',
    items: [
      {
        title: 'All products',
        route: routes.listProducts,
      },
      {
        title: 'Collections',
        route: routes.listProductCollections,
      },
    ],
  },
  {
    title: 'Accounts',
    items: [
      {
        title: 'All accounts',
        route: routes.listAccounts,
        quickFilter: AccountQuickFilter.NonArchived,
      },
      {
        title: 'Clients',
        route: routes.listClient,
      },
    ],
  },
  {
    title: 'Users',
    items: [
      {
        title: 'All users',
        route: routes.listUsers,
      },
      {
        title: 'Roles',
        route: routes.listRoles,
      },
    ],
  },
];

export const TopMenu = () => (
  <Stack sx={{ pl: 3, pr: 3 }} direction="row" gap={2}>
    {menuItems.map((item, index) => (
      <MenuItemGroup key={`rootmenu_${index}`} {...item} />
    ))}
  </Stack>
);
