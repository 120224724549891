/* eslint-disable max-lines-per-function */
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  detailsButton: {
    backgroundColor: 'white',
    borderStyle: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonPrompt: {
    fontSize: 15,
    fontWeight: 600,
    marginRight: 8,
    display: 'flex',
  },
  spaceBetween: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  marginBottomFontWeight: {
    marginBottom: 4,
    fontWeight: 'bold',
  },
  col2Card: {
    boxShadow: 'none',
  },
  group: { flexGrow: '1' },
});
