import { useDropDownMenu } from '@components/Button/Dropdown';
import { CUSTOMER_ORDER_QUERY } from '@hooks/useCustomerOrder';
import { useSnackbar } from '@hooks/useSnackbar';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

import { useCloseEscalation, useReopenEscalation } from './graphql';
import { useStyles } from './styles';

interface IEscalationMenuItemProps {
  customerOrderId: string;
  commentId: string;
}

export const ReOpenEscalationMenuItem: React.FC<IEscalationMenuItemProps> = ({
  customerOrderId,
  commentId,
}) => {
  const classes = useStyles();
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const { reopenEscalationMutation } = useReopenEscalation({
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Escalation reopened');
    },
    onError: (error) => {
      enqueueSnackbar(`Escalation failed to reopen with error: ${error.message}`, {
        variant: 'error',
      });
    },
    refetchQueries: [CUSTOMER_ORDER_QUERY],
  });

  const handleClick = () => {
    reopenEscalationMutation({
      variables: { customerOrderId, commentId },
    });
  };

  return (
    <MenuItem onClick={handleClick}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        Reopen Escalation
      </Typography>
    </MenuItem>
  );
};

export const CloseEscalationMenuItem: React.FC<IEscalationMenuItemProps> = ({
  customerOrderId,
  commentId,
}) => {
  const classes = useStyles();
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const { closeEscalationMutation } = useCloseEscalation({
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Escalation closed');
    },
    onError: (error) => {
      enqueueSnackbar(`Escalation failed to close with error: ${error.message}`, {
        variant: 'error',
      });
    },
    refetchQueries: [CUSTOMER_ORDER_QUERY],
  });

  const handleClick = () => {
    closeEscalationMutation({
      variables: { customerOrderId, commentId },
    });
  };

  return (
    <MenuItem onClick={handleClick}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        Close Escalation
      </Typography>
    </MenuItem>
  );
};
