import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { QueryProductCollectionArgs } from '@common/types/GraphqlTypes.d';

export type IProductCollection = {
  name: string;
  handle: string;
  publicId: string;
  productIds: string[];
  account: {
    publicId: string;
    name: string;
  };
};

const PRODUCT_COLLECTION_QUERY = gql`
  query productCollection($_id: String!) {
    productCollection(_id: $_id) {
      name
      handle
      publicId
      productIds
      account {
        publicId
        name
      }
    }
  }
`;

const UPDATE_PRODUCT_COLLECTION_MUTATION = gql`
  mutation updateProductCollection($_id: String!, $input: ProductCollectionInput!) {
    updateProductCollection(_id: $_id, input: $input) {
      _id
    }
  }
`;

/**
 * Hook for fetching product collection.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Do not use IMerchantData interface - define custom one!
 */
export const useProductCollection = (
  options: QueryHookOptions<{ productCollection: IProductCollection }, QueryProductCollectionArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productCollection: IProductCollection }, QueryProductCollectionArgs>(
    PRODUCT_COLLECTION_QUERY,
    {
      fetchPolicy: 'no-cache',
      ...options,
    },
  );

  const { productCollection } = data || {};

  return { data: productCollection, isLoading, apiError };
};

/**
 * Hook for editing product collection.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditProductCollection = (options: MutationHookOptions = {}) => {
  const [updateProductCollectionMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateProductCollection: { _id: string };
  }>(UPDATE_PRODUCT_COLLECTION_MUTATION, options);

  return { updateProductCollectionMutation, isLoading, apiError };
};
