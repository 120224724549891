import useDebounce from '@hooks/useDebounce';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import * as React from 'react';

// eslint-disable-next-line max-lines-per-function

/**
 * @deprecated - See whether this can be replaced with FilterAutocomplete or abstract pieces of that component?
 */
export const AsynchronousAutocomplete = <T,>({
  value,
  setValue,
  isOptionEqualToValue,
  getOptionLabel,
  getAutocompleteData,
  disabled,
  label,
  error,
  defaultInputValue,
}: {
  getAutocompleteData: (input: string) => Promise<T[]>;
  disabled?: boolean;
  value?: T | null;
  defaultInputValue?: string;
  setValue?: (value_: T | null) => void;
  isOptionEqualToValue: ((option: T, value: T) => boolean) | undefined;
  getOptionLabel: (input: T) => string;
  label: string;
  error?: boolean;
}) => {
  const [inputValue, setInputValue] = React.useState<string>(defaultInputValue ?? '');

  const debouncedInputValue = useDebounce(inputValue, 500);
  const [isOpen, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly T[]>([]);
  const isLoading = isOpen && options.length === 0;

  React.useEffect(() => {
    if (!isLoading) {
      return;
    }

    (async () => {
      if (debouncedInputValue) {
        const fetchedOptions = await getAutocompleteData(debouncedInputValue);

        setOptions([...fetchedOptions]);
      }
    })();
  }, [isLoading, debouncedInputValue]);

  React.useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      sx={{ width: 300 }}
      open={isOpen}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(_, value) => {
        setValue && setValue(value);
      }}
      inputValue={inputValue}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </>
      )}
    />
  );
};
