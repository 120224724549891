import { GridColDef } from '@mui/x-data-grid-pro';
import { StyledLink } from '@components/DataGrid/Styled';
import { IAccount } from '../graphql';
import { routes } from '@routes/private';
import React from 'react';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { AccountStatus } from '@common/types/GraphqlTypes.d';

/**
 * Style conditions for published stage.
 * @todo: Move this into reusable function outside of columns config.
 */
const statusTagConditions = (publishedStage: string = '') => [
  { type: StatusTagType.Success, match: () => publishedStage === AccountStatus.Live },
  { type: StatusTagType.Error, match: () => publishedStage === AccountStatus.Archived },
];

export const columns: GridColDef<IAccount>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: ({ value, row }) => (
      <StyledLink to={routes.editAccount.getPath(row.publicId)}>{value}</StyledLink>
    ),
  },
  {
    field: 'publicId',
    headerName: 'Account ID',
    flex: 1,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => <StatusTag text={value} conditions={statusTagConditions(value)} />,
    sortable: false,
    flex: 1,
  },
  {
    field: 'publisher',
    headerName: 'Client',
    flex: 1,
    renderCell: ({ value }) => {
      const client = value as IAccount['publisher'];

      return (
        <StyledLink to={routes.editClient.getPath(client?._id as string)}>
          {client?.name}
        </StyledLink>
      );
    },
    sortable: false,
  },
  {
    field: 'billing',
    headerName: 'Fee',
    width: 100,
    renderCell: ({ value: billing }) => <div>{billing?.fee}%</div>,
    sortable: false,
  },
];
