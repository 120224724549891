import makeStyles from '@mui/styles/makeStyles';

import { Color } from '../../../theme/palette';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& label': {
      cursor: 'pointer',
      width: '100%',
      overflow: 'auto',
      overflowWrap: 'break-word',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  input: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },

  button: {
    borderColor: Color.Gray2,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 'inherit',
    height: '90px',
  },

  reset: {
    position: 'absolute',
    width: '22px',
    height: '22px',
    borderRadius: '100%',
    background: Color.Gray4,
    color: theme.palette.primary.main,
    border: '1px solid transparent',
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 12%)',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    right: '-10px',
    top: '-10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  filename: {
    paddingTop: '5px',
  },

  label: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    transform: 'inherit', // Needed to counteract fanciness on MUI labels
    position: 'inherit', // Needed to counteract fanciness on MUI labels
    fontSize: '0.8rem',
    marginBottom: '5px',
  },

  loader: {
    transform: 'none',
  },
}));
