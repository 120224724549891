import { Workspaces as WorkspacesIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Color } from '@theme/palette';
import React from 'react';

export type ProductBrandProps = {
  category: string;
  brand: string | 'brand_undefined';
  gender: string;
};

/**
 * Displays brand, category & gender.
 * @todo - Filter out brand_undefined or highlight not a brand.
 */
export const ProductBrand = ({ category, gender, brand }: ProductBrandProps) => (
  <span
    style={{
      fontSize: '0.7rem',
      fontWeight: 300,
      color: Color.Gray1,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Tooltip title={category ?? 'No Google category'}>
      <WorkspacesIcon style={{ width: '1rem', marginRight: '5px', height: '1rem' }} />
    </Tooltip>
    {brand} - {gender}
  </span>
);
