import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    rowGap: theme.spacing(3),
  },
  divider: {
    margin: '0 -32px',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
}));
