import { Currency } from '@utils/types';

// TODO:
enum AccountEmailProvider {
  SendGrid = 'sendGrid',
  External = 'external',
}

// TODO:
enum AccountShippingConfig {
  Merchant = 'Merchant',
  MerchantIgnoreFree = 'Merchant - ignore free shipping',
  Free = 'Free',
}

// Account default values
export const ACCOUNT_DEFAULTS = {
  currency: Currency.USD,
  order: {
    shipping: AccountShippingConfig.MerchantIgnoreFree,
  },
  emailProvider: AccountEmailProvider.SendGrid,
};

export const ACCOUNT_CURRENCIES = [
  { value: Currency.AUD, label: 'AUD' },
  { value: Currency.CAD, label: 'CAD' },
  { value: Currency.USD, label: 'USD' },
  { value: Currency.GBP, label: 'GBP' },
  { value: Currency.EUR, label: 'EUR' },
];

export const ACCOUNT_ORDER_SHIPPING_OPTIONS = [
  { value: AccountShippingConfig.Merchant, label: "Merchant's shipping" },
  {
    value: AccountShippingConfig.MerchantIgnoreFree,
    label: "Merchant's shipping - ignore free shipping",
  },
  { value: AccountShippingConfig.Free, label: 'Free shipping' },
];

export const ACCOUNT_EMAIL_PROVIDER_OPTIONS = [
  { value: AccountEmailProvider.SendGrid, label: 'Sendgrid' },
  { value: AccountEmailProvider.External, label: 'External' },
];
