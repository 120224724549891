import { ICurrentUser } from '@hooks/useCurrentUser';
import { useSnackbar } from '@hooks/useSnackbar';
import { Typography } from '@mui/material';
import { UserPermission, userPermissions } from '@types';
import queryString from 'query-string';
import React, { useEffect } from 'react';

import { CurrentUserMerchant } from '@common/types/GraphqlTypes.d';
import { ENV } from '@constants/environment';
import { shapeStripeConnectURL } from '@lib/stripe';
import { hasPermission } from '@utils/permission';

import { useUpdateMerchantStripe } from '../graphql';
import { useStyles } from './styles';

interface IMerchantInfoProps {
  merchant: CurrentUserMerchant;
}

interface UserInfoProps {
  user: ICurrentUser;
}

enum StripeAccount {
  US = 'US',
  CA = 'CA',
}

const MerchantInfo: React.FC<IMerchantInfoProps> = ({
  merchant: {
    name,
    isStripeConnected,
    stripeState: merchantStripeState,
    offices: { country },
  },
}) => {
  const classes = useStyles();

  const { updateMerchantStripe } = useUpdateMerchantStripe();
  const { enqueueSnackbar } = useSnackbar();
  const stripeAccount = country === 'US' ? StripeAccount.US : StripeAccount.CA;
  const apiKey = country === 'US' ? ENV.stripeClientUS : ENV.stripeClientCA;

  useEffect(() => {
    const { search = '' } = window.location || {};
    const parameters = queryString.parse(search);
    const { state: stripeState, code: stripeCode } = parameters;

    if (stripeState === merchantStripeState && stripeCode) {
      updateMerchantStripe({
        variables: { input: { stripeCode, stripeState, stripeAccount } },
        onCompleted: () => {
          enqueueSnackbar('Stripe successfully connected.');
        },
      });
    }
    if (stripeState && stripeState !== merchantStripeState) {
      enqueueSnackbar('Invalid response from Stripe', { variant: 'error' });
    }
  }, []);

  return (
    <div className={classes.merchantInfo}>
      <div>
        <Typography variant="h6">Stripe connection to {name}</Typography>
        Click the Connect with Stripe button to set up the connection with your Stripe account.This
        is needed to handle payments.
      </div>

      <div className={classes.stripeButton}>
        {isStripeConnected ? (
          <span>Stripe connected</span>
        ) : (
          <a
            href={shapeStripeConnectURL({
              stripeState: merchantStripeState || '',
              apiKey,
            })}
          >
            <img src="/stripe.png" alt="Connect with Stripe" width="200px" />
          </a>
        )}
      </div>
    </div>
  );
};

export const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const classes = useStyles();
  const { email, profile, permissions, merchant } = user;

  const name = `${profile.firstName} ${profile.lastName}`;

  return (
    <div className={classes.root}>
      <div className={classes.basicInfo}>
        <div>
          <Typography variant="subtitle2">Name</Typography>
          <div>{name}</div>
        </div>
        <div>
          <Typography variant="subtitle2">Email</Typography>
          <div>{email}</div>
        </div>
      </div>
      <div className={classes.permissionsInfo}>
        <Typography variant="subtitle2">Current permission(s)</Typography>

        {(permissions || []).map((permission) => (
          <div key={permission}>- {userPermissions[permission].name}</div>
        ))}
      </div>

      {merchant && hasPermission(user, UserPermission.MerchantConnectStripe) && (
        <MerchantInfo merchant={merchant} />
      )}
    </div>
  );
};
