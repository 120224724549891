/* eslint-disable sort-keys */
import { Login, NewPassword, ResetPassword } from '@pages/Authentication';

import { IRouteConfig } from './interfaces';

export enum PublicRoutes {
  /**
   * Login.
   */
  login = 'login',

  /**
   * Password management.
   */
  newPassword = 'newPassword',
  resetPassword = 'resetPassword',
}

export const routes: Record<PublicRoutes, IRouteConfig> = {
  /**
   * Login.
   */
  [PublicRoutes.login]: {
    name: 'Login',
    path: '/login',
    getPath: () => '/login',
    component: Login,
  },

  /**
   * Password management.
   */
  [PublicRoutes.newPassword]: {
    name: 'New Password',
    path: '/reset/:resetPasswordToken',
    getPath: (token: string) => `/reset/${token}`,
    component: NewPassword,
  },

  [PublicRoutes.resetPassword]: {
    name: 'Reset Password',
    path: '/reset',
    getPath: () => '/reset',
    component: ResetPassword,
  },
};
