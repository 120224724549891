import { gql, MutationHookOptions, useLazyQuery } from '@apollo/client';
export const EXPORT_MERCHANT_ORDERS_QUERY = gql`
  query exportMerchantOrders($input: ExportMerchantOrdersInput!) {
    exportMerchantOrders(input: $input) {
      fileName
      encodedCsv
    }
  }
`;

/**
 * Hook for creating merchants.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useExportMerchantOrders = (options: MutationHookOptions = {}) => {
  const [exportMerchantOrdersQuery, { error: apiError, loading: isLoading, data: exportData }] =
    useLazyQuery<{
      exportMerchantOrders: { fileName: string; encodedCsv: string };
    }>(EXPORT_MERCHANT_ORDERS_QUERY, options);

  return { exportMerchantOrdersQuery, isLoading, apiError, exportData };
};
