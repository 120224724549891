import { useLazyQuery } from '@apollo/client';

import { CLIENTS_AUTOCOMPLETE_QUERY } from './graphql';

export type IClientAutocomplete = {
  _id: string;
  name: string;
};

export const useClientAutocomplete = (): {
  query: (value: string) => Promise<IClientAutocomplete[]>;
} => {
  const [operation] = useLazyQuery<{
    clients: IClientAutocomplete[];
  }>(CLIENTS_AUTOCOMPLETE_QUERY);

  const query = async (value: string): Promise<IClientAutocomplete[]> => {
    const input = {
      search: value,
      filters: {},
      page: 1,
      pageSize: 50, // Show 50 results max.
      sortBy: {
        name: 1,
      },
    };

    const { data } = await operation({
      variables: {
        input,
      },
    });

    return data?.clients ?? [];
  };

  return { query };
};
