import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { QueryUsersArgs, QueryUsersCountArgs, User } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within USERS_QUERY.
export type IUser = Pick<User, '_id' | 'email' | 'profile' | 'createdAt'>;

export const USERS_QUERY = gql`
  query users($input: UsersInput!) {
    users(input: $input) {
      _id
      email
      profile {
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export const USERS_COUNT_QUERY = gql`
  query usersCount($input: UsersCountInput!) {
    usersCount(input: $input) {
      total
    }
  }
`;

/**
 * Hook for fetching users.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useUsers = (options: QueryHookOptions<{ users: IUser[] }, QueryUsersArgs>) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ users: IUser[] }, QueryUsersArgs>(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { users: data = [] } = apiResult ?? {};

  return { data, isLoading, apiError };
};

/**
 * Hook for fetching users total count.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useUsersCount = (
  options: QueryHookOptions<{ usersCount: { total: number } }, QueryUsersCountArgs>,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ usersCount: { total: number } }, QueryUsersCountArgs>(USERS_COUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { total: data = 0 } = apiResult?.usersCount ?? {};

  return { data, isLoading, apiError };
};
