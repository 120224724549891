/**
 * Converts a string from csv format to bulk merchant orders update format.
 * @param file - CSV file read into a string.
 */
export const csvToOrdersUpdate = (csv: string): Record<string, string>[] => {
  const rows = csv.split('\n');

  const headers = rows[0].split(',');
  const orderPropertyNames = headers.map((header) => header.trim().replace(/\s/g, ''));

  const updateRows = rows.slice(1, rows.length);

  return updateRows.map((row) => {
    const rowColumnValues = row.split(',');

    return Object.fromEntries(
      rowColumnValues.map((columnValue, columnIndex) => [
        orderPropertyNames[columnIndex],
        columnValue.trim(),
      ]),
    );
  });
};
