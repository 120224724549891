/* eslint-disable complexity */

import { ITextFieldProps, TextField } from '@components/Input/TextField';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  FormControl,
  InputLabel,
  Skeleton,
  TextFieldProps,
} from '@mui/material';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { useStyles } from './styles';

export type IAutocompleteProps<T> = {
  // Built-in loading support
  isLoading?: boolean;

  // Customizable props for text field component
  textFieldProps?: ITextFieldProps & TextFieldProps;

  // Query hook when loading data (lazy-loaded)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchQuery: (value: string, args?: any) => Promise<T[]>;

  label?: string;
};

/**
 * Autocomplete select dropdown component.
 * Uses react-hook-form by default.
 * @todo - Figure out typing for AutoCompleteProps!
 */
export const Autocomplete = <T,>({
  id = getUniqueId(),
  className,
  isLoading = false,
  fetchQuery,
  label,
  placeholder,
  textFieldProps = {}, // Props for input field
  ...otherProps
}: IAutocompleteProps<T> & Partial<AutocompleteProps<T, false, false, false>>) => {
  const classes = useStyles();
  const [availableOptions, setAvailableOptions] = React.useState<T[]>([]);

  const { watch, setValue } = useFormContext();

  // Used to prefill value
  const value = watch(id);

  /**
   * When input field value changes (during typing).
   * @todo - Use memoization to avoid unnecessary API calls.
   * @todo - Use useDebounce to avoid unnecessary API calls on every keystroke.
   *
   * Checks whether a value is already selected.
   * @todo - This is a horrendous & hacky way to check whether a value is already selected.
   * An infinite render loop is currently caused. Suggesting to rework Autocomplete, FilterAutocomplete & AsynchronousAutocomplete into a clean single component.
   * Potentially have some different Styled components as filters & input fields might differ.
   */
  const handleOnInputChange = useCallback(
    async (inputValue: string) => {
      const originalLabel = otherProps.getOptionLabel ? otherProps.getOptionLabel(value) : '';

      if (originalLabel !== inputValue) {
        const result = await fetchQuery(inputValue);

        // Update dropdown list with options
        setAvailableOptions(result);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchQuery, otherProps.getOptionLabel],
  );

  /**
   * When a dropdown item gets selected or cleared.
   * @todo - Provide better types using T generic where possible.
   */
  const onChange = (_: React.SyntheticEvent, value: T | null) => {
    setValue(id, value);
  };

  return (
    <FormControl variant="outlined" size="small" className={clsx([classes.root, className])}>
      {label && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      )}

      {isLoading ? (
        <Skeleton className={classes.loader} height="36px" />
      ) : (
        <MuiAutocomplete
          disablePortal
          autoSelect
          options={availableOptions}
          value={value}
          onChange={onChange}
          onInputChange={(_, value) => handleOnInputChange(value)}
          renderInput={(props) => (
            // TODO: Make regular textField validation work. Avoid adding another ID to the form values.
            <TextField
              {...props}
              {...textFieldProps}
              useHook={false} // Value will be updated controlled (see onChange)
              placeholder={placeholder}
              size="small"
            />
          )}
          {...otherProps}
        />
      )}
    </FormControl>
  );
};
