import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  card: {
    padding: '24px 20px',
  },
  cardTitleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabBar: {
    marginTop: '10px',
    marginRight: '7px',
  },
  tabPanel: {
    padding: '15px 0',
  },
  menu: {
    position: 'relative',
    right: '-12px',
  },
}));
