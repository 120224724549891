import { useAuth } from '@hooks/useAuthentication';
import { Unauthorized } from '@pages/Misc/Unauthorized';
import { UserPermission } from '@types';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { Navigation } from '../navigation';
import { routes } from '../routes/public';

type AuthorizedProps = {
  children: JSX.Element;
  permission?: UserPermission; // Components can be linked to specific permissions
};

/**
 * Checks whether user permissions has at least 1 of the required permissions.
 * @param {string} [requiredPermission] - Required permission.
 * @param {string[]} userPermissions - User permissions.
 */
const hasPermission = (
  requiredPermission?: UserPermission,
  userPermissions: UserPermission[] = [],
): boolean => (requiredPermission ? userPermissions.includes(requiredPermission) : true);

/**
 * Performs authorization checks on protected routes.
 * Returns error pages/components if requirements are not met.
 */
export const AuthorizedRoute = ({ children, permission }: AuthorizedProps): JSX.Element => {
  const { isAuthenticated, currentUser } = useAuth();

  // User is not authenticated
  if (!isAuthenticated || !currentUser) {
    return <Navigate to={routes.login.path} />;
  }

  // Authenticated but not the required permissions
  // Still show navigation menu when this happens to allow easy change of page
  if (isAuthenticated && !hasPermission(permission, currentUser.permissions)) {
    return (
      <Navigation>
        <Unauthorized />
      </Navigation>
    );
  }

  return <Navigation>{children}</Navigation>;
};
