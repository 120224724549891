import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .sui-search-box__submit': {
      display: 'none',
    },

    '& .sui-result__title': {
      color: theme.palette.primary.main,
    },

    '& .sui-result__title-link': {
      color: theme.palette.action.selected,
    },

    '& .sui-layout-header': {
      padding: '20px 0',
    },

    '& .sui-results-container': {
      columns: '2',
    },

    '& .sui-result': {
      padding: '20px',
      position: 'relative',
    },

    '& .sui-result + .sui-result': {
      marginTop: '20px',
    },
  },
}));
