import React from 'react';

type SvgElementProps = {
  dataurl: string;
  className?: string;
};

/**
 * Component that's able to render a stringified `svg` (data url). This allows us
 * to style and/or modify SVG tree structure if needed.
 */
export const SvgElement = ({ dataurl, className }: SvgElementProps) => {
  try {
    // Strip out the protocol.
    const __html = decodeURIComponent(dataurl.split('data:image/svg+xml,')[1]);

    // Reify svg data string into React svg elements.
    return <div dangerouslySetInnerHTML={{ __html }} className={className} />;
  } catch {
    return <div />;
  }
};
