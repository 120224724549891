import { ApolloError, useQuery } from '@apollo/client';
import { UserPermission } from '@types';

import { CurrentUser } from '@common/types/GraphqlTypes.d';

import { CURRENT_USER_QUERY } from './graphql';

export interface ICurrentUser extends CurrentUser {
  name: string;
  permissions: UserPermission[];
}

/**
 * Hook for fetching current user information.
 */
export const useCurrentUser = (): {
  isLoading: boolean;
  currentUser?: ICurrentUser;
  error?: ApolloError;
} => {
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<{ currentUser: ICurrentUser }>(CURRENT_USER_QUERY);

  if (isLoading) {
    return { isLoading };
  }

  if (error) {
    return { error, isLoading: false };
  }

  if (data?.currentUser) {
    return { currentUser: data.currentUser, isLoading };
  }

  return { isLoading: false };
};
