import { Header } from '@components/Header';
import React from 'react';

type HeaderProps = {
  orderNumber?: string;
  isLoading: boolean;
};

export const ViewCustomerOrderHeader = ({ orderNumber, isLoading }: HeaderProps) => (
  <Header title={`Order #${orderNumber}`} isLoading={isLoading} />
);
