import React from 'react';
import { useLocation } from 'react-router';

import { useStyles } from './styles';

export const NotFound: React.FC = () => {
  // triggers a message reload between different pages that are not found
  useLocation();

  const classes = useStyles();

  return (
    <h1 className={classes.root}>
      <div>
        <div className={classes.icon}>
          <span role="img" aria-label="shrug">
            🛩
          </span>
        </div>

        <div>
          <span className={classes.bud}>Apologies</span>, the page you're looking for could not be
          found.
        </div>

        <div className={classes.statusCode}>404</div>
      </div>
    </h1>
  );
};
