import { SearchBox } from '@elastic/react-search-ui';
import React from 'react';

export const SearchProductsSearchBox = () => (
  <SearchBox
    inputProps={{ placeholder: 'Search products' }}
    // TODO: Once we have a product detail page or modal, this would simply navigate to it or open based on `urlField`
    // TODO: If autocomplete is preferred over searchAsYouType we should fix the navigate below (does not refresh the page atm)
    /*                     autocompleteResults={{
                      sectionTitle: 'Suggested products',
                      titleField: 'name',
                      urlField: 'nps_link',
                    }}
                    autocompleteMinimumCharacters={3}
                    autocompleteSuggestions={true}
                    onSubmit={() => {}}
                    onSelectAutocomplete={(selection, {}, defaultOnSelectAutocomplete) => {
                      if (selection?.name?.raw) {
                        navigate('/products/search?q=' + selection.name.raw);
                      } else {
                        defaultOnSelectAutocomplete(selection);
                      }
                    }} */
    // searchAsYouType={true}
    // debounceLength={300}
  />
);
