/* eslint-disable sort-keys */

import { CreateAccount, ListAccounts, EditAccount } from '@pages/Account';
import { ListBrands } from '@pages/Brand';
import { CreateClient, EditClient, ListClients } from '@pages/Client';
import { ViewOrderV2, ListOrdersV2 } from '@pages/CustomerOrderV2';
import { Home } from '@pages/Home';
import { ListMerchants, CreateMerchant, EditMerchant } from '@pages/Merchant';
import { NotFound } from '@pages/Misc';
import {
  ViewOrder,
  RefundMerchantOrder,
  CreateShippingTracking,
  EditShippingTracking,
  EditFeedOrder,
  CreateFeedOrder,
  ArchivedFeedOrders,
} from '@pages/Order';
import { Preferences } from '@pages/Preferences';
import { ListProducts } from '@pages/Product';
import {
  CreateProductCollection,
  EditProductCollection,
  ListProductCollections,
  ViewProductCollection,
} from '@pages/ProductCollection';
import { ViewProfile, EditProfile } from '@pages/Profile';
import { CreateRole, ListRoles, EditRole } from '@pages/Role';
import { ListUsers, CreateUser, EditUser } from '@pages/User';
import { UserPermission } from '@types';

import { IRouteConfig } from './interfaces';

export enum PrivateRoutes {
  /**
   * Customer orders.
   */
  createShippingTracking = 'createShippingTracking',
  editShippingTracking = 'editShippingTracking',
  listOrdersV2 = 'listOrdersV2',
  refundOrder = 'refundOrder',
  viewOrder = 'viewOrder',
  viewOrderV2 = 'viewOrderV2',

  /**
   * Feed orders.
   */
  archivedFeedOrders = 'archivedFeedOrders',
  createFeedOrder = 'createFeedOrder',
  editFeedOrder = 'editFeedOrder',

  /**
   * Merchants.
   */
  createMerchant = 'createMerchant',
  editMerchant = 'editMerchant',
  listMerchants = 'listMerchants',

  /**
   * Products.
   */
  listProducts = 'listProducts',

  /**
   * Product collections.
   */
  viewProductCollection = 'viewProductCollection',
  createProductCollection = 'createProductCollection',
  editProductCollection = 'editProductCollection',
  listProductCollections = 'listProductCollections',

  /**
   * Brands.
   */
  listBrands = 'listBrands',

  /**
   * Clients.
   */
  createClient = 'createClient',
  editClient = 'editClient',
  listClient = 'listClients',

  /**
   * Accounts.
   */
  createAccount = 'createAccount',
  editAccount = 'editAccount',
  listAccounts = 'listAccounts',

  /**
   * Users.
   */
  createUser = 'createUser',
  editUser = 'editUser',
  listUsers = 'listUsers',

  /**
   * Roles.
   */
  createRole = 'createRole',
  editRole = 'editRole',
  listRoles = 'listRoles',

  /**
   * Profile.
   */
  viewProfile = 'viewProfile',
  editProfile = 'editProfile',

  /**
   * Misc.
   */
  home = 'home',
  preferences = 'preferences',
  notFound = 'notFound',
}

/**
 * Defines all available private routes.
 * Make sure to use plural terms and the following format:
 * /view/:id, /edit/:id, /list, ...
 */
export const routes: Record<PrivateRoutes, IRouteConfig> = {
  /**
   * Home.
   *
   */
  [PrivateRoutes.home]: {
    name: 'Overview',
    path: '/',
    getPath: () => '/',
    component: Home,
    exact: true,
  },

  /**
   * Orders.
   */
  [PrivateRoutes.archivedFeedOrders]: {
    name: 'Archived feed orders',
    path: '/feed-orders/archived/:_id',
    getPath: (_id: string) => `/feed-orders/archived/${_id}`,
    component: ArchivedFeedOrders,
    permission: UserPermission.ManageOrders,
    exact: true,
  },

  [PrivateRoutes.createFeedOrder]: {
    name: 'Create feed order',
    path: '/feed-orders/:_id',
    getPath: (_id: string) => `/feed-orders/${_id}`,
    component: CreateFeedOrder,
    permission: UserPermission.ManageOrders,
    exact: true,
  },

  [PrivateRoutes.editFeedOrder]: {
    name: 'Edit feed order',
    path: '/feed-orders/:merchantOrderId/:feedOrderId/edit',
    getPath: (merchantOrderId: string, feedOrderId: string) =>
      `/feed-orders/${merchantOrderId}/${feedOrderId}/edit`,
    component: EditFeedOrder,
    permission: UserPermission.ManageOrders,
    exact: true,
  },

  [PrivateRoutes.createShippingTracking]: {
    name: 'Submit shipping tracking',
    path: '/orders/shipping-tracking/:merchantOrderId',
    getPath: (merchantOrderId: string) => `/orders/shipping-tracking/${merchantOrderId}`,
    component: CreateShippingTracking,
    permission: UserPermission.ManageOrders,
    exact: true,
  },

  [PrivateRoutes.editShippingTracking]: {
    name: 'Submit shipping tracking',
    path: '/orders/shipping-tracking/:merchantOrderId/edit/:fulfillmentId',
    getPath: (merchantOrderId: string, fulfillmentId: string) =>
      `/orders/shipping-tracking/${merchantOrderId}/edit/${fulfillmentId}`,
    component: EditShippingTracking,
    permission: UserPermission.ManageOrders,
    exact: true,
  },

  [PrivateRoutes.listOrdersV2]: {
    name: 'View orders',
    path: '/orders/v2/list',
    getPath: () => '/orders/v2/list',
    component: ListOrdersV2,
    permission: UserPermission.ManageOrders,
  },

  [PrivateRoutes.refundOrder]: {
    name: 'Refund order',
    path: '/orders/refund/:merchantOrderId',
    getPath: (merchantOrderId: string) => `/orders/refund/${merchantOrderId}`,
    component: RefundMerchantOrder,
    permission: UserPermission.ManageOrders,
  },

  [PrivateRoutes.viewOrder]: {
    name: 'View order details',
    path: '/orders/view/:_id',
    getPath: (_id: string) => `/orders/view/${_id}`,
    component: ViewOrder,
    permission: UserPermission.ManageOrders,
  },

  [PrivateRoutes.viewOrderV2]: {
    name: 'View order details',
    path: '/orders/v2/view/:id',
    getPath: (id: string) => `/orders/v2/view/${id}`,
    component: ViewOrderV2,
    permission: UserPermission.ManageOrders,
  },

  /**
   * Merchants.
   */
  [PrivateRoutes.createMerchant]: {
    name: 'Create merchant',
    path: '/merchants/create',
    getPath: () => '/merchants/create',
    component: CreateMerchant,
    permission: UserPermission.ManageMerchants,
  },

  [PrivateRoutes.editMerchant]: {
    name: 'Edit merchant',
    path: '/merchants/edit/:id',
    getPath: (id: string) => `/merchants/edit/${id}`,
    component: EditMerchant,
    permission: UserPermission.ManageMerchants,
  },

  [PrivateRoutes.listMerchants]: {
    name: 'View merchants',
    path: '/merchants/list',
    getPath: () => '/merchants/list',
    component: ListMerchants,
    permission: UserPermission.ManageMerchants,
  },

  /**
   * Products.
   */
  [PrivateRoutes.listProducts]: {
    name: 'View products',
    path: '/products/list',
    getPath: () => '/products/list',
    component: ListProducts,
    permission: UserPermission.ManageProducts,
  },

  /**
   * Product collections.
   */

  [PrivateRoutes.viewProductCollection]: {
    name: 'View product collection',
    path: '/productCollections/view/:id',
    getPath: (id: string) => `/productCollections/view/${id}`,
    component: ViewProductCollection,
    permission: UserPermission.ManageProductCollections,
  },

  [PrivateRoutes.createProductCollection]: {
    name: 'Create product collection',
    path: '/productCollections/create',
    getPath: () => '/productCollections/create',
    component: CreateProductCollection,
    permission: UserPermission.ManageProductCollections,
  },

  [PrivateRoutes.editProductCollection]: {
    name: 'Edit product collection',
    path: '/productCollections/edit/:id',
    getPath: (id: string) => `/productCollections/edit/${id}`,
    component: EditProductCollection,
    permission: UserPermission.ManageProductCollections,
  },

  [PrivateRoutes.listProductCollections]: {
    name: 'View product collections',
    path: '/productCollections/list',
    getPath: () => '/productCollections/list',
    component: ListProductCollections,
    permission: UserPermission.ManageProductCollections,
  },

  /**
   * Brands.
   */

  [PrivateRoutes.listBrands]: {
    name: 'View brands',
    path: '/brands/list',
    getPath: () => '/brands/list',
    component: ListBrands,
    permission: UserPermission.ManageBrands,
  },

  /**
   * Clients.
   */
  [PrivateRoutes.createClient]: {
    name: 'Create client',
    path: '/clients/create',
    getPath: () => '/clients/create',
    component: CreateClient,
    permission: UserPermission.ManageClients,
  },

  [PrivateRoutes.editClient]: {
    name: 'Edit client',
    path: '/clients/edit/:id',
    getPath: (id: string) => `/clients/edit/${id}`,
    component: EditClient,
    permission: UserPermission.ManageClients,
  },

  [PrivateRoutes.listClient]: {
    name: 'View clients',
    path: '/clients/list',
    getPath: () => '/clients/list',
    component: ListClients,
    permission: UserPermission.ManageClients,
  },

  /**
   * Accounts - Native Commerce.
   */
  [PrivateRoutes.createAccount]: {
    name: 'Create account',
    path: '/accounts/create',
    getPath: () => '/accounts/create',
    component: CreateAccount,
    permission: UserPermission.ManageClients,
  },

  [PrivateRoutes.editAccount]: {
    name: 'Edit account',
    path: '/accounts/edit/:id',
    getPath: (id: string) => `/accounts/edit/${id}`,
    component: EditAccount,
    permission: UserPermission.ManageClients,
  },

  [PrivateRoutes.listAccounts]: {
    name: 'View accounts',
    path: '/accounts/list',
    getPath: () => '/accounts/list',
    component: ListAccounts,
    permission: UserPermission.ManageClients,
  },

  /**
   * Users.
   */
  [PrivateRoutes.createUser]: {
    name: 'Create user',
    component: CreateUser,
    getPath: () => '/users/create',
    permission: UserPermission.ManageUsers,
    path: '/users/create',
  },

  [PrivateRoutes.editUser]: {
    name: 'Edit user',
    path: '/users/edit/:id',
    getPath: (id: string) => `/users/edit/${id}`,
    component: EditUser,
    permission: UserPermission.ManageUsers,
  },

  [PrivateRoutes.listUsers]: {
    name: 'View users',
    path: '/users/list',
    getPath: () => '/users/list',
    component: ListUsers,
    permission: UserPermission.ManageUsers,
  },

  /**
   * Roles.
   */
  [PrivateRoutes.editRole]: {
    name: 'Edit role',
    path: '/roles/edit/:id',
    getPath: (id: string) => `/roles/edit/${id}`,
    component: EditRole,
    permission: UserPermission.ManageRoles,
  },

  [PrivateRoutes.createRole]: {
    name: 'Create user role',
    path: '/roles/create',
    getPath: () => '/roles/create',
    component: CreateRole,
    permission: UserPermission.ManageRoles,
    exact: true,
  },

  [PrivateRoutes.listRoles]: {
    name: 'View roles',
    path: '/roles/list',
    getPath: () => '/roles/list',
    component: ListRoles,
    permission: UserPermission.ManageRoles,
  },

  /**
   * Profile.
   */

  [PrivateRoutes.viewProfile]: {
    name: 'Profile',
    path: '/profile',
    getPath: () => '/profile',
    component: ViewProfile,
    exact: true,
  },
  [PrivateRoutes.editProfile]: {
    name: 'Edit Profile',
    path: '/profile/edit',
    getPath: () => '/profile/edit',
    component: EditProfile,
    exact: true,
  },

  /**
   * Misc.
   */
  [PrivateRoutes.preferences]: {
    name: 'Preferences',
    path: '/settings/preferences',
    getPath: () => '/settings/preferences',
    component: Preferences,
  },
  [PrivateRoutes.notFound]: {
    name: 'Not Found',
    path: '*',
    component: NotFound,
    getPath: () => '*',
  },
};
