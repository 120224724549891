import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_ROLE_QUERY = gql`
  mutation createRole($input: RoleInput!) {
    createRole(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for creating role.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateRole = (options: MutationHookOptions = {}) => {
  const [createRoleMutation, { error: apiError, loading: isLoading }] = useMutation<{
    createRole: { _id: string };
  }>(CREATE_ROLE_QUERY, options);

  return { createRoleMutation, isLoading, apiError };
};
