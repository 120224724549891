import React from 'react';

import { IRole } from '../graphql';
import { DeleteRoleMenuItem } from './DeleteRole';

type ActionsProps = {
  role: IRole;
};

export const RoleActions = ({ role }: ActionsProps) => (
  <>
    <DeleteRoleMenuItem role={role} />
  </>
);
