import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  upload: {
    width: '90px',
    '& button': {
      width: '90px',
      height: '90px',
    },
  },
}));
