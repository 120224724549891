import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import GradingIcon from '@mui/icons-material/Grading';
import React from 'react';

import { useTestMerchantProducts } from '../graphql';

type ActionsProps = {
  id: string;
};

export const TestProductsMenuItem = ({ id }: ActionsProps) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const { getData } = useTestMerchantProducts(id, {
    onCompleted: ({ testMerchantProducts: { message, errors } }) => {
      const didAllProductPass = errors.length === 0;

      enqueueSnackbar(message, {
        variant: didAllProductPass ? 'success' : 'error',
      });
      if (!didAllProductPass) {
        enqueueSnackbar(`Errors:  ${errors}`);
      }
      handleClose();
    },
  });

  return <MenuItem action={getData} icon={GradingIcon} label="Test Products" />;
};
