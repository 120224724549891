import { TimelineItem } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { ComponentType } from 'react';

type Props = Record<string, unknown>;

export const withLayout =
  <TRightProps extends Props, TLeftProps extends Props>(
    RightComponent: ComponentType<TRightProps>,
    LeftComponent?: ComponentType<TLeftProps>,
    className?: string,
  ) =>
  (props: TRightProps & TLeftProps) =>
    (
      <TimelineItem className={className}>
        <Grid container>
          <Grid item xs={2}>
            {LeftComponent && <LeftComponent {...props} />}
          </Grid>
          <Grid item xs={10} display="flex">
            <RightComponent {...props} />
          </Grid>
        </Grid>
      </TimelineItem>
    );
