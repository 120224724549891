import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles(() => ({
  existingCommentsWrapper: {
    marginBottom: '15px',
    marginTop: '15px',
  },
  existingComment: {
    borderBottom: `1px solid ${Color.Gray2}`,
    display: 'flex',
    padding: '10px 0',
    margin: '15px 0',
  },
  status: {
    background: Color.ErrorRed,
    borderRadius: '4px',
    display: 'inline-block',
    marginTop: '10px',
    padding: '2px 8px',
  },

  statusText: {
    color: Color.White,
    fontSize: '13px',
    fontWeight: '500',
  },
  date: {
    fontSize: '11px',
    lineHeight: '12px',
    fontWeight: '500',
    color: Color.Gray1,
    left: '44px',
    top: '40px',
  },
  comment: {
    borderBottom: '1px',
    borderColor: Color.Gray1,
    position: 'relative',
  },
  commentText: {
    color: Color.DarkNight,
    fontSize: '13px',
    fontWeight: '400',
    marginTop: '10px',
    wordBreak: 'break-word',
  },
  commentHeader: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  flag: {
    color: Color.ErrorRed,
  },
  check: {
    color: Color.DarkNight,
  },
  escalationStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0px',
    marginLeft: '20px',
    gap: '4px',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '20px',
    color: Color.Gray1,
  },
  commentUser: {
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '20px',
    color: Color.DarkNight,
  },
  divider: {
    position: 'absolute',
    top: '136px',
    left: '0px',
    height: '0px',
    border: `0.5px solid ${Color.Gray2}`,
    width: '100%',
  },
  userIcon: {
    display: 'inline-block',
  },
  newComment: {
    background: Color.White,
    border: `1px solid ${Color.Gray2}`,
    borderRadius: '4px',
    display: 'inline-block',
    height: '136px',
    position: 'relative',
    width: '100%',
  },
  commentInput: {
    border: '0px',
    padding: '10px',
    width: 'inherit',
  },
  dropDown: {
    background: Color.White,
    color: Color.Gray1,
    fontSize: '13px',
  },
  newCommentOptionsBar: {
    bottom: '12px',
    height: '36px',
    paddingLeft: '12px',
    paddingRight: '12px',
    position: 'absolute',
    width: '100%',
  },
  postButton: {
    marginLeft: '0',
  },
  newCommentUserIcon: {
    display: 'inline-block',
  },
  productName: {
    color: Color.DarkNight,
    fontSize: '13px',
    fontWeight: 600,
    paddingLeft: '8px',
  },
  menuItemText: {
    cursor: 'pointer',
    fontWeight: 600,
    marginRight: 20,
  },
}));
