import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 400,
    fontSize: '0.875rem',
  },
}));
