import { gql, useMutation } from '@apollo/client';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import { Send as InviteIcon } from '@mui/icons-material';
import React from 'react';

import { IUser } from '../../../fields';

type ActionsProps = {
  user: IUser;
};

const REINVITE_USER = gql`
  mutation reinviteUser($input: ReinviteUserInput!) {
    reinviteUser(input: $input) {
      result
    }
  }
`;

export const ReinviteUserMenuItem = ({ user }: ActionsProps) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [reinviteUser] = useMutation(REINVITE_USER, {
    errorPolicy: 'all',
    onCompleted: () => {
      enqueueSnackbar(`An invitiation has been sent to ${user.email}`);
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleClose();
    },
    variables: {
      input: {
        email: user.email,
      },
    },
  });

  return <MenuItem action={reinviteUser} icon={InviteIcon} label="Reinvite via email" />;
};
