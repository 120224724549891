import { getPrices } from '@pages/Product/utils';
import { Color } from '@theme/palette';
import React from 'react';

import { Currency } from '@common/types/GraphqlTypes.d';

export type IProductPriceProps = {
  minPrice: number | null;
  maxPrice: number | null;
  currency: Currency;
};

/**
 * Displays price information.
 */
export const ProductPrice = ({ minPrice, maxPrice, currency }: IProductPriceProps) => {
  const price = getPrices(minPrice, maxPrice, currency);

  return (
    <h4 style={{ margin: '0 0 3px 0' }}>
      <span style={{ fontSize: '1.1rem', fontWeight: 600 }}>{price}</span>
      <span
        style={{
          fontSize: '0.8rem',
          color: Color.Gray2,
          fontWeight: 400,
          paddingLeft: '3px',
        }}
      >
        {currency}
      </span>
    </h4>
  );
};
