import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';

// TODO: Replace with LoaderButton once they fix the issue of TS entirely hanging when using it. (tsc hangs...)
export const PrimaryButton = ({
  children,
  loading: _unused = false,
  ...props
}: ButtonProps & { loading?: boolean }) => (
  <Button color="primary" variant="contained" {...props}>
    {children}
  </Button>
);
