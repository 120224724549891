import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccount($input: AccountInputCreate!) {
    createAccount(input: $input) {
      publicId
    }
  }
`;

/**
 * Hook for creating account.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateAccount = (options: MutationHookOptions = {}) => {
  const [createAccountMutation, { error: apiError, loading: isLoading }] = useMutation<{
    createAccount: { publicId: string };
  }>(CREATE_ACCOUNT_MUTATION, options);

  return { createAccountMutation, isLoading, apiError };
};
