/**
 * Returns base64 string for files.
 * @param {object} file - Selected file object.
 */
export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      if (reader.result) {
        return resolve(reader.result as string);
      }
      reject(new Error('Could not convert file into Base64 format'));
    });
    reader.addEventListener('error', (error) => reject(error));
  });
