import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { TAccounts } from '@common/types/graphql';
import { QueryClientArgs } from '@common/types/GraphqlTypes.d';

export type IClient = {
  _id: string;
  name: string;
  status: string;
  accounts: TAccounts;
};

const CLIENT_QUERY = gql`
  query client($id: String!) {
    client(id: $id) {
      _id
      name
      status
      accounts {
        name
        source
      }
    }
  }
`;

const UPDATE_CLIENT_MUTATION = gql`
  mutation updateClient($id: String!, $input: ClientInputUpdate!) {
    updateClient(id: $id, input: $input) {
      _id
    }
  }
`;

/**
 * Hook for fetching client information.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Do not use IMerchantData interface - define custom one!
 */
export const useClient = (options: QueryHookOptions<{ client: IClient }, QueryClientArgs>) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ client: IClient }, QueryClientArgs>(CLIENT_QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const { client } = data || {};

  return { data: client, isLoading, apiError };
};

/**
 * Hook for editing clients.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditClient = (options: MutationHookOptions = {}) => {
  const [updateClientMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateClient: { _id: string };
  }>(UPDATE_CLIENT_MUTATION, options);

  return { updateClientMutation, isLoading, apiError };
};
