import { ColorModeContext } from '@contexts/colorModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

export const ColorToggle = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Tooltip title="Switch dark/light mode (experimental!)">
      <IconButton
        onClick={colorMode.toggleColorMode}
        aria-label="Menu"
        sx={(theme) => ({
          color: theme.palette.navbar.contrastText,

          // TODO: Show once dark move is more stable!
          display: 'none',
        })}
      >
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
};
