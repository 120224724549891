import currencyJs from 'currency.js';

import { CheckoutVariant, MerchantOrder, ShortcutProduct } from '@common/types/GraphqlTypes.d';
import { Optional } from '@utils/types';

type IProductDetail = {
  quantity: number;
  productId: string;
  variantId: string;
  checkoutVariant: CheckoutVariant;
};

/**
 * Gets the checkout variants for items on an existing ghost order.
 * @param ghostOrder - The ghost order.
 * @param checkoutVariants - Checkout variants including products on the ghost order.
 */
const getMatchingProducts = (
  shortCutProducts: ShortcutProduct[],
  checkoutVariants: CheckoutVariant[],
): CheckoutVariant[] => {
  const productDetails = shortCutProducts
    .map(({ productId, variantId, quantity }) => ({
      quantity,
      productId,
      variantId,
      checkoutVariant: checkoutVariants.find(
        ({ productId: id, variant: { id: vId } }) => id === productId && vId === variantId,
      ),
    }))
    .filter(({ checkoutVariant }) => !!checkoutVariant) as IProductDetail[];

  return productDetails.map(({ checkoutVariant, quantity }) => ({
    ...checkoutVariant,
    purchaseQuantity: quantity,
  }));
};

/**
 * Gets checkout variants to display on a feed order form for a merchantOrder.
 * @param merchantOrder - A merchant order.
 * @param feedOrderId - A feed/ghost order id for the provided merchantOrder.
 */
export const getCheckoutVariants = (
  merchantOrder?: Pick<
    MerchantOrder,
    'checkoutVariants' | 'ghostExternalOrders' | 'fulfillmentDetails'
  >,
  feedOrderId?: string,
  fulfillmentId?: string,
): CheckoutVariant[] => {
  if (!merchantOrder) {
    return [];
  }

  const { checkoutVariants, ghostExternalOrders, fulfillmentDetails } = merchantOrder;

  if (feedOrderId) {
    const selectedGhostOrder = ghostExternalOrders?.find((order) => order?._id === feedOrderId);

    if (!selectedGhostOrder) {
      return [];
    }

    return getMatchingProducts(selectedGhostOrder.products, checkoutVariants);
  }

  if (fulfillmentId) {
    const selectedFulfillment = fulfillmentDetails?.find(
      (fulfillment) => fulfillment?._id === fulfillmentId,
    );

    if (!selectedFulfillment) {
      return [];
    }

    return getMatchingProducts(selectedFulfillment.products, checkoutVariants);
  }

  return checkoutVariants;
};

/**
 * Converts a checkout variant to a shortCutProduct.
 * @param checkoutVariant - A checkout variant.
 * @returns {ShortcutProduct} - Short cut product.
 */
export const checkoutVariantToShortCutProduct = ({
  productId,
  purchaseQuantity,
  variant: { id: variantId },
}: CheckoutVariant): ShortcutProduct => ({
  productId,
  variantId: variantId || '',
  quantity: purchaseQuantity,
});

/**
 * Gets the portion of tax associated with a single quantity checkout variant.
 * @param checkoutVariant - Checkout variant.
 * @param merchantOrder - Merchant order.
 * @returns {number} - Amount of tax or 0.
 */
export const getTaxForCheckoutVariant = (
  checkoutVariant: CheckoutVariant,
  merchantOrder?: Optional<Pick<MerchantOrder, 'summary'>, 'summary'>,
): number => {
  if (!merchantOrder) {
    return 0;
  }
  const { summary } = merchantOrder;

  if (!summary) {
    return 0;
  }
  const {
    // For merchant orders this should be defined.
    variant: { priceUSD },
  } = checkoutVariant;

  if (!priceUSD) {
    return 0;
  }
  const { subTotal, shippingWithTax, customerChargeTotal, duties } = summary;

  const { value: taxes } = currencyJs(customerChargeTotal)
    .subtract(subTotal)
    .subtract(shippingWithTax)
    .subtract(duties);

  const { value: taxPercentage } = currencyJs(priceUSD).divide(subTotal);

  return currencyJs(taxes).multiply(taxPercentage).value;
};
