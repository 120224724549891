import { ColorModeContext } from '@contexts/colorModeContext';
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';

import { LocalStorageKey } from '@constants/localStorage';

import { components } from './components';
import { getPalette } from './palette';

enum ColorMode {
  Light = 'light',
  Dark = 'dark',
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  // const isSystemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const defaultMode = isSystemDarkMode ? ColorMode.Dark : ColorMode.Light;
  // TODO: Use system preferences once dark mode is more stable!

  // Fetches preference from localStorage
  const localMode = localStorage.getItem(LocalStorageKey.ColorMode) as ColorMode;

  // Either use the preference from localStorage or the default mode
  const [mode, setMode] = useState<PaletteMode>(localMode ?? ColorMode.Light);

  // Update the theme only if the mode changes
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousModel) => {
          const updatedMode = previousModel === ColorMode.Light ? ColorMode.Dark : ColorMode.Light;

          // Updates local storage
          localStorage.setItem(LocalStorageKey.ColorMode, updatedMode);

          return updatedMode;
        });
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(
    () =>
      createTheme({
        components,
        palette: {
          mode,
          ...getPalette(mode),
        },
        typography: {
          button: {
            boxShadow: 'none',
            textTransform: 'capitalize',
          },
          fontFamily: "'Inter', sans-serif",
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};
