import { StatusTag } from '@components/StatusTag';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Color } from '@theme/palette';
import React from 'react';

import { FilterSelectProps } from '.';

const StyledSingleSelect = styled(Box)({
  fontSize: '0.9em',
  color: Color.Black,
});

const StyledPlaceholder = styled(Box)({
  fontSize: '0.9em',
  color: Color.Gray1,
});

type SelectButtonProps = FilterSelectProps & {
  selected: string | number | string[];
};

/**
 * Returns custom select button used for Select dropdown.
 * Shows amount of selected item within status tag.
 */
export const SelectButton = ({ selected, placeholder, multiple, options }: SelectButtonProps) => {
  const foundValue = options?.find(({ value }) => value === selected)?.label ?? selected;
  const selectedOptions = Array.isArray(selected) ? selected.length : 0;

  /**
   * Multiple options - render differently.
   */
  if (multiple && selectedOptions > 0) {
    return (
      <Stack direction="row" alignItems="center" gap={2}>
        <StyledPlaceholder>{placeholder}</StyledPlaceholder>
        <StatusTag
          text={selectedOptions.toString()}
          color={Color.White}
          backgroundColor={Color.SteelBlue}
          sx={{ padding: '0 8px !important' }}
        />
      </Stack>
    );
  }

  return !multiple && foundValue ? (
    <StyledSingleSelect>{foundValue}</StyledSingleSelect>
  ) : (
    <StyledPlaceholder>{placeholder}</StyledPlaceholder>
  );
};
