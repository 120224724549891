import useRouter from '@hooks/useRouter';
import { MenuItem, Typography } from '@mui/material';
import { PrivateRoutes } from '@routes/private';
import { PublicRoutes } from '@routes/public';
import React from 'react';

import { useStyles } from './styles';

interface IGoToPageItemProps {
  label: string;
  path: PrivateRoutes | PublicRoutes;
  pathParams: string[];
}

export const GoToPageItem: React.FC<IGoToPageItemProps> = ({ label, path, pathParams }) => {
  const classes = useStyles();
  const { goto } = useRouter();

  const goToPage = () => {
    goto[path](...pathParams);
  };

  return (
    <MenuItem onClick={goToPage}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        {label}
      </Typography>
    </MenuItem>
  );
};
