import { DataField, DataFieldProps } from '@components/DataField';
import { Stack } from '@mui/material';
import React from 'react';

type DataStackProps = {
  /**
   * Unique key to identifity data stack.
   * Needed for looping over data fields.
   */
  stackId: string;

  /**
   * Control loading state automatically rendering skeleton loader.
   */
  isLoading?: boolean;

  /**
   * List of data fields to display.
   */
  items: DataFieldProps[];
};

/**
 * Renders a list of fields for displaying information.
 * This component will be used in most view pages with key/value pairs of information.
 */
export const DataStack = ({ stackId, items, isLoading = false }: DataStackProps) => (
  <Stack direction="column" spacing={1}>
    {items.map(({ isLoading: isFieldLoading, ...fieldProps }, index) => (
      <DataField
        key={`${stackId}-field-${index}`}
        {...fieldProps}
        isLoading={isFieldLoading ?? isLoading}
      />
    ))}
  </Stack>
);
