import { ControlledSelect } from '@components/Input/Select';
import { findByIso2 } from 'country-list-js';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const CheckoutState = () => {
  const { watch } = useFormContext();

  const country = watch('country');
  const countryInformation = findByIso2(country);

  // If country can not be found, do not render any select component
  // Currently only allow US to have dynamic state selection for now!
  if (!countryInformation?.provinces || countryInformation.name !== 'United States') {
    return null;
  }

  const options = countryInformation.provinces.map(({ name }: any) => ({
    value: name,
    label: name,
  }));

  // Once we support more countries with dynamic provinces/state, this needs to change
  const defaultState = 'Alabama';

  return (
    <ControlledSelect
      id="state"
      label="State or province"
      options={options}
      defaultValue={defaultState}
      required
    />
  );
};
