import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  discount: {
    display: 'flex',
    justifyContent: 'end',
  },
  dropdown: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    fontSize: 19,
    fontWeight: 600,
    margin: '0 -5px',
  },
  lineItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  priceBreakdown: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    rowGap: theme.spacing(3),
  },
  textField: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.black,
    },
  },
}));
