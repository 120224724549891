import { Header } from '@components/Header';
import React from 'react';

import { IProductCollection } from './graphql';

type ActionsProps = {
  collection?: IProductCollection;
  isLoading: boolean;
};

export const EditProductCollectionHeader = ({ collection, isLoading }: ActionsProps) => (
  <Header title={`Edit collection - ${collection?.name}`} isLoading={isLoading} />
);
