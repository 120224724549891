import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc/NotFound';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { getMatchingDotValues } from '@utils/object';

import { BasicInformation } from '../components/Basic';
import { Permissions } from '../components/Permissions';
import { IRoleInput, ROLE_FIELDS } from '../fields';
import { IRole, useEditRole, useRole } from './graphql';
import { EditRoleHeader } from './Header';

/**
 * Returns mapped GraphQL values into input fields key/value for autopopulation.
 */
const getValues = (role: IRole) => getMatchingDotValues<IRoleInput>(ROLE_FIELDS, role);

type IEditRoleParams = { id: string };

const EditRole = ({ id }: IEditRoleParams) => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IRoleInput>({
    mode: 'onSubmit',
  });

  // Track when fields got updated
  const { isDirty: hasFormChanged } = useFormState({
    control: form.control,
  });

  // GraphQL hooks
  const { isLoading, data } = useRole({
    variables: { _id: id },
    onCompleted: ({ role }) => {
      form.reset(getValues(role)); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching role:', error);
    },
  });

  const { updateRoleMutation, apiError: updateApiError } = useEditRole();

  const onSubmit = (values: IRoleInput) => {
    updateRoleMutation({
      variables: { id, input: values },
      onCompleted: () => {
        enqueueSnackbar('Succesfully updated role');

        // Set values to submitted values (resets dirty state)
        form.reset(values);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating role:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listRoles();
  };

  // Role could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <Stack gap={3}>
      <EditRoleHeader role={data} isLoading={isLoading} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Stack gap={3}>
                <BasicInformation isLoading={isLoading} />
                <Permissions isLoading={isLoading} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={isLoading || !hasFormChanged}>
                  Save changes
                </PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};

export const EditRolePage = withParams<IEditRoleParams>(['id'], EditRole);
