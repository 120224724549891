/* eslint-disable max-lines-per-function */
import { Theme } from '@mui/material/styles/createTheme';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    paddingLeft: '20px',
  },
  basicInfo: {
    display: 'flex',
    gap: '50px',
  },
  permissionsInfo: {
    marginTop: '15px',
  },
  merchantInfo: {
    padding: '10px',
    display: 'flex',
  },
  stripeButton: {
    marginLeft: 'auto',
    order: 2,
  },
}));
