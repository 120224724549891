import { gql } from '@apollo/client';

export const ACCOUNTS_AUTOCOMPLETE_QUERY = gql`
  query accounts($input: AccountsInput!) {
    accounts(input: $input) {
      _id
      name
      publicId
      apiKey
    }
  }
`;
