import { UncontrolledSelect } from '@components/Input/Select';
import { UserIcon } from '@components/UserIcon';
import { useConfirmDialog } from '@hooks/useConfirmDialog';
import { ICxContractor, useCxContractersV2 } from '@hooks/useCxContractors';
import { useSnackbar } from '@hooks/useSnackbar';
import { FormControl, SelectChangeEvent, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import { UserRef } from '@common/types/GraphqlTypes.d';

import { ReassignmentConfirmModal } from './ConfirmModal';
import { useUpdateOrderAssignee } from './graphql';

const useStyles = makeStyles({
  root: {
    minWidth: '250px',
  },
});

type AssigneeProps = {
  orderId: string;
  currentAssignee?: UserRef | null;
};

/**
 * Returns selected assignee properties based on selected user ID.
 */
const getAssignee = (assignees: ICxContractor[], selectedUserId?: string): UserRef | undefined => {
  if (!selectedUserId) {
    return;
  }

  const selectedAssignee = assignees.find(({ userId }) => userId === selectedUserId);

  return selectedAssignee
    ? { userId: selectedAssignee.userId, name: selectedAssignee.name }
    : undefined;
};

/**
 * Returns Assignee component.
 * Automatically fetches all CX contractors from API.
 */
export const CustomerOrderAssignee = ({ orderId, currentAssignee }: AssigneeProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [existingAssignee, setExistingAssignee] = useState<string | undefined>(
    currentAssignee?.userId,
  );

  // Currently allowed assignees are only CX constractors.
  // TODO: Add sorting back to CX contractors.
  const { contractors: allowedAssignees, isLoading } = useCxContractersV2();

  // Handle confirmation modal
  const {
    isModalOpen,
    newValue,
    cancel: closeModal,
    openConfirm: openModal,
    confirm: handleConfirmModal,
  } = useConfirmDialog<UserRef | undefined>();

  // Handle assignee API update
  const { updateAssigneeMutation } = useUpdateOrderAssignee();
  const updateAssignee = (assignee: UserRef | null = null) => {
    updateAssigneeMutation({
      variables: { _id: orderId, input: { assignee } },
      onCompleted: () => {
        // Update dropdown value after API call completes
        setExistingAssignee(assignee?.userId);

        enqueueSnackbar(
          assignee?.userId
            ? `Assigned customer order to ${assignee?.name}`
            : 'Customer order has been unassigned',
        );
      },
      onError: (error) => {
        enqueueSnackbar(`Error assigning customer order - ${error.message}`, { variant: 'error' });
      },
    });
  };

  // Handles when dropdown value changes
  const handleSelectChange = ({ target: { value } }: SelectChangeEvent<string | number>) => {
    const selectedAssignee = getAssignee(allowedAssignees, value as string);

    // Only if assigned (not when unassigned)
    if (existingAssignee) {
      openModal(selectedAssignee);
    } else if (selectedAssignee) {
      updateAssignee(selectedAssignee);
    }
  };

  return (
    <>
      <FormControl className={classes.root}>
        <UncontrolledSelect
          labelId="assignee-select-label"
          value={existingAssignee ?? 'Unassigned'}
          onChange={handleSelectChange}
          isLoading={isLoading}
        >
          {allowedAssignees.map(({ name, userId, color }) => (
            <MenuItem key={userId} value={userId}>
              <UserIcon name={name} color={color} showName={true} />
            </MenuItem>
          ))}
          <MenuItem value={'Unassigned'}>
            <UserIcon name="Unassigned" showName={true} isDefault={true} />
          </MenuItem>
        </UncontrolledSelect>
      </FormControl>

      <ReassignmentConfirmModal
        oldAssignee={getAssignee(allowedAssignees, existingAssignee)}
        newAssignee={newValue}
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={() => handleConfirmModal(updateAssignee)}
      />
    </>
  );
};
