import { Facet, Sorting } from '@elastic/react-search-ui';
import { MultiCheckboxFacet } from '@elastic/react-search-ui-views';
import React from 'react';

import { appSearchSorting, ProductField } from '../../config';

export const SearchProductsSidebar = () => (
  <div>
    <Sorting label="Sort on" sortOptions={appSearchSorting} />
    <Facet
      field={ProductField.merchantName}
      label="merchant"
      isFilterable={true}
      view={MultiCheckboxFacet}
      filterType="any"
    />
    <Facet
      field={ProductField.brandName}
      label="brand"
      isFilterable={true}
      view={MultiCheckboxFacet}
      filterType="any"
    />
    <Facet
      field={ProductField.gender}
      label="gender"
      isFilterable={false}
      view={MultiCheckboxFacet}
      filterType="any"
    />
    <Facet
      field={ProductField.validationFlags}
      label="Validation flags"
      isFilterable={true}
      view={MultiCheckboxFacet}
      filterType="any"
    />
  </div>
);
