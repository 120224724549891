import { Header } from '@components/Header';
import React from 'react';

import { IAccount } from './graphql';

type ActionsProps = {
  account?: IAccount;
  isLoading: boolean;
};

export const EditAccountHeader = ({ account, isLoading }: ActionsProps) => (
  <Header title={`Accounts - ${account?.name}`} isLoading={isLoading} />
);
