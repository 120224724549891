import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useSnackbar } from '@hooks/useSnackbar';

import { MerchantOrder } from '@common/types/GraphqlTypes.d';

export const MERCHANT_ORDER_WITH_FULFILLMENT_QUERY = gql`
  query getOneMerchantOrder($input: MerchantOrderInput!) {
    getOneMerchantOrder(input: $input) {
      _id
      customerOrderId
      fulfillmentDetails {
        _id
        trackingNumber
        trackingUrl
        shippingCarrier
        shippingCarrierCustomName
        cancelled
        products {
          productId
          variantId
          quantity
        }
      }
      checkoutVariants {
        variant {
          id
          brand
          currency
          images
          optionTitles
          optionValues
          price
          priceUSD
        }
        _id
        name
        productId
        productURL
        publicId
        purchaseQuantity
      }
      ghostExternalOrders {
        _id
        products {
          productId
          quantity
        }
      }
    }
  }
`;

export type OrderData = Pick<
  MerchantOrder,
  '_id' | 'customerOrderId' | 'fulfillmentDetails' | 'checkoutVariants' | 'ghostExternalOrders'
>;

type Data = { getOneMerchantOrder: OrderData };

export const useMerchantOrderWithFulfillment = (
  merchantOrderId?: string,
  options: QueryHookOptions<Data> = {},
) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery(MERCHANT_ORDER_WITH_FULFILLMENT_QUERY, {
    variables: { input: { merchantOrderId } },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    ...options,
  });

  return {
    isLoading,
    data: data?.getOneMerchantOrder,
    error,
  };
};
