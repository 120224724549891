import { joiResolver } from '@hookform/resolvers/joi';
import { Grid, Stack } from '@mui/material';
import { CustomerOrderCustomer } from '@pages/CustomerOrderV2/View/components/Customer';
import React from 'react';
import { useForm } from 'react-hook-form';

import { MerchantOrder, ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { Items } from '../ItemsSelect';
import { OrderDetails } from './OrderDetails';
import {
  FeedOrderFormSchema,
  FEED_ORDER_FIELD,
  FEED_ORDER_MODE,
  IFeedOrderFormData,
} from './types';
import { getFormDefaultValues } from './utils';

interface IFeedOrderForm {
  feedOrderId?: string;
  isLoading: boolean;
  merchantOrder: MerchantOrder;
  mode: FEED_ORDER_MODE;
  onProcessFeedOrder: (feedOrderData: IFeedOrderFormData) => void;
}

export const FeedOrderForm = ({
  feedOrderId,
  isLoading,
  merchantOrder,
  mode,
  onProcessFeedOrder,
}: IFeedOrderForm) => {
  const feedOrder = merchantOrder.ghostExternalOrders?.find(({ _id }) => _id === feedOrderId);

  const formValidationHook = useForm<IFeedOrderFormData>({
    // TODO: Fix discrepancy between ghostOrder types!!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: getFormDefaultValues(merchantOrder, feedOrder as any),
    mode: 'onChange',
    resolver: joiResolver(FeedOrderFormSchema),
  });

  const getProductValues = () => formValidationHook.getValues(FEED_ORDER_FIELD.PRODUCTS);
  const setProductValues = (items: ShortcutProduct[]) =>
    formValidationHook.setValue(FEED_ORDER_FIELD.PRODUCTS, items);

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={6}>
        <Stack gap={3}>
          <Items
            getValues={getProductValues}
            setValues={setProductValues}
            isLoading={isLoading}
            merchantOrder={merchantOrder}
            feedOrderId={feedOrderId}
          />
          <CustomerOrderCustomer orderId={merchantOrder.customerOrderId} />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <OrderDetails
          formValidationHook={formValidationHook}
          merchantOrder={merchantOrder}
          mode={mode}
          onProcessFeedOrder={formValidationHook.handleSubmit(onProcessFeedOrder)}
        />
      </Grid>
    </Grid>
  );
};
