import { IRoute } from '@routes/interfaces';
import { routes as privateRoutes } from '@routes/private';
import { routes as publicRoutes } from '@routes/public';
import { useLocation } from 'react-router-dom';

const existingRoutes = { ...publicRoutes, ...privateRoutes };

export const usePath = () => {
  const { pathname, search } = useLocation();

  /**
   * Returns true if the current path is active.
   * Supports optional quick filter.
   * @param {string} route - Route name (defined in public/private `routes.ts`).
   * @param {string} quickFilter - Optional quick filter to be appended to the URL query.
   */
  const isActive = (route?: IRoute, quickFilter?: string) => {
    if (!route) {
      return false;
    }

    // Find existing route
    const { path } = existingRoutes[route] || {};

    if (!path) {
      return false;
    }

    return quickFilter
      ? pathname === path && search.includes(`quickFilter=${quickFilter}`)
      : pathname === path;
  };

  /**
   * Indicates whether any of the provided routes has an active path.
   * @param routes - List of routes to check.
   */
  const hasActivePath = (routes: IRoute[]) =>
    routes.map((key) => existingRoutes[key].path).includes(pathname);

  return {
    isActive,
    hasActivePath,
  };
};
