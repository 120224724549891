import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { Stack } from '@mui/material';
import React from 'react';

import { ACCOUNT_DEFAULTS, ACCOUNT_EMAIL_PROVIDER_OPTIONS } from '@constants/account';

import { ACCOUNT_FIELDS } from '../fields';
const { emailProvider: inputField } = ACCOUNT_FIELDS;

export const EmailConfig = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Email configuration">
    <Stack spacing={2}>
      <ControlledSelect
        id={inputField.type}
        isLoading={isLoading}
        label="Email Provider"
        options={ACCOUNT_EMAIL_PROVIDER_OPTIONS}
        defaultValue={ACCOUNT_DEFAULTS.emailProvider}
      />
    </Stack>
  </Card>
);
