import React from 'react';

import { LogoutMenuItem } from './Logout';
import { ResetPasswordMenuItem } from './ResetPassword';

export const ProfileActions = () => (
  <>
    <ResetPasswordMenuItem />
    <LogoutMenuItem />
  </>
);
