import { Card } from '@components/Card';
import { DataFieldProps } from '@components/DataField';
import { DataStack } from '@components/DataStack';
import { ICustomerOrderMetadata } from '@hooks/useCustomerOrderV2';
import React from 'react';

type CustomerOrderMetadataProps = {
  customerOrder?: ICustomerOrderMetadata;
  isLoading: boolean;
};

export const CustomerOrderMetadata = ({ customerOrder, isLoading }: CustomerOrderMetadataProps) => {
  const { _id, publicId } = customerOrder || {};

  // Display the following information
  const items: DataFieldProps[] = [
    { label: 'Internal ID', value: _id, copy: true, shouldCapitalize: false },
    { label: 'Public ID', value: publicId, copy: true, shouldCapitalize: false },
  ];

  return (
    <Card title="Metadata">
      <DataStack stackId="customerOrderMetadata" items={items} isLoading={isLoading} />
    </Card>
  );
};
