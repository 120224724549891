import { gql } from '@apollo/client';

/**
 * Returns order ID.
 */
export const CONTRACTORS_QUERY = gql`
  query allCxContractorUsers {
    allCxContractorUsers {
      users {
        _id
        profile {
          name
        }
        email
      }
    }
  }
`;
