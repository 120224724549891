import { gql } from '@apollo/client';

export const GET_MERCHANTS_AUTOCOMPLETE = gql`
  query merchantAutocomplete($input: AutocompleteInput!) {
    merchantAutocomplete(input: $input) {
      _id
      name
    }
  }
`;
