import { DropDownButton } from '@components/Button/Dropdown';
import { Fullscreen as FullscreenIcon, ArrowDropDown as DropdownIcon } from '@mui/icons-material';
import { Box, Divider, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  MarkProductAvailableItem,
  MarkProductUnavailableItem,
} from '@pages/Order/components/Product/menuItems/HandleProductAvailability';
import { OpenProductInBonsaiUI } from '@pages/Order/components/Product/menuItems/OpenBonsaiUi';
import { ProductBrand } from '@pages/Product/components/Brand';
import { ProductInventory } from '@pages/Product/components/Inventory';
import { ProductMerchant } from '@pages/Product/components/Merchant';
import { ProductName } from '@pages/Product/components/Name';
import { ProductPrice } from '@pages/Product/components/Price';
import { ResultContext } from '@pages/Product/Overview';
import { Color } from '@theme/palette';
import React, { useContext } from 'react';

import { getProductValues, IProductResult } from './utils';

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    height: 19,
    marginRight: 5,
  },
  actions: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    marginRight: '5px',
    marginBottom: '5px',
    color: Color.DarkNight,
  },
  showActions: {
    padding: '2px',
  },
  showDetails: {
    width: '1em',
    cursor: 'pointer',
  },
}));

/**
 * Main component used for rendering a single product result.
 */
export const SearchProductResult = ({ result }: { result: IProductResult }) => {
  const classes = useStyles();

  const {
    publicId,
    name,
    thumbnail,
    inventory,
    url,
    minPrice,
    maxPrice,
    currency,
    category,
    merchant,
    gender,
    brand,
    updatedAt,
    validationFlags,
  } = getProductValues(result);

  const isUnavailable = validationFlags.includes('isUnavailable');

  const { setSelectedProduct } = useContext(ResultContext);

  const handleOpenProduct = () => {
    setSelectedProduct({ publicId, updatedAt });
  };

  // TODO: Pull markProductAsUnavailable out of Order pages
  const menuItems = (
    <>
      {isUnavailable ? (
        <MarkProductAvailableItem productPublicId={publicId} />
      ) : (
        <MarkProductUnavailableItem productPublicId={publicId} />
      )}

      <OpenProductInBonsaiUI productPublicId={publicId} />
    </>
  );

  return (
    <Box className="sui-result">
      {/* Header */}
      <div className="product-header">
        {/* Brand, category & gender */}
        <ProductBrand category={category} brand={brand.name} gender={gender} />

        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
          {/* Product name */}
          <ProductName publicId={publicId} name={name} validationFlags={validationFlags} />

          {/* Inventory & validation flags */}
          <ProductInventory inventory={inventory} validationFlags={validationFlags} />
        </Stack>
      </div>

      {/* Body */}
      <div className="product-body" style={{ marginTop: '10px' }}>
        <Stack spacing={2} direction="row">
          {/* Image */}
          <img
            style={{ maxWidth: '70px', maxHeight: '70px' }}
            src={`${thumbnail}?w=70`}
            alt={name}
            loading="lazy"
          />

          <div className="product-info">
            {/* Price */}
            <ProductPrice minPrice={minPrice} maxPrice={maxPrice} currency={currency} />

            {/* Merchant */}
            <ProductMerchant id={merchant.id} name={merchant.name} url={url} />
          </div>
        </Stack>
      </div>

      {/* View details */}
      <span className={classes.actions}>
        <Stack direction="row" alignItems="center">
          <DropDownButton
            Icon={DropdownIcon}
            menuItems={menuItems}
            className={classes.showActions}
          />
          <Divider orientation="vertical" className={classes.divider} />
          <FullscreenIcon className={classes.showDetails} onClick={handleOpenProduct} />
        </Stack>
      </span>
    </Box>
  );
};
