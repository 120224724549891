import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';

import { ACCOUNT_FIELDS } from '../fields';
const { metadata: inputField } = ACCOUNT_FIELDS;

export const Metadata = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Metadata">
    <Stack spacing={2}>
      <TextField
        id={inputField.publicId}
        isLoading={isLoading}
        label="Public ID"
        allowCopy
        disabled
        useHook
      />
      <TextField
        id={inputField.apiKey}
        isLoading={isLoading}
        label="Publishable API key"
        allowCopy
        disabled
        useHook
      />
      <TextField
        id={inputField.privateApiKey}
        isLoading={isLoading}
        label="Secret API Key"
        allowCopy
        disabled
        useHook
      />
      <TextField
        id={inputField.testPublishableApiKey}
        isLoading={isLoading}
        label="Test Publishable API Key"
        allowCopy
        disabled
        useHook
      />
      <TextField
        id={inputField.testSecretApiKey}
        isLoading={isLoading}
        label="Test Secret API Key"
        allowCopy
        disabled
        useHook
      />
    </Stack>
  </Card>
);
