import MuiButton from '@mui/material/Button';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiUnstyledInput from '@mui/material/InputBase';
import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import MuiSwitch from '@mui/material/Switch';
import MuiTextField from '@mui/material/TextField';

import { Color } from '../../theme/palette';

export const INPUT_HEIGHT = 45;

/**
 * Returns a styled Select input component.
 * See https://mui.com/material-ui/api/input-base/ for more info.
 */
export const StyledSelectInput = styled(MuiUnstyledInput)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: INPUT_HEIGHT,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${Color.Gray3}`,
    fontSize: '0.9em',
    padding: '10px 42px 10px 16px !important', // Important overwrites default padding-right
    '&:hover': {
      border: `1px solid ${Color.Gray1}`,
    },
    '&:focus': {
      border: `1px solid ${Color.Gray1}`,
    },
  },
  '& .MuiMenuItem-root': {
    padding: '5px 16px',
    fontSize: '1em',
  },
}));

/**
 * Returns a styled TextField component.
 * See https://mui.com/material-ui/react-text-field for more info.
 */
export const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: INPUT_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    '& fieldset': {
      border: `1px solid ${Color.Gray3}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${Color.Gray1}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${Color.Gray1}`,
    },
  },
  '& .MuiInputBase-root': {
    paddingRight: '15px !important',
  },
}));

/**
 * Returns a styled Button component.
 * See https://mui.com/material-ui/react-button for more info.
 */
export const StyledButton = styled(MuiButton)(({ theme }) => ({
  height: INPUT_HEIGHT,
  padding: '7px 16px',
  color: Color.Gray1,
  fontWeight: 400,
  fontSize: '0.9em',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${Color.Gray3}`,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${Color.Gray1}`,
  },
  disableRipple: true,
  size: 'medium',
}));

/**
 * Returns a styled Popper component.
 * See https://mui.com/material-ui/react-popper for more info.
 */
export const StyledPopper = styled(MuiPopper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: '0.9em',
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

/**
 * Returns a styled Switch component.
 * See https://mui.com/material-ui/switch/ for more info.
 */
export const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 38,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18,
    },
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: Color.MapleRed,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: Color.Gray3,
    opacity: 1,
  },
}));

/**
 * Returns a styled FormControlLabel component.
 * See https://mui.com/material-ui/api/form-control-label/ for more info.
 */
export const StyledFormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: 0,
  '& .MuiTypography-root': {
    fontSize: '1em',
    marginLeft: 5,
  },
}));
