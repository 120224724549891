import { CountryCode, Currency, CommissionType } from '@types';

import { IntegrationType } from '@common/types/GraphqlTypes.d';

// Merchant default values
export const MERCHANT_DEFAULTS = {
  currency: Currency.USD,
  country: CountryCode.US,
  taxType: 'default',
  integrationType: IntegrationType.Shopify,
  comissionType: CommissionType.SalesAfterReturns,
  canUseFlexiblePayment: true,
  shipping: 'simple',
};

export const MERCHANT_CURRENCIES = [
  { value: Currency.AUD, label: 'AUD' },
  { value: Currency.CAD, label: 'CAD' },
  { value: Currency.USD, label: 'USD' },
  { value: Currency.GBP, label: 'GBP' },
  { value: Currency.EUR, label: 'EUR' },
];

export const MERCHANT_COUNTRIES = [
  { value: CountryCode.CA, label: 'Canada' },
  { value: 'GB', label: 'United Kingdom' },
  { value: CountryCode.US, label: 'United States' },
  { value: CountryCode.PL, label: 'Poland' },
  { value: CountryCode.IT, label: 'Italy' },
  { value: CountryCode.NL, label: 'Netherlands' },
];

export const MERCHANT_COMMISSIONS = [
  { value: CommissionType.SalesAfterReturns, label: 'Sales after returns' },
  { value: CommissionType.SalesBeforeReturns, label: 'Sales before returns' },
];

export const MERCHANT_SHIPPING_OPTIONS = [{ value: 'simple', label: 'Default' }];

export const MERCHANT_SHIPPING_COUNTRIES = [
  ...MERCHANT_COUNTRIES,
  { value: 'OTHER', label: 'OTHER' },
];

export const MERCHANT_INTEGRATION_TYPES = [
  { value: IntegrationType.Feed, label: 'Feed' },
  { value: IntegrationType.Shopify, label: 'Shopify' },
];

export const MERCHANT_TAX_TYPES = [
  { value: 'default', label: 'Default' },
  { value: 'eci', label: 'E-Commerce Integration' },
];

// Single point of constants

export { IntegrationType as MerchantIntegrationType } from '@common/types/GraphqlTypes.d';
