import { Card } from '@components/Card';
import { DataGridV2 } from '@components/DataGrid';
import { GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { merchantWebhooks } from '@types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ClientStatus, Merchant } from '@common/types/GraphqlTypes.d';
import { MerchantIntegrationType, MERCHANT_DEFAULTS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { integration: inputField, basic: basicField } = MERCHANT_FIELDS;

const columns: GridColDef<Merchant>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
];

export const Webhooks = ({ isLoading }: { isLoading?: boolean }) => {
  const { getValues, setValue } = useFormContext();
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

  // Fetch permissions from form values
  const webhooks = getValues(inputField.enabledWebhooks);

  // All available webhooks (shared across FE & API)
  // Map `_id` as every row needs a unique id and DataGrid does some funky stuff with getRowId
  const availableWebhooks = Object.values(merchantWebhooks).map((x) => ({ ...x, _id: x.key }));

  // After permissions get loaded, set initial selected checkboxes. (row ID = permission key)
  useEffect(() => {
    setSelectionModel(webhooks || []);
  }, [webhooks]);

  // Whenever a checkbox gets selected. Returns entire list of grid IDs (permission keys)
  const onSelectionModelChange = (value: GridRowId[]) => {
    // Update UI form checkbox
    setSelectionModel(value);

    // Update react-hook-form value - trigger dirty change (affects save button)
    setValue(inputField.enabledWebhooks, value, { shouldDirty: true });
  };

  // Webhooks are currently only supported for Shopify
  const integrationType = useWatch({
    name: inputField.type,
    defaultValue: MERCHANT_DEFAULTS.integrationType,
  });

  // Webhooks can only be managed when merchant is not archived.
  // Archived merchants will have webhooks disabled by default.
  const publishedStage = useWatch({
    name: basicField.stage,
    defaultValue: false,
  });

  return integrationType === MerchantIntegrationType.Shopify &&
    publishedStage !== ClientStatus.Archived ? (
    <Card title="Webhooks" isLoading={isLoading}>
      {!isLoading && (
        <DataGridV2
          checkboxSelection={true}
          pagination={false}
          loading={isLoading}
          rows={availableWebhooks}
          columns={columns}
          getRowId={(row) => row._id}
          rowCount={availableWebhooks.length}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
        />
      )}
    </Card>
  ) : null;
};
