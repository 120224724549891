import { Card } from '@components/Card';
import { DataStack } from '@components/DataStack';
import { routes } from '@routes/private';
import React from 'react';
import { Link } from 'react-router-dom';

import { IProductCollection } from '../graphql';

type BasicInformationProps = {
  collection?: IProductCollection;
  isLoading?: boolean;
};

export const rows = ({ name, handle, account }: IProductCollection) => [
  {
    label: 'Name',
    value: name,
  },
  {
    label: 'Handle',
    value: handle,
  },
  {
    label: 'Account',

    // TODO: Figure out why publicId works?!
    value: <Link to={routes.editAccount.getPath(account.publicId)}>{account.name}</Link>,
  },
];

export const ViewBasicInformation = ({ isLoading, collection }: BasicInformationProps) => (
  <Card title="Basic Information" isLoading={isLoading}>
    <DataStack stackId="basic" isLoading={isLoading} items={collection ? rows(collection) : []} />
  </Card>
);
