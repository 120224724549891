import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  shippingHeader: {
    marginTop: '10px',
  },
  hidden: {
    visibility: 'hidden',
  },
  ratesTitle: {
    margin: '10px 0 0 0',
  },
  optionsTitle: {
    margin: '10px 0 0 0',
  },
}));
