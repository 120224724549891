/* eslint-disable complexity */
import { PrimaryButton } from '@components/Button/Primary';
import { Form } from '@components/Form';
import { ControlledSelect } from '@components/Input/Select';
import { TextEditor } from '@components/Input/TextEditor';
import { UserIcon } from '@components/UserIcon';
import { useAuth } from '@hooks/useAuthentication';
import { useMerchantOrdersByCustomerOrderId } from '@hooks/useMerchantOrders';
import { useSnackbar } from '@hooks/useSnackbar';
import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Color, getProfileColorForId } from '@theme/palette';
import { Escalation } from '@types';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { CUSTOMER_ORDER_QUERY } from '../../../../../hooks/useCustomerOrder';
import { ApiError } from '../../../../Misc/ApiError';
import { useAddComment } from './graphql';

export const useStyles = makeStyles({
  editor: {
    '& .ql-container': {
      border: 'none',
    },
    '& .ql-toolbar': {
      border: 'none',
    },
  },
  form: {
    backgroundColor: Color.White,
    border: `1px solid ${Color.Gray2}`,
    borderRadius: 4,
    width: '100%',
  },
  actions: {
    padding: '15px',
  },
  dropdownSelector: {
    width: 250,
  },
});

export type ICommentInput = {
  comment: string;
  escalationStatus: Escalation | string;
  product: string;
};

// All available escalation options
const ESCALATION_OPTIONS = Object.values(Escalation).map((status) => ({
  label: status,
  value: status,
}));

type CustomerOrderAddCommentProps = {
  /**
   * Order ID.
   */
  orderId: string;

  /**
   * Callback method when orders got successfully added.
   * Optional given the component calls API and adds a comment.
   */
  onCommentAdded?: (values: ICommentInput) => void;
};

/**
 * Returns add order comment.
 */
export const AddCustomerOrderComment = ({
  orderId,
  onCommentAdded,
}: CustomerOrderAddCommentProps) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // Fetch merchant orders for the current order.
  // Needed to select a product for escalation.
  // TODO: Optimize by only fetching required product IDs & names
  const { isLoading: isLoadingMerchantOrders, merchantOrders } = useMerchantOrdersByCustomerOrderId(
    orderId,
    { fetchPolicy: 'cache-and-network' },
  );

  // Add comment mutation
  const { addCommentMutation } = useAddComment();

  // Set up React form hook
  const form = useForm<ICommentInput>({
    mode: 'onSubmit',
  });

  // Track when form got updated
  const { dirtyFields } = useFormState({
    control: form.control,
  });

  // Could not load merchant orders - product selection is unavailable.
  if ((!merchantOrders && !isLoadingMerchantOrders) || !currentUser) {
    return <ApiError card={false} message="Currently unable to add new comments." />;
  }

  // Current user information. This is used to display the user icon.
  const { name, id: currentUserId } = currentUser;

  // Submit new comment
  const onSubmit = (values: ICommentInput) => {
    const { comment, escalationStatus, product } = values;

    addCommentMutation({
      variables: {
        _id: orderId,
        input: {
          comment,
          // If nothing is selected this defaults to empty string which we don't want
          escalationStatus: escalationStatus || undefined,
          product: product === '' ? undefined : JSON.parse(product),

          // Track user submitting the comment
          user: { name, userId: currentUserId },
        },
      },

      // TODO: Refetching makes the page re-render. We should retrieve the new comment from the mutation result.
      refetchQueries: [CUSTOMER_ORDER_QUERY],
      onCompleted: () => {
        form.reset();

        // Inform parent component that comment was added.
        if (onCommentAdded) {
          onCommentAdded(values);
        }
      },
      onError: (error) => {
        enqueueSnackbar(`Comment could not be added due to ${error.message}`, { variant: 'error' });
      },
    });
  };

  // Allows escalation to be unselected
  const escalationOptions = [{ value: '', label: 'None' }, ...ESCALATION_OPTIONS];

  // Allowed products to escalate.
  const productOptions = [
    { value: '', label: 'None' },
    ...merchantOrders.flatMap(({ checkoutVariants }) =>
      checkoutVariants.map(({ name, productId, variant: { id } }) => ({
        label: name,

        // JSON objects are not supported as select values
        value: JSON.stringify({ name, productId, variantId: id }),
      })),
    ),
  ];

  return (
    <Stack direction="row" alignItems="flex-start" gap={1}>
      <UserIcon name={name} color={getProfileColorForId(currentUserId)} showName={false} />

      <FormProvider {...form}>
        <Form className={classes.form} onSubmit={form.handleSubmit(onSubmit)}>
          {/* Editor for comment */}
          <TextEditor
            id="comment"
            placeholder="Add a comment"
            options={{ required: true }}
            className={classes.editor}
            useHook
          />

          {/* Action bar */}
          <Stack
            className={classes.actions}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={2}
          >
            <Stack direction="row" flexWrap="wrap" gap={1}>
              <ControlledSelect
                id="escalationStatus"
                placeholder="Select Escalation"
                className={classes.dropdownSelector}
                options={escalationOptions}
                defaultValue={''}
              />

              <ControlledSelect
                id="product"
                placeholder="Select Product"
                className={classes.dropdownSelector}
                options={productOptions}
                defaultValue={''}
                isLoading={isLoadingMerchantOrders}
              />
            </Stack>

            <PrimaryButton type="submit" disabled={!dirtyFields.comment}>
              Post
            </PrimaryButton>
          </Stack>
        </Form>
      </FormProvider>
    </Stack>
  );
};
