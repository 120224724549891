/* eslint-disable max-lines-per-function */
import { Theme } from '@mui/material/styles/createTheme';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  image: {
    alignItems: 'center',
    background: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: '72px',
    height: '220px',
    justifyContent: 'center',
    letterSpacing: theme.spacing(1),
    textAlign: 'center',
    width: '220px',
  },
}));
