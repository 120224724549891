import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { CURRENT_USER_QUERY } from '@hooks/useCurrentUser/graphql';

interface IUpdateMerchantStripeInput {
  input: {
    stripeCode: string;
    stripeStatus: string;
  };
}

export const UPDATE_MERCHANT_STRIPE_MUTATION = gql`
  mutation updateMerchantStripe($input: UpdateMerchantStripeInput!) {
    updateMerchantStripe(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for updating merchant stripe state.
 * @param options - {object} [options] - Mutation options for graphql.
 */
export const useUpdateMerchantStripe = (options: MutationHookOptions = {}) => {
  const [updateMerchantStripe, { error, loading: isLoading }] =
    useMutation<IUpdateMerchantStripeInput>(UPDATE_MERCHANT_STRIPE_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [CURRENT_USER_QUERY],
      ...options,
    });

  return { updateMerchantStripe, error, isLoading };
};
