import Stack from '@mui/material/Stack';
import { Color } from '@theme/palette';
import React from 'react';

import { CustomerOrder } from '@common/types/GraphqlTypes.d';

import { IUnprocessedOrderItems } from '../interfaces';
import { ItemStatus } from '../ItemStatus';
import { getTotalItemPurchaseQuantity } from '../utils';
import { UnprocessedItem } from './unprocessedItem';

interface IUnprocessedItemsProps {
  /**
   * Current customer order.
   * Mainly needed for Aria Chrome Extension. Will be `id` only once Aria handles fetching data itself.
   */
  customerOrder: CustomerOrder;

  /**
   * List of unprocessed items within specific merchant order.
   */
  items: IUnprocessedOrderItems[];
}

export const UnprocessedItems: React.FC<IUnprocessedItemsProps> = ({ items, customerOrder }) => {
  if (items.length === 0) {
    return null;
  }
  const numberOfItems = items.reduce(
    (total, { checkoutVariants }) => total + getTotalItemPurchaseQuantity(checkoutVariants),
    0,
  );

  return (
    <div>
      <ItemStatus statusText="Unprocessed" statusColor={Color.Red} numberOfItems={numberOfItems} />
      <Stack gap={2} direction="column">
        {items.map((item) => (
          <UnprocessedItem items={item} key={item.merchantOrderId} customerOrder={customerOrder} />
        ))}
      </Stack>
    </div>
  );
};
