/* eslint-disable max-lines-per-function */
import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BasicInformation } from '../components/Basic/Basic';
import { UserMerchant } from '../components/Basic/Merchant';
import { UserRoles } from '../components/Roles';
import { IUserInput } from '../fields';
import { useCreateUser } from './graphql';
import { CreateUserHeader } from './Header';

export const CreateUser = () => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IUserInput>({
    mode: 'onSubmit',
  });

  // GraphQL hooks
  const { createUserMutation, apiError } = useCreateUser();

  const onSubmit = (values: IUserInput) => {
    // Map merchant object to merchantId
    const createValues = {
      ...R.omit(['merchant'], values),
      merchantId: values.merchant?._id ?? undefined,
    };

    // Manual check as table & checkboxes are not hooked decently into react-hook-form
    if (!createValues.userRoles || createValues.userRoles.length === 0) {
      return enqueueSnackbar('A user needs to have at least 1 role assigned', { variant: 'error' });
    }

    createUserMutation({
      variables: { input: createValues },
      onCompleted: ({ createUser }) => {
        goto.editUser(createUser._id);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error creating user:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listUsers();
  };

  return (
    <Stack gap={3}>
      <CreateUserHeader />

      <FormProvider {...form}>
        <Form error={apiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={3}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <BasicInformation />
              </Grid>

              <Grid item xs={6}>
                <UserMerchant />
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <UserRoles />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  <PrimaryButton type="submit">Create user</PrimaryButton>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormProvider>
    </Stack>
  );
};
