import { Close } from '@mui/icons-material';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';

export type DialogProps = MuiDialogProps & {
  /**
   * Title of the dialog.
   */
  title: string;

  /**
   * Indicates whether to show dialog or not.
   */
  open: boolean;

  /**
   * Gets called when the user closes the dialog.
   */
  onClose: () => void;

  /**
   * List of actions to show in the dialog at the bottom.
   */
  actions?: React.ReactNode;

  /**
   * Children to render inside the dialog.
   */
  children?: React.ReactNode;
};

export const Dialog = ({ title, children, open, onClose, actions, ...muiProps }: DialogProps) => (
  <MuiDialog open={open} onClose={onClose} {...muiProps}>
    <DialogTitle>
      {title}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      )}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    {actions && (
      <DialogActions sx={{ paddingX: '20px', paddingBottom: '20px' }}>{actions}</DialogActions>
    )}
  </MuiDialog>
);
