import { UserInput } from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
export interface IUserInput extends UserInput {
  merchant?: {
    _id: string;
    name: string;
  };
}

// TODO: Better use of GraphQL types. They can't be trusted fully right now due to weird Maybe types as well as not selecting all props all the time.
// Note: Once hooks get autogenerated, these types are no longer needed
export type IUser = {
  _id: string;
  profile: {
    firstName: string;
    lastName: string;
  };
  merchant?: {
    _id: string;
    name: string;
  };
  merchantId?: string;
  roles: string[];
  email: string;
  userRoles: string[];
  createdAt: string;
};

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for keys.
 */
export type IUserField = Join<
  PathsToStringProps<IUser & { merchant: string; userRoles: string }>,
  '.'
>;

/**
 * Returns all available user fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const USER_FIELDS: Record<string, IUserField> = {
  firstName: 'profile.firstName',
  lastName: 'profile.lastName',
  email: 'email',
  userRoles: 'userRoles',
  merchantId: 'merchantId',
  merchant: 'merchant',
};
