import { Header } from '@components/Header';
import { withParams } from '@components/WithParams';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { checkoutVariantToShortCutProduct } from '@pages/Order/components/ItemsSelect/utils';
import React from 'react';

import { CheckoutVariant, ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { useCreateFulfillment } from '../graphql/useCreateFulfillment';
import {
  OrderData,
  useMerchantOrderWithFulfillment,
} from '../graphql/useMerchantOrderWithFulfillment';
import { Fields, EMPTY_VALUES } from '../utils/form';
import { ShippingTrackingForm } from '../views/ShippingTrackingForm';

const filterAlreadyFulfilledProducts = (
  checkoutVariants: CheckoutVariant[],
  products: ShortcutProduct[],
): CheckoutVariant[] => {
  // Gather all fulfilled products and their quantities
  const fulfilledProductQuantities = products.reduce((acc, { productId, variantId, quantity }) => {
    const key = `${productId}-${variantId ?? ''}`;

    return {
      ...acc,
      [key]: (acc[key] ?? 0) + quantity,
    };
  }, {} as Record<string, number>);

  // Filter out products that have already been fulfilled. If the quantity is the same, remove it.
  // Otherwise, subtract the fulfilled quantity from the purchase quantity.
  return checkoutVariants.flatMap((product) => {
    const {
      productId,
      variant: { id },
      purchaseQuantity,
    } = product;
    const key = `${productId}-${id ?? ''}`;

    const isSame = fulfilledProductQuantities[key] === purchaseQuantity;

    if (isSame) {
      return [];
    }

    return [
      {
        ...product,
        purchaseQuantity: purchaseQuantity - (fulfilledProductQuantities[key] ?? 0),
      },
    ];
  });
};

const getDefaultValuesAndCheckoutVariants = (order?: OrderData) => {
  const filtered = filterAlreadyFulfilledProducts(
    order?.checkoutVariants ?? [],
    order?.fulfillmentDetails.flatMap(({ products }) => products) ?? [],
  );

  return {
    form: {
      ...EMPTY_VALUES,
      [Fields.Products]: filtered.map(checkoutVariantToShortCutProduct),
    },
    checkoutVariants: filtered,
  };
};

type ICreateShippingTrackingParams = { merchantOrderId: string };

const CreateShippingTracking = ({ merchantOrderId }: ICreateShippingTrackingParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { goto } = useRouter();
  const { data: order, isLoading } = useMerchantOrderWithFulfillment(merchantOrderId);
  const {
    createFulfillmentMutation,
    isLoading: isCreating,
    apiError,
  } = useCreateFulfillment({
    onCompleted: () => {
      enqueueSnackbar('Shipping tracking created', { variant: 'success' });

      if (order) {
        goto.viewOrder(order.customerOrderId);
      }
    },
  });

  return (
    <>
      <Header title="Shipping" />
      <ShippingTrackingForm
        merchantOrder={order}
        error={apiError}
        getDefaultValues={getDefaultValuesAndCheckoutVariants}
        onSubmit={(data) =>
          createFulfillmentMutation({
            variables: { input: { fulfillmentDetails: data, merchantOrderId } },
          })
        }
        isSubmitting={isCreating}
        isLoading={isLoading}
        ctaText="Submit shipping"
      />
    </>
  );
};

export const CreateShippingTrackingPage = withParams<ICreateShippingTrackingParams>(
  ['merchantOrderId'],
  CreateShippingTracking,
);
