import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
    display: 'flex',
    height: '80px',
    width: '60px',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '80px',
    maxWidth: '60px',
  },
});
