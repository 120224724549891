import { gql, useMutation, MutationHookOptions } from '@apollo/client';

export const SEND_CONFIRMATION_EMAIL = gql`
  mutation resendOrderConfirmationEmail($customerOrderId: String!) {
    resendOrderConfirmationEmail(customerOrderId: $customerOrderId) {
      result
    }
  }
`;

export const SEND_REFUND_EMAIL = gql`
  mutation resendRefundEmail($input: ResendRefundEmailInput!) {
    resendRefundEmail(input: $input) {
      result
    }
  }
`;

export const SEND_SHIPPING_EMAIL = gql`
  mutation sendFulfillmentDetailEmail($input: SendFulfillmentDetailEmailInput!) {
    sendFulfillmentDetailEmail(input: $input) {
      result
    }
  }
`;

/**
 * Hook for sending a shipping confirmation email.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useSendShippingEmail = (options: MutationHookOptions = {}) => {
  const [sendEmail, { error: apiError, loading: isLoading }] = useMutation(
    SEND_SHIPPING_EMAIL,
    options,
  );

  return { sendEmail, isLoading, apiError };
};

/**
 * Hook for sending a refund confirmation email.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useSendRefundEmail = (options: MutationHookOptions = {}) => {
  const [sendEmail, { error: apiError, loading: isLoading }] = useMutation(
    SEND_REFUND_EMAIL,
    options,
  );

  return { sendEmail, isLoading, apiError };
};

/**
 * Hook for sending an order confirmation email.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useSendOrderConfirmationEmail = (options: MutationHookOptions = {}) => {
  const [sendEmail, { error: apiError, loading: isLoading }] = useMutation(
    SEND_CONFIRMATION_EMAIL,
    options,
  );

  return { sendEmail, isLoading, apiError };
};
