import { MenuItem } from '@components/MenuItem';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

import { IRole } from '../../graphql';
import { useDeleteRole } from './graphql';

type ActionsProps = {
  role: IRole;
};

export const DeleteRoleMenuItem = ({ role }: ActionsProps) => {
  const { goto } = useRouter();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // GraphQL hook
  const { deleteRoleMutation } = useDeleteRole();

  const onSubmit = () => {
    deleteRoleMutation({
      variables: { id: role._id },
      onCompleted: () => {
        goto.listRoles();
      },
      onError: (error) => {
        enqueueSnackbar(`Deleting role failed with error ${error}`, {
          variant: 'error',
        });
      },
    });
  };

  const openConfirmModal = () => {
    // TODO: Avoid modal from immediately being closed due to handleClose from
    setIsConfirmDialogOpen(true);
  };

  const message = role.isProtected
    ? 'This is a protected role to keep the integrity of our system running and can not be deleted. Contact your administrator for more information.'
    : `Deleting this user role can not be reverted and will revoke these roles for existing
  users immediately. Make sure you know what you're doing or contact an administrator
  for more information.`;

  return (
    <>
      <MenuItem action={openConfirmModal} icon={DeleteIcon} label="Delete role" />

      <div>
        <Dialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`You are deleting ${role.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="error"
              onClick={onSubmit}
              disabled={role.isProtected}
            >
              Delete Role
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
