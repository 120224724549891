import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
import { withParams } from '@components/WithParams';
import { useSnackbar } from '@hooks/useSnackbar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { NotFound } from '@pages/Misc/NotFound';
import { omit } from 'ramda';
import React from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';

import { getMatchingDotValues } from '@utils/object';

import { Accounts } from '../components/Accounts';
import { BasicInformation } from '../components/Basic';
import { Metadata } from '../components/Metadata';
import { CLIENT_FIELDS, IClientInputUpdate } from '../fields';
import { IClient, useClient, useEditClient } from './graphql';
import { EditClientHeader } from './Header';

/**
 * Returns mapped GraphQL values into input fields key/value for auto-population.
 */
const getValues = (client: IClient) => getMatchingDotValues(CLIENT_FIELDS, client);

type IEditClientParams = { id: string };

const EditClient = ({ id }: IEditClientParams) => {
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IClientInputUpdate>({
    mode: 'onSubmit',
  });

  const { isDirty: hasFormChanged } = useFormState({
    control: form.control,
  });

  // GraphQL hooks
  const { isLoading, data } = useClient({
    variables: { id },
    onCompleted: ({ client }) => {
      form.reset(getValues(client)); // Set react-hook-form values
    },
    onError: (error) => {
      console.error('API error fetching client:', error);
    },
  });

  const { updateClientMutation, apiError: updateApiError } = useEditClient();

  const onSubmit = (values: IClientInputUpdate) => {
    const { status } = values;

    // TODO: Look into why this conversion needs to happen. Values should match going in & out
    const input = status ? { ...values, status: status.toLowerCase() } : values;

    updateClientMutation({
      variables: { id, input: omit(['_id', 'accounts'], input) },
      onCompleted: () => {
        enqueueSnackbar('Successfully updated client');

        // Set values to submitted values (resets dirty state)
        form.reset(values);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error updating client:', error);
      },
    });
  };

  const onCancel = () => {
    form.reset();
  };

  // Client could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <>
      <EditClientHeader client={data} isLoading={isLoading} />

      <FormProvider {...form}>
        <Form error={updateApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={8}>
                <BasicInformation isLoading={isLoading} />
              </Grid>

              <Grid item xs={4}>
                <Stack gap={4}>
                  <Accounts isLoading={isLoading} />
                  <Metadata isLoading={isLoading} />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="end">
                  {!isLoading && hasFormChanged && (
                    <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                  )}
                  <PrimaryButton type="submit" disabled={isLoading || !hasFormChanged}>
                    Save changes
                  </PrimaryButton>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormProvider>
    </>
  );
};

export const EditClientPage = withParams<IEditClientParams>(['id'], EditClient);
