import { Card } from '@components/Card';
import { DataStack } from '@components/DataStack';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TAccounts } from '@common/types/graphql';

import { CLIENT_FIELDS as inputField } from '../fields';

export const Accounts = ({ isLoading }: { isLoading?: boolean }) => {
  const { getValues } = useFormContext();

  const accounts: TAccounts = getValues(inputField.accounts) ?? [];
  const items = accounts.map(({ name: value, source: label }) => ({
    label: label === 'widget' ? 'Native Commerce' : label,
    value,
  }));

  return (
    <Card title="Accounts">
      <DataStack stackId={inputField.accounts} isLoading={isLoading} items={items} />
    </Card>
  );
};
