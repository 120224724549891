import * as R from 'ramda';

import { AccountSource } from '@common/types/GraphqlTypes.d';

import { IAccountInputCreate, IAccount } from '../fields';

/**
 * Converts form account valeus into account input values for creating & updating.
 * Both operations allow the same for the moment, but might become different in the future.
 * @param {object} account - Fetched account.
 */
export const toInputValues = (account: IAccount): IAccountInputCreate => ({
  ...R.omit(
    [
      '_id',
      'apiKey',
      'privateApiKey',
      'testPublishableApiKey',
      'testSecretApiKey',
      'publisher',
      'source',
      'orderConfig.slackNotificationChannel',
    ],
    account,
  ),
  source: AccountSource.Widget,
  publisher: account.publisher._id,
});
