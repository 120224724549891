import { UserIcon } from '@components/UserIcon';
import Stack from '@mui/material/Stack';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Escalation as EscalationComponent } from '@pages/Order/components/Escalation';
import { routes } from '@routes/private';
import React from 'react';

import { toFormattedDate } from '@utils/date';
import { EscalationState, FulfillmentStatus } from '@utils/types';
import { ICustomerOrder } from '../graphql';
import { StyledLink } from '@components/DataGrid/Styled';
import Box from '@mui/material/Box';
import { Copy } from '@components/Copy';
import { DropdownCell } from '@components/DataGrid/DropdownCell';
import { StatusTag, StatusTagType } from '@components/StatusTag';

const NOT_FOUND_TEXT = 'n/a';
/**
 * Style conditions for fulfillment status.
 */
const statusTagConditions = (fulfillmentStatus: FulfillmentStatus) => [
  { type: StatusTagType.Success, match: () => fulfillmentStatus === FulfillmentStatus.FULFILLED },
  {
    type: StatusTagType.Warn,
    match: () => fulfillmentStatus === FulfillmentStatus.SENT_TO_MERCHANT,
  },
  {
    type: StatusTagType.Error,
    match: () =>
      fulfillmentStatus !== FulfillmentStatus.FULFILLED &&
      fulfillmentStatus !== FulfillmentStatus.SENT_TO_MERCHANT,
  },
];

/**
 * Returns cell for customer information.
 * Allows onClick showing extra information.
 */
const CustomCustomerCell = ({ row: { customer, deliveryAddress } }: { row: ICustomerOrder }) => {
  const { name, email } = customer;
  const { city, province, country, addressLineOne, addressLineTwo, postalCode } =
    deliveryAddress ?? {};

  // Specific format for copy value
  const copyValue = `${name} ${addressLineOne} ${addressLineTwo} ${city} ${province} ${postalCode}`;

  return (
    <DropdownCell name={name}>
      <Stack gap={0.5}>
        <Box sx={{ fontSize: '1em', fontWeight: 600 }}>{name}</Box>
        <Copy value={copyValue}>
          <Stack direction="column" gap={0.5}>
            <Box>{`${addressLineOne} ${addressLineTwo}`}</Box>
            <Box>
              {city}, {province}, {postalCode}, {country}
            </Box>
          </Stack>
        </Copy>
        <Box>
          <a href={`mailto:${email}`}>{email}</a>
        </Box>
      </Stack>
    </DropdownCell>
  );
};

/**
 * Returns column definition for the order overview table.
 */
export const columns: GridColDef<ICustomerOrder>[] = [
  {
    field: 'orderNumber',
    headerName: 'Order',
    width: 120,
    renderCell: ({ row: { _id }, value }: { row: ICustomerOrder; value?: string }) => (
      <StyledLink to={routes.viewOrder.getPath(_id)}>{value}</StyledLink>
    ),
  },
  {
    field: 'customer.name',
    headerName: 'Customer',
    sortable: false,
    flex: 1,
    renderCell: CustomCustomerCell,
  },
  {
    field: 'summary.subTotal',
    headerName: 'Total',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }: { row: ICustomerOrder }) => {
      if (!row.summary) {
        return NOT_FOUND_TEXT;
      }

      return `$${row.summary?.subTotal} ${row.summary?.currency}`;
    },
  },
  {
    field: 'account',
    headerName: 'Account',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: { row: ICustomerOrder }) => {
      if (!row.account) {
        return NOT_FOUND_TEXT;
      }

      return (
        <StyledLink to={routes.editAccount.getPath(row.account?.publicId)}>
          {row.account?.name}
        </StyledLink>
      );
    },
  },
  {
    field: 'merchantNames',
    headerName: 'Merchant(s)',
    sortable: false,
    flex: 1,
  },
  {
    field: 'fulfillmentStatus',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: { fulfillmentStatus } }: { row: ICustomerOrder }) => (
      <StatusTag
        text={fulfillmentStatus}
        conditions={statusTagConditions(fulfillmentStatus as FulfillmentStatus)}
      />
    ),
    flex: 1,
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment',
    sortable: false,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    flex: 1,
    valueGetter: ({ row }: { row: ICustomerOrder }) => toFormattedDate(row.createdAt),
  },
  {
    field: 'assignee',
    headerName: 'Assignee',
    width: 80,
    renderCell: ({ row: { assignee } }: { row: ICustomerOrder }) => (
      <UserIcon name={assignee?.name ?? ''} isDefault={!assignee} />
    ),
  },
  {
    field: 'escalations',
    headerName: 'Escalations',
    sortable: false,
    flex: 1,
    renderCell: ({ row: { escalations } }: { row: ICustomerOrder }) => {
      const openEscalations = (escalations ?? []).filter(
        (escalation) => escalation?.state === EscalationState.Open,
      );

      return (
        <Stack direction="row" gap={0.5} flexWrap="wrap">
          {openEscalations.map((escalation, key) => {
            // TODO: Better types to avoid needing all these checks & defaults
            const { status = 'n/a' } = escalation ?? {};

            return (
              // TODO: Replace with root-level reusable Escalation component
              // TODO: Do we need product name in this overview?
              <EscalationComponent key={key} status={status} productName={''} />
            );
          })}
        </Stack>
      );
    },
  },
];
