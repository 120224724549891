import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarProvider as LibrarySnackbarProvider, closeSnackbar } from 'notistack';
import React, { ReactNode } from 'react';

type SnackbarProps = {
  children: ReactNode | undefined;
};

export const SnackBarProvider = ({ children }: SnackbarProps) => (
  <LibrarySnackbarProvider
    action={(snackbarId) => (
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <Close />
      </IconButton>
    )}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    {children}
  </LibrarySnackbarProvider>
);
