import { UserPermission } from '@types';

import { ICurrentUser } from '../hooks/useCurrentUser';

/**
 * Returns whether user has the right permission access.
 * @param {object} user - Current user.
 * @param {string} permission - User permission.
 */
export const hasPermission = (user?: ICurrentUser, permission?: UserPermission) => {
  if (!permission) {
    return true;
  }

  return (user?.permissions || []).includes(permission);
};
