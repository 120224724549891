/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  InputAdornment,
  InputLabel,
  Skeleton,
  SvgIconProps,
  TextField as MuiTextField,
  TextFieldProps,
  FormControl,
  Tooltip,
  Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { path } from 'ramda';
import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { useStyles } from './styles';
// TODO: Use better types from react-hook-form instead of any!

export interface ITextFieldProps {
  icon?: {
    value: React.FC<SvgIconProps>;
    position?: 'start' | 'end';
  };

  // Customization
  setEmptyAsNull?: boolean;

  // Support for react-hook-form
  useHook?: boolean;
  options?: RegisterOptions<any, any>;

  // Built-in loading support
  isLoading?: boolean;

  // Support for copy
  allowCopy?: boolean;

  // Tooltip to show potential extra information
  tooltip?: string;

  // Support for showing/hiding sensitive values (show eye icon)
  isSensitive?: boolean;
}

/**
 * Input text field with optional label & icon.
 * Supports react-hook-form out-of-the-box.
 */
export const TextField: React.FC<ITextFieldProps & TextFieldProps> = ({
  id = getUniqueId(),
  label,
  error,
  className,
  helperText,
  icon,
  isLoading = false,
  useHook = false, // TODO: Should be default `true` but to avoid breaking other pages it's false FOR NOW
  allowCopy = false,
  setEmptyAsNull = false,
  tooltip,
  isSensitive = false,
  options, // React-hook-form options
  ...otherProps
}) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = React.useState(false);
  const [showSensitive, setShowSensitive] = React.useState(!isSensitive);

  // React-hook-form support (best practices)
  // Even if register is not used it has to be called since it's a React hook. Bunch of {} defaults in case forms aren't used
  const { register, getValues, formState: { errors = {} } = {} } = useFormContext<any>() || {};

  // Support for both react-hook-form errors and passed along errors
  // Use R.path so ids with dot notation can be found
  const errorMessage = (path(id.split('.'), errors) as any)?.message || helperText;
  const hasError = Boolean(errorMessage) || error;

  // [Optional] - React-hooks form support
  const registerProps = () => {
    if (useHook) {
      const { ref, ...restOfRegistrationProps } = register(id, {
        ...options,

        // Sets value as null when it's an empty string
        setValueAs: setEmptyAsNull ? (x) => (x === '' ? null : x) : (x) => x,

        // Sets value as number instead of string
        // Bug within react-hook-form causes wrongful boolean type check
        valueAsNumber: (otherProps.type === 'number') as any,
      });

      return {
        inputRef: ref,
        ...restOfRegistrationProps,
      };
    }

    return {};
  };

  // [Optional] - Icon support
  if (icon?.value) {
    const position = icon.position || 'start';

    otherProps['InputProps'] = {
      [position === 'start' ? 'startAdornment' : 'endAdornment']: (
        <InputAdornment position={position}>
          <icon.value className={classes.icon} />
        </InputAdornment>
      ),
    };
  }

  // Copy support
  // TODO: Update to use `Copy` component when clicking the input field.
  if (allowCopy) {
    const handleCopy = () => {
      navigator.clipboard.writeText(getValues(id));
      setIsCopied(true);

      // Automatically hide after 2000ms
      setTimeout(() => setIsCopied(false), 2000);
    };

    otherProps['InputProps'] = {
      endAdornment: (
        <Tooltip open={isCopied} title={`${label} has been copied to your clipboard`}>
          <InputAdornment position="end">
            <ContentCopyIcon
              className={clsx(classes.icon, classes.copyIcon)}
              onClick={handleCopy}
            />
          </InputAdornment>
        </Tooltip>
      ),
    };
  }

  // Sensitive value support
  if (isSensitive) {
    const handleClickShowSensitive = () => setShowSensitive((show) => !show);

    const handleMouseDownSensitive = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    otherProps['InputProps'] = {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowSensitive}
            onMouseDown={handleMouseDownSensitive}
            edge="end"
          >
            {showSensitive ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  return (
    <FormControl
      variant="outlined"
      className={clsx([classes.root, className, otherProps.hidden ? classes.hidden : ''])}
      error={hasError}
    >
      {label && (
        <Stack direction="row">
          <InputLabel shrink htmlFor={id} className={classes.label}>
            {label}
          </InputLabel>

          {tooltip && (
            <Tooltip title={tooltip} className={classes.tooltip}>
              <InfoOutlined />
            </Tooltip>
          )}
        </Stack>
      )}

      {isLoading ? (
        <Skeleton className={classes.loader} height={`${Number(otherProps.minRows || 1) * 36}px`} />
      ) : (
        <MuiTextField
          id={id}
          size="small"
          // eslint-disable-next-line no-nested-ternary
          type={isSensitive ? (showSensitive ? 'text' : 'password') : otherProps.type}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            className: classes.input,
          }}
          fullWidth
          error={hasError}
          {...registerProps()}
          {...otherProps}
        />
      )}

      {hasError && <span className={classes.error}>{errorMessage}</span>}
    </FormControl>
  );
};
