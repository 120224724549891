import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const MARK_VARIANT_UNAVAILABLE_MUTATION = gql`
  mutation markVariantUnavailable($input: VariantUnavailableInput!) {
    markVariantUnavailable(input: $input)
  }
`;
/**
 * Hook for marking product unavailable.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useMarkVariantUnavailable = (options: MutationHookOptions = {}) => {
  const [markVariantUnavailable, { error: apiError, loading: isLoading }] = useMutation(
    MARK_VARIANT_UNAVAILABLE_MUTATION,
    options,
  );

  return { markVariantUnavailable, isLoading, apiError };
};

export const MARK_PRODUCT_UNAVAILABLE_MUTATION = gql`
  mutation markProductUnavailable($input: ProductIdInput!) {
    markProductUnavailable(input: $input)
  }
`;

/**
 * Hook for marking product unavailable.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useMarkProductUnavailable = (options: MutationHookOptions = {}) => {
  const [markProductUnavailable, { error: apiError, loading: isLoading }] = useMutation(
    MARK_PRODUCT_UNAVAILABLE_MUTATION,
    options,
  );

  return { markProductUnavailable, isLoading, apiError };
};

export const MARK_PRODUCT_AVAILABLE_MUTATION = gql`
  mutation markProductAvailable($input: ProductIdInput!) {
    markProductAvailable(input: $input)
  }
`;

/**
 * Hook for marking product available.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useMarkProductAvailable = (options: MutationHookOptions = {}) => {
  const [markProductAvailable, { error: apiError, loading: isLoading }] = useMutation(
    MARK_PRODUCT_AVAILABLE_MUTATION,
    options,
  );

  return { markProductAvailable, isLoading, apiError };
};
