import { MenuItem } from '@components/MenuItem';
import useRouter from '@hooks/useRouter';
import { Password as PasswordIcon } from '@mui/icons-material';
import React from 'react';

export const ResetPasswordMenuItem = () => {
  const { goto } = useRouter();

  const handleReset = () => {
    goto.resetPassword();
  };

  return (
    <>
      <MenuItem action={handleReset} label="Reset password" icon={PasswordIcon} />
    </>
  );
};
