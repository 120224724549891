import { gql, MutationHookOptions, useMutation, useQuery } from '@apollo/client';

import { MerchantOrder } from '@common/types/GraphqlTypes.d';

export const MERCHANT_ORDER_QUERY = gql`
  query getOneMerchantOrder($input: MerchantOrderInput!) {
    getOneMerchantOrder(input: $input) {
      _id
      customerOrderId
      summary {
        subTotal
        shippingWithTax
        customerChargeTotal
        duties
        currency
      }
      merchant {
        name
        commissionType
      }
      checkoutVariants {
        variant {
          id
          optionTitles
          optionValues
          images
          price
          priceUSD
          currency
          brand
        }
        _id
        name
        productId
        publicId
        purchaseQuantity
        productURL
      }
    }
  }
`;

const REFUND_MERCHANT_ORDER_MUTATION = gql`
  mutation submitMerchantOrderRefund($input: RefundInput!) {
    submitMerchantOrderRefund(input: $input) {
      products {
        productId
        variantId
        quantity
      }
      reason
      merchantOrderId
      amount
      subtotal
      error
      shipping
      isBonsaiOnlyRefund
      status
    }
  }
`;

/**
 * Hook for submitting refunds.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useSubmitRefund = (options: MutationHookOptions = {}) => {
  const [refundMerchantOrderMutation, { error: apiError, loading: isLoading }] = useMutation(
    REFUND_MERCHANT_ORDER_MUTATION,
    options,
  );

  return { refundMerchantOrderMutation, isLoading, apiError };
};

interface IMerchantOrderQuery {
  input: { merchantOrderId: string };
}
interface IMerchantOrderData {
  getOneMerchantOrder: MerchantOrder;
}

/**
 * Hook for getting a merchant order.
 * @param id - Order id.
 */
export const useMerchantOrder = (merchantOrderId: string) => {
  const {
    data,
    loading: isLoading,
    error: apiError,
  } = useQuery<IMerchantOrderData, IMerchantOrderQuery>(MERCHANT_ORDER_QUERY, {
    variables: {
      input: { merchantOrderId },
    },
  });

  const merchantOrder = data?.getOneMerchantOrder;

  return { merchantOrder, isLoading, apiError };
};
