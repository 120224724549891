import { useAuth } from '@hooks/useAuthentication';
import { routes } from '@routes/private';
import { UserPermission } from '@types';
import React from 'react';
import { Link } from 'react-router-dom';

import { hasPermission } from '@utils/permission';

export const Home = () => {
  const { currentUser } = useAuth();

  const shouldConnectStripe =
    hasPermission(currentUser, UserPermission.MerchantConnectStripe) &&
    !currentUser?.merchant?.isStripeConnected;

  return (
    <div>
      <h2>An overview page is in the works. Stay tuned! </h2>
      <div>
        {shouldConnectStripe && (
          <>
            <Link to={routes.viewProfile.path}>Profile page</Link> allowing you to connect Stripe
          </>
        )}
      </div>
    </div>
  );
};
