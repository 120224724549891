import { routes } from '@routes/private';
import { Color } from '@theme/palette';
import React from 'react';
import { Link } from 'react-router-dom';

export type ProductMerchantProps = {
  id: string;
  name: string;
  url: string;
};

/**
 * Displays merchant information.
 */
export const ProductMerchant = ({ id, name, url }: ProductMerchantProps) => (
  <>
    {/* Merchant */}
    <span
      style={{
        fontSize: '0.9rem',
      }}
    >
      By
      <Link
        to={routes.editMerchant.getPath(id)}
        style={{
          color: Color.DarkNight,
          fontWeight: 'bold',
          textDecoration: 'none',
          paddingLeft: '3px',
        }}
      >
        {name}
      </Link>
    </span>

    {/* Merchant link */}
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      style={{
        fontSize: '0.9em',
        color: Color.DarkNight,
        display: 'flex',
        alignItems: 'center',
        marginTop: '-3px',
      }}
    >
      View product on merchant site
    </a>
  </>
);
