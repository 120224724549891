import {
  eventType,
  OrderPrimaryEvent,
  OrderSecondaryEvent,
  PrimaryEventType,
} from '@pages/Order/components/OrderHistoryTimeline/interfaces';
import { isSecondaryEventType } from '@pages/Order/components/OrderHistoryTimeline/utils';

import {
  OrderHistory as TOrderHistory,
  OrderHistoryStatus,
  OrderHistoryEventType,
  OrderHistoryPayload,
} from '@common/types/GraphqlTypes.d';
import { ORDER_HISTORY_MESSAGES } from '@constants/message';

import { additionalComponents } from './';
import { IHistoryCustomerOrder } from './graphql';

const getAdditionalComponentFn = (action: OrderHistoryEventType) => {
  if (action === OrderHistoryEventType.CustomerOrderEmailSent) {
    return additionalComponents[action];
  }
};

/**
 * Returns message to display for order history item.
 * Uses the event type, status (success/error) and payload to determine the message.
 */
const getHistoryItemMessage = (
  eventType: OrderHistoryEventType,
  status: OrderHistoryStatus,
  payload: OrderHistoryPayload,
): string => ORDER_HISTORY_MESSAGES(payload)[eventType][status];

/**
 * Converts an order history event to a primary timeline event.
 * @param historyEvent - Order history event.
 * @returns {OrderPrimaryEvent} - Order timeline event.
 */
const orderHistoryToPrimaryEvent = ({
  event: { type, action },
  createdAt,
  status,
  payload,
}: TOrderHistory): OrderPrimaryEvent => ({
  title: getHistoryItemMessage(action, status, payload),
  //TODO don't cast to unkown once we have events that are history and primary.
  type: type as unknown as PrimaryEventType,
  createdAt,
  status,
});

/**
 * Converts an order history event to a secondary timeline event.
 * @param historyEvent - Order history event.
 * @returns {OrderSecondaryEvent} - Order timeline event.
 */
const orderHistoryToSecondaryEvent = (
  { event: { type, action }, createdAt, status, payload }: TOrderHistory,
  customerOrder: IHistoryCustomerOrder,
): OrderSecondaryEvent => {
  const getComponent = getAdditionalComponentFn(action);

  return {
    title: getHistoryItemMessage(action, status, payload),
    type,
    secondary: true,
    createdAt,
    status,
    additionalComponent: getComponent ? getComponent(customerOrder) : undefined,
  };
};

/**
 * Converts an order history event to a timeline event.
 * @param historyEvent - Order history event.
 * @returns {OrderPrimaryEvent | OrderSecondaryEvent} - Order timeline event.
 */
export const orderHistoryToTimelineItem =
  (customerOrder: IHistoryCustomerOrder) =>
  (historyEvent: TOrderHistory): OrderPrimaryEvent | OrderSecondaryEvent =>
    isSecondaryEventType(historyEvent.event.type)
      ? orderHistoryToSecondaryEvent(historyEvent, customerOrder)
      : orderHistoryToPrimaryEvent(historyEvent);

/**
 * Creates an event for the creation of an order.
 * @param customerOrder - The order this event is for.
 * @returns {OrderPrimaryEvent} - Order creation event.
 */
export const createdEvent = ({
  createdAt,
  account: { name },
}: IHistoryCustomerOrder): OrderPrimaryEvent => ({
  createdAt,
  title: `Order received from ${name}`,
  type: eventType.OrderReceivedEvent,
  status: OrderHistoryStatus.Success,
});
