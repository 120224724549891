import { useLazyQuery } from '@apollo/client';

import { ACCOUNTS_AUTOCOMPLETE_QUERY } from './graphql';

export type AccountAutocomplete = {
  _id: string;
  name: string;
  publicId: string;
  apiKey: string;
};

type Filters = {
  hasEnabledCsvOrders?: boolean;
};

export const useAccountAutocomplete = (): {
  query: (value: string, filters?: Filters) => Promise<AccountAutocomplete[]>;
  isLoading: boolean;
} => {
  const [operation, { loading: isLoading }] = useLazyQuery<{
    accounts: AccountAutocomplete[];
  }>(ACCOUNTS_AUTOCOMPLETE_QUERY);

  const query = async (value: string, filters?: Filters): Promise<AccountAutocomplete[]> => {
    const input = {
      search: value,
      filters,
      page: 1,
      pageSize: 50, // Show 50 results max.
      sortBy: {
        name: 1,
      },
    };

    const { data } = await operation({
      variables: {
        input,
      },
    });

    return data?.accounts ?? [];
  };

  return { query, isLoading };
};
