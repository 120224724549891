/* eslint-disable complexity */

import { CardTitle } from '@components/CardTitle';
import { useAuth } from '@hooks/useAuthentication';
import { useCustomerOrderComments } from '@hooks/useCustomerOrderV2';
import { Box, Skeleton, Stack } from '@mui/material';
import { ApiError } from '@pages/Misc/ApiError';
import { Comment } from '@pages/Order/View/Comments/comment';
import { sort } from 'ramda';
import React from 'react';

import { AddCustomerOrderComment } from './AddComment';

type CustomerOrderCommentsProps = {
  orderId: string;
};

const title = 'Comments';

/**
 * Returns customer order comments.
 */
export const CustomerOrderComments = ({ orderId }: CustomerOrderCommentsProps) => {
  const { currentUser } = useAuth();

  // Fetch comments for the current order.
  const { isLoading, comments } = useCustomerOrderComments(orderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Comments could not be found after API call, or current user is not found
  if ((!comments && !isLoading) || !currentUser) {
    return <ApiError cardTitle={title} message="Comments could not be loaded." />;
  }

  return (
    <Box>
      <CardTitle title={title} />

      {isLoading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        <Stack direction="column" gap={2}>
          <AddCustomerOrderComment orderId={orderId} />

          {comments && (
            /** TODO: Replace Comment with V2 component! */
            <div>
              {sort((a, b) => b?.createdAt - a?.createdAt, comments).map((comment) => (
                <Comment key={comment._id} comment={comment} customerOrderId={orderId} />
              ))}
            </div>
          )}
        </Stack>
      )}
    </Box>
  );
};
