import { ShippingCarrier } from '@common/types/GraphqlTypes.d';

const COURIER_TRACKING_PATTERNS: { [key in ShippingCarrier]: RegExp[] } = {
  [ShippingCarrier.Ups]: [
    new RegExp(
      /\b(1z(?: ?[\da-z]{3}){2} ?[\da-z]{2} ?[\da-z]{4} ?[\da-z]{3} ?[\da-z]|t\d{3} ?\d{4} ?\d{3})\b/i,
    ),
  ],
  [ShippingCarrier.Usps]: [
    new RegExp(
      /\b((420 ?\d{5} ?)?(91|92|93|94|95|01|03|04|70|23|13)\d{2}(?: ?\d{4}){4}( ?\d{2,6})?)\b/i,
    ),
    new RegExp(
      /\b((m|p[a-z]?|d[c-z]|lk|e[a-c]|v[a-z]|r[a-z]|cp|cj|lc|lj)(?: ?\d{3}){3} ?[a-z]{0,2})\b/i,
    ),
    new RegExp(/\b(82(?: ?\d{3}){2} ?\d{2})\b/i),
  ],
  [ShippingCarrier.Dhl]: [
    new RegExp(/\b((?:\d{4}[ -]?){2}\d{2}|\d{3}[ -]?\d{8}|[a-z]{3}\d{7})\b/i),
  ],
  [ShippingCarrier.FedEx]: [
    new RegExp(/\b(((96\d\d|6\d)\d{3} ?\d{4}|96\d{2}|\d{4}) ?\d{4} ?\d{4}( ?\d{3}|\d{15})?)\b/i),
  ],

  // Match anything
  [ShippingCarrier.Other]: [new RegExp(/[\S\s]*/i)],
};

/**
 * Checks whether a tracking number is valid for a given carrier.
 * @param trackingNumber - Tracking number.
 * @param carrier - Shipping carrier.
 */
export const isValidTrackingNumber = (
  trackingNumber: string,
  carrier: ShippingCarrier,
): boolean => {
  // eslint-disable-next-line prefer-destructuring
  const carrierValidator = COURIER_TRACKING_PATTERNS[carrier];

  return carrierValidator.some((pattern) => pattern.test(trackingNumber));
};
