import { gql, MutationHookOptions, QueryHookOptions, useMutation } from '@apollo/client';

import { Merchant, QueryMerchantArgs } from '@common/types/GraphqlTypes.d';
import { useTypedQuery } from '@utils/graphql';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within MERCHANT_QUERY.
export type IMerchant = Pick<
  Merchant,
  | '_id'
  | 'name'
  | 'description'
  | 'returnPolicy'
  | 'publishedStage'
  | 'email'
  | 'billing'
  | 'offices'
  | 'squareImage'
  | 'config'
  | 'areTaxesIncluded'
  | 'isStripeConnected'
  | 'companyCode'
  | 'fulfillment'
  | 'currency'
  | 'private'
>;

export const MERCHANT_QUERY = gql`
  query merchant($id: String!) {
    merchant(id: $id) {
      _id
      name
      slug
      description
      returnPolicy
      publishedStage
      email
      enabledWebhooks
      billing {
        accountingId
        commissionType
      }
      offices {
        phoneNumber
        addressLine1
        addressLine2
        postalCode
        city
        country
        name
        region
      }
      squareImage {
        url
      }
      config {
        options {
          importLimit
          shouldLimitImport
          shouldUseImageProxy
          shouldForceImport
          locations
          shouldRemoveQueryParamsFromImgUrls
          targetCurrency
          selfHosted
          defaultInventoryAmount
          imageHeaders
        }
        review {
          auto
        }
        taxes
        hasNexusEnabled
        isTestMerchant
        shipping {
          simple {
            description
            shippingRateCurrency
            shippingRatePerCountry {
              countryCode
              freeIfPassedSubtotal
              shippingRate
            }
          }
          free {
            enabled
          }
          type
          bookie
        }
        canUseFlexiblePayment
        restockingFee
      }
      areTaxesIncluded
      companyCode
      currency
      isStripeConnected
      stripeAccount
      private {
        fee
        credentials {
          integrationType
          shopify {
            shopName
            keys {
              order {
                accessToken
              }
              product {
                accessToken
              }
            }
          }
          feed {
            feedName
            url
            accessCredentials {
              aws {
                kind
                accessKeyId
                secretAccessKey
                region
              }
            }
          }
        }
      }
      fulfillment {
        instructions
      }
    }
  }
`;

const EDIT_MERCHANT_MUTATION = gql`
  mutation editMerchant($input: MerchantInput!) {
    editMerchant(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for fetching merchant information.
 * @param {string} id - Merchant ID.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useMerchant = (
  id: string,
  options: QueryHookOptions<{ merchant: IMerchant }, QueryMerchantArgs>,
) => {
  const {
    data,
    error: apiError,
    loading: isLoading,
  } = useTypedQuery(
    MERCHANT_QUERY,
    { id },
    {
      fetchPolicy: 'no-cache',
      ...options,
    },
  );

  const { merchant } = data || {};

  return { data: merchant, isLoading, apiError };
};

/**
 * Hook for editing merchant.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useEditMerchant = (options: MutationHookOptions = {}) => {
  const [editMerchantMutation, { error: apiError, loading: isLoading }] = useMutation<{
    editMerchant: { _id: string };
  }>(EDIT_MERCHANT_MUTATION, options);

  return { editMerchantMutation, isLoading, apiError };
};
