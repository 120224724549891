import { GoToPageItem } from '@pages/Order/components/GoToPageMenuItem';
import { OrderItems } from '@pages/Order/components/OrderItems';
import { OtherDetails } from '@pages/Order/components/OtherDetails';
import { PaymentDetails } from '@pages/Order/components/PaymentDetails';
import { ShippingTracking } from '@pages/Order/components/ShippingTracking';
import { PrivateRoutes } from '@routes/private';
import React, { FunctionComponent } from 'react';

import { OrderCard, OrderTabLabels } from '../../OrderCard';
import { ProcessedOrderHeader } from '../Header';
import { IProcessedOrder } from '../interfaces';
import { EditFeedOrderItem } from '../menuItems';

interface IOrderDetailsProps {
  order: IProcessedOrder;
}

export const FulfilledOrder: FunctionComponent<IOrderDetailsProps> = ({ order }) => {
  const menuItems = (
    <>
      <GoToPageItem
        label={'Refund Order'}
        path={PrivateRoutes.refundOrder}
        pathParams={[order.merchantOrderId]}
      />
      <EditFeedOrderItem order={order} />
    </>
  );

  const tabPanels = {
    [OrderTabLabels.Details]: {
      component: (
        <OrderItems
          checkoutVariants={order.checkoutVariants}
          integrationType={order.integrationType}
          merchantName={order.merchantName}
        />
      ),
    },
    [OrderTabLabels.Shipping]: {
      component: <ShippingTracking order={order} />,
    },
    [OrderTabLabels.Payment]: {
      component: <PaymentDetails order={order} />,
    },
    [OrderTabLabels.Other]: {
      component: <OtherDetails order={order} />,
    },
  };

  return (
    <OrderCard
      menuItems={menuItems}
      tabPanels={tabPanels}
      header={<ProcessedOrderHeader order={order} />}
    />
  );
};
