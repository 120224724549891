import { useEffect, useState } from 'react';

/**
 * Hook to sync the state to localstorage.
 * @param input - Required input to setup the local storage method.
 * @returns
 */
// eslint-disable-next-line max-lines-per-function
export const useLocalStorage = <T>({
  key,
  initialValue,
  serializer = {
    stringify: JSON.stringify,
    parse: JSON.parse,
  },
}: {
  key: string;
  initialValue?: T;
  serializer?: { stringify: (value: T) => string; parse: (input: string) => T };
}) => {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);

      return item ? serializer.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  /**
   * Set s the passed value.
   * @param value - Value to save.
   */
  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, serializer.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Removes value.
   */
  const removeValue = () => {
    try {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setStoredValue(undefined);
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleNewValue = () => {
      const newValue = window.localStorage.getItem(key);

      return newValue ? setStoredValue(serializer.parse(newValue)) : removeValue();
    };

    window.addEventListener('storage', handleNewValue);

    return () => window.removeEventListener('storage', handleNewValue);
  });

  return { storedValue, setValue, removeValue };
};
