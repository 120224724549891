import { useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlag } from '@constants/featureFlag';

/**
 * Returns whether specific feature is enabled through our feature management tool.
 */
export const isFeatureEnabled = (name: FeatureFlag): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const flags = useFlags();

  return !!flags?.[name]; // property access optional chaining to reduce the need for `|| {}` and lint comment, and `!!` to cast to boolean to respect function signature
};
