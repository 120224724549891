import { ClearFilters } from '@components/DataFilter/Clear';
import { DataGridV2 } from '@components/DataGrid';
import ErrorBoundary from '@components/ErrorBoundary';
import { usePagination } from '@hooks/useDataStore/usePagination';
import { useSearch } from '@hooks/useDataStore/useSearch';
import { ISorting, SortOrder, useSorting } from '@hooks/useDataStore/useSorting';
import Stack from '@mui/material/Stack';
import React from 'react';

import { CustomerOrder, CustomerOrdersQueryInput } from '@common/types/GraphqlTypes.d';

import { columns } from './config/columns';
import { useOrderFilters } from './config/filters';
import { AccountFilter } from './Filters/Account';
import { AssigneeFilter } from './Filters/Assignee';
import { DateFilter } from './Filters/Date';
import { EscalationFilter } from './Filters/Escalation';
import { FulfillmentStatusFilter } from './Filters/FulfillmentStatus';
import { IsTestFilter } from './Filters/IsTest';
import { PaymentStatusFilter } from './Filters/Payment';
import { SearchFilter } from './Filters/Search';
import { useCustomerOrders } from './graphql';
import { OrderOverviewHeader } from './Header';

const DEFAULT_SORTING: ISorting = {
  sortField: 'createdAt',
  sortOrder: SortOrder.Desc,
};

export const ListOrders = () => {
  // Fetch global filters, pagination & sorting
  const { pagination, updatePagination, getGraphQLPagination } = usePagination();
  const { getDataGridSort, updateDataGridSort, getGraphQLSort } = useSorting(DEFAULT_SORTING);
  const { filters, clearFilters, hasChanged } = useOrderFilters();
  const { search } = useSearch();

  /**
   * Ensures dates are numbers.
   * Needed for GraphQL query. UseStore converts them to strings in an Array.
   * @todo - Should not be needed anymore once Redux is implemented.
   */
  const getDates = () => (filters.date ? [Number(filters.date[0]), Number(filters.date[1])] : null);

  const {
    data = [],
    total: totalRowCount,
    isLoading,
  } = useCustomerOrders({
    variables: {
      input: {
        ...getGraphQLPagination(),
        ...getGraphQLSort(),
        filters: {
          ...filters,
          date: getDates(),
          // TODO: Type mismatch due to `isTest` being a string vs Array.
        } as CustomerOrdersQueryInput,
        search,
      },
    },
  });

  return (
    <ErrorBoundary>
      <OrderOverviewHeader />

      <Stack gap={2}>
        {/** All available filters. */}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
            <SearchFilter />
            <FulfillmentStatusFilter />
            <PaymentStatusFilter />
            <AccountFilter />
            <AssigneeFilter />
            <EscalationFilter />
            <DateFilter />

            <ClearFilters visible={hasChanged} clear={clearFilters} />
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <IsTestFilter />
          </Stack>
        </Stack>

        {/** Data table. */}
        <DataGridV2
          rows={data}
          columns={columns}
          loading={isLoading}
          rowCount={totalRowCount}
          getRowId={(order: CustomerOrder) => order._id}
          paginationModel={pagination}
          onPaginationModelChange={updatePagination}
          onSortChange={updateDataGridSort}
          initialState={{
            sorting: getDataGridSort(),
          }}
        />
      </Stack>
    </ErrorBoundary>
  );
};
