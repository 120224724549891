import { Color } from '@theme/palette';
import { PaymentStatus } from '@types';

// TODO: Swap with theme-specific palette colors
const paymentStatusColorMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Paid]: '#4CAf50',
  [PaymentStatus.Unpaid]: Color.ErrorRed,
  [PaymentStatus.Refunded]: '#FF9800',
  [PaymentStatus.PartiallyRefunded]: '#FF9800',
};

/**
 * Returns the color for the payment status.
 * @param status - Payment status of the order.
 */
export const statusToColor = (status: PaymentStatus) => paymentStatusColorMap[status];

const paymentStatusTitleMap: Record<PaymentStatus, string> = {
  [PaymentStatus.Paid]: 'Payment Successful',
  [PaymentStatus.Unpaid]: 'Payment Unsuccessful',
  [PaymentStatus.Refunded]: 'Payment Refunded',
  [PaymentStatus.PartiallyRefunded]: 'Payment Partially Refunded',
};

/**
 * Returns the title for the payment status.
 * @param status - Payment status of the order.
 */
export const statusToTitle = (status: PaymentStatus) => paymentStatusTitleMap[status];
