import { Logo } from '@components/Logo';
import { SetNewPasswordFormPage } from '@pages/Authentication/NewPassword/Form';
import React from 'react';

import { useStyles } from './styles';

export const NewPassword = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Logo className={classes.logo} />
        <SetNewPasswordFormPage className={classes.setNewPasswordForm} />
      </div>
    </div>
  );
};
