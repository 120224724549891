import { useMutation } from '@apollo/client';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { CUSTOMER_ORDER_QUERY } from '@hooks/useCustomerOrder';
import { MERCHANT_ORDERS_QUERY } from '@hooks/useMerchantOrders';
import { useSnackbar } from '@hooks/useSnackbar';
import { MenuItem, Typography } from '@mui/material';
import { EciIntegrationType, isDeadEndIntegrationType } from '@types';
import React from 'react';

import { SEND_EXTERNAL_ORDER_MUTATION, SEND_MERCHANT_PAYMENT_MUTATION } from '../graphql';
import { useStyles } from '../styles';

interface ISendExternalorderItemProps {
  merchantOrderId: string;
  integrationType: EciIntegrationType;
}

export const SendExternalOrderItem: React.FC<ISendExternalorderItemProps> = ({
  merchantOrderId,
  integrationType,
}) => {
  const classes = useStyles();
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [sendOrderToMerchant] = useMutation(SEND_EXTERNAL_ORDER_MUTATION, {
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Order sent to merchant');
    },
    onError: (error) => {
      enqueueSnackbar(`Order failed to send to merchant with error: ${error.message}`, {
        variant: 'error',
      });
    },
    refetchQueries: [CUSTOMER_ORDER_QUERY, MERCHANT_ORDERS_QUERY],
  });

  if (isDeadEndIntegrationType(integrationType)) {
    return null;
  }

  const handleSendOrderToMerchant = () => {
    sendOrderToMerchant({
      variables: { input: { merchantOrderId } },
    });
  };

  return (
    <MenuItem onClick={handleSendOrderToMerchant}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        Send Order to Merchant
      </Typography>
    </MenuItem>
  );
};

export const SendMerchantTransferOrderItem: React.FC<ISendExternalorderItemProps> = ({
  merchantOrderId,
  integrationType,
}) => {
  const classes = useStyles();
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [sendPaymentToMerchant] = useMutation(SEND_MERCHANT_PAYMENT_MUTATION, {
    onCompleted: ({ sendMerchantTransfer: { error } }) => {
      if (error) {
        enqueueSnackbar(`Payment failed to send to merchant with error: ${error}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Payment sent to merchant');
      }
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(`Payment failed to send to merchant with error: ${error.message}`, {
        variant: 'error',
      });
    },
    refetchQueries: [CUSTOMER_ORDER_QUERY, MERCHANT_ORDERS_QUERY],
  });

  if (isDeadEndIntegrationType(integrationType)) {
    return null;
  }

  const handleSendPaymentToMerchant = () => {
    sendPaymentToMerchant({
      variables: { input: { merchantOrderId } },
    });
  };

  return (
    <MenuItem onClick={handleSendPaymentToMerchant}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        Send Payment to Merchant
      </Typography>
    </MenuItem>
  );
};
