import { Logo } from '@components/Logo';
import React from 'react';

import { ResetForm } from './Form';
import { useStyles } from './styles';

export const ResetPassword = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Logo className={classes.logo} />
        <ResetForm className={classes.resetForm} />
      </div>
    </div>
  );
};
