import { Header } from '@components/Header';
import React from 'react';

import { IClient } from './graphql';

type ActionsProps = {
  client?: IClient;
  isLoading: boolean;
};

export const EditClientHeader = ({ client, isLoading }: ActionsProps) => (
  <Header title={`Edit client - ${client?.name}`} isLoading={isLoading} />
);
