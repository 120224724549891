import { Accordion, AccordionDetails, AccordionSummary } from '@components/Accordion';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { DataGridV2 } from '@components/DataGrid';
import { Form } from '@components/Form';
import { ControlledSelect } from '@components/Input/Select';
import { Text } from '@components/Text';
import { AccountAutocomplete, useAccountAutocomplete } from '@hooks/useAccountAutocomplete';
import { useSnackbar } from '@hooks/useSnackbar';
import { FileUpload } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { UploadFileDialog } from '@pages/Order/components/FileUploadDialog';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { UploadCsvOrdersError } from '@common/types/GraphqlTypes.d';

import { useProcessCsvOrdersFile } from './graphql';

const columns = [
  { field: 'orderNumber', headerName: 'Order Number', flex: 1 },
  { field: 'errorMessage', headerName: 'Error Message', flex: 1 },
  { field: 'errorCode', headerName: 'Error Code', flex: 1 },
  { field: 'rows', headerName: 'CSV Rows', flex: 1 },
];

export const UploadNewOrdersCsvMenuItem = () => {
  const { handleClose: handleMenuClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [accounts, setAccounts] = useState<AccountAutocomplete[]>([]);
  const [errors, setErrors] = useState<UploadCsvOrdersError[]>([]);

  const { query: getAccounts } = useAccountAutocomplete();

  useEffect(() => {
    const fetchAccounts = async () => {
      const results = await getAccounts('', { hasEnabledCsvOrders: true });

      setAccounts(results);
    };

    fetchAccounts();
  }, []);

  const options = accounts.map((account) => ({
    label: account.name,
    value: account.publicId,
  }));

  const { processCsvOrdersFile, data } = useProcessCsvOrdersFile({
    onCompleted: () => {
      const { errors = [] } = data?.processCsvOrdersFile?.[0] ?? {};

      if (errors.length > 0) {
        setErrors(errors);
        enqueueSnackbar('Failed to process some orders. See the form for more details.', {
          variant: 'error',
        });

        return;
      }

      enqueueSnackbar('Processed orders successfully');
      handleMenuClose();
    },
    onError: (error) => {
      enqueueSnackbar(`Failed to process orders with error - ${error.message}`, {
        variant: 'error',
      });
      handleMenuClose();
    },
  });

  const form = useForm({ mode: 'onSubmit', defaultValues: { client: '' } });
  const { client: clientValue } = form.watch();

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>
        <FileUpload />
        Upload{' '}
        <Text fontWeight={600} gutterBottom={false}>
          new order(s)
        </Text>
      </MenuItem>
      <FormProvider {...form}>
        <Form error={undefined}>
          <UploadFileDialog
            allowedFileTypes={['csv']}
            handleSubmit={async (file: File) => {
              setErrors([]);
              const data = {
                accountId: clientValue,
                file,
              };

              await processCsvOrdersFile({ variables: { input: [data] } });
            }}
            title="Upload new order(s)"
            onClose={() => {
              setIsOpen(false);
              form.reset();
            }}
            open={isOpen}
            uploadFileLabel="Customer order(s)"
            isSubmitDisabled={clientValue === ''}
            startComponent={
              <ControlledSelect
                id="client"
                label="Client"
                options={options}
                placeholder={
                  options.length > 0 ? 'Select account' : 'No accounts with CSV orders available'
                }
              />
            }
            endComponent={
              errors.length > 0 && (
                <Accordion defaultExpanded>
                  <AccordionSummary>Order Processing Errors</AccordionSummary>

                  <AccordionDetails>
                    <DataGridV2
                      columns={columns}
                      rows={errors.map((error) => ({
                        orderNumber: error.order.number,
                        errorCode: error.code ?? 'Unknown',
                        errorMessage: error.message,
                        rows: error.order.rows.join(', '),
                      }))}
                      getRowId={(row) => row.orderNumber}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            }
          />
        </Form>
      </FormProvider>
    </>
  );
};
