import { Header } from '@components/Header';
import { withParams } from '@components/WithParams';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { checkoutVariantToShortCutProduct } from '@pages/Order/components/ItemsSelect/utils';
import React from 'react';

import { CheckoutVariant, ShortcutProduct } from '@common/types/GraphqlTypes.d';

import { OrderData } from '../graphql/useMerchantOrderWithFulfillment';
import { useMerchantOrderWithFulfillment } from '../graphql/useMerchantOrderWithFulfillment';
import { useUpdateFulfillment } from '../graphql/useUpdateFulfillment';
import { Fields, EMPTY_VALUES } from '../utils/form';
import { ShippingTrackingForm } from '../views/ShippingTrackingForm';

const filterNotFulfilledProducts = (
  checkoutVariants: CheckoutVariant[],
  products: ShortcutProduct[],
): CheckoutVariant[] => {
  // Gather all fulfilled products and their quantities
  const fulfilledProductQuantities = products.reduce((acc, { productId, variantId, quantity }) => {
    const key = `${productId}-${variantId ?? ''}`;

    return {
      ...acc,
      [key]: (acc[key] ?? 0) + quantity,
    };
  }, {} as Record<string, number>);

  // Filter out products that are not part of this fulfillment.
  return checkoutVariants.flatMap((product) => {
    const {
      productId,
      variant: { id },
    } = product;
    const key = `${productId}-${id ?? ''}`;

    const didMatch = fulfilledProductQuantities[key] > 0;

    if (didMatch) {
      return [
        {
          ...product,
          purchaseQuantity: fulfilledProductQuantities[key] ?? 0,
        },
      ];
    }

    return [];
  });
};

const getFormValuesFromFulfillment = (fulfillmentId?: string, order?: OrderData) => {
  const {
    shippingCarrier,
    shippingCarrierCustomName,
    trackingNumber,
    trackingUrl,
    products = [],
  } = order?.fulfillmentDetails?.find(({ _id }) => _id === fulfillmentId) ?? {};
  const filtered = filterNotFulfilledProducts(order?.checkoutVariants ?? [], products);

  return {
    form: {
      ...(shippingCarrier ? { [Fields.Carrier]: shippingCarrier } : {}),
      ...(shippingCarrierCustomName
        ? { [Fields.CarrierCustomName]: shippingCarrierCustomName }
        : {}),
      ...(trackingNumber ? { [Fields.TrackingNumber]: trackingNumber } : {}),
      ...(trackingUrl ? { [Fields.TrackingUrl]: trackingUrl } : {}),
      [Fields.Products]: filtered.map(checkoutVariantToShortCutProduct),
    },
    checkoutVariants: filtered,
  };
};

const getDefaultValuesAndCheckoutVariants = (fulfillmentId?: string) => (order?: OrderData) => {
  if (!fulfillmentId) {
    return {
      form: EMPTY_VALUES,
      checkoutVariants: [],
    };
  }

  const { form, checkoutVariants } = getFormValuesFromFulfillment(fulfillmentId, order);

  return {
    form: {
      ...EMPTY_VALUES,
      ...form,
    },
    checkoutVariants,
  };
};

type IEditShippingTrackingParams = { merchantOrderId: string; fulfillmentId: string };

const EditShippingTracking = ({ merchantOrderId, fulfillmentId }: IEditShippingTrackingParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { goto } = useRouter();
  const { data: order, isLoading } = useMerchantOrderWithFulfillment(merchantOrderId);
  const {
    updateFulfillmentMutation,
    isLoading: isUpdating,
    apiError,
  } = useUpdateFulfillment({
    onCompleted: () => {
      enqueueSnackbar('Shipping tracking updated', { variant: 'success' });

      if (order) {
        goto.viewOrder(order.customerOrderId);
      }
    },
  });

  return (
    <>
      <Header title="Edit Shipping" />
      <ShippingTrackingForm
        merchantOrder={order}
        error={apiError}
        getDefaultValues={getDefaultValuesAndCheckoutVariants(fulfillmentId)}
        onSubmit={(data) =>
          updateFulfillmentMutation({
            variables: {
              input: {
                fulfillmentDetails: data,
                merchantOrderId,
                fulfillmentDetailId: fulfillmentId,
              },
            },
          })
        }
        fulfillmentId={fulfillmentId}
        isSubmitting={isUpdating}
        isLoading={isLoading}
        ctaText="Update shipping"
      />
    </>
  );
};

export const EditShippingTrackingPage = withParams<IEditShippingTrackingParams>(
  ['fulfillmentId', 'merchantOrderId'],
  EditShippingTracking,
);
