import { Card } from '@components/Card';
import { StyledLink } from '@components/DataGrid/Styled';
import { DataStack } from '@components/DataStack';
import { useCustomerOrder } from '@hooks/useCustomerOrderV2';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiError } from '@pages/Misc/ApiError';
import { routes } from '@routes/private';
import { Color } from '@theme/palette';
import React from 'react';

import { toFormattedDate } from '@utils/date';

import { CustomerOrderAssignee } from './Assignee';
import { CustomerOrderFulfillmentStatus } from './FulfillmentStatus';

export const useStyles = makeStyles({
  assignee: {
    borderBottom: `1px solid ${Color.Gray3}`,
    paddingBottom: 10,
    marginBottom: 10,
  },
});

type CustomerOrderBasicInformationProps = {
  orderId: string;
};

const title = 'Order information';

export const CustomerOrderBasicInformation = ({ orderId }: CustomerOrderBasicInformationProps) => {
  const classes = useStyles();

  const { isLoading, customerOrder } = useCustomerOrder(orderId, {
    fetchPolicy: 'cache-and-network',
  });

  // Basic information could not be found after API call
  if (!customerOrder && !isLoading) {
    return <ApiError cardTitle={title} message="Order information could not be loaded." />;
  }

  const isTestOrder = customerOrder?.isTest;

  const { account, createdAt, orderNumber, assignee, fulfillmentStatus, externalOrderId } =
    customerOrder || {};

  // Display the following information
  const items = [
    {
      label: 'Assignee',
      value: <CustomerOrderAssignee orderId={orderId} currentAssignee={assignee} />,
      className: classes.assignee,
    },
    { label: 'Order No.', value: orderNumber, default: 'Unknown order number', copy: true },
    { label: 'External Order No.', value: externalOrderId, default: 'N/A', copy: true },
    {
      label: 'Account',
      value: account && (
        <StyledLink to={routes.editAccount.getPath(account.publicId)}>{account.name}</StyledLink>
      ),
    },
    {
      label: 'Placed On',
      value: toFormattedDate(Number(createdAt)),
      default: 'Unknown date',
    },
    {
      label: 'Fulfillment Status',
      value: <CustomerOrderFulfillmentStatus status={fulfillmentStatus} />,
    },
    {
      label: 'Note',
      value: customerOrder?.note,
    },
  ];

  return (
    <Card title={title}>
      {isTestOrder && (
        <Typography variant="h5" color="error">
          This is a test order to be ignored and not fullfiled.
        </Typography>
      )}
      <DataStack stackId="customerOrderInformation" items={items} isLoading={isLoading} />
    </Card>
  );
};
