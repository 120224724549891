import { LoginInput as ILoginInput } from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
// eslint-disable-next-line unicorn/prefer-export-from
export type { ILoginInput };

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for keys.
 */
export type ILoginField = Join<PathsToStringProps<ILoginInput>, '.'>;

/**
 * Returns all available login fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const LOGIN_FIELDS: Record<string, ILoginField> = {
  email: 'email',
  password: 'password',
};
