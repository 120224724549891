import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';
import { useWatch } from 'react-hook-form';

import {
  MERCHANT_INTEGRATION_TYPES,
  MERCHANT_DEFAULTS,
  MerchantIntegrationType,
} from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { integration: inputField } = MERCHANT_FIELDS;

export const IntegrationInformation = ({ isLoading }: { isLoading?: boolean }) => {
  // Needed to render conditional fields
  // TODO: Should switching clear the conditional values from the previous one?
  const integrationType = useWatch({
    name: inputField.type,
    defaultValue: MERCHANT_DEFAULTS.integrationType,
  });

  const limitImport = useWatch({
    name: inputField.feedShouldLimitImport,
    defaultValue: false,
  });

  const forceImport = useWatch({
    name: inputField.feedShouldForceImport,
    defaultValue: false,
  });

  const feedUrl = useWatch({
    name: inputField.feedUrl,
    defaultValue: null,
  });
  const isAwsFeed = (feedUrl || '').includes('s3:');

  return (
    <Card title="Integration">
      <Stack spacing={2}>
        <ControlledSelect
          id={inputField.type}
          isLoading={isLoading}
          label="Type"
          options={MERCHANT_INTEGRATION_TYPES}
          defaultValue={MERCHANT_DEFAULTS.integrationType}
        />

        {/* Shopify */}
        {integrationType === MerchantIntegrationType.Shopify && (
          <>
            <TextField
              id={inputField.shopifyShopName}
              isLoading={isLoading}
              label="Shopify name"
              placeholder="Provide Shopify shop name"
              useHook
            />

            <TextField
              id={inputField.shopifyProductAccessToken}
              isLoading={isLoading}
              label="Products - Access token"
              placeholder="Access token for product management"
              isSensitive
              useHook
            />

            <TextField
              id={inputField.shopifyOrderAccessToken}
              isLoading={isLoading}
              label="Orders - Access token (optional)"
              placeholder="Access token for order management"
              tooltip="This is only needed if rate limiting issues occur when importing products. Default to the access token for products."
              isSensitive
              useHook
            />
          </>
        )}

        {/* Feed */}
        {integrationType === MerchantIntegrationType.Feed && (
          <>
            <TextField
              id={inputField.feedName}
              isLoading={isLoading}
              label="Feed name"
              placeholder="Provide Feed name"
              useHook
            />
            <TextField
              id={inputField.feedUrl}
              isLoading={isLoading}
              label="Feed URL"
              placeholder="Provide Feed URL"
              useHook
            />

            {isAwsFeed && (
              <>
                <ControlledSelect
                  id={inputField.feedAccessKind}
                  isLoading={isLoading}
                  options={[{ value: 'AWS', label: 'AWS' }]}
                  label="Feed Access Kind"
                  placeholder="Provide feed access kind"
                  required
                />

                <TextField
                  id={inputField.feedAccessKeyId}
                  isLoading={isLoading}
                  label="Aws Access Key Id"
                  placeholder="Provide Access Key Id"
                  required
                  useHook
                />

                <TextField
                  id={inputField.feedSecretAccessKey}
                  isLoading={isLoading}
                  label="Aws Secret Access Key"
                  placeholder="Provide Secret Access Key"
                  required
                  useHook
                />

                <TextField
                  id={inputField.feedRegion}
                  isLoading={isLoading}
                  label="Aws Region"
                  placeholder="Provide AWS Region"
                  useHook
                />
              </>
            )}

            <Switch
              checked={limitImport}
              id={inputField.feedShouldLimitImport}
              label="Should limit import"
              useHook
            />
            {/* Only render when limiting import*/}
            {limitImport === true && (
              <TextField
                id={inputField.feedImportLimit}
                isLoading={isLoading}
                type="number"
                label="Import limit"
                placeholder="Limit amount of products being imported"
                useHook
              />
            )}
            <Switch
              checked={forceImport}
              id={inputField.feedShouldForceImport}
              label="Should force import"
              useHook
            />
          </>
        )}
      </Stack>
    </Card>
  );
};
