import { styled } from '@mui/material/styles';
import {
  gridClasses,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { Color } from '@theme/palette';
import React, { useCallback, useEffect, useState } from 'react';

import { StyledLinearProgress } from './Styled';

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarDensitySelector />
  </GridToolbarContainer>
);

const defaultProps: Partial<DataGridProProps> = {
  autoHeight: true,
  columnBuffer: 0,
  density: 'standard',
  pagination: true,
  pageSizeOptions: [10, 25],
  sortingOrder: ['desc', 'asc'],
  disableColumnMenu: true,
  hideFooterSelectedRowCount: true,
  disableRowSelectionOnClick: true,
  disableColumnReorder: true,
  disableColumnResize: true,
  disableMultipleColumnsSorting: true,
  getRowHeight: () => 'auto',

  /**
   * Filters, sort & pagination are handled by the server by default.
   */
  filterMode: 'server',
  paginationMode: 'server',
  sortingMode: 'server',
};

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 4px 8px 0px rgba(38, 50, 56, 0.05)',
  [`& .${gridClasses.columnHeader}`]: {
    color: '#667085',
    backgroundColor: '#F9FAFB',
    '&:focus-within': {
      outline: 'none !important',
    },

    // Hides | between columns given resizing is disabled.
    '& > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },

  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontWeight: 500,
    fontSize: '1em',
  },

  [`& .${gridClasses.cell}`]: {
    fontWeight: 400,
    fontSize: '1em',
    borderBottom: `0.5px solid ${Color.Gray3}`,
    '&:focus-within': {
      outline: 'none !important',
    },
  },

  /**
   * Due to auto-calculating height, padding per density needs to be set manually.
   */
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
    paddingTop: '14px',
    paddingBottom: '14px',
  },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
    paddingTop: '22px',
    paddingBottom: '22px',
  },
}));

type DataGridProps<T> = {
  /**
   * Used internally to identify the row.
   */
  getRowId: (row: T) => string;

  /**
   * Called when the sorting changes.
   */
  onSortChange?: (sorting: GridSortModel) => void;

  /**
   * Shows toolbar with column selector & density selector.
   */
  showToolbar?: boolean;
};

export const DataGridV2 = <T,>({
  rows,
  columns,
  rowCount,
  showToolbar = false,
  onSortChange = () => {},
  ...muiProps
}: DataGridProps<T> & DataGridProProps) => {
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(rowCount ?? 0);

  useEffect(() => {
    setRowCountState((previousRowCountState) => rowCount ?? previousRowCountState);
  }, [rowCount, setRowCountState]);

  const handleSortModelChange = useCallback((sorting: GridSortModel) => {
    onSortChange(sorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Currently only supports column selector & density selector on a basic toolbar.
   * Can easily be expanded in the future.
   */
  const toolbarComponent = showToolbar ? { Toolbar: CustomToolbar } : {};

  return (
    <StyledDataGridPro
      {...defaultProps}
      rows={rows}
      columns={columns}
      rowCount={rowCountState}
      disableRowSelectionOnClick
      onSortModelChange={handleSortModelChange}
      components={{
        LoadingOverlay: StyledLinearProgress,
        ...toolbarComponent,
      }}
      {...muiProps}
    />
  );
};
