import { StyledSelectInput } from '@components/DataFilter/Styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import { Color } from '@theme/palette';
import React from 'react';
import { RefCallBack } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { SelectButton } from './SelectButton';

type PartialProps<T> = {
  id?: string;

  /**
   * Automatically render basic menu items with value/label.
   */
  options?: {
    value: T;
    label: string;
  }[];

  /**
   * Renders custom list itmes if this is preferred instead of options.
   */
  children?: React.ReactNode;

  /**
   * Allows to set default value for the select.
   */
  defaultValue?: T;

  /**
   * Displays helper text when no options selected.
   */
  placeholder?: string | JSX.Element;

  /**
   * Allows to pass ref to the select when used together with react-hook-form.
   * Only use this when using react-hook-form.
   */
  innerRef?: RefCallBack;

  /**
   * Gets called when the value of the select changes.
   * Returns the value of the select (or multiple ones if multiple is set to true).
   * @todo - Return proper value type! MUI Select is giving me a hard time here....
   */
  onUpdate?: (value: any) => void;
};

export type FilterSelectProps = SelectProps<string | number | string[]> &
  PartialProps<string | number | string[]>;

/**
 * Returns enhanced MUI select component.
 * Currently supports String values only (no numbers).
 */
export const FilterSelect = ({
  id = getUniqueId(),
  className,
  placeholder = 'Select an option',
  children,
  options,
  multiple = false,
  defaultValue,
  innerRef,
  onUpdate,
  value,
  ...rest
}: FilterSelectProps) => (
  <FormControl variant="outlined" size="small" className={className}>
    <MuiSelect
      id={id}
      ref={innerRef}
      labelId={id}
      defaultValue={defaultValue ?? (multiple ? [] : '')}
      multiple={multiple}
      value={value}
      input={<StyledSelectInput />}
      IconComponent={ExpandMoreIcon}
      onChange={(event) => {
        if (onUpdate) {
          onUpdate(event.target.value as any);
        }
      }}
      renderValue={(selected) => (
        <SelectButton
          selected={selected}
          options={options}
          multiple={multiple}
          placeholder={placeholder}
        />
      )}
      displayEmpty
      fullWidth
      sx={{
        // TODO: Figure out this weirdness at 1.3em...
        '& .MuiSvgIcon-root': {
          fontSize: '1.2em',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiMenuItem-root': {
              padding: '5px 16px',
              fontSize: '1em',
            },
          },
        },
      }}
      {...rest}
    >
      {/** Show placeholder in list when multiple selection is not applicable. */}
      {!multiple && (
        <MenuItem disabled value="" sx={{ fontSize: '1em', color: Color.Gray1 }}>
          {placeholder}
        </MenuItem>
      )}

      {/* Default label/value items */}
      {options?.map(({ label, value }, index) => (
        <MenuItem
          value={value}
          key={index}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {label}
        </MenuItem>
      ))}

      {children}
    </MuiSelect>
  </FormControl>
);
