import { DataGridV2 } from '@components/DataGrid';
import ErrorBoundary from '@components/ErrorBoundary';
import { usePagination } from '@hooks/useDataStore/usePagination';
import { useSearch } from '@hooks/useDataStore/useSearch';
import { ISorting, SortOrder, useSorting } from '@hooks/useDataStore/useSorting';
import Stack from '@mui/material/Stack';
import React from 'react';

import { columns } from './config/columns';
import { useAccountFilters } from './config/filters';
import { SearchFilter } from './Filters/Search';
import { StatusFilter } from './Filters/Status';
import { IAccount, useAccounts } from './graphql';
import { AccountOverviewHeader } from './Header';

const DEFAULT_SORTING: ISorting = {
  sortField: 'name',
  sortOrder: SortOrder.Asc,
};

export const ListAccounts = () => {
  // Fetch global filters, pagination & sorting
  const { pagination, updatePagination, getGraphQLPagination } = usePagination();
  const { getDataGridSort, updateDataGridSort, getGraphQLSort } = useSorting(DEFAULT_SORTING);
  const { filters } = useAccountFilters();
  const { search } = useSearch();

  const {
    data = [],
    total: totalRowCount,
    isLoading,
  } = useAccounts({
    variables: {
      input: {
        ...getGraphQLPagination(),
        ...getGraphQLSort(),
        filters,
        search,
      },
    },
  });

  return (
    <ErrorBoundary>
      <AccountOverviewHeader />

      <Stack gap={2}>
        <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
          <SearchFilter />
          <StatusFilter />
        </Stack>

        <DataGridV2
          rows={data}
          columns={columns}
          loading={isLoading}
          rowCount={totalRowCount}
          getRowId={(account: IAccount) => account._id}
          paginationModel={pagination}
          onPaginationModelChange={updatePagination}
          onSortChange={updateDataGridSort}
          initialState={{
            sorting: getDataGridSort(),
          }}
        />
      </Stack>
    </ErrorBoundary>
  );
};
