import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ViewProduct } from '@pages/Product/View';
import { Color } from '@theme/palette';
import React from 'react';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1024px',
    width: '100%',
    backgroundColor: Color.White,
    boxShadow: '24',
    outline: 'none',
    padding: 25,
  },
  contentWrapper: {
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  content: {
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: '-45px',
    top: 0,
    backgroundColor: Color.DarkNight,
    padding: 5,
    color: Color.White,
    height: 35,
    cursor: 'pointer',
  },
}));

export type SelectedProduct = {
  publicId: string;
  updatedAt: Date | null;
};

type ProductModalProps = {
  product: SelectedProduct | null;
  closeModal: (product: null) => void;
};

export const ProductModal = ({ product, closeModal }: ProductModalProps) => {
  const classes = useStyles();

  if (!product) {
    return null;
  }

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.root}>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <ViewProduct product={product} />
          </div>

          <div className={classes.close}>
            <CloseIcon onClick={() => closeModal(null)} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
