import { IProcessedOrder } from '@pages/Order/View/OrderedItems/interfaces';
import { makeMerchantOrderAndGhostOrderToProcessedOrder } from '@pages/Order/View/OrderedItems/utils';
import { FulfillmentStatus } from '@types';

import { MerchantOrder } from '@common/types/GraphqlTypes.d';

/*
 * Takes in a merchant order and returns a copy of it with only checkout variants that are part of an archived feed order.
 */
export const getArchivedProcessedOrders = (merchantOrder: MerchantOrder): IProcessedOrder[] => {
  const { ghostExternalOrders } = merchantOrder;

  const getProcessedOrder = makeMerchantOrderAndGhostOrderToProcessedOrder(merchantOrder);

  return (
    ghostExternalOrders?.flatMap((order) =>
      order?.fulfillment === FulfillmentStatus.CANCELLED ? [getProcessedOrder(order)] : [],
    ) ?? []
  );
};
