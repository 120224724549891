import { Card } from '@components/Card';
import { DataField } from '@components/DataField';
import { FileField } from '@components/Input/File';
import { Switch } from '@components/Input/Switch';
import { TextField } from '@components/Input/TextField';
import { StatusTag, StatusTagType } from '@components/StatusTag';
import { Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ClientStatus } from '@common/types/GraphqlTypes.d';

import { MERCHANT_FIELDS } from '../../fields';
import { useStyles } from './styles';
const { basic: inputField } = MERCHANT_FIELDS;

export const BasicInformation = ({
  isLoading,
  canEditSlug,
}: {
  isLoading?: boolean;
  canEditSlug: boolean;
}) => {
  const classes = useStyles();

  const { getValues } = useFormContext();
  const stage: ClientStatus = getValues(inputField.stage);

  const slug = getValues(inputField.slug) || '';

  const [inputValue, setInputValue] = useState(slug);
  const [isValid, setIsValid] = useState(true);

  const slugValidations = {
    message: 'Must be lowercase alphanumeric with single dashes used to separate words.',
    conditions: [
      /^[\da-z\-]+$/, // valid chars only
      /^(?!.*--).*$/, // checks double dash
      /^(?!-)/, //checks trailing dash
      /(?<!-)$/, // checks leading dash
    ],
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    const isValidInput = slugValidations.conditions.every((condition) =>
      condition.test(event.target.value),
    );

    setIsValid(isValidInput);
  };

  const conditions = [
    { type: StatusTagType.Success, match: () => stage === ClientStatus.Live },
    { type: StatusTagType.Warn, match: () => stage === ClientStatus.Draft },
    { type: StatusTagType.Error, match: () => stage === ClientStatus.Archived },
  ];

  return (
    <Card title="Basic Information">
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <FileField
              id={inputField.logo}
              label="Upload logo"
              className={classes.upload}
              accept="image/*"
              isLoading={isLoading}
              showPreview
            />
          </Grid>

          <Grid item xs>
            <Stack direction="column" gap={1}>
              {/** Only show when value exists (during edit) */}
              {stage && (
                <DataField
                  isLoading={isLoading}
                  direction="column"
                  label="Status"
                  value={<StatusTag text={stage} conditions={conditions} />}
                />
              )}
              <Stack direction="row" gap={1}>
                <TextField
                  id={inputField.name}
                  isLoading={isLoading}
                  label="Name"
                  placeholder="Enter the merchant name"
                  options={{
                    required: 'Merchant name is required',
                  }}
                  useHook
                />
                {canEditSlug ? (
                  <TextField
                    id={inputField.slug}
                    isLoading={isLoading}
                    label="Slug"
                    placeholder="Each should have a unique slug."
                    options={{
                      required: 'Merchant slug is required',
                    }}
                    onChange={handleInputChange}
                    error={!isValid}
                    helperText={isValid ? '' : slugValidations.message}
                    useHook
                    value={inputValue}
                  />
                ) : (
                  <TextField
                    id={inputField.slug}
                    isLoading={isLoading}
                    label="Slug"
                    disabled
                    value={slug}
                    allowCopy
                    useHook
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={2}>
          <TextField
            id={inputField.description}
            isLoading={isLoading}
            label="Description"
            placeholder="Display description for the company"
            multiline
            minRows={4}
            useHook
          />
          <TextField
            id={inputField.returnPolicy}
            isLoading={isLoading}
            label="Return policy"
            placeholder="Provide optional return policy"
            multiline
            minRows={4}
            useHook
          />
          <DataField
            label="Test merchant"
            direction="column"
            value={
              <Switch
                id={inputField.isTestMerchant}
                label="Toggle on if this merchant is used for testing"
                useHook
              />
            }
          />
        </Stack>
      </Stack>
    </Card>
  );
};
