/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormControlLabel, Switch as MuiSwitch, SwitchProps, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

import { getUniqueId } from '@utils/id';

import { useStyles } from './styles';

export type ISwitchProps = {
  label?: string;

  // Support for react-hook-form
  useHook?: boolean;
  options?: RegisterOptions<any, any>;
};

export const Switch: React.FC<SwitchProps & ISwitchProps> = ({
  id = getUniqueId(),
  label,
  className,
  options = {},
  useHook = true,
  ...otherProps
}) => {
  const classes = useStyles();

  // React-hook-form support (best practices)
  // Even if register is not used it has to be called since it's a React hook
  const { register } = useFormContext<any>() || {};
  const formValue = useWatch({
    name: id,
    defaultValue: false,
  });

  // [Optional] - React-hooks form support
  const registerProps = () => {
    if (useHook) {
      const { ref, ...restOfRegistrationProps } = register(id, options);

      return {
        inputRef: ref,
        checked: formValue,
        ...restOfRegistrationProps,
      };
    }

    return {};
  };

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          id={id}
          size="small"
          className={clsx([classes.root, className])}
          {...registerProps()}
          {...otherProps}
        />
      }
      label={<Typography className={classes.label}>{label || ''}</Typography>}
    />
  );
};
