import { Card } from '@components/Card';
import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  message: {
    color: theme.palette.primary.main,
  },
}));

type ApiErrorProps = {
  /**
   * Message to display.
   * Can also be a React element for more custom views.
   */
  message: string | React.ReactElement;

  /**
   * Indicates whether to wrap the content in a Card component.
   * Mainly used for subcomponents on pages.
   */
  card?: boolean;

  /**
   * Optional title for card.
   */
  cardTitle?: string;
};

export const ApiError = ({ message, card, cardTitle }: ApiErrorProps) => {
  const classes = useStyles();

  const content = (
    <Stack className={classes.root}>
      <span className={classes.message}>
        {message} <br />
        Please try again, or contact our technical support team
      </span>
    </Stack>
  );

  // Optionally wrap content in card. If title is provided, no need to pass card too.
  if (card || cardTitle) {
    return <Card title={cardTitle}>{content}</Card>;
  }

  return content;
};
