/* eslint-disable max-lines-per-function */
import { gql, useMutation } from '@apollo/client';
import { Form } from '@components/Form';
import { PasswordInput } from '@components/Input/__deprecated/Password';
import { withParams } from '@components/WithParams';
import { Alert } from '@mui/lab';
import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import publicIp from 'public-ip';
import React from 'react';
import { browserName } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useStyles } from './styles';

interface IFormData {
  newPassword: string;
  confirmNewPassword: string;
}

interface ISetNewPasswordFormProps {
  className: string;
}
type ISetNewPassWordFormParams = { resetPasswordToken: string };

const SET_NEW_PASSWORD_MUTATION = gql`
  mutation setNewPassword($input: SetNewPasswordInput!) {
    setNewPassword(input: $input) {
      result
    }
  }
`;

const SetNewPasswordForm = ({
  className,
  resetPasswordToken,
}: ISetNewPasswordFormProps & ISetNewPassWordFormParams): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { formState, register, handleSubmit, getValues, trigger } = useForm<IFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [setNewPassword, { error, loading: isLoading, data }] = useMutation(
    SET_NEW_PASSWORD_MUTATION,
    {
      errorPolicy: 'all',
      onCompleted: ({ setNewPassword }) =>
        setNewPassword?.result && navigate('/login?success=true'),
    },
  );

  const validateFirstPassword = (password: string) => {
    trigger('confirmNewPassword');

    return (
      (Boolean(/[^\dA-Za-z]/g.test(password)) && Boolean(/\d/g.test(password))) ||
      'password must contain 1 number and 1 special character'
    );
  };

  const validateConfirmPassword = (password: string) =>
    Boolean(getValues()?.newPassword === password) || 'passwords must match';

  const validatePassword = ({ newPassword, confirmNewPassword }: IFormData) => {
    if (!validateConfirmPassword(confirmNewPassword)) {
      throw new Error("Passwords don't match");
    } else if (newPassword.length < 8) {
      throw new Error('Password must be a minimum 8 characters');
    } else if (!validateFirstPassword(newPassword)) {
      throw new Error('Password must contain at least 1 number & 1 special character');
    }
  };

  const onSubmit = async ({ confirmNewPassword, newPassword }: IFormData) => {
    validatePassword({ confirmNewPassword, newPassword });
    const ipAddress = await publicIp.v4();

    setNewPassword({
      variables: {
        input: {
          browserName,
          confirmNewPassword,
          ipAddress,
          newPassword,
          resetPasswordToken,
        },
      },
    });
  };

  const { isValid } = formState;

  return (
    <Form className={clsx([classes.root, className])} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.header}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          Set New Password
        </Typography>
        <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
          Set a new password for your account.
        </Typography>
      </div>
      <PasswordInput
        className={classes.newPasswordInput}
        hasError={Boolean(error)}
        name="newPassword"
        placeholder={'Password'}
        register={register}
      />
      <PasswordInput
        className={classes.confirmNewPasswordInput}
        hasError={Boolean(error)}
        name="confirmNewPassword"
        placeholder={'Confirm Password'}
        register={register}
      />
      {isLoading ? <CircularProgress /> : null}
      <Button
        className={classes.confirmButton}
        color="primary"
        disabled={!isValid || isLoading}
        onClick={handleSubmit(onSubmit)}
        type="submit"
        variant="contained"
      >
        Confirm
      </Button>
      {error && <Alert severity="error">{error.message}</Alert>}
      {data?.setNewPassword?.result && <Alert severity="success">Password Set!</Alert>}
      <Button onClick={() => navigate(-1)} className={classes.cancel}>
        Cancel
      </Button>
    </Form>
  );
};

export const SetNewPasswordFormPage = withParams<
  ISetNewPassWordFormParams,
  ISetNewPasswordFormProps
>(['resetPasswordToken'], SetNewPasswordForm);
