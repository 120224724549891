import { Text } from '@components/Text';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CustomerOrderFulfillmentStatus } from '@pages/CustomerOrderV2/View/components/OrderInformation/FulfillmentStatus';
import React from 'react';

import { toFormattedDate } from '@utils/date';

import { IProcessedOrder } from '../interfaces';
import { useStyles } from '../styles';

type Props = {
  updatedAt: Date | number;
  updatedBy: string;
  className?: string;
};

export const MerchantOrderSubtitle = ({ updatedAt, updatedBy, className }: Props): JSX.Element => {
  const text = `Last updated on ${
    updatedAt ? toFormattedDate(updatedAt) : 'Unknown date'
  }, by ${updatedBy}`;

  return (
    <Text className={className} fontSize="0.625rem">
      {text}
    </Text>
  );
};

export const ProcessedOrderHeader = ({ order }: { order: IProcessedOrder }) => {
  const classes = useStyles();

  return (
    <>
      <Stack direction="row" gap={2}>
        <Typography fontSize="15px" fontWeight={600} gutterBottom>
          Merchant Order No. {order.externalOrderNumber}
        </Typography>
        <CustomerOrderFulfillmentStatus
          className={classes.fulfillmentStatus}
          status={order.fulfillmentStatus}
        />
      </Stack>

      <MerchantOrderSubtitle updatedAt={order.updatedAt} updatedBy={order.createdBy} />
    </>
  );
};
