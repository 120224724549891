import { CardTitle } from '@components/CardTitle';
import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export type CardProps = Omit<PaperProps, 'title'> & {
  /**
   * Renders optional title above the card.
   */
  title?: string | JSX.Element;

  /**
   * Removes padding from the card.
   * Useful when e.g. Rendering a table within a card without padding.
   */
  noPadding?: boolean;

  /**
   * Adds custom class to the card.
   * Useful when applying custom styling to card.
   */
  className?: string;

  /**
   * Renders a divider below the title.
   */
  divider?: boolean;

  /**
   * Renders a loading skeleton within the card.
   */
  isLoading?: boolean;

  /**
   * Renders children within the card.
   */
  children: React.ReactNode;
};

export const Card = ({
  children,
  className,
  divider = false,
  isLoading = false,
  noPadding = false,
  title,
  ...muiProps
}: CardProps) => {
  const classes = useStyles();

  return (
    <Box component="div">
      <CardTitle title={title} divider={divider} />

      {isLoading ? (
        <Paper className={clsx([classes.paper, classes.paperLoading, className])}>
          <Skeleton />
        </Paper>
      ) : (
        <Paper className={clsx([classes.paper, className])} {...muiProps}>
          <Stack gap={2} sx={{ padding: noPadding ? 0 : '16px' }}>
            {children}
          </Stack>
        </Paper>
      )}
    </Box>
  );
};
