import { gql, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { Account, QueryAccountsArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within BRANDS_QUERY.
export type IAccount = Pick<Account, '_id' | 'billing' | 'name' | 'publicId' | 'publisher'>;

const ACCOUNTS_QUERY = gql`
  query accounts($input: AccountsInput!) {
    accounts(input: $input) {
      data {
        _id
        status
        billing {
          fee
        }
        name
        publicId
        publisher {
          _id
          name
        }
      }
      totalCount
    }
  }
`;

/**
 * Hook for fetching accounts.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useAccounts = (
  options: QueryHookOptions<
    { accounts: { data: IAccount[]; totalCount: number } },
    QueryAccountsArgs
  >,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ accounts: { data: IAccount[]; totalCount: number } }, QueryAccountsArgs>(
    ACCOUNTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const { data, totalCount: total } = apiResult?.accounts ?? {};

  return { data, total, isLoading, apiError };
};
