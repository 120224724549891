import { FilterSelect } from '@components/DataFilter/Select';
import { PaymentStatus } from '@types';
import React from 'react';

import { useOrderFilters } from '../config/filters';

const options = Object.values(PaymentStatus).map((status) => ({
  label: status,
  value: status,
}));

export const PaymentStatusFilter = () => {
  const { filters, updateFilters } = useOrderFilters();

  return (
    <FilterSelect
      placeholder="Payment Status"
      options={options}
      value={filters.paymentStatuses}
      onUpdate={(paymentStatuses: PaymentStatus[]) => updateFilters({ paymentStatuses })}
      multiple
    />
  );
};
