/* eslint-disable max-lines-per-function */

import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { TextField } from '@components/Input/TextField';
import { Divider, Grid, Stack } from '@mui/material';
import { RefundReason } from '@types';
import currencyJs from 'currency.js';
import React from 'react';

import { MerchantOrderSummary } from '@common/types/GraphqlTypes.d';

import { useStyles } from './styles';
import { RefundFormFields } from './types';

const refundReasonOptions = Object.values(RefundReason).map((value) => ({
  value,
  label: value,
}));

interface IRefundFieldsProps {
  isLoading: boolean;
  onCancel: () => void;
  orderSummary: MerchantOrderSummary;
}

export const RefundFields: React.FC<IRefundFieldsProps> = ({
  isLoading,
  onCancel,
  orderSummary,
}) => {
  const classes = useStyles();

  const { subTotal, shippingWithTax, customerChargeTotal, duties } = orderSummary;

  const { value: taxes } = currencyJs(customerChargeTotal)
    .subtract(subTotal)
    .subtract(shippingWithTax)
    .subtract(duties);

  return (
    <Card title="Refund details" isLoading={isLoading} className={classes.card}>
      <Stack spacing={2}>
        <TextField
          id={RefundFormFields.Amount}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0,
            max: subTotal,
          }}
          isLoading={isLoading}
          label="Amount to refund (subtotal)"
          placeholder="Amount to refund"
          defaultValue={subTotal}
          useHook
        />
        {`Maximum of ${subTotal}`}

        <TextField
          id={RefundFormFields.Shipping}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0,
            max: shippingWithTax,
          }}
          isLoading={isLoading}
          label="Amount of Shipping to refund. Includes shipping tax."
          placeholder="Amount"
          defaultValue={0}
          useHook
        />
        {`Maximum of ${shippingWithTax}`}
        <TextField
          id={RefundFormFields.Duties}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0,
            max: duties,
          }}
          isLoading={isLoading}
          label="Amount of Duties to refund."
          placeholder="Amount"
          defaultValue={0}
          useHook
        />
        {`Maximum of ${duties}`}
        <TextField
          id={RefundFormFields.Taxes}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0,
            max: taxes,
          }}
          isLoading={isLoading}
          label="Amount of Taxes to refund"
          placeholder="Taxes"
          defaultValue={taxes}
          useHook
        />
        {`Maximum of ${taxes}`}
        <ControlledSelect
          className={classes.formField}
          id={RefundFormFields.Reason}
          options={refundReasonOptions}
          defaultValue={RefundReason.CustomerCanceled}
          label="Refund Reason"
        />
        <Grid>
          <Switch
            className={classes.formField}
            id={RefundFormFields.IsBonsaiOnly}
            label="Bonsai only refund"
            useHook
          />
        </Grid>
        <Divider className={classes.divider} />
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" disabled={isLoading}>
          Submit refund
        </PrimaryButton>
      </Stack>
    </Card>
  );
};
