import ErrorBoundary from '@components/ErrorBoundary';
import Box from '@mui/material/Box';
import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * The overall application layout used across all pages.
 */
export const Layout = () => (
  <Box sx={{ display: 'flex', height: '100vh' }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <ErrorBoundary>
        {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
        <Outlet />
      </ErrorBoundary>
    </Box>
  </Box>
);
