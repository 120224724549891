import { gql } from '@apollo/client';

/**
 * Returns order ID.
 */
export const CUSTOMER_ORDER_ID_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      orderNumber
      publicId
    }
  }
`;

/**
 * Returns basic customer order information.
 */
export const CUSTOMER_ORDER_BASIC_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      externalOrderId
      merchantOrderIds
      orderNumber
      publicId
      fulfillmentStatus
      note
      account {
        publicId
        name
      }
      assignee {
        name
        userId
      }
      createdAt
      isTest
    }
  }
`;

/**
 * Returns customer order customer information.
 */
export const CUSTOMER_ORDER_CUSTOMER_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      deliveryAddress {
        addressLineOne
        addressLineTwo
        city
        country
        name
        phoneNumber
        postalCode
        province
      }
      customer {
        name
        id
        email
        firstName
        lastName
      }
    }
  }
`;

/**
 * Returns customer order payment information.
 */
export const CUSTOMER_ORDER_PAYMENT_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      paymentStatus
      paymentMethod
      externalPayment {
        currency
        amounts {
          shipping
          taxes
          goods
        }
      }
    }
  }
`;

/**
 * Returns history for customer order.
 */
export const CUSTOMER_ORDER_HISTORY_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      history {
        event {
          type
          action
        }
        payload {
          message
        }
        status
        createdAt
      }
    }
  }
`;

/**
 * Returns comments for customer order.
 */
export const CUSTOMER_ORDER_COMMENTS_QUERY = gql`
  query ($id: String!) {
    customerOrder(_id: $id) {
      _id
      comments {
        _id
        comment
        escalation {
          status
          state
          productName
        }
        createdAt
        user {
          name
          userId
        }
      }
    }
  }
`;
