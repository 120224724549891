import { useMutation } from '@apollo/client';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { CUSTOMER_ORDER_QUERY } from '@hooks/useCustomerOrder';
import { MERCHANT_ORDERS_QUERY } from '@hooks/useMerchantOrders';
import { useSnackbar } from '@hooks/useSnackbar';
import { MenuItem, Typography } from '@mui/material';
import { GoToPageItem } from '@pages/Order/components/GoToPageMenuItem';
import { PrivateRoutes } from '@routes/private';
import { FulfillmentStatus } from '@types';
import React from 'react';

import { setFulfillmentStatusMutation } from './graphql';
import { IProcessedOrder } from './interfaces';
import { useStyles } from './styles';
import { isOrderFeed } from './utils';

interface IEditFeedOrderItemProps {
  order: IProcessedOrder;
}

export const EditFeedOrderItem: React.FC<IEditFeedOrderItemProps> = ({ order }) =>
  isOrderFeed(order) ? (
    <GoToPageItem
      label={'Edit'}
      path={PrivateRoutes.editFeedOrder}
      pathParams={[order.merchantOrderId, order.processedOrderId]}
    />
  ) : null;

interface IFulfillOrderItemProps {
  merchantOrderId: string;
  fulfillmentStatus: FulfillmentStatus;
}

export const UpdateFulfillmentStatusItem: React.FC<IFulfillOrderItemProps> = ({
  fulfillmentStatus,
  merchantOrderId,
}) => {
  const classes = useStyles();
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();
  const [setFulfillmentStatus] = useMutation(setFulfillmentStatusMutation, {
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: [CUSTOMER_ORDER_QUERY, MERCHANT_ORDERS_QUERY],
  });

  const handleFulfillmentStatusChange = async () => {
    await setFulfillmentStatus({
      variables: {
        input: {
          merchantOrderId,
          fulfillmentStatus,
        },
      },
    });

    // Show reminder to refund if order is cancelled.
    if (fulfillmentStatus === FulfillmentStatus.CANCELLED) {
      enqueueSnackbar('Make sure to issue a refund after this cancellation!', {
        variant: 'warning',
      });
    }
  };

  return (
    <MenuItem onClick={handleFulfillmentStatusChange}>
      <Typography className={classes.menuItemText} variant="subtitle2">
        {`Mark as ${fulfillmentStatus}`}
      </Typography>
    </MenuItem>
  );
};
