import { withParams } from '@components/WithParams';
import { Grid, Stack } from '@mui/material';
import { NotFound } from '@pages/Misc/NotFound';
import React from 'react';

import { ViewBasicInformation } from './components/Basic';
import { ViewMetaData } from './components/Metadata';
import { ViewProducts } from './components/Products';
import { useProductCollection, useProducts } from './graphql';
import { ViewProductCollectionHeader } from './Header';

type IViewProductCollectionParams = { id: string };

const ViewProductCollection = ({ id }: IViewProductCollectionParams) => {
  // GraphQL hooks
  const { isLoading, data } = useProductCollection({
    variables: { _id: id },
    onError: (error) => {
      console.error('API error fetching product collection:', error);
    },
  });

  // Load product information only when collection data has loaded
  const { isLoading: areProductsLoading, data: productData } = useProducts(!!data, {
    variables: { input: { productIds: data?.productIds || [] } },
    onError: (error) => {
      console.error('API error fetching products:', error);
    },
  });

  // Product collection could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  return (
    <Stack gap={2}>
      <ViewProductCollectionHeader collection={data} isLoading={isLoading} />
      <Grid container direction="row" spacing={2}>
        <Grid item xs={8}>
          <ViewBasicInformation collection={data} isLoading={isLoading} />
        </Grid>

        <Grid item xs={4}>
          <ViewMetaData collection={data} isLoading={isLoading} />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <ViewProducts products={productData} isLoading={areProductsLoading} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export const ViewProductCollectionPage = withParams<IViewProductCollectionParams>(
  ['id'],
  ViewProductCollection,
);
