import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Brand, QueryBrandsArgs } from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within BRANDS_QUERY.
export type IBrand = Pick<Brand, '_id' | 'name' | 'publishedStage'>;

export const BRANDS_QUERY = gql`
  query brands($input: BrandListInput!) {
    brands(input: $input) {
      data {
        _id
        name
        publishedStage
      }
      total
    }
  }
`;

/**
 * Hook for fetching brands.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useBrands = (
  options: QueryHookOptions<{ brands: { data: IBrand[]; total: number } }, QueryBrandsArgs>,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
    fetchMore,
  } = useQuery<{ brands: { data: IBrand[]; total: number } }, QueryBrandsArgs>(BRANDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const { data, total } = apiResult?.brands ?? {};

  return { data, fetchMore, total, isLoading, apiError };
};
