import { SecondaryButton } from '@components/Button/Secondary';
import React from 'react';

type ClearFiltersProps = {
  /**
   * Function to be called when the clear filters button is clicked.
   */
  clear: () => void;

  /**
   * Indicates whether the clear button should be visible.
   */
  visible: boolean;
};

/**
 * Returns component used for clearing filters.
 */
export const ClearFilters = ({ visible, clear }: ClearFiltersProps) =>
  visible ? (
    <SecondaryButton onClick={clear} sx={{ border: 'none !important' }}>
      Clear filters
    </SecondaryButton>
  ) : null;
