import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Dialog } from '@components/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { UserRef } from '@common/types/GraphqlTypes.d';

export const useStyles = makeStyles({
  buttonGroup: {
    marginTop: 15,
  },
  content: {
    fontSize: '1em',
  },
});

type ReassignConfirmProps = {
  oldAssignee?: UserRef | null;
  newAssignee?: UserRef;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: <T>(value?: T) => void;
};

export const ReassignmentConfirmModal = ({
  oldAssignee,
  newAssignee,
  isOpen,
  onCancel,
  onConfirm,
}: ReassignConfirmProps) => {
  const classes = useStyles();

  return (
    <Dialog title="Reassign Order?" onClose={onCancel} open={isOpen}>
      <Box>
        <Typography className={classes.content}>
          This customer order has already been assigned to
          <b> {oldAssignee?.name ?? 'Unassigned'}</b>. Are you sure you want to re-assign it to
          <b> {newAssignee?.name ?? 'Unassigned'}</b>?
        </Typography>

        <Stack
          className={classes.buttonGroup}
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="end"
        >
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => onConfirm(newAssignee)}>Re-assign</PrimaryButton>
        </Stack>
      </Box>
    </Dialog>
  );
};
