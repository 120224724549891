import {
  MerchantInput as IMerchantInput,
  Merchant as IMerchant,
} from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
// eslint-disable-next-line unicorn/prefer-export-from
export type { IMerchantInput, IMerchant };

export type IMerchantCategory =
  | 'basic'
  | 'office'
  | 'financial'
  | 'integration'
  | 'taxes'
  | 'shipping'
  | 'inventory'
  | 'fulfillment'
  | 'metadata';

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for nested keys.
 * @todo - Allow nested keys (integration > shopify > ...).
 */
export type IMerchantField = Join<
  PathsToStringProps<
    IMerchant & {
      config: {
        shipping: {
          simple: string;
        };
      };
      enabledWebhooks: string;
    }
  >,
  '.'
>;

/**
 * Returns all available merchant fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const MERCHANT_FIELDS: Record<IMerchantCategory, Record<string, IMerchantField | '_id'>> = {
  // Basic information
  basic: {
    logo: 'squareImage.url',
    name: 'name',
    slug: 'slug',
    stage: 'publishedStage',
    description: 'description',
    returnPolicy: 'returnPolicy',
    isTestMerchant: 'config.isTestMerchant',
  },

  // Office
  office: {
    name: 'offices.name',
    addressLine1: 'offices.addressLine1',
    addressLine2: 'offices.addressLine2',
    postalCode: 'offices.postalCode',
    city: 'offices.city',
    region: 'offices.region',
    country: 'offices.country',
    phoneNumber: 'offices.phoneNumber',
    email: 'email',
  },

  // Financial
  financial: {
    currency: 'currency',
    fee: 'private.fee',
    commissionType: 'billing.commissionType',
    isStripeConnected: 'isStripeConnected',
    stripeAccount: 'stripeAccount',
    canUseFlexiblePayment: 'config.canUseFlexiblePayment',
  },

  // Integration
  integration: {
    type: 'private.credentials.integrationType',
    autoReview: 'config.review.auto',
    enabledWebhooks: 'enabledWebhooks',

    // Shopify
    shopifyShopName: 'private.credentials.shopify.shopName',
    shopifyProductAccessToken: 'private.credentials.shopify.keys.product.accessToken',
    shopifyOrderAccessToken: 'private.credentials.shopify.keys.order.accessToken',

    // Feed
    feedDefaultInventoryAmount: 'config.options.defaultInventoryAmount',
    feedImageHeaders: 'config.options.imageHeaders',
    feedImportLimit: 'config.options.importLimit',
    feedName: 'private.credentials.feed.feedName',
    feedSelfHosted: 'config.options.selfHosted',
    feedShouldForceImport: 'config.options.shouldForceImport',
    feedShouldLimitImport: 'config.options.shouldLimitImport',
    feedShouldRemoveQueryParamsFromImgUrls: 'config.options.shouldRemoveQueryParamsFromImgUrls',
    feedShouldUseImageProxy: 'config.options.shouldUseImageProxy',
    feedUrl: 'private.credentials.feed.url',
    feedAccessKind: 'private.credentials.feed.accessCredentials.aws.kind',
    feedAccessKeyId: 'private.credentials.feed.accessCredentials.aws.accessKeyId',
    feedSecretAccessKey: 'private.credentials.feed.accessCredentials.aws.secretAccessKey',
    feedRegion: 'private.credentials.feed.accessCredentials.aws.region',
  },

  // Taxes
  taxes: {
    type: 'config.taxes',
    avataxCode: 'companyCode',
    areTaxesIncluded: 'areTaxesIncluded',
    hasNexusEnabled: 'config.hasNexusEnabled',
  },

  // Shipping
  shipping: {
    bookie: 'config.shipping.bookie',
    type: 'config.shipping.type',
    simple: 'config.shipping.simple',
    freeShipping: 'config.shipping.free.enabled',
  },

  // Inventory management
  inventory: {
    restockingFee: 'config.restockingFee',
  },

  // Fulfillment
  fulfillment: {
    instructions: 'fulfillment.instructions',
  },

  // Metadata (read-only)
  metadata: {
    id: '_id',
  },
};
