import { gql, useQuery, QueryHookOptions } from '@apollo/client';

import { MerchantOrder as IMerchantOrder } from '@common/types/GraphqlTypes.d';

export interface IOrderData {
  getMultipleMerchantOrders: IMerchantOrder[];
}

const merchantOrderFields = `#graphql
  _id
  fulfillmentStatus
  checkoutVariants {
    variant {
      id
      brand
      currency
      images
      optionTitles
      optionValues
      price
      priceUSD
    }
    _id
    name
    productId
    productURL
    publicId
    purchaseQuantity
  }
  createdAt
  externalOrder {
    _id
    createExternalOrder {
      result
    }
    externalOrderId
    orderNumber
    updatedAt
    createdAt
  }
  summary {
    shippingWithTax
    subTotal
    shipping
    subTotalWithTax
    customerChargeTotal
    duties
  }
  ghostExternalOrders {
    _id
    fulfillment
    merchantOrderNumber
    createdAt
    updatedAt
    shipping
    subtotal
    discount
    taxes
    email
    comments
    paymentMethod
    products {
      productId
      quantity
      variantId
    }
  }
  incrementalId
  merchant {
    integrationType
    name
  }
  payment {
    name
    processor
    stripeMessage
  }
  paymentStatus
  shipping {
    selectedShippingOption {
      code
      description
      shippingRate
      shippingRateCurrency
      shippingTax
      shippingTaxCurrency
    }
  }
  fulfillmentDetails {
    _id
    shippingCarrier
    shippingCarrierCustomName
    trackingNumber
    trackingUrl
    cancelled
    products {
      productId
      variantId
      quantity
    }
  }
  refunds {
    _id
    amount
    checkoutVariants {
      variant {
        id
        brand
        currency
        images
        optionTitles
        optionValues
        price
      }
      _id
      name
      productId
      productURL
      publicId
      purchaseQuantity
    }
    createdAt
    createdBy {
      name
    }
    refundReason
    refundedAmount
    refundedShipping
    refundedDuties
    refundedSubtotal
    refundedTaxes
  }
  merchantPayment
  updatedAt
  fulfillmentInstructions
`;

export const MERCHANT_ORDERS_QUERY = gql`
  query getMultipleMerchantOrders($input: MerchantOrderIdsInput!) {
    getMultipleMerchantOrders(input: $input) {
      ${merchantOrderFields}
    }
  }
`;

export const useMerchantOrders = (
  merchantOrderIds: string[] = [],
  options: QueryHookOptions = {},
) => {
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<IOrderData>(MERCHANT_ORDERS_QUERY, {
    variables: { input: { merchantOrderIds } },
    ...options,
  });

  const merchantOrders = data?.getMultipleMerchantOrders ?? [];

  return { merchantOrders, isLoading, error };
};

export const MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID = gql`
  query getMerchantOrdersByCustomerOrderId($input: CustomerOrderIdInput!) {
    getMerchantOrdersByCustomerOrderId(input: $input) {
      ${merchantOrderFields}
    }
  }
`;

export const useMerchantOrdersByCustomerOrderId = (
  customerOrderId: string,
  options: QueryHookOptions = {},
) => {
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<{
    getMerchantOrdersByCustomerOrderId: IMerchantOrder[];
  }>(MERCHANT_ORDERS_BY_CUSTOMER_ORDER_ID, {
    variables: { input: { customerOrderId } },
    ...options,
  });

  const merchantOrders = data?.getMerchantOrdersByCustomerOrderId ?? [];

  return { merchantOrders, isLoading, error };
};
