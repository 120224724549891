import {
  OrderHistoryStatus,
  OrderHistoryEventType,
  OrderHistoryPayload,
} from '@common/types/GraphqlTypes.d';

// Ideally, we should have the proper type depending on the event, but graphQL isn't loving union types right now.
// Worst case, this will simply not show all information if there's a bug, but not crash or show incorrect information.
export const ORDER_HISTORY_MESSAGES = ({
  name = 'Unknown',
  email = 'n/a',
  merchantOrderNumber,
}: OrderHistoryPayload): Record<
  OrderHistoryEventType,
  {
    [OrderHistoryStatus.Error]: string;
    [OrderHistoryStatus.Success]: string;
  }
> => ({
  // Emails
  [OrderHistoryEventType.CustomerOrderProcessedOrderEmailSent]: {
    [OrderHistoryStatus.Success]: `Customer order processed email sent to ${name} (${email})`,
    [OrderHistoryStatus.Error]: `Failed to send processed order email to ${name} (${email})`,
  },
  [OrderHistoryEventType.CustomerOrderEmailSent]: {
    [OrderHistoryStatus.Success]: `Customer order email sent to ${name} (${email})`,
    [OrderHistoryStatus.Error]: `Failed to send order email to ${name} (${email})`,
  },
  [OrderHistoryEventType.CustomerOrderRefundEmailSent]: {
    [OrderHistoryStatus.Success]: `Customer order refund email sent to ${name} (${email})`,
    [OrderHistoryStatus.Error]: `Failed to send refund email to ${name} (${email})`,
  },
  [OrderHistoryEventType.CustomerOrderShippingEmailSent]: {
    [OrderHistoryStatus.Success]: `Customer order shipping email sent to ${name} (${email})`,
    [OrderHistoryStatus.Error]: `Failed to send shipping email to ${name} (${email})`,
  },

  // Fulfillment updates
  [OrderHistoryEventType.MerchantOrderShippingRecorded]: {
    [OrderHistoryStatus.Success]: `Shipment details submitted for merchant order ${
      merchantOrderNumber ? 'No. ' + merchantOrderNumber : ''
    }`,
    [OrderHistoryStatus.Error]: `Failed to submit shipment details for merchant order ${
      merchantOrderNumber ? 'No. ' + merchantOrderNumber : ''
    }`,
  },
  [OrderHistoryEventType.MerchantOrderShippingUpdated]: {
    [OrderHistoryStatus.Success]: `Shipment details updated for merchant order ${
      merchantOrderNumber ? 'No. ' + merchantOrderNumber : ''
    }`,
    [OrderHistoryStatus.Error]: `Failed to updated shipment details for merchant order ${
      merchantOrderNumber ? 'No. ' + merchantOrderNumber : ''
    }`,
  },
});
