import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CustomerOrder as ICustomerOrder } from '@common/types/GraphqlTypes.d';

type IAccountData = Pick<ICustomerOrder['account'], 'name'>;

export type IHistoryCustomerOrder = Pick<ICustomerOrder, 'history' | 'createdAt' | '_id'> & {
  account: IAccountData;
};

export interface IOrderData {
  customerOrder: IHistoryCustomerOrder;
}

export interface ICustomerOrderVariables {
  _id: string;
}

export const CUSTOMER_ORDER_HISTORY_QUERY = gql`
  query ($_id: String!) {
    customerOrder(_id: $_id) {
      _id
      account {
        name
      }
      createdAt
      history {
        event {
          type
          action
        }
        payload {
          merchantOrderId
          merchantOrderNumber
          name
          email
        }
        status
        createdAt
      }
    }
  }
`;

export const useCustomerOrderHistory = (
  customerOrderId: string,
  options: QueryHookOptions<IOrderData, ICustomerOrderVariables> = {},
) => {
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<IOrderData, ICustomerOrderVariables>(CUSTOMER_ORDER_HISTORY_QUERY, {
    ...options,
    variables: { _id: customerOrderId },
  });

  const customerOrder = data?.customerOrder;

  return { customerOrder, error, isLoading };
};
