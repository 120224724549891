import { Header } from '@components/Header';
import React from 'react';

import { IUser } from '../fields';
import { UserActions } from './Actions';

type ActionsProps = {
  user?: IUser;
  isLoading: boolean;
};

export const EditUserHeader = ({ user, isLoading }: ActionsProps) => (
  <Header
    title={`Edit user - ${user?.profile.firstName} ${user?.profile.lastName}`}
    isLoading={isLoading}
    actions={<UserActions user={user} />}
  />
);
