import { ProductCollectionInput as IProductCollectionInput } from '@common/types/GraphqlTypes.d';
import { Join, PathsToStringProps } from '@utils/typing';

// Avoids importing from GraphQL types
// eslint-disable-next-line unicorn/prefer-export-from
export type { IProductCollectionInput };

/**
 * Combines all nested properties of GraphQL types into dotted string notation.
 * { config: { taxes: string } } => 'config.taxes'.
 * @todo - Make autocomplete work for keys.
 */
export type IProductCollectionField = Join<
  PathsToStringProps<IProductCollectionInput & { productIds: string; account: string }>,
  '.'
>;

/**
 * Returns all available product collection fields.
 * IMPORTANT: All keys need to be unique, even when nested!
 */
export const PRODUCT_COLLECTION_FIELDS: Record<string, IProductCollectionField> = {
  name: 'name',
  handle: 'handle',
  productIds: 'productIds',
  account: 'account',
};
