import { FilterAutocomplete } from '@components/DataFilter/Autocomplete';
import { AssigneeAutocomplete, useAssigneeAutocomplete } from '@hooks/useCxContractors';
import { Truthy } from 'lodash';
import React from 'react';

import { useOrderFilters } from '../config/filters';

export const AssigneeFilter = () => {
  const { query: assigneeQuery, isLoading } = useAssigneeAutocomplete();
  const { filters, updateFilters } = useOrderFilters();

  // All available options for autocomplete.
  const [options, setOptions] = React.useState<AssigneeAutocomplete[]>([]);

  /**
   * When search field value changes (during typing).
   * Updates available options for rendering in autocomplete.
   */
  const handleInputChange = async (inputValue: string) => {
    const users = await assigneeQuery(inputValue);

    // Set matching results as options within autocomplete.
    setOptions(users);

    // Needs to be returned for preselecting options after reload.
    return users;
  };

  /**
   * When value changes (after selecting an option).
   * Updates filters with new value(s).
   */
  const handleValueChange = (values: AssigneeAutocomplete[]) => {
    updateFilters({ assigneeIds: values.map((assignee) => assignee._id) });
  };

  /**
   * Returns initial preselected values for autocomplete when loading.
   * Currently fetches all assigness. Should be replaced by "fetch by IDs" query.
   * Needed to preselect options after reload which is a pain in the *ss.
   */
  const onInitialRender = async () => {
    const users = await handleInputChange('');

    return (filters.assigneeIds ?? [])
      .map((assigneeId) => users.find((user) => user._id === assigneeId))
      .filter(Boolean) as Truthy<AssigneeAutocomplete>[];
  };

  return (
    <FilterAutocomplete
      options={options}
      value={filters.assigneeIds}
      onInputUpdate={handleInputChange}
      onValueUpdate={handleValueChange}
      onInitialRender={onInitialRender}
      placeholder="Assignee"
      noOptionsText="No users found"
      getOptionLabel={(option) => (option as AssigneeAutocomplete).profile?.name}
      isLoading={isLoading}
      multiple
    />
  );
};
