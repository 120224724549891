import { DataField } from '@components/DataField';
import Stack from '@mui/material/Stack';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { Maybe } from '@common/types/GraphqlTypes.d';

type VariantOptionsProps = {
  optionTitles?: Maybe<Maybe<string>[]>;
  optionValues?: Maybe<Maybe<string>[]>;
  sx?: SxProps<Theme>;
};

const StyledDataField = styled(DataField)(({ theme }) => ({
  justifyContent: 'flex-start',
  '& .label': {
    fontSize: '13px',
    fontWeight: 400,
    color: theme.palette.grey[500], // replace with actual color value
  },
  '& .value': {
    fontSize: '13px',
  },
}));

/**
 * Capitalizes first letter of each word.
 * @param sentence - Sentence.
 */
const capitalizeFirstLetterOfEachWord = (sentence: string) =>
  sentence.replace(/(^\w)|(\s+\w)/g, (letter) => letter.toUpperCase());

/**
 * Renders a list of key-value pairs for product variants.
 * @param props.optionTitles - The titles of the variant options.
 * @param props.optionValues - The values of the variant options.
 */
export const VariantOptions = ({
  optionTitles,
  optionValues,
  sx,
}: VariantOptionsProps): JSX.Element => (
  <Stack sx={sx}>
    {optionTitles?.map((key, index) => {
      const optionValue = optionValues?.[index];

      const title = capitalizeFirstLetterOfEachWord(key || '');
      const value = capitalizeFirstLetterOfEachWord(optionValue || '');

      return <StyledDataField key={title} label={title} value={value} />;
    })}
  </Stack>
);
