import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Grid } from '@mui/material';
import React from 'react';

import { USER_FIELDS as inputField } from '../../fields';

export type IBasicProps = {
  isLoading?: boolean;
  isEmailDisabled?: boolean;
};

export const BasicInformation = ({ isLoading, isEmailDisabled = false }: IBasicProps) => (
  <Card title="Basic Information">
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <TextField
          id={inputField.firstName}
          isLoading={isLoading}
          label="First name"
          placeholder="Enter the user's first name"
          options={{
            required: 'First name is required',
          }}
          useHook
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id={inputField.lastName}
          isLoading={isLoading}
          label="Last name"
          placeholder="Enter the user's last name"
          options={{
            required: 'Last name is required',
          }}
          useHook
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id={inputField.email}
          isLoading={isLoading}
          label="Email address"
          placeholder="Enter a unique email address"
          options={{
            required: 'Email is required',
          }}
          disabled={isEmailDisabled}
          useHook
        />
      </Grid>
    </Grid>
  </Card>
);
