import makeStyles from '@mui/styles/makeStyles';

import { Color } from '../../../theme/palette';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    transform: 'inherit', // Needed to counteract fanciness on MUI labels
    position: 'inherit', // Needed to counteract fanciness on MUI labels
    fontSize: '0.8rem',
    marginBottom: '5px',
  },
  select: {
    fontSize: 'inherit',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.8rem',
    fontWeight: 400,
    marginTop: '5px',
  },
  loader: {
    transform: 'none',
  },
  selectMenuItem: {
    '& .placeholder': {
      color: Color.Gray1,
    },
  },
}));
