import React from 'react';

import { IProductCollection } from '../graphql';
import { DeleteProductCollectionMenuItem } from './DeleteProductCollection';

type ActionsProps = {
  collection: IProductCollection;
};

export const ProductCollectionActions = ({ collection }: ActionsProps) => (
  <>
    <DeleteProductCollectionMenuItem collection={collection} />
  </>
);
