import ErrorBoundary from '@components/ErrorBoundary';
import { withParams } from '@components/WithParams';
import { useCustomerOrder } from '@hooks/useCustomerOrder';
import { useMerchantOrdersByCustomerOrderId } from '@hooks/useMerchantOrders';
import { Grid, Stack } from '@mui/material';
import { CustomerOrderComments } from '@pages/CustomerOrderV2/View/components/Comments';
import { CustomerOrderCustomer } from '@pages/CustomerOrderV2/View/components/Customer';
import { CustomerOrderMetadata } from '@pages/CustomerOrderV2/View/components/Metadata';
import { CustomerOrderBasicInformation } from '@pages/CustomerOrderV2/View/components/OrderInformation';
import { Loading } from '@pages/Order/components/Loading';
import React from 'react';

import { Header } from '../../../components/Header';
import { OrderFulfillmentInstructions } from './Instructions';
import { OrderedItems } from './OrderedItems';
import { OrderHistory } from './OrderHistory';
import { OrderMenuItem } from './OrderMenuItem';
import { OrderRefunds } from './OrderRefunds';
import { PaymentDetails } from './PaymentDetails';

const getOrderNumberMessage = (orderNumber?: number) =>
  orderNumber ? `Order #${orderNumber}` : 'Unknown order';

type OrderPageParams = {
  _id: string;
};

const ViewOrder = ({ _id: customerOrderId }: OrderPageParams) => {
  const { isLoading, customerOrder } = useCustomerOrder(customerOrderId, {
    fetchPolicy: 'cache-and-network',
  });
  const { merchantOrders } = useMerchantOrdersByCustomerOrderId(customerOrderId, {
    fetchPolicy: 'cache-and-network',
  });

  // TODO: Allow passing `undefined` as customer order to components down the tree since they support skeleton loading animation
  if (isLoading) {
    return <Loading />;
  }

  if (!customerOrder) {
    return <div>Order does not exist</div>;
  }

  const orderNumberMsg = getOrderNumberMessage(customerOrder.orderNumber);

  return (
    <ErrorBoundary>
      {/** TODO: Move into Header component. */}
      <Header
        title={orderNumberMsg}
        actions={
          <>
            <OrderMenuItem customerOrderId={customerOrder?._id} />
          </>
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack direction="column" spacing={4}>
            <CustomerOrderBasicInformation orderId={customerOrderId} />
            <OrderedItems merchantOrders={merchantOrders} customerOrder={customerOrder} />
            <OrderRefunds merchantOrders={merchantOrders} />
            <CustomerOrderComments orderId={customerOrderId} />
            <OrderHistory customerOrderId={customerOrderId} />
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Stack direction="column" spacing={4}>
            <CustomerOrderCustomer orderId={customerOrderId} />
            <PaymentDetails
              isLoading={isLoading}
              order={customerOrder}
              merchantOrders={merchantOrders}
            />
            <OrderFulfillmentInstructions
              isLoading={isLoading}
              customerOrder={customerOrder}
              merchantOrders={merchantOrders}
            />
            <CustomerOrderMetadata
              customerOrder={{
                _id: customerOrderId,
                publicId: customerOrder?.publicId || '',
              }}
              isLoading={isLoading}
            />
          </Stack>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export const ViewOrderPage = withParams<OrderPageParams>(['_id'], ViewOrder);
