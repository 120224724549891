import { Accordion, AccordionDetails, AccordionSummary } from '@components/Accordion';
import { PrimaryButton } from '@components/Button/Primary';
import { Form } from '@components/Form';
import { Autocomplete } from '@components/Input/__deprecated/Autocomplete';
import { ControlledSelect } from '@components/Input/Select';
import { AccountAutocomplete, useAccountAutocomplete } from '@hooks/useAccountAutocomplete';
import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MERCHANT_COUNTRIES, MERCHANT_CURRENCIES, MERCHANT_DEFAULTS } from '@constants/merchant';

import { StatusTag, StatusTagType } from '../StatusTag';
import { ICheckoutStatus, performApiCheckout } from './api';
import { CheckoutState } from './State';

export const useStyles = makeStyles(() => ({
  form: {
    marginTop: '20px',
  },
  formFields: {
    paddingBottom: '20px',
    borderBottom: `1px solid ${Color.Gray2}`,
  },
  apiResponse: {
    marginTop: '0 !important',
  },
  apiResponseDetails: {
    border: `1px solid ${Color.Gray2}`,
    padding: 20,
    marginBottom: 15,
  },
  apiResponseHeader: {
    padding: 0,
  },
  apiResponseBody: {
    padding: 0,
  },
  apiResponseData: {
    overflow: 'auto',
  },
}));

export type ProductCheckoutProps = {
  productPublicId: string;
  variantId?: string;
};

export type ICheckoutInput = {
  account: AccountAutocomplete | null;
  currency: string;
  country: string;
  state: string; // Can also be province
};

/**
 * Allows product checkout.
 */
export const ProductCheckout = ({ productPublicId, variantId }: ProductCheckoutProps) => {
  const classes = useStyles();
  const [apiResponse, setApiResponse] = useState<{
    data: string;
    input: string;
    status: ICheckoutStatus;
    httpStatus: number;
  }>();

  // Set up React form hook
  const form = useForm<ICheckoutInput>({
    mode: 'onSubmit',
    defaultValues: {
      // Defaults to Internal Bonsai for ease of use
      account: {
        publicId: 'ckhm921rg000101mfhkwi0379',
        apiKey: 'clk8qld8t000528o2a5019yu4',
        name: 'Internal Bonsai',
      },
    },
  });

  // GraphQL hook
  const { query: accountQuery } = useAccountAutocomplete();

  if (!variantId) {
    return <span>No variant was selected in order to perform a checkout</span>;
  }

  const onSubmit = async (values: ICheckoutInput) => {
    const {
      data = {},
      input,
      httpStatus,
      status,
    } = await performApiCheckout(productPublicId, variantId, values);

    setApiResponse({
      data: JSON.stringify(data, null, 2),
      input: JSON.stringify(input, null, 2),
      status: status as ICheckoutStatus,
      httpStatus,
    });
  };

  // Show different status tag based on API response
  const conditions = [
    {
      type: StatusTagType.Success,
      match: () => apiResponse?.status === 'success',
    },
    {
      type: StatusTagType.Warn,
      match: () => apiResponse?.status === 'warning',
    },
    {
      type: StatusTagType.Error,
      match: () => apiResponse?.status === 'error',
    },
  ];

  const statusText =
    apiResponse?.httpStatus === 200
      ? `Checkout successfully executed${apiResponse?.status === 'warning' ? ', with errors' : ''}`
      : 'Checkout requested failed';

  return (
    <>
      <span>
        This allows you to perform a checkout API call with the selected product variant. It will
        provide any information and errors, but can not guarantee the correctness of a client's
        implementation. A random address will be used automatically after selecting your preferences
        below.
      </span>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)} className={classes.form}>
          <Stack spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              {/* Account */}
              <Autocomplete
                id={'account'}
                fetchQuery={accountQuery}
                label="Account"
                placeholder="Search for an account"
                noOptionsText="No accounts - start searching"
                getOptionLabel={(value: AccountAutocomplete) => value.name}
                isOptionEqualToValue={(option: { publicId: string }, value: { publicId: string }) =>
                  option.publicId === value.publicId
                }
                textFieldProps={{
                  required: true,
                }}
              />

              {/* Currency */}
              <ControlledSelect
                id="currency"
                label="Currency"
                options={MERCHANT_CURRENCIES}
                defaultValue={MERCHANT_DEFAULTS.currency}
                required
              />
            </Stack>

            <Stack spacing={2} direction="row">
              {/* Country */}
              <ControlledSelect
                id="country"
                label="Country"
                options={MERCHANT_COUNTRIES}
                defaultValue={MERCHANT_DEFAULTS.country}
                required
              />

              <CheckoutState />
            </Stack>

            <PrimaryButton type="submit">Perform checkout</PrimaryButton>

            {/* API Response */}
            {apiResponse && (
              <>
                <b>API Response</b>
                <Accordion className={classes.apiResponse}>
                  <AccordionSummary className={classes.apiResponseHeader}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <StatusTag conditions={conditions} text={`HTTP ${apiResponse.httpStatus}`} />
                      <span>{statusText}</span>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails className={classes.apiResponseBody}>
                    <div className={classes.apiResponseDetails}>
                      <b>Response</b>
                      <pre className={classes.apiResponseData}>{apiResponse.data}</pre>
                    </div>

                    <div className={classes.apiResponseDetails}>
                      <b>Request</b>
                      <pre className={classes.apiResponseData}>{apiResponse.input}</pre>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Stack>
        </Form>
      </FormProvider>
    </>
  );
};
