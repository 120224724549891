import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import { UserImage } from '@pages/Profile/View/UserImage';
import React from 'react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

import { IFormData } from './index';
import { useStyles } from './styles';
import { splitFullName } from './utils';

interface IEditProfileBodyProps {
  errors: DeepMap<Partial<IFormData>, FieldError>;
  firstName: string;
  lastName: string;
  email: string;
  register: UseFormRegister<IFormData>;
}

export const EditProfileBody = ({
  errors,
  email,
  register,
  firstName,
  lastName,
}: IEditProfileBodyProps) => {
  const classes = useStyles();

  const { ref: firstNameRef, ...restOfFirstNameRegistration } = register('firstName');
  const { ref: lastNameRef, ...restOfLastNameRegistration } = register('lastName');
  const { ref: emailRef, ...restOfEmailRegistration } = register('email');

  const name = `${firstName} ${lastName}`;

  return (
    <Stack className={classes.profileCard} direction="row" gap={4}>
      <UserImage name={name} />

      <Stack gap={2} direction="column">
        <Stack direction="row" gap={2}>
          <TextField
            error={Boolean(errors.firstName?.message)}
            helperText={errors.firstName?.message}
            inputRef={firstNameRef}
            label="First Name"
            defaultValue={splitFullName(name)?.firstName}
            {...restOfFirstNameRegistration}
          />
          <TextField
            error={Boolean(errors.lastName?.message)}
            helperText={errors.lastName?.message}
            inputRef={lastNameRef}
            label="Last Name"
            defaultValue={splitFullName(name)?.lastName}
            {...restOfLastNameRegistration}
          />
        </Stack>
        <TextField
          error={Boolean(errors.email?.message)}
          helperText={errors.email?.message}
          inputRef={emailRef}
          label="Email"
          defaultValue={email}
          disabled={true}
          {...restOfEmailRegistration}
        />
      </Stack>
    </Stack>
  );
};
