import makeStyles from '@mui/styles/makeStyles';
import { Color } from '@theme/palette';

export const useStyles = makeStyles({
  totalText: {
    fontWeight: 700,
  },
  divider: {
    marginTop: '3.45px',
    marginBottom: '8px',
    color: Color.Gray3,
  },
});
