import { PrimaryButton } from '@components/Button/Primary';
import { Card } from '@components/Card';
import { Form } from '@components/Form';
import { Header } from '@components/Header';
import { ControlledSelect } from '@components/Input/Select';
import { Switch } from '@components/Input/Switch';
import { useSnackbar } from '@hooks/useSnackbar';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTimezoneSelect } from 'react-timezone-select';

import {
  getLocalTimezone,
  setLocalStorageTimezone,
  setLocalStorageUseRelative,
  shouldUseRelativeDates,
} from '@utils/date';

interface IPreferencesForm {
  timezone: string;
  useRelativeDate: boolean;
}

export const Preferences = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Get default timezone from local storage or browser.
  const defaultTimezone = getLocalTimezone();
  const defaultRelativeDate = shouldUseRelativeDates();

  const { options, parseTimezone } = useTimezoneSelect({ labelStyle: 'original' });

  // Set up React form hook
  const form = useForm<IPreferencesForm>({
    mode: 'onSubmit',
  });

  // Bug with Switch component setting the value after first cycle (always occurs for API calls...)
  // TODO: Should be able to use defaultValues in useForm but it doesn't work with Switch component
  form.reset({
    timezone: parseTimezone(defaultTimezone).value,
    useRelativeDate: defaultRelativeDate,
  });

  const onSubmit = ({ timezone, useRelativeDate }: IPreferencesForm) => {
    // Updates local storage timezone.
    if (timezone) {
      setLocalStorageTimezone(timezone);
    }

    // Updates local storage relative date.
    setLocalStorageUseRelative(useRelativeDate);

    enqueueSnackbar('Succesfully saved settings', { variant: 'success' });
  };

  return (
    <Stack gap={3}>
      <Header title="Preferences" />

      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Card title="Date & formatting">
                <ControlledSelect id="timezone" options={options} label="Current timezone" />
                <Switch
                  id="useRelativeDate"
                  label="Show relative dates (today, yesterday,...)"
                  useHook
                />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};
