import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
  quantityButton: {
    justifyContent: 'flex-end',
  },
}));
