import { useDropDownMenu } from '@components/Button/Dropdown';
import { useSnackbar } from '@hooks/useSnackbar';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

import {
  useMarkProductAvailable,
  useMarkVariantUnavailable,
  useMarkProductUnavailable,
} from '../graphql';

interface IMarkVariantUnavailableItem {
  productPublicId: string;
  productVariantId: string;
}

export const MarkVariantUnavailableItem: React.FC<IMarkVariantUnavailableItem> = ({
  productPublicId,
  productVariantId,
}) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();
  const { markVariantUnavailable } = useMarkVariantUnavailable({
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Product variant marked as unavailable for at least 24hrs.');
    },
    onError: (error) => {
      handleClose();
      enqueueSnackbar(
        `Failed to mark product variant as unavailable with error: ${error.message}`,
        {
          variant: 'error',
        },
      );
    },
  });

  const handleMarkVariantUnavailable = () => {
    markVariantUnavailable({
      variables: {
        input: {
          productId: productPublicId,
          variantId: productVariantId,
        },
      },
    });
  };

  return (
    <MenuItem onClick={handleMarkVariantUnavailable}>
      <Typography variant="subtitle2">Mark product variant as unavailable for 24 hours</Typography>
    </MenuItem>
  );
};

interface IMarkProductUnavailableItem {
  productPublicId: string;
}

export const MarkProductUnavailableItem: React.FC<IMarkProductUnavailableItem> = ({
  productPublicId,
}) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();
  const { markProductUnavailable } = useMarkProductUnavailable({
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Product marked as unavailable for at least 24hrs.');
    },
    onError: (error) => {
      handleClose();
      enqueueSnackbar(`Failed to mark product as unavailable with error: ${error.message}`, {
        variant: 'error',
      });
    },
  });

  const handleMarkProductUnavailable = () => {
    markProductUnavailable({
      variables: {
        input: {
          productId: productPublicId,
        },
      },
    });
  };

  return (
    <MenuItem onClick={handleMarkProductUnavailable}>
      <Typography variant="subtitle2">Mark product as unavailable for 24 hours</Typography>
    </MenuItem>
  );
};

interface IMarkProductAvailableItem {
  productPublicId: string;
}

export const MarkProductAvailableItem: React.FC<IMarkProductAvailableItem> = ({
  productPublicId,
}) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();
  const { markProductAvailable } = useMarkProductAvailable({
    onCompleted: () => {
      handleClose();
      enqueueSnackbar('Product marked as available');
    },
    onError: (error) => {
      handleClose();
      enqueueSnackbar(`Failed to mark product as available with error: ${error.message}`, {
        variant: 'error',
      });
    },
  });

  const handleMarkProductAvailable = () => {
    markProductAvailable({
      variables: {
        input: {
          productId: productPublicId,
        },
      },
    });
  };

  return (
    <MenuItem onClick={handleMarkProductAvailable}>
      <Typography variant="subtitle2">Mark product as available</Typography>
    </MenuItem>
  );
};
