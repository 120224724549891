import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { UniversalOrder } from '@common/types/GraphqlTypes.d';
const PROCESS_CSV_ORDERS_FILE = gql`
  mutation processCsvOrdersFile($input: [UploadCsvOrdersInput!]!) {
    processCsvOrdersFile(input: $input) {
      accountId
      url
      orders {
        public_id
      }
      errors {
        code
        message
        order {
          number
          rows
        }
      }
    }
  }
`;

/**
 * Hook for updating merchant orders.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useProcessCsvOrdersFile = (options: MutationHookOptions = {}) => {
  const [processCsvOrdersFile, { data, error: apiError }] = useMutation<{
    processCsvOrdersFile: {
      accountId: string;
      url: string;
      orders: UniversalOrder[];
      errors: {
        code?: string;
        message: string;
        order: {
          number: string;
          rows: number[];
        };
      }[];
    }[];
  }>(PROCESS_CSV_ORDERS_FILE, options);

  return { processCsvOrdersFile, apiError, data };
};
