import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const CREATE_PRODUCT_COLLECTION_MUTATION = gql`
  mutation createProductCollection($input: ProductCollectionInput!) {
    createProductCollection(input: $input) {
      _id
    }
  }
`;

/**
 * Hook for creating product collection.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useCreateProductCollection = (options: MutationHookOptions = {}) => {
  const [createProductCollectionMutation, { error: apiError, loading: isLoading }] = useMutation<{
    createProductCollection: { _id: string };
  }>(CREATE_PRODUCT_COLLECTION_MUTATION, options);

  return { createProductCollectionMutation, isLoading, apiError };
};
