import { Color } from '@theme/palette';
import { sum } from 'ramda';
import React from 'react';

import { IProcessedOrder } from '../interfaces';
import { ItemStatus } from '../ItemStatus';
import { getTotalItemPurchaseQuantity } from '../utils';
import { FulfilledOrder } from './fulfilledOrder';

interface IOrderDetailsProps {
  orders: IProcessedOrder[];
}

export const FulfilledOrders: React.FC<IOrderDetailsProps> = ({ orders }) => {
  if (orders.length === 0) {
    return null;
  }

  const numberOfItems = sum(
    orders.map(({ checkoutVariants }) => getTotalItemPurchaseQuantity(checkoutVariants)),
  );

  return (
    <>
      <ItemStatus statusText="Fulfilled" statusColor={Color.Green} numberOfItems={numberOfItems} />
      {orders.map((order) => (
        <FulfilledOrder order={order} key={order.processedOrderId} />
      ))}
    </>
  );
};
