export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
  GBP = 'GBP',
  AUD = 'AUD',
  EUR = 'EUR',
}
export enum CountryCode {
  CA = 'CA',
  UK = 'UK',
  NL = 'NL',
  US = 'US',
  PL = 'PL',
  IT = 'IT',
  Unknown = 'ZZ',
}

export enum UserPermission {
  ManageOrders = 'orders/manage',
  ManageUsers = 'users/manage',
  FulfilOrders = 'orders/fulfillment',
  ManageMerchants = 'merchants/manage',
  MerchantConnectStripe = 'merchants/connectStripe',
  ManageBrands = 'brands/manage',
  ManageProducts = 'products/manage',
  ManageProductCollections = 'productCollections/manage',
  ManageClients = 'clients/manage',
  ManageRoles = 'userRoles/manage',
}

export enum MerchantWebhook {
  UpdateProduct = 'products/update',
  DeleteProduct = 'products/delete',
  FulfillOrder = 'orders/fulfilled',
  PartialFulfilOrder = 'orders/partially_fulfilled',
  RefundOrder = 'refunds/create',
  CancelOrder = 'orders/cancelled',
}

// import { Escalation, FulfillmentStatus, PaymentStatus } from '@types';
export enum Escalation {
  Shipping = 'Shipping',
  OutOfStock = 'Out of stock',
  Payment = 'Payment',
  Pricing = 'Pricing',
  Other = 'Other',
  Delayed = 'Delayed',
  Return = 'Return',
  MerchantCancellation = 'Merchant Cancellation',
  Disputes = 'Disputes',
}
export enum FulfillmentStatus {
  PENDING = 'pending', // transitional while the order is being created
  FULFILLED = 'fulfilled',
  PARTIALLY_FULFILLED = 'Partially Fulfilled', //For ghost orders if customer ops has fulfilled some of the order.
  CANCELLED = 'cancelled',
  SENT_TO_MERCHANT = 'sent to merchant',
  FAILED = 'failed (merchant)', // Send to merchant has failed
  UNKNOWN_ERROR = 'failed (unknown)', // There was an unknown issue while processing
  PAYMENT_FAILED = 'failed (payment)',
  PAYMENT_CANCELLATION = 'cancelled (payment)',
  PAYMENT_VALIDATION_FAILED = 'failed (payment validation)',
  PAYMENT_SUCCESSFUL = 'payment successful',
  INVENTORY_ISSUES = 'failed (inventory)',
  INVENTORY_ISSUES_RELATED = 'failed (inventory - related)',
}
export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially refunded',
}

export enum EciIntegrationType {
  SHOPIFY = 'shopify',
  WIDGET = 'widget',
  FEED = 'feed',
}
export enum EscalationState {
  Open = 'open',
  Closed = 'closed',
}

/**
 * Different types of commission calculations.
 */
export enum CommissionType {
  /**
   * Comission is taken from order before any returns.
   * Ie. If there is a return, the commission is not adjusted.
   */
  SalesBeforeReturns = 'sale_before_returns',
  /**
   * Comission is taken from order after any returns.
   * Ie. If there is a return, the commission for that order is reversed.
   */
  SalesAfterReturns = 'sale_after_returns',
}

export enum RefundReason {
  ItemUnavailable = 'item unavailable',
  CustomerCanceled = 'customer canceled',
  CustomerReturned = 'customer returned',
  Reimbursement = 'reimbursement',
  Fraudulent = 'fraudulent',
  Duplicate = 'duplicate',
  Historical = 'Historical refund. Reason missing',
}
export const userPermissions: { [key in UserPermission]: IPermission } = {
  [UserPermission.ManageOrders]: {
    key: UserPermission.ManageOrders,
    name: 'Manage orders',
    description: 'View and manage orders',
  },

  [UserPermission.FulfilOrders]: {
    key: UserPermission.FulfilOrders,
    name: 'Fulfilling orders',
    description: 'Able to assign & fulflil orders',
  },

  [UserPermission.ManageMerchants]: {
    key: UserPermission.ManageMerchants,
    name: 'Manage merchants',
    description: 'View and manage merchants',
  },

  [UserPermission.MerchantConnectStripe]: {
    key: UserPermission.MerchantConnectStripe,
    name: 'Allow Stripe connection',
    description: 'Allows ability to connect stripe account (only for merchants)',
  },

  [UserPermission.ManageBrands]: {
    key: UserPermission.ManageBrands,
    name: 'Manage brands',
    description: 'View and manage brands',
  },

  [UserPermission.ManageProducts]: {
    key: UserPermission.ManageProducts,
    name: 'Manage products',
    description: 'View and manage products',
  },

  [UserPermission.ManageProductCollections]: {
    key: UserPermission.ManageProductCollections,
    name: 'Manage product collections',
    description: 'View and manage product collections',
  },

  [UserPermission.ManageUsers]: {
    key: UserPermission.ManageUsers,
    name: 'Manage users',
    description: 'View and manage users',
  },

  [UserPermission.ManageClients]: {
    key: UserPermission.ManageClients,
    name: 'Manage clients & accounts',
    description: 'View and manage clients & accounts',
  },

  [UserPermission.ManageRoles]: {
    key: UserPermission.ManageRoles,
    name: 'Manage user roles',
    description: 'View and manage user roles',
  },
};

export interface IPermission {
  key: UserPermission;
  name: string;
  description: string;
}

export const merchantWebhooks: { [key in MerchantWebhook]: IMerchantWebhook } = {
  [MerchantWebhook.UpdateProduct]: {
    key: MerchantWebhook.UpdateProduct,
    name: 'Product updates',
  },
  [MerchantWebhook.DeleteProduct]: {
    key: MerchantWebhook.DeleteProduct,
    name: 'Product deletion',
  },
  [MerchantWebhook.FulfillOrder]: {
    key: MerchantWebhook.FulfillOrder,
    name: 'Order fulfillment',
  },
  [MerchantWebhook.PartialFulfilOrder]: {
    key: MerchantWebhook.PartialFulfilOrder,
    name: 'Partial order fulfillment',
  },
  [MerchantWebhook.CancelOrder]: {
    key: MerchantWebhook.CancelOrder,
    name: 'Order cancellation',
  },
  [MerchantWebhook.RefundOrder]: {
    key: MerchantWebhook.RefundOrder,
    name: 'Order refunds',
  },
};

export interface IMerchantWebhook {
  key: MerchantWebhook;
  name: string;
}

const DEAD_END_INTEGRATION_TYPES = new Set([
  'feed', // Feed integration type
]);

export const isDeadEndIntegrationType = (integrationType: EciIntegrationType): boolean =>
  DEAD_END_INTEGRATION_TYPES.has(integrationType);

export enum ShippingCarrier {
  DHL = 'DHL',
  FedEx = 'FedEx',
  UPS = 'UPS',
  USPS = 'USPS',
  Other = 'Other',
}

/**
 * Utility type that makes specified properties K in T optional.
 */
export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;
