import { DataGridV2 } from '@components/DataGrid';
import ErrorBoundary from '@components/ErrorBoundary';
import { usePagination } from '@hooks/useDataStore/usePagination';
import { useSearch } from '@hooks/useDataStore/useSearch';
import { ISorting, SortOrder, useSorting } from '@hooks/useDataStore/useSorting';
import Stack from '@mui/material/Stack';
import React from 'react';

import { columns } from './config/columns';
import { SearchFilter } from './Filters/Search';
import { IClient, useClients, useClientsCount } from './graphql';
import { ClientOverviewHeader } from './Header';

const DEFAULT_SORTING: ISorting = {
  sortField: 'name',
  sortOrder: SortOrder.Asc,
};

export const ListClients = () => {
  // Fetch global filters, pagination & sorting
  const { pagination, updatePagination, getGraphQLPagination } = usePagination();
  const { getDataGridSort, updateDataGridSort, getGraphQLSort } = useSorting(DEFAULT_SORTING);
  const { search } = useSearch();

  const { data, isLoading } = useClients({
    variables: {
      input: {
        ...getGraphQLPagination(),
        ...getGraphQLSort(),
        search,
      },
    },
  });

  /**
   * Fetches total row count for pagination separately from the main query.
   * Avoids blocking the main query with a `count` query which is often an expensive operation.
   * Does not get called unless filters change (Ignores pagination & sorting).
   */
  const { data: totalRowCount } = useClientsCount({
    variables: {
      input: {
        search,
      },
    },
  });

  return (
    <ErrorBoundary>
      <ClientOverviewHeader />

      <Stack gap={2}>
        <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
          <SearchFilter />
        </Stack>

        <DataGridV2
          rows={data}
          columns={columns}
          loading={isLoading}
          rowCount={totalRowCount}
          getRowId={(client: IClient) => client._id}
          paginationModel={pagination}
          onPaginationModelChange={updatePagination}
          onSortChange={updateDataGridSort}
          initialState={{
            sorting: getDataGridSort(),
          }}
        />
      </Stack>
    </ErrorBoundary>
  );
};
