import { Card } from '@components/Card';
import { TextField } from '@components/Input/TextField';
import { Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { MERCHANT_DEFAULTS } from '@constants/merchant';

import { MERCHANT_FIELDS } from '../../fields';
const { inventory: inputField, financial: financialInputField } = MERCHANT_FIELDS;

export const Inventory = ({ isLoading }: { isLoading?: boolean }) => {
  const { watch } = useFormContext();

  const currency = watch(financialInputField.currency);

  return (
    <Card title="Inventory Management">
      <Stack spacing={2}>
        <TextField
          id={inputField.restockingFee}
          isLoading={isLoading}
          label={`Restocking fee (${currency || MERCHANT_DEFAULTS.currency})`}
          type="number"
          inputProps={{
            step: 0.01,
          }}
          setEmptyAsNull
          useHook
        />
      </Stack>
    </Card>
  );
};
