import useRouter from '@hooks/useRouter';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Grid, MenuItem } from '@mui/material';
import React from 'react';

interface IOrderMenuItem {
  customerOrderId: string;
}

export const OrderMenuItem: React.FC<IOrderMenuItem> = ({ customerOrderId }) => {
  const { goto } = useRouter();
  const redirectToArchivedFeedOrders = () => goto.archivedFeedOrders(customerOrderId);

  return (
    <MenuItem onClick={redirectToArchivedFeedOrders}>
      <Grid container columnGap={2}>
        <ArchiveOutlinedIcon />
        Archived merchant orders
      </Grid>
    </MenuItem>
  );
};
