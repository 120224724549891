import { ApolloError } from '@apollo/client';
import { useSnackbar } from '@hooks/useSnackbar';
import React, { useEffect } from 'react';

type FormProps = {
  /**
   * Callback function to handle form submission.
   */
  onSubmit?(): void;

  /**
   * Error to display in snackbar.
   */
  error?: Error | ApolloError;

  /**
   * Class name to apply to form.
   * Can be used to apply styles to form.
   */
  className?: string;

  /**
   * Children to render inside form.
   */
  children: React.ReactNode;
};

export const Form = ({ children, className, onSubmit, error }: FormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
