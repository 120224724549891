import { FilterSelect } from '@components/DataFilter/Select';
import { FulfillmentStatus } from '@types';
import React from 'react';

import { useOrderFilters } from '../config/filters';

const options = Object.values(FulfillmentStatus).map((status) => ({
  label: status,
  value: status,
}));

export const FulfillmentStatusFilter = () => {
  const { filters, updateFilters } = useOrderFilters();

  return (
    <FilterSelect
      placeholder="Fulfillment Status"
      options={options}
      value={filters.fulfillmentStatuses}
      onUpdate={(fulfillmentStatuses: FulfillmentStatus[]) =>
        updateFilters({ fulfillmentStatuses })
      }
      multiple
    />
  );
};
