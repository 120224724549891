import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Color } from '@theme/palette';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
  color: Color.DarkNight,
});

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 2,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: Color.Gray4,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === 'light' ? Color.MapleRed : '#308fe8',
  },
}));
