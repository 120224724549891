import React from 'react';

import { useStyles } from './styles';

interface IMessage {
  icon: {
    images: string[];
    label: string;
  };
  text: string;
}

const messages: IMessage[] = [
  {
    icon: {
      images: ['🛩'],
      label: 'shrug',
    },
    text: 'you are not authorized to view this page',
  },
];

const sample = function <T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
};

export const Unauthorized: React.FC = () => {
  const classes = useStyles();
  const message = sample(messages);

  return (
    <h1 className={classes.root}>
      <div>
        <div className={classes.icon}>
          <span role="img" aria-label={message.icon.label}>
            {sample(message.icon.images)}
          </span>
        </div>

        <div>
          <span className={classes.bud}>Apologies</span>, {message.text}.
        </div>

        <div className={classes.statusCode}>Unauthorized</div>
      </div>
    </h1>
  );
};
