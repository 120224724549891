/* eslint-disable max-lines-per-function */
import { DataStack } from '@components/DataStack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Refund } from '@common/types/GraphqlTypes.d';
import { toFormattedDate } from '@utils/date';

import { useStyles } from './styles';

interface IOrderDetailsProps {
  refund: Refund;
}

export const RefundDetails: React.FC<IOrderDetailsProps> = ({ refund }) => {
  const classes = useStyles();

  const [shouldShowRefundDetails, setShouldShowRefundDetails] = useState(false);

  const firstInformationValues = [
    { label: 'Refund Created At: ', value: toFormattedDate(refund.createdAt) },
    { label: 'Refund Created By: ', value: refund.createdBy.name },
    { label: 'Refund Reason: ', value: refund.refundReason },
  ];

  const secondInformationValues = [
    { label: 'Subtotal: ', value: `${refund.refundedSubtotal.toFixed(2)}` },
    { label: 'Shipping: ', value: `${refund.refundedShipping.toFixed(2)}` },
    { label: 'Duties: ', value: `${refund.refundedDuties.toFixed(2)}` },
    { label: 'Taxes: ', value: `${refund.refundedTaxes.toFixed(2)}` },
    {
      label: 'Total: ',
      value: <Box fontWeight={700}>{`${refund.refundedAmount.toFixed(2)}`}</Box>,
    },
  ];

  return (
    <>
      <button
        className={classes.detailsButton}
        onClick={() => setShouldShowRefundDetails(!shouldShowRefundDetails)}
      >
        {shouldShowRefundDetails ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        <Typography className={classes.buttonPrompt}>
          {shouldShowRefundDetails ? 'Hide refund details' : 'Show refund details'}
        </Typography>
      </button>
      {shouldShowRefundDetails ? (
        <Stack>
          <Grid container columnSpacing={4}>
            <Grid item xs={8}>
              <DataStack stackId="refundOne" items={firstInformationValues} />
            </Grid>
            <Grid item xs={4}>
              <DataStack stackId="refundTwo" items={secondInformationValues} />
            </Grid>
          </Grid>
        </Stack>
      ) : null}
    </>
  );
};
