import { Card } from '@components/Card';
import { TextEditor } from '@components/Input/TextEditor';
import { Stack } from '@mui/material';
import React from 'react';

import { MERCHANT_FIELDS } from '../../fields';
const { fulfillment: inputField } = MERCHANT_FIELDS;

const TEXT_EDITOR_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ color: [] }],
    ['clean'],
  ],
};

export const Fulfillment = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Fulfillment">
    <Stack spacing={2}>
      <TextEditor
        id={inputField.instructions}
        label="Fulfillment instructions"
        placeholder="Manage instructions for fulfillment"
        isLoading={isLoading}
        modules={TEXT_EDITOR_MODULES}
        useHook
      />
    </Stack>
  </Card>
);
