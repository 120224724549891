import { CreateButton } from '@components/Button/Create';
import { Header } from '@components/Header';
import useRouter from '@hooks/useRouter';
import React from 'react';

const Buttons: React.FC = () => {
  const { goto } = useRouter();

  return (
    <CreateButton onClick={() => goto.createProductCollection()}>
      Create Product collection
    </CreateButton>
  );
};

export const ProductCollectionOverviewHeader = () => (
  <Header title={'Product collections'} buttons={<Buttons />} />
);
