import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    gridArea: 'buttons',
    textAlign: 'right',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  root: {
    display: 'grid',
    gridTemplate: `
      "title buttons"
    `,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
  },
  titleText: {
    alignItems: 'center',
    display: 'flex',
    gridArea: 'title',
    textAlign: 'left',
    fontWeight: 600,
  },
  goBack: {
    cursor: 'pointer',
    marginRight: '10px',
  },
  tooltip: {
    fontSize: '1rem',
    marginTop: '-15px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
}));
