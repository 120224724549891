import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  buttonText: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',
    justifyContent: 'center',
    maxWidth: '60px',
    width: '100%',
  },
  endText: {
    color: theme.palette.grey[200],
    fontSize: '13px',
  },
  icon: {
    height: '30px',
    paddingBottom: '10px',
    width: '30px',
  },
  iconContainer: {
    height: '30px',
    width: '30px',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '50px',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));
