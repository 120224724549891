import React from 'react';

import { useStyles } from './styles';

type ImageProps = {
  name: string;
  image?: string | null;
};

/**
 * Renders an image within a container.
 * @param props.name - Used as `alt` attribute for the image.
 * @param props.image - The image URL.
 */
export const Image = ({ name, image }: ImageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img alt={name} className={classes.image} src={image || undefined} />
    </div>
  );
};
