import { head, last } from 'ramda';

/**
 * Receives a string and returns the initials if provided first and last name. Returns only one initials if only the first name was provided.
 * @param {string} name - Name resulting in initials derived from it.
 */
export const getInitials = (name: string = ''): string => {
  const names = name.split(' ');

  const firstInitial = head(head(names) ?? '').toUpperCase();
  const lastInitial = head(last(names) ?? '').toUpperCase();

  return names.length === 1 ? firstInitial : `${firstInitial}${lastInitial}`;
};

/**
 * Returns an array of strings based on a string with newline delimiter.
 * Eg. A text area field where every item on a new line is a separate array item.
 * @param {string} text - String containing newline characters.
 */
export const newlineToArray = (text: string): string[] => text.split(/\r?\n/).filter(Boolean);

/**
 * Returns a string split by newlines based on an array.
 * @param {string} array - Array containing strings.
 */
export const arrayToNewline = (array: string[]): string => array.join('\n');

export const capitalizeFirstLetter = (string: string): string =>
  `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;
