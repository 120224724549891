import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';

import { IRole } from '../graphql';
import { StyledLink } from '@components/DataGrid/Styled';
import { routes } from '@routes/private';
import { toFormattedDate } from '@utils/date';

export const columns: GridColDef<IRole>[] = [
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) =>
      row._id && (
        <StyledLink
          style={{
            color: 'black',
          }}
          to={routes.editRole.getPath(row._id)}
        >
          {value}
        </StyledLink>
      ),
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    valueGetter: ({ row }) => row?.description,
    flex: 1,
  },
  {
    field: 'permissions',
    headerName: 'Permissions',
    sortable: false,
    valueGetter: ({ row }) => row?.permissions?.length ?? 'Unknown',
    width: 150,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    valueGetter: ({ row }) => toFormattedDate(Number(row?.createdAt)),
    width: 300,
  },
];
