import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export type TextProps = TypographyProps & {
  children: React.ReactChild | React.ReactNode;
  className?: string;
};

/**
 * Renders text. Uses a bottom margin by default.
 * @todo - Turn into styled Text component. No need for a full component with a single styling change.
 * @param props.children - The text.
 * @param props.fontSize - The font size. Defaults to 1rem.
 * @param props.className - The class name.
 */
export const Text = ({ children, className, fontSize = '1rem', ...rest }: TextProps) => (
  <Typography gutterBottom fontSize={fontSize} className={className} {...rest}>
    {children}
  </Typography>
);
