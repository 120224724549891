import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>(() => ({
  paper: {
    borderRadius: '4px',
    boxShadow: '0px 4px 8px rgba(38, 50, 56, 0.05)',
  },
  paperLoading: {
    padding: '15px',
  },
}));
