import { gql, useMutation } from '@apollo/client';
import { MenuItem } from '@components/MenuItem';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Archive as ArchiveIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

import { Merchant } from '@common/types/GraphqlTypes.d';

type ActionsProps = {
  merchant: Merchant;
};

const ARCHIVE_MERCHANT = gql`
  mutation deleteMerchant($input: PublishMerchantInput!) {
    deleteMerchant(input: $input) {
      message
      nextStep
      result
    }
  }
`;

export const ArchiveMerchantMenuItem = ({ merchant }: ActionsProps) => {
  const { goto } = useRouter();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [archiveMerchant] = useMutation<{
    deleteMerchant: { result: boolean; nextStep?: string; message: string };
  }>(ARCHIVE_MERCHANT, {
    onCompleted: ({ deleteMerchant: { result, nextStep, message } }) => {
      if (result) {
        enqueueSnackbar(message);
        goto.listMerchants();
      } else {
        enqueueSnackbar(`${message}. ${nextStep}`, {
          variant: 'error',
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(`Archiving merchant failed with error ${error}`, {
        variant: 'error',
      });
    },
  });

  const handleArchiveMerchant = () => {
    archiveMerchant({
      variables: {
        input: {
          id: merchant._id,
        },
      },
    });
  };

  const openConfirmModal = () => {
    // TODO: Avoid modal from immediately being closed due to handleClose from
    setIsConfirmDialogOpen(true);
  };

  return (
    <>
      <MenuItem action={openConfirmModal} icon={ArchiveIcon} label="Archive merchant" />

      <div>
        <Dialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`You are archiving ${merchant.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Archiving this merchant will make their products unavailable and delete any S3 feed
              matching the merchant slug. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={handleArchiveMerchant}>
              Archive Merchant
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
