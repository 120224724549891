import { gql, useQuery, useMutation } from '@apollo/client';
import { Dialog } from '@components/Dialog';
import { withParams } from '@components/WithParams';
import { useAuth } from '@hooks/useAuthentication';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Grid, Typography } from '@mui/material';
import { FeedOrderForm } from '@pages/Order/components/FeedOrderForm';
import { FEED_ORDER_MODE, IFeedOrderFormData } from '@pages/Order/components/FeedOrderForm/types';
import { Loading } from '@pages/Order/components/Loading';
import { FulfillmentStatus } from '@types';
import React, { useState } from 'react';

import { filterZeroQuantityProducts } from '../utils/products';
import { useStyles } from './styles';

const MERCHANT_ORDER_QUERY = gql`
  query getOneMerchantOrder($input: MerchantOrderInput!) {
    getOneMerchantOrder(input: $input) {
      _id
      customerOrderId
      merchant {
        name
        integrationType
      }
      externalOrder {
        externalOrderId
        createExternalOrder {
          result
        }
      }
      summary {
        subTotal
      }
      ghostExternalOrders {
        _id
        merchantOrderNumber
        taxes
        shipping
        subtotal
        discount
        paymentMethod
        currency
        comments
        createdAt
        products {
          productId
          quantity
          variantId
        }
      }
      checkoutVariants {
        variant {
          id
          optionTitles
          optionValues
          images
          price
          priceUSD
          currency
          brand
        }
        _id
        name
        productId
        publicId
        purchaseQuantity
        productURL
      }
    }
  }
`;

const UPDATE_GHOST_EXTERNAL_ORDER_MUTATION = gql`
  mutation updateGhostExternalOrder($input: UpdateGhostOrderInput!) {
    updateGhostExternalOrder(input: $input) {
      result
    }
  }
`;

type IEditFeedOrderParams = { merchantOrderId: string; feedOrderId: string };

const EditFeedOrder = ({ merchantOrderId, feedOrderId }: IEditFeedOrderParams) => {
  const classes = useStyles();
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  const { data: merchantOrderData, loading: isLoading } = useQuery(MERCHANT_ORDER_QUERY, {
    variables: {
      input: { merchantOrderId },
    },
  });

  const { currentUser } = useAuth();

  const merchantOrder = merchantOrderData?.getOneMerchantOrder;

  const [updateGhostExternalOrder] = useMutation(UPDATE_GHOST_EXTERNAL_ORDER_MUTATION, {
    errorPolicy: 'all',
    onCompleted: () => {
      goto.viewOrder(merchantOrder.customerOrderId);
    },
    refetchQueries: [
      {
        query: MERCHANT_ORDER_QUERY,
        variables: {
          input: { merchantOrderId },
        },
      },
    ],
  });

  const onUpdateFeedOrder = ({
    currency,
    discount,
    merchantOrderNumber,
    paymentMethod,
    products,
    shipping,
    subtotal,
    taxes,
    comments,
  }: IFeedOrderFormData) => {
    enqueueSnackbar(`Merchant Order No. ${feedOrderId} successfully updated`);
    updateGhostExternalOrder({
      variables: {
        input: {
          feedOrderId,
          merchantOrderId: merchantOrder?._id,
          order: {
            currency,
            discount,
            email: currentUser?.email,
            merchantOrderNumber: merchantOrderNumber.trim(),
            paymentMethod,
            products: filterZeroQuantityProducts(products),
            shipping,
            subtotal,
            taxes,
            comments,
          },
        },
      },
    });
  };

  const onDeleteFeedOrder = () => {
    enqueueSnackbar(`Merchant Order No. ${feedOrderId} successfully archived`);
    updateGhostExternalOrder({
      variables: {
        input: {
          feedOrderId,
          merchantOrderId: merchantOrder?._id,
          order: {
            fulfillment: FulfillmentStatus.CANCELLED,
          },
        },
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Typography variant="h5" className={classes.pageTitle}>
          Edit Feed Order Form
        </Typography>
        <Button
          variant="text"
          color="error"
          onClick={onOpenModal}
          startIcon={<DeleteOutlineIcon color="error" />}
        >
          Delete order
        </Button>
      </Grid>
      <FeedOrderForm
        feedOrderId={feedOrderId}
        isLoading={isLoading}
        merchantOrder={merchantOrder}
        mode={FEED_ORDER_MODE.EDIT}
        onProcessFeedOrder={onUpdateFeedOrder}
      />
      <Dialog title={'Delete and archive order'} onClose={onCloseModal} open={isModalOpen}>
        <Grid container rowGap={3}>
          <Grid container>
            <span>
              Are you sure you want to delete <b>Merchant Order No. {feedOrderId}</b>? A copy of the
              order form will be saved in archive for future reference.
            </span>
          </Grid>
          <Grid container justifyContent="flex-end" columnGap={2}>
            <Button
              onClick={onCloseModal}
              variant="outlined"
              className={`${classes.modalButton} ${classes.modalCancelButton}`}
            >
              Cancel
            </Button>
            <Button
              onClick={onDeleteFeedOrder}
              variant="contained"
              color="error"
              className={classes.modalButton}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export const EditFeedOrderPage = withParams<IEditFeedOrderParams>(
  ['feedOrderId', 'merchantOrderId'],
  EditFeedOrder,
);
