import Autolinker from 'autolinker';
/**
 * Checks if a url has http or https and prepends https if not.
 * @param url - The url.
 * @returns {string} - A url that starts with http or https.
 */
export const formatUrl = (url: string): string | null => {
  if (!url) {
    return null;
  }

  return /^(http(s)?:\/\/.*)/.test(url) ? url : `https://${url}`;
};

/**
 * Automatically converts string and converts hyperlinks.
 * @param {string} text - Text string.
 */
export const autoHyperlink = (text: string) =>
  Autolinker.link(text, {
    urls: {
      schemeMatches: true,
      tldMatches: true,
      ipV4Matches: true,
    },
    email: true,
    phone: true,
    mention: false,
    hashtag: false,
    stripPrefix: true,
    stripTrailingSlash: true,
    newWindow: true,
    truncate: {
      length: 0,
      location: 'end',
    },
  });
