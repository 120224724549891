import { timelineContentClasses, timelineOppositeContentClasses } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import React, { ComponentProps } from 'react';

import { PrimaryItem, PrimaryItemProps } from './PrimaryItem';
import { SecondaryItem, SecondaryItemProps } from './SecondaryItem';

export type PrimaryEvent = Omit<PrimaryItemProps, 'last'>;
export type SecondaryEvent = SecondaryItemProps & { secondary: true };

type HistoryTimelineProps = {
  items: (PrimaryEvent | SecondaryEvent)[];
};

// Check if event is secondary
export const isSecondaryEvent = (event: PrimaryEvent | SecondaryEvent): event is SecondaryEvent =>
  (event as SecondaryEvent).secondary === true;

export const HistoryTimeline = ({ items }: HistoryTimelineProps) => {
  const allCreatedAtDates = items.map((item) => item.createdAt);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          padding: 0,
          flex: 0,
        },
        [`& .${timelineContentClasses.root}`]: {
          pr: 0,
        },
        [`& .${timelineOppositeContentClasses.root}`]: {
          pt: '15px',
        },
        [`& .secondary .${timelineOppositeContentClasses.root}`]: {
          pt: '13px',
        },
        padding: 0,
        position: 'relative',
        left: '-50px',
        width: 'calc(100% + 50px)',
      }}
    >
      {items.map((item, index) => {
        const props = {
          ...item,
          key: index,
          last: index + 1 === items.length,
          allCreatedAtDates,
        };

        return isSecondaryEvent(item) ? (
          <SecondaryItem {...(props as ComponentProps<typeof SecondaryItem>)} first={index === 0} />
        ) : (
          <PrimaryItem {...(props as ComponentProps<typeof PrimaryItem>)} />
        );
      })}
    </Timeline>
  );
};
