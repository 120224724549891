import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

/**
 * Returns a typed graphQL query without needing to define the same types on both options & query hook.
 */
export const useTypedQuery = <T, K extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<T, K>,
  variables: K,
  options: QueryHookOptions<T, K> = {},
) => useQuery<T, K>(query, { ...options, variables });
