import { Card } from '@components/Card';
import { ControlledSelect } from '@components/Input/Select';
import { TextEditor } from '@components/Input/TextEditor';
import { TextField } from '@components/Input/TextField';
import TagIcon from '@mui/icons-material/Tag';
import { Stack } from '@mui/material';
import React from 'react';

import { ACCOUNT_DEFAULTS, ACCOUNT_ORDER_SHIPPING_OPTIONS } from '@constants/account';

import { ACCOUNT_FIELDS } from '../fields';

const { order: inputField } = ACCOUNT_FIELDS;

const TEXT_EDITOR_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ color: [] }],
    ['clean'],
  ],
};

export const OrderConfig = ({ isLoading }: { isLoading?: boolean }) => (
  <Card title="Order configuration">
    <Stack spacing={2}>
      <ControlledSelect
        id={inputField.shipping}
        isLoading={isLoading}
        label="Shipping"
        options={ACCOUNT_ORDER_SHIPPING_OPTIONS}
        defaultValue={ACCOUNT_DEFAULTS.order.shipping}
      />

      <TextField
        id={inputField.slackNotificationChannel}
        isLoading={isLoading}
        label="Slack notification channel"
        placeholder="Provide the unique Slack channel name"
        icon={{
          position: 'start',
          value: TagIcon,
        }}
        setEmptyAsNull
        useHook
      />

      <TextEditor
        id={inputField.fulfillmentInstructions}
        label="Fulfillment instructions"
        placeholder="Manage instructions for fulfillment"
        isLoading={isLoading}
        modules={TEXT_EDITOR_MODULES}
        useHook
      />
    </Stack>
  </Card>
);
