import { useStore } from 'react-context-hook';
import { useSearchParams } from 'react-router-dom';

import { getQueryUrl, parseQueryURL } from '@utils/query';

export type ISearch = string;

/**
 * Returns default search values.
 */
export const getDefaultSearch = () => {
  const { q: searchQuery = '' } = parseQueryURL<{ q: ISearch }>() ?? {};

  return searchQuery;
};

/**
 * Returns hook for managing global search state.
 * @param {string} key - Key to identify the context store (defaults to pagination). Useful if you want to use multiple pagination hooks.
 */
export const useSearch = (key: string = 'search') => {
  const defaultValues = getDefaultSearch();
  const [_searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useStore<string>(key, defaultValues);

  const updateSearch = (updatedValue: string) => {
    setSearch(updatedValue);

    // Update URL with new pagination values. (appends to query - not replace)
    setSearchParams(getQueryUrl({ q: updatedValue }, { replace: false }));
  };

  return {
    search,
    updateSearch,
  };
};
