import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const UPDATE_CUSTOMER_ORDER_ASSIGNEE_MUTATION = gql`
  mutation updateCustomerOrder($_id: String!, $input: UpdateCustomerOrderInput!) {
    updateCustomerOrder(_id: $_id, input: $input) {
      customerOrder {
        _id
        orderNumber
        assignee {
          name
          userId
        }
      }
    }
  }
`;

/**
 * Hook for updating customer order assignee.
 * @param {object} [options] - Mutation hook options for GraphQL.
 */
export const useUpdateOrderAssignee = (options: MutationHookOptions = {}) => {
  const [updateAssigneeMutation, { error: apiError, loading: isLoading }] = useMutation<{
    updateCustomerOrder: { _id: string };
  }>(UPDATE_CUSTOMER_ORDER_ASSIGNEE_MUTATION, options);

  return { updateAssigneeMutation, isLoading, apiError };
};
