import { gql, useMutation } from '@apollo/client';
import { useDropDownMenu } from '@components/Button/Dropdown';
import { MenuItem } from '@components/MenuItem';
import { useSnackbar } from '@hooks/useSnackbar';
import { Download as ImportProductsIcon } from '@mui/icons-material';
import React from 'react';

type ActionsProps = {
  id: string;
};

const IMPORT_PRODUCTS = gql`
  mutation importProducts($input: ImportProductsInput!) {
    importProducts(input: $input) {
      result
    }
  }
`;

export const ImportProductsMenuItem = ({ id }: ActionsProps) => {
  const { handleClose } = useDropDownMenu();
  const { enqueueSnackbar } = useSnackbar();

  const [importProducts] = useMutation(IMPORT_PRODUCTS, {
    onCompleted: () => {
      enqueueSnackbar('Products for this merchant will start importing soon');
      handleClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      handleClose();
    },
    variables: {
      input: {
        id,
      },
    },
  });

  return <MenuItem action={importProducts} icon={ImportProductsIcon} label="Import products" />;
};
