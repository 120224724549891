import { gql, useQuery } from '@apollo/client';
import { Card } from '@components/Card';
import { Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { MerchantOrder } from '@common/types/GraphqlTypes.d';

import {
  FEED_ORDER_FIELD,
  FEED_ORDER_MODE,
  FEED_ORDER_MODE_DETAILS,
  IFeedOrderFormData,
} from '../types';
import { Details } from './Details';
import { PriceBreakdown } from './PriceBreakdown';
import { useStyles } from './styles';

const CUSTOMER_ORDER_MERCHANT_ORDERS_QUERY = gql`
  query ($_id: String!) {
    customerOrder(_id: $_id) {
      merchantOrderIds
    }
  }
`;

interface IOrderDetailsProps {
  formValidationHook: UseFormReturn<IFeedOrderFormData>;
  merchantOrder: MerchantOrder;
  mode: FEED_ORDER_MODE;
  onProcessFeedOrder: () => void;
}

export const OrderDetails: React.FC<IOrderDetailsProps> = ({
  formValidationHook,
  merchantOrder,
  mode,
  onProcessFeedOrder,
}) => {
  const classes = useStyles();
  const {
    watch,
    formState: { isValid },
  } = formValidationHook;

  const products = watch(FEED_ORDER_FIELD.PRODUCTS);
  const productQuantities = products.reduce((acc, { quantity }) => acc + quantity, 0);

  const { data, loading: isLoading } = useQuery(CUSTOMER_ORDER_MERCHANT_ORDERS_QUERY, {
    variables: { _id: merchantOrder?.customerOrderId },
  });

  return (
    <>
      <Typography variant="h6">Order Details</Typography>
      <Card className={classes.card} isLoading={isLoading}>
        <Details
          formValidationHook={formValidationHook}
          merchantOrderIds={data?.customerOrder?.merchantOrderIds}
        />
        <Divider className={classes.divider} />
        <PriceBreakdown formValidationHook={formValidationHook} />
        <Button
          className={classes.button}
          disabled={productQuantities === 0 || !isValid}
          onClick={onProcessFeedOrder}
          size="large"
          variant="contained"
        >
          {FEED_ORDER_MODE_DETAILS[mode].buttonText}
        </Button>
      </Card>
    </>
  );
};
