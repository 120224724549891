import { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledAutocompletePopper = styled(Box)(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

/**
 * @todo - Fix type!
 */
export const PopperComponent = ({ disablePortal, anchorEl, open, ...other }: any) => (
  <StyledAutocompletePopper {...other} />
);
