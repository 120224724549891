import '@elastic/react-search-ui-views/lib/styles/styles.css';

import { DropDownButton } from '@components/Button/Dropdown';
import { ProductCheckout } from '@components/Checkout';
import { Grid, Skeleton, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NotFound } from '@pages/Misc';
import { ProductBrand } from '@pages/Product/components/Brand';
import { ProductImages } from '@pages/Product/components/ImageCarousel';
import { ProductInventory } from '@pages/Product/components/Inventory';
import { ProductMerchant } from '@pages/Product/components/Merchant';
import { ProductName } from '@pages/Product/components/Name';
import { ProductPrice } from '@pages/Product/components/Price';
import { ProductSyncStatus } from '@pages/Product/components/SyncStatus';
import { ProductVariants } from '@pages/Product/components/Variants';
import React, { useState } from 'react';

import { Tabs } from '../../../components/Tabs';
import { ProductActions } from './Actions';
import { useProduct } from './graphql';

const useStyles = makeStyles(() => ({
  tabPanel: {
    padding: 0,
    margin: '15px 0',
  },
  tabBar: {
    marginTop: 20,
  },
  descriptionContent: {
    marginTop: 0,
  },
  merchant: {
    marginTop: 10,
  },
  variants: {
    marginTop: 10,
  },
  brand: {
    marginTop: 10,
  },
}));

type ViewProductProps = {
  product: {
    publicId: string;
    updatedAt: Date | null;
  };
};

/**
 * Skeleton loader matching basic layout.
 */
const showLoading = () => (
  <Grid container spacing={2}>
    <Grid item xs={4}>
      <Skeleton variant="rectangular" style={{ minHeight: '400px' }} />
    </Grid>
    <Grid item xs={8}>
      <Skeleton variant="rectangular" style={{ minHeight: '400px' }} />
    </Grid>
  </Grid>
);

export const ViewProduct = ({
  product: { publicId, updatedAt: externalUpdatedAt },
}: ViewProductProps) => {
  const classes = useStyles();

  // Dynamic based on variant selection
  const [price, setPrice] = useState<number>();
  const [inventory, setInventory] = useState<number>();

  // Track selected variant (needed for checkout)
  const [variantId, setVariantId] = useState<string>();

  // GraphQL hooks
  const { isLoading, data } = useProduct({
    variables: { input: { productId: publicId } },
    onError: (error) => {
      console.error('API error fetching product:', error);

      return <NotFound />;
    },
  });

  // Product could not be found after API call
  if (!data && !isLoading) {
    return <NotFound />;
  }

  // No data while still loading
  if (!data) {
    return showLoading();
  }

  // All necessary product information
  const {
    name,
    description,
    images,
    currency,
    brand,
    merchant,
    gender,
    productURL,
    variants,
    updatedAt,
    type: category,
    truthyValidationFlags: validationFlags,
  } = data;

  // TODO: Below causes errors during rendering. Fix it!!
  const onVariantChange = (variantId: string) => {
    const existingVariant = variants.find((variant) => variant.importVariantId === variantId);

    if (existingVariant) {
      setPrice(existingVariant.price);
      setInventory(existingVariant.inventory);
      setVariantId(variantId);
    }
  };

  // Tab menu
  const tabPanels = {
    Details: {
      component: (
        <div>
          <b>Description</b>
          <p
            className={classes.descriptionContent}
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
      ),
    },
    Checkout: {
      component: <ProductCheckout productPublicId={publicId} variantId={variantId} />,
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ProductImages images={images} />
      </Grid>

      <Grid item xs={8}>
        {/* Inventory & validation flags */}
        <div>
          {inventory !== undefined && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <ProductInventory inventory={inventory} validationFlags={validationFlags} />
              <DropDownButton menuItems={<ProductActions publicId={publicId} />} />
            </Stack>
          )}
        </div>
        {/* Brand information */}
        <div className={classes.brand}>
          <ProductBrand category={category} brand={brand.name} gender={gender} />
        </div>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          {/* Product name */}
          <ProductName publicId={publicId} name={name} validationFlags={validationFlags} />

          {/* Price information - Variant will always be selected hence min/max price are the same */}
          {price !== undefined && (
            <ProductPrice minPrice={price} maxPrice={price} currency={currency} />
          )}
        </Stack>
        {/* Merchant information */}
        <div className={classes.merchant}>
          <ProductMerchant id={merchant._id} name={merchant.name} url={productURL} />
        </div>
        {/* Variant information */}
        <div className={classes.variants}>
          <ProductVariants variants={variants} onVariantChange={onVariantChange} />
        </div>
        {/* Tab menu displaying description & checkout */}
        <Tabs
          tabPanels={tabPanels}
          tabBarClassName={classes.tabBar}
          tabPanelClassName={classes.tabPanel}
        />

        {/* Latest updatedAt status */}
        <div className="product-sync-status">
          <ProductSyncStatus updatedAt={Number(updatedAt)} externalUpdatedAt={externalUpdatedAt} />
        </div>
      </Grid>
    </Grid>
  );
};
