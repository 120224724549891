import { gql, useQuery, useMutation } from '@apollo/client';
import { withParams } from '@components/WithParams';
import { useAuth } from '@hooks/useAuthentication';
import { CUSTOMER_ORDER_QUERY } from '@hooks/useCustomerOrder';
import useRouter from '@hooks/useRouter';
import { Typography } from '@mui/material';
import { FeedOrderForm } from '@pages/Order/components/FeedOrderForm';
import { FEED_ORDER_MODE, IFeedOrderFormData } from '@pages/Order/components/FeedOrderForm/types';
import { Loading } from '@pages/Order/components/Loading';
import { FulfillmentStatus } from '@types';
import React from 'react';

import { filterZeroQuantityProducts } from '../utils/products';
import { useStyles } from './styles';

const MERCHANT_ORDER_QUERY = gql`
  query getOneMerchantOrder($input: MerchantOrderInput!) {
    getOneMerchantOrder(input: $input) {
      _id
      customerOrderId
      merchant {
        name
        integrationType
      }
      externalOrder {
        externalOrderId
        createExternalOrder {
          result
        }
      }
      ghostExternalOrders {
        _id
        products {
          productId
          quantity
        }
      }
      summary {
        subTotal
      }
      checkoutVariants {
        variant {
          id
          optionTitles
          optionValues
          images
          price
          priceUSD
          currency
          brand
        }
        _id
        name
        productId
        publicId
        purchaseQuantity
        productURL
      }
    }
  }
`;

const GHOST_EXTERNAL_ORDER_MUTATION = gql`
  mutation addGhostExternalOrder($input: GhostOrderInput!) {
    addGhostExternalOrder(input: $input) {
      status
      statusText
    }
  }
`;

type ICreateFeedOrderParams = { _id: string };

const CreateFeedOrder = ({ _id }: ICreateFeedOrderParams) => {
  const classes = useStyles();
  const { goto } = useRouter();

  const { data: merchantOrderData, loading: isLoading } = useQuery(MERCHANT_ORDER_QUERY, {
    variables: {
      input: { merchantOrderId: _id },
    },
  });

  const { currentUser } = useAuth();

  const [addGhostExternalOrder] = useMutation(GHOST_EXTERNAL_ORDER_MUTATION, {
    errorPolicy: 'all',
    onCompleted: () => {
      goto.viewOrder(merchantOrderData?.getOneMerchantOrder?.customerOrderId);
    },
    refetchQueries: [
      {
        query: MERCHANT_ORDER_QUERY,
        variables: {
          input: { merchantOrderId: _id },
        },
      },
      CUSTOMER_ORDER_QUERY,
    ],
  });

  const onSubmitFeedOrder = ({
    currency,
    discount,
    merchantOrderNumber,
    paymentMethod,
    products,
    shipping,
    subtotal,
    taxes,
    comments,
  }: IFeedOrderFormData) => {
    addGhostExternalOrder({
      variables: {
        input: {
          merchantOrderId: merchantOrderData?.getOneMerchantOrder?._id,
          order: {
            currency,
            discount,
            email: currentUser?.email,
            fulfillment: FulfillmentStatus.SENT_TO_MERCHANT,
            merchantOrderNumber: merchantOrderNumber.trim(),
            paymentMethod,
            products: filterZeroQuantityProducts(products),
            shipping,
            subtotal,
            taxes,
            comments,
          },
        },
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h5" className={classes.pageTitle}>
        Feed Order Form
      </Typography>
      <FeedOrderForm
        isLoading={isLoading}
        merchantOrder={merchantOrderData?.getOneMerchantOrder}
        mode={FEED_ORDER_MODE.CREATE}
        onProcessFeedOrder={onSubmitFeedOrder}
      />
    </>
  );
};

export const CreateFeedOrderPage = withParams<ICreateFeedOrderParams>(['_id'], CreateFeedOrder);
