import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  ProductCollection,
  QueryProductCollectionsArgs,
  QueryProductCollectionsCountArgs,
} from '@common/types/GraphqlTypes.d';

// Not all returned properties are necessarily fetched as graphQL might be exposing more.
// This matches the ones selected within PRODUCT_COLLECTIONS_QUERY.
export type IProductCollection = Pick<
  ProductCollection,
  '_id' | 'handle' | 'name' | 'productIds' | 'createdAt'
>;

export const PRODUCT_COLLECTIONS_QUERY = gql`
  query productCollections($input: ProductCollectionsInput!) {
    productCollections(input: $input) {
      _id
      handle
      name
      productIds
      createdAt
    }
  }
`;

export const PRODUCT_COLLECTIONS_COUNT_QUERY = gql`
  query productCollectionsCount($input: ProductCollectionsCountInput!) {
    productCollectionsCount(input: $input) {
      total
    }
  }
`;

/**
 * Hook for fetching product collections.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useProductCollections = (
  options: QueryHookOptions<
    { productCollections: IProductCollection[] },
    QueryProductCollectionsArgs
  >,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productCollections: IProductCollection[] }, QueryProductCollectionsArgs>(
    PRODUCT_COLLECTIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const { productCollections: data = [] } = apiResult ?? {};

  return { data, isLoading, apiError };
};

/**
 * Hook for fetching product collections total count.
 * @param {object} [options] - Mutation hook options for GraphQL.
 * @todo - Replace with `useTypedQuery`.
 */
export const useProductCollectionsCount = (
  options: QueryHookOptions<
    { productCollectionsCount: { total: number } },
    QueryProductCollectionsCountArgs
  >,
) => {
  const {
    data: apiResult,
    error: apiError,
    loading: isLoading,
  } = useQuery<{ productCollectionsCount: { total: number } }, QueryProductCollectionsCountArgs>(
    PRODUCT_COLLECTIONS_COUNT_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    },
  );

  const { total: data = 0 } = apiResult?.productCollectionsCount ?? {};

  return { data, isLoading, apiError };
};
