import { Logo } from '@components/Logo';
import useRouter from '@hooks/useRouter';
import TuneIcon from '@mui/icons-material/Tune';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Color } from '@theme/palette';
import React from 'react';

import { ColorToggle } from './ColorToggle';
import { TopMenu } from './Menu';
import { UserInfo } from './UserInfo';

type NavigationProps = {
  children: React.ReactNode;
};

const StyledContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

const StyledMain = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  minWidth: '997px',
  marginTop: '60px',
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  background: 'transparent',
  backgroundColor: theme.palette.navbar.main,
  borderBottom: `2px solid ${Color.Gray3}`,
  boxShadow: 'none',
  width: '100%',
  zIndex: 9,
}));

export const Navigation = ({ children }: NavigationProps) => {
  const { goto } = useRouter();

  return (
    <StyledContainer>
      <StyledAppBar>
        <CssBaseline />
        <Toolbar disableGutters sx={(theme) => ({ px: theme.spacing(4) })}>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            {/** Left side of navbar. */}
            <Stack direction="row" alignItems="center" gap={3}>
              <Logo height={18} onClick={() => goto.home()} sx={{ cursor: 'pointer' }} />
              <TopMenu />
            </Stack>

            {/** Right side of navbar. */}
            <Stack direction="row" gap={1} alignItems="center" sx={{ pr: 1 }}>
              <UserInfo />
              <ColorToggle />

              <Box sx={{ lineHeight: '0', cursor: 'pointer', color: Color.Black }}>
                <IconButton onClick={() => goto.preferences()}>
                  <TuneIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>

      <StyledMain>{children}</StyledMain>
    </StyledContainer>
  );
};
