import { gql } from '@apollo/client';

export const setFulfillmentStatusMutation = gql`
  mutation setFulfillmentStatus($input: MerchantOrderInput!) {
    setFulfillmentStatus(input: $input) {
      result
    }
  }
`;

export const SEND_EXTERNAL_ORDER_MUTATION = gql`
  mutation sendMerchantOrderToExternalService($input: MerchantOrderInput!) {
    sendMerchantOrderToExternalService(input: $input) {
      result
    }
  }
`;

export const SEND_MERCHANT_PAYMENT_MUTATION = gql`
  mutation sendMerchantTransfer($input: MerchantOrderInput!) {
    sendMerchantTransfer(input: $input) {
      result
      error
    }
  }
`;
