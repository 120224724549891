/* eslint-disable complexity */
import { useAuth } from '@hooks/useAuthentication';
import { QUICK_FILTER_KEY } from '@hooks/useDataStore/useFilters';
import { usePath } from '@hooks/usePath';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { IRoute } from '@routes/interfaces';
import { routes as privateRoutes } from '@routes/private';
import { routes as publicRoutes } from '@routes/public';
import React, { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isFeatureEnabled } from '@lib/launchdarkly';
import { getUniqueId } from '@utils/id';
import { hasPermission } from '@utils/permission';

const routes = { ...publicRoutes, ...privateRoutes };

export type MenuItemProps = Omit<ListItemButtonProps, 'title'> & {
  /**
   * Title of the menu item.
   */
  title: string | JSX.Element;

  /**
   * Pass entire route to allow automatic permissions & avoid typos.
   */
  route: IRoute;

  /**
   * Optional quick filter to be appended to the URL query.
   */
  quickFilter?: string;

  /**
   * Optional orientation of the menu item.
   */
  orientation?: 'horizontal' | 'vertical';

  /**
   * Handle click event.
   * Useful for nested menu items handling extra.
   */
  handleClick?: () => void;

  /**
   * Indicates if the menu item is active.
   * Send from parent to allow parent menu to be highlighted when child is active.
   */
  isActive?: boolean;
};

export type MenuItemGroupProps = Partial<MenuItemProps> & {
  /**
   * List of menu items for submenu.
   */
  items?: MenuItemProps[];
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  paddingRight: '10px',
  '& .MuiList-root': {
    backgroundColor: theme.palette.navbar.main,
    color: theme.palette.navbar.contrastText,
  },
}));

/**
 * Returns a single menu item.
 */
export const MenuItem = ({
  route,
  title,
  quickFilter,
  handleClick,
  isActive = false,
  ...muiProps
}: MenuItemProps) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  // const { pathname } = useLocation();

  // Find matching route from defined list of routes
  const { path, permission, featureFlag } = routes[route] || {};

  // Optional feature flag rendering
  if (featureFlag && !isFeatureEnabled(featureFlag)) {
    return null;
  }

  // Optional permission check for logged in user
  if (!hasPermission(currentUser, permission)) {
    return null;
  }

  // Handle navigation
  const onClick = () => {
    const url = quickFilter ? `${path}?${QUICK_FILTER_KEY}=${quickFilter}` : path;

    // Inform parent component about the click
    if (handleClick) {
      handleClick();
    }

    navigate(url);
  };

  return (
    <ListItemButton
      dense
      onClick={onClick}
      sx={(theme) => ({
        borderRadius: '6px',
        backgroundColor: isActive ? theme.palette.navbar.light : 'inherit',
        '&:hover': {
          backgroundColor: theme.palette.navbar.light,
        },
      })}
      {...muiProps}
    >
      <ListItemText
        primary={title}
        sx={(theme) => ({ color: theme.palette.navbar.contrastText, fontWeight: 600 })}
      />
    </ListItemButton>
  );
};

/**
 * Return a group of menu items.
 * Allows nesting of submenus. Currently only supports 1 level of nesting.
 */
export const MenuItemGroup = ({ items = [], ...props }: MenuItemGroupProps) => {
  const id = getUniqueId();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { isActive, hasActivePath } = usePath();

  const isOpen = Boolean(anchorElement);

  // Find matching route from defined list of routes
  if (items.length === 0 && props.route && props.title) {
    return (
      <MenuItem
        {...(props as MenuItemProps)}
        orientation="horizontal"
        isActive={isActive(props.route, props.quickFilter)}
      />
    );
  }

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleOpen = (event: MouseEvent<HTMLDivElement> | undefined) => {
    if (event && anchorElement !== event.currentTarget) {
      setAnchorElement(event.currentTarget);
    }
  };

  /**
   * Returns submenu title including expand / collapse icon.
   */
  const getTitle = () => (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={0.5}>
      {props.title}
      <Box sx={{ lineHeight: 1, fontWeight: 400 }}>
        <ExpandMoreIcon sx={{ verticalAlign: 'middle', fontSize: '1.3em' }} />
      </Box>
    </Stack>
  );

  /**
   * Checks whether any of the child menu items are active.
   * Sets parent as active if any of the children are active.
   */
  const hasActiveSubPath = hasActivePath(items.map(({ route }) => route));

  return (
    <>
      <MenuItem
        {...(props as MenuItemProps)}
        title={getTitle()}
        orientation="horizontal"
        aria-expanded={isOpen ? 'true' : undefined}
        aria-owns={anchorElement ? id : undefined}
        onClick={handleOpen}
        isActive={hasActiveSubPath}
      />
      <StyledMenu id={id} open={isOpen} anchorEl={anchorElement} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem
            {...item}
            handleClick={handleClose}
            key={`submenu_${index}_${item.route}`}
            orientation="vertical"
            isActive={isActive(item.route, item.quickFilter)}
          />
        ))}
      </StyledMenu>
    </>
  );
};
