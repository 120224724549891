import { TimelineOppositeContent } from '@mui/lab';
import React from 'react';

import { getMostRecentDate, areDatesEqual, toFormattedDate } from '@utils/date';

type DateComponentProps = {
  createdAt: string;
  allCreatedAtDates: string[];
};

/**
 * Renders the date of the current item if it is the most recent date within that day.
 * @param props.createdAt - The date of the current item.
 * @param props.allCreatedAtDates - All the dates of the items.
 */
export const DateComponent = ({ allCreatedAtDates, createdAt }: DateComponentProps) => {
  const createdAtDate = new Date(createdAt);
  const mostRecentDate = getMostRecentDate(
    createdAtDate,
    allCreatedAtDates.map((d) => new Date(d)),
  );

  if (areDatesEqual(createdAtDate, mostRecentDate)) {
    return (
      <TimelineOppositeContent
        sx={{ pl: 0, flex: 0 }}
        variant="body2"
        color="text.secondary"
        fontSize="x-small"
      >
        {toFormattedDate(createdAtDate, { includeTime: false })}
      </TimelineOppositeContent>
    );
  }

  return null;
};
