import { StatusTag } from '@components/StatusTag';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IProcessedOrder } from '@pages/Order/View/OrderedItems/interfaces';
import currencyJs from 'currency.js';
import React from 'react';

import { statusToColor, statusToTitle } from './paymentStatus';
import { useStyles } from './styles';

interface IOrderDetailsProps {
  order: IProcessedOrder;
}

const PriceContainer = ({ children }: { children?: React.ReactNode }) => (
  <Stack direction="row" justifyContent="space-between">
    {children}
  </Stack>
);

const StyledText = styled(Typography)(() => ({
  gutterBottom: true,
  fontSize: 'small',
}));

export const PriceRow = ({
  title,
  price,
  fontWeight = 'normal',
}: {
  title: string;
  price: number;
  fontWeight?: string | number;
}) => (
  <PriceContainer>
    <StyledText sx={{ fontWeight }}>{title}</StyledText>
    <StyledText sx={{ fontWeight }}>{`$${price.toFixed(2)}`}</StyledText>
  </PriceContainer>
);

export const PaymentDetails = ({ order }: IOrderDetailsProps) => {
  const classes = useStyles();

  const { paymentStatus, subtotal, shipping, taxes, discount, payment, duties } = order;

  const { value: totalCost } = currencyJs(subtotal)
    .add(shipping)
    .add(taxes)
    .add(duties)
    .subtract(discount);

  return (
    <Grid container>
      <Grid item xs={5}>
        <Stack direction="column" gap={2}>
          <Stack direction="column" gap={0.5} alignItems="flex-start">
            <StyledText sx={{ fontWeight: 600 }}>Status</StyledText>
            <StatusTag color={statusToColor(paymentStatus)} text={statusToTitle(paymentStatus)} />
          </Stack>
          <Stack direction="column" gap={0.5}>
            <StyledText sx={{ fontWeight: 600 }}>Payment Method</StyledText>
            <StyledText>{payment}</StyledText>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={7}>
        <StyledText sx={{ fontWeight: 600 }}>Payment Summary</StyledText>
        <PriceRow title="Subtotal" price={subtotal} />
        <PriceRow title="Shipping" price={shipping} />
        <PriceRow title="Taxes" price={taxes} />
        <PriceRow title="Duties" price={duties} />
        <PriceRow title="Discount" price={discount} />
        <Divider className={classes.divider} />
        <PriceRow title="Total" price={totalCost} fontWeight={700} />
      </Grid>
    </Grid>
  );
};
