import { MarkProductUnavailableItem } from '@pages/Order/components/Product/menuItems/HandleProductAvailability';
import { OpenProductInProductUI } from '@pages/Order/components/Product/menuItems/ViewProduct';
import React from 'react';

type ActionsProps = {
  publicId: string;
};

export const ProductActions = ({ publicId }: ActionsProps) => (
  <>
    <MarkProductUnavailableItem productPublicId={publicId} />
    <OpenProductInProductUI productPublicId={publicId} />
  </>
);
