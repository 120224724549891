import { StatusTag, StatusTagType } from '@components/StatusTag';
import { Flag as FlagIcon } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { Color } from '@theme/palette';
import React from 'react';

export type ProductInventoryProps = {
  inventory: string | number;
  validationFlags?: string[];
};

const statusTagConditions = (inventory: number, isOutOfStock?: boolean) => [
  { type: StatusTagType.Error, match: () => isOutOfStock === true },
  { type: StatusTagType.Warn, match: () => inventory <= 5 },
  { type: StatusTagType.Success, match: () => inventory > 6 && isOutOfStock !== true },
];

/**
 * Displays inventory level & optional validation flags.
 * @todo - Make validation flags match designs instead of tool incl. Mapping to readable strings.
 */
export const ProductInventory = ({ inventory, validationFlags = [] }: ProductInventoryProps) => {
  const inventoryLevel = typeof inventory === 'number' ? inventory : Number.parseInt(inventory, 10);
  const isOutOfStock = inventoryLevel <= 0;

  // Colour for labels
  const tagConditions = statusTagConditions(inventoryLevel, isOutOfStock);

  // Issues
  const isFlagged = validationFlags.length > 0;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {/* Issue flags */}
      {isFlagged && (
        <Tooltip title={validationFlags.toString()}>
          <FlagIcon style={{ width: '1em', color: Color.ErrorRed }} />
        </Tooltip>
      )}

      {/* Inventory */}
      <span style={{ textAlign: 'center' }}>
        <StatusTag
          text={isOutOfStock ? 'Out of stock' : `${inventory} units`}
          conditions={tagConditions}
        />
      </span>
    </Stack>
  );
};
