import { NotFound } from '@pages/Misc';
import { all } from 'ramda';
import React from 'react';
import { useParams } from 'react-router';

type ObjectKey = string | number | symbol;

/**
 * Checks urlParams for a given key.
 * @param urlParams - UrlParams from useParams hook.
 * @returns {boolean} - If the key exists.
 */
const paramsHasKey = (urlParams: Partial<Record<string, string>>) => (key: ObjectKey) =>
  urlParams.hasOwnProperty(key);
/**
 * Checks that all keys exist in the urlParams object.
 * @param urlParams - Object containing params from useParams hook.
 * @param keys - Keys to check for in urlParams.
 * @returns {boolean} - If all keys exist.
 */
const hasAllParams = (urlParams: Partial<Record<string, string>>, keys: ObjectKey[]): boolean =>
  all<ObjectKey>(paramsHasKey(urlParams), keys);

/**
 * Higher order function which handles getting values from the useParams hook.
 * Will validate that all params in the paramKeys list exist and return an error page if they do not.
 * @param paramKeys - List of params the wrapped component will recieve.
 * @param WrappedComponent - Component which will recieve params.
 * @returns - Component function with url params added or error.
 */
export const withParams = function <T extends Record<string, string>, K = Record<string, never>>(
  paramKeys: (keyof T)[],
  WrappedComponent: React.ComponentType<T & K>,
): (componentParams: K) => JSX.Element {
  return (componentParams: K) => {
    const urlParams = useParams<T>();

    return hasAllParams(urlParams, paramKeys) ? (
      <WrappedComponent {...(urlParams as T)} {...componentParams} />
    ) : (
      <NotFound />
    );
  };
};
