import { Header } from '@components/Header';
import useRouter from '@hooks/useRouter';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React from 'react';

import { PrimaryButton } from '../../../components/Button/Primary';
import { ProductCollectionActions } from './Actions';
import { IProductCollection } from './graphql';

type ActionsProps = {
  collection?: IProductCollection;
  isLoading: boolean;
};

export const ViewProductCollectionHeader = ({ collection, isLoading }: ActionsProps) => {
  const { goto } = useRouter();
  const { _id } = collection || {};

  return (
    <Header
      title={`View collection - ${collection?.name}`}
      isLoading={isLoading}
      actions={collection && <ProductCollectionActions collection={collection} />}
      buttons={
        _id && (
          <PrimaryButton
            onClick={() => goto.editProductCollection(_id)}
            startIcon={<ModeEditIcon />}
          >
            Edit collection
          </PrimaryButton>
        )
      }
    />
  );
};
