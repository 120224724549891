import { AttachMoney, Bolt, Check, LocalShipping, Send, Error } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import React from 'react';

import { OrderHistoryStatus } from '@common/types/GraphqlTypes.d';

import { eventType, TEventType } from './interfaces';

/**
 * Renders an icon for the given event type.
 * @param props.type - The type of the event.
 */
export const EventIcon = ({
  type,
  status,
  ...otherProps
}: { type: TEventType; status: OrderHistoryStatus } & SvgIconProps) => {
  if (status === OrderHistoryStatus.Error) {
    return <Error {...otherProps} color="error" />;
  }
  switch (type) {
    case eventType.OrderFulfilledEvent: {
      return <Check {...otherProps} />;
    }
    case eventType.PaymentEvent: {
      return <AttachMoney {...otherProps} sx={{ pl: '1px' }} />;
    }
    case eventType.OrderReceivedEvent: {
      return <Bolt {...otherProps} />;
    }
    case eventType.EmailEvent: {
      return <Send {...otherProps} sx={{ pt: '1px', pl: '2px' }} />;
    }
    case eventType.ShippingEvent: {
      return <LocalShipping {...otherProps} sx={{ pt: '1px', pl: '1px' }} />;
    }
    default: {
      return null;
    }
  }
};
