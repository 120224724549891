import { PrimaryButton } from '@components/Button/Primary';
import { SecondaryButton } from '@components/Button/Secondary';
import { Form } from '@components/Form';
// import { useUploadImage } from '@hooks/useImageUpload';
import useRouter from '@hooks/useRouter';
import { useSnackbar } from '@hooks/useSnackbar';
import { Grid, Stack } from '@mui/material';
import * as R from 'ramda';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BasicInformation } from '../components/Basic';
import { BillingConfig } from '../components/BillingConfig';
import { EmailConfig } from '../components/EmailProvider';
import { OrderConfig } from '../components/OrderConfig';
import { Preferences } from '../components/Preferences';
import { IAccount } from '../fields';
import { useCreateAccount } from './graphql';
import { CreateAccountHeader } from './Header';
import { toInputValues } from './utils';

export const CreateAccount = () => {
  const { goto } = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  // Set up React form hook
  const form = useForm<IAccount>({
    mode: 'onSubmit',
  });

  const { createAccountMutation, apiError: createApiError } = useCreateAccount();
  // const { uploadImage } = useUploadImage();

  /**
   * Submit form values.
   * Includes file uploading.
   * @todo - Fix `asAccount` casting for setting footer & header.
   */
  const onSubmit = (values: IAccount) => {
    // 1. Upload images
    const defaultImageUrl = 'https://picsum.photos/200';

    const logo = defaultImageUrl;
    const footer = defaultImageUrl;
    const favicon = null;
    const cartTitleImage = null;

    // ⚠️ Sharpener is not available to upload images
    // Please update image in db after creating the account
    // const logo = await uploadImage('logo', values.config.logo);
    // const footer = await uploadImage('footer', values.config.footer);
    // const favicon = await uploadImage('favicon', values.config.favicon);
    // const cartTitleImage = await uploadImage('cartTitleImage', values.config.cartTitleImage);

    // TODO: Once FileInput has validation, this will not be needed anymore
    if (!logo || !footer) {
      return enqueueSnackbar('Header & footer are required', { variant: 'error' });
    }

    const createValues = toInputValues(
      R.mergeDeepRight(values, {
        config: { logo, footer, favicon, cartTitleImage },
      }) as IAccount,
    );

    // 2. Create account
    createAccountMutation({
      variables: { input: createValues },
      onCompleted: ({ createAccount }) => {
        goto.editAccount(createAccount.publicId);
      },
      onError: (error) => {
        // Error snackbar gets automatically rendering through `error={apiError}` in Form component.
        // Handling onError is required to avoid Promise from timing out
        console.error('API error creating account:', error);
      },
    });
  };

  const onCancel = () => {
    goto.listAccounts();
  };

  return (
    <Stack gap={3}>
      <CreateAccountHeader />

      <FormProvider {...form}>
        <Form error={createApiError} onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8}>
              <Stack gap={3}>
                <BasicInformation />
                <BillingConfig />
                <OrderConfig />
                <EmailConfig />
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Preferences />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Create account</PrimaryButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Stack>
  );
};
