import { StatusTag } from '@components/StatusTag';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Color } from '@theme/palette';
import React from 'react';

import { useStyles } from '../styles';

interface IItemStatusProps {
  statusText: string;
  statusColor: string;
  numberOfItems: number;
}

const StatusCircle = styled(Box)(() => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
}));

export const ItemStatus = ({ statusText, statusColor, numberOfItems }: IItemStatusProps) => {
  const classes = useStyles();

  return (
    <Stack gap={1} className={classes.statusContainer}>
      <StatusCircle bgcolor={statusColor}></StatusCircle>
      <StatusTag
        text={numberOfItems.toString()}
        backgroundColor={Color.DarkNight}
        color={Color.White}
      >
        {statusText}
      </StatusTag>
    </Stack>
  );
};
