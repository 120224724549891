import { gql } from '@apollo/client';

export const CLIENTS_AUTOCOMPLETE_QUERY = gql`
  query clients($input: ClientsInput!) {
    clients(input: $input) {
      _id
      name
    }
  }
`;
